import {
  PRODUCT_REQUEST_LOADING,
  GET_PRODUCT_REQUESTS,
  GET_REQUEST_DETAIL,
  UPDATE_REQUEST,
  UPDATE_FILTER_PS,
  SET_REQUEST_ELIGIBILITY,
  CACHE_PRODUCTS,
} from 'shared/constants/ActionTypes';
import { loadingKeys } from 'shared/constants/AppsContants';

import { LoadingKeyDetail } from '../../pages/apps/ProductUsage/constants';
const initialState = {
  requests: [],
  selectedRequest: {},
  totalRequest: 0,
  loading: {
    [loadingKeys.getList]: false,
    [LoadingKeyDetail]: false
  },
  filters: null,
  requestEligibility: null,
  cachedProducts: {},
};

const pointsSystemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PRODUCT_REQUEST_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.value,
        },
      };
    case GET_PRODUCT_REQUESTS: {
      return {
        ...state,
        requests: payload?.Items || [],
        totalRequest: payload?.TotalCount || 0,
      };
    }
    case GET_REQUEST_DETAIL:
      return {
        ...state,
        selectedRequest: payload,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        selectedRequest: payload,
      };
    case UPDATE_FILTER_PS:
      return {
        ...state,
        filters: payload,
      }
    case SET_REQUEST_ELIGIBILITY:
      return {
        ...state,
        requestEligibility: payload,
      };
    case CACHE_PRODUCTS:
      const cachedKey = state.cachedProducts[payload.key] || [];
      return {
        ...state,
        cachedProducts: Object.assign(
          state.cachedProducts,
          {
            ...state.cachedProducts,
            [payload.key]: [
              ...cachedKey,
              ...payload.value
            ],
          },
        )
      };

    default:
      return state;
  }
};

export default pointsSystemReducer;
