import jwtAxios from '@tenant/services/auth/jwt-auth';
import React, { useCallback } from 'react';
import { CONTRACT_ENDPOINT } from '../contants/endpoints';

const useContractService = () => {
  const getUsersByTenantId = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.get(
          CONTRACT_ENDPOINT.GET_USERS +
            `?TenantId=${payload?.TenantId}&IsPrimary=false`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const editStripeMaxUsers = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.post(
          CONTRACT_ENDPOINT.EDIT_STRIPE_MAX_USERS,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const markRemovalLicense = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.post(
          CONTRACT_ENDPOINT.MARK_REMOVAL_LICENSE,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );
  const lockoutContract = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { contractId } = payload ?? {};
        const { data } = await jwtAxios.post(
          CONTRACT_ENDPOINT.LOCKOUT_CONTRACT + `?contractId=${contractId}`,
        );
        onSuccess?.(data);
      } catch (err) {
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const getUserLicenseAudit = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.get(
          CONTRACT_ENDPOINT.GET_USER_LICENSE_AUDIT,
          { params: payload },
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const checkHasPendingIncreaseRequest = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.get(
          CONTRACT_ENDPOINT.HAS_PENDING_INCREASE_REQUEST +
            `/${payload.contractId}`,
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const getEditUserPreview = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.get(
          CONTRACT_ENDPOINT.GET_EDIT_USER_PREVIEW + `/${payload.contractId}`,
          {
            params: {
              licenseCount: payload?.licenseCount,
              tenantId: payload?.tenantId,
              increaseCount: payload.increaseCount,
            },
          },
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  const getEditUserPreviewWithDiscount = useCallback(
    async ({ payload, onSuccess, onError, onFinally }) => {
      try {
        const { data } = await jwtAxios.get(
          CONTRACT_ENDPOINT.GET_EDIT_USER_PREVIEW_WITH_DISCOUNT + `/${payload.contractId}`,
          {
            params: {
              tenantId: payload?.tenantId,
              increaseCount: payload.increaseCount,
              discountAmount: payload?.discountAmount,
              discountPercent: payload?.discountPercent,
            },
          },
        );
        onSuccess?.(data);
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        onFinally?.();
      }
    },
    [],
  );

  return {
    getUsersByTenantId,
    editStripeMaxUsers,
    markRemovalLicense,
    getUserLicenseAudit,
    checkHasPendingIncreaseRequest,
    lockoutContract,
    getEditUserPreview,
    getEditUserPreviewWithDiscount,
  };
};

export default useContractService;
