import React from 'react';

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className='CirecleBullet'
      style={{
        backgroundColor,
        width: size,
        height: size,
      }}
    ></div>
  );
};

export default Bullet;