import jwtAxios from '../../@tenant/services/auth/jwt-auth';
import { API_ENDPOINT } from '../../pages/apps/Company/contants/endpoints';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONTRACT_ITEMS,
  GET_LIST_COMPANIES,
  GET_LIST_COMPANIES_LOADING,
  GET_PAYMENT_TERMS,
  IS_INIT,
  LIST_INVOICE_LINE_EDIT,
  SET_COMPANY_CONTRACTS,
  SET_CURR_COMPANY,
  SET_CUR_CONTRACT,
  SET_CUR_TENANT,
  SET_CUR_TENANT_ID,
  SET_FILTER_COMPANIES_LIST,
  SET_IS_INIT_TRANSACTIONS,
  SET_PACKAGE_INFO,
  SET_PRECONDITION,
  SET_PURCHASE_INVOICES,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_PARAMS,
  SET_TRANSACTION_FILTERS,
  SET_INCREASE_MAX_USER_NUMBER,
  SET_IS_MULTIPLE_TENANTS,
  SET_CONTRACT_SUMMARY,
} from '../../shared/constants/ActionTypes';
import { appIntl, getPropertyByField } from '@tenant/utility/helper/Utils';
import { processInputFilterCompanyList } from 'pages/apps/Company/helpers/helper';
import IntlMessages from '@tenant/utility/IntlMessages';
import { RANGE_DATE } from 'pages/tenantDetail/constants';

export const getContractLineItems = () => {
  const { messages } = appIntl();

  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    const response = await jwtAxios.get(API_ENDPOINT.GET_CONTRACT_ITEMS);

    if (response.status === 200) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_CONTRACT_ITEMS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};

export const getPaymentTerms = () => {
  const { messages } = appIntl();

  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    const response = await jwtAxios.get(API_ENDPOINT.GET_LIST_PAYMENT_TERM);

    if (response.status === 200) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_PAYMENT_TERMS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};

export const getListCompany = (params) => {
  return async (dispatch) => {
    return jwtAxios
      .get(API_ENDPOINT.GET_LIST_COMPANY, {
        params,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: SET_FILTER_COMPANIES_LIST,
            payload: { ...params, totalCount: data.data.TotalCount ?? 0 },
          });
          dispatch({
            type: GET_LIST_COMPANIES,
            payload: getPropertyByField(data, 'data.Items'),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getListCompanyFilter = (params, callback) => {
  const payloadFilter = processInputFilterCompanyList(params);

  return async (dispatch) => {
    dispatch({
      type: GET_LIST_COMPANIES_LOADING,
      payload: true,
    });
    return jwtAxios
      .post(API_ENDPOINT.GET_LIST_COMPANY_FILTER, payloadFilter)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: SET_FILTER_COMPANIES_LIST,
            payload: { ...params, totalCount: data.data.TotalCount ?? 0 },
          });
          dispatch({
            type: GET_LIST_COMPANIES,
            payload: getPropertyByField(data, 'data.Items'),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      })
      .finally(() => {
        dispatch({
          type: GET_LIST_COMPANIES_LOADING,
          payload: false,
        });
      });
  };
};

export const setPackageInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_PACKAGE_INFO,
      payload,
    });
  };
};

export const setCurrentTenant = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUR_TENANT_ID,
      payload,
    });
  };
};

export const setCurrentContract = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUR_CONTRACT,
      payload,
    });
  };
};

export const setCurrentValueTenant = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUR_TENANT,
      payload,
    });
  };
};

export const setSavePrecondition = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRECONDITION,
      payload,
    });
  };
};

export const setListIdInvoiceLine = (payload) => {
  return (dispatch) => {
    dispatch({
      type: LIST_INVOICE_LINE_EDIT,
      payload,
    });
  };
};

export const setCompanyContracts = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY_CONTRACTS,
      payload,
    });
  };
};

export const setCurrentCompany = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURR_COMPANY,
      payload,
    });
  };
};

export const setPurchaseInvoices = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_PURCHASE_INVOICES,
      payload,
    });
  };
};

export const setIsInit = (payload) => {
  return (dispatch) => {
    dispatch({
      type: IS_INIT,
      payload,
    });
  };
};

export const resetStorageTransactions = () => {
  return (dispatch) => {
    dispatch({
      type: SET_TRANSACTION_FILTERS,
      payload: {
        tenants: [],
        transactionTypes: [],
      }
    });

    dispatch({
      type: SET_TRANSACTIONS_PARAMS,
      payload: {
        pageIndex: 1,
        pageSize: 10,
        rangeDate: RANGE_DATE.THIS_YEAR,
      }
    });

    dispatch({
      type: SET_TRANSACTIONS,
      payload: [],
    });

    dispatch({
      type: SET_IS_INIT_TRANSACTIONS,
      payload: true,
    });
  }
};

export const setMaxUserNumber = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_INCREASE_MAX_USER_NUMBER,
      payload,
    });
  };
};

export const setIsMultipleTenants = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_MULTIPLE_TENANTS,
      payload,
    });
  };
}

export const setContractSummary = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CONTRACT_SUMMARY,
      payload,
    });
  };
}
