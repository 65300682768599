import React from 'react';
import AppTooltip from '@tenant/core/AppTooltip';
import {useIntl} from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from '@mui/material';

const TOOLTIP_KEY_INT = 'analytics.publicAPI.tooltip';

const DescriptionTooltip = () => {
  const {messages} = useIntl();
  return (
    <AppTooltip
      arrow
      title={
        <Box>
          <Box component='p'>{messages[`${TOOLTIP_KEY_INT}.caption`]}</Box>
          <Box fontSize={13}>
            <Box component='strong' mr={1}>
              - Success:
            </Box>
            <Box component='span'>The total response is a successful. </Box>
          </Box>
          <Box fontSize={13}>
            <Box component='strong' mr={1}>
              - Failure:
            </Box>
            <Box component='span'>The total response is a failure. </Box>
          </Box>
          <Box fontSize={13}>
            <Box component='strong' mr={1}>
              - Rate Limit:
            </Box>
            <Box component='span'>
              The total response is a refusal to authorize.
            </Box>
          </Box>
        </Box>
      }
    >
      <InfoOutlinedIcon sx={{fontSize: 20}} />
    </AppTooltip>
  );
};

export default React.memo(DescriptionTooltip);

DescriptionTooltip.propTypes = {};
