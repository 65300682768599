import {useState} from 'react';

import jwtAxios from '@tenant/services/auth/jwt-auth';

const useVehicleTrackingInfo = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const getDetail = async (id, callback) => {
    try {
      if (!id) {
        return;
      }
      setIsLoadingData(true);
      const {data} = await jwtAxios.get(`api/DeviceInfo/${id}`);
      callback?.(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  return {
    getDetail,
    isLoadingData,
  };
};

export default useVehicleTrackingInfo;
