import React, { useState, useMemo, useCallback, isValidElement, } from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';

import { appIntl } from '@tenant/utility/helper/Utils';


const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginLeft: -8,
  width: 57,
  height: 37,
  left: 5,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    marginLeft: 2,
    marginTop: 1,

  },
}));

const Toggle = ({
  checked,
  confirmationContent,
  requiredConfirm = false,
  disabled = false,
  handleChange,
  ...rest
}) => {
  const { messages } = appIntl();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const onChangeSwitch = (e) => {
    if (requiredConfirm) {
      handleOpenDialog();
      return;
    }

    handleCloseDialog();
    handleChange(e.target.checked);
  };

  return (
    <>
      <StyledSwitch
        disabled={disabled}
        checked={checked}
        onChange={onChangeSwitch}
        {...rest}
      />

      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ fontSize: 20 }} id='draggable-dialog-title'>
          Confirm
        </DialogTitle>
        <DialogContent>
          {isValidElement(confirmationContent) ? (
            confirmationContent
          ) : (
            <DialogContentText>
              {messages['tenant.confirmationContent']}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            No
          </Button>
          <Button onClick={() => {
            handleCloseDialog();
            handleChange(!checked);
          }} variant='contained'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>



  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  requiredConfirm: PropTypes.bool,
  confirmationContent: PropTypes.node,
  handleChange: PropTypes.func.isRequired,
};

export default React.memo(Toggle);

