import mock from '../MockConfig';
import orders from '@tenant/services/db/bundles/orders';
import orderDetails from '@tenant/services/db/bundles/orderDetails';

mock.onGet('/api/Bundle/Orders').reply(200, orders);

mock.onGet('/api/Bundle/Orders/3304').reply(200, orderDetails);




