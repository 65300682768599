import { DEFAULT_DATE_RANGE_DASHBOARD } from '@tenant/utility/constants/default';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import moment from 'moment';
import { inputFormat } from 'pages/tenantDetail/constants';
import {
  GET_SUBCONTRACTOR_TENANT_TREND,
  SET_FILTER_SUBCONTRACTOR_TENANT_TREND,
} from 'shared/constants/ActionTypes';

const { start, end, dateGroupType } = getRangeDateByKeyName(
  DEFAULT_DATE_RANGE_DASHBOARD,
);

const initialState = {
  data: [],
  dataRange: DEFAULT_DATE_RANGE_DASHBOARD,
  endDate: new moment(end, inputFormat).toISOString(),
  startDate: new moment(start, inputFormat).toISOString(),
  dateGroupType: dateGroupType,
};

const subcontractorTenantTrendReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCONTRACTOR_TENANT_TREND: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case SET_FILTER_SUBCONTRACTOR_TENANT_TREND: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default subcontractorTenantTrendReducer;
