import React, { isValidElement, } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const EmphasicContent = ({
  content,
  color,
  ...rest
}) => (
  <Typography
    component='span'
    sx={{
      fontWeight: 700,
      color: color,
    }}
    {...rest}
  >
    {content}
  </Typography>
);

EmphasicContent.propTypes = {};

export default React.memo(EmphasicContent);