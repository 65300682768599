import React, { useCallback, useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { MenuItem, } from '@mui/material';

import useBundle from '../../hooks/useBundle';
import IntlMessages from '@tenant/utility/IntlMessages';
import { BUNDLE } from '../../constants';
import useTenant from 'pages/apps/hooks/useTenant';
import { appIntl } from '@tenant/utility/helper/Utils';

const EarlyAdoption = ({
  sxStyle,
  contractId,
  showInMenu = false,
  handleSuccess,
  handleClickItem,
}) => {
  const {
    purchasePoints,
    isSubmittingForm,
  } = useBundle();
  const {
    loadingBtnAdoption,
    checkEarlyAdoption,
  } = useTenant();
  const { messages } = appIntl();
  const [isEarlyAdoptionAvailable, setIsEarlyAdoptionAvailable] = useState(false);

  const onClickEarlyAdoption = useCallback(() => {
    const payload = {
      ContractId: contractId,
      Note: '',
      BundlePurchases: [
        {
          BundleId: BUNDLE.EarlyAdoption,
          Points: 0
        }
      ],
    };

    purchasePoints({
      payload,
      successMsg: messages['message.getAdoptionSuccess'],
      cbSuccess: () => {
        setIsEarlyAdoptionAvailable(false);
        handleSuccess?.(false);
      },
      cbError: () => {
        setIsEarlyAdoptionAvailable(true);
      }
    });
  }, [contractId]);

  useEffect(() => {
    // Check the availability of early adoption on the tenant detail screen
    !!contractId && checkEarlyAdoption(contractId, (data) => {
      setIsEarlyAdoptionAvailable(data?.IsEarlyAdoptionAvailable || false);
    });
  }, [contractId]);

  return (
    showInMenu ? (
      <MenuItem
        onClick={() => handleClickItem?.()}
      >
        <IntlMessages id='bundleContract.getEarlyAdoption' />
      </MenuItem>
    ) : (
      <LoadingButton
        sx={{
          color: 'white',
          ...sxStyle,
        }}
        loading={isSubmittingForm || loadingBtnAdoption}
        onClick={onClickEarlyAdoption}
        variant='contained'
        disabled={!isEarlyAdoptionAvailable}
        color="success"
      >
        <IntlMessages id='bundleContract.getEarlyAdoption' />
      </LoadingButton>
    )
  );
};

export default React.memo(EarlyAdoption);

EarlyAdoption.propTypes = {
  sxStyle: PropTypes.object,
  contractId: PropTypes.string,
  showInMenu: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClickItem: PropTypes.func,
};
