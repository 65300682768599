import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import AuthRoutes from '@tenant/utility/AuthRoutes';
import AppContextProvider from '@tenant/utility/AppContextProvider';
import AppThemeProvider from '@tenant/utility/AppThemeProvider';
import AppStyleProvider from '@tenant/utility/AppStyleProvider';
import AppLocaleProvider from '@tenant/utility/AppLocaleProvider';
import AppLayout from '@tenant/core/AppLayout';
import configureStore, { history } from 'redux/store';
import FirebaseAuthProvider from '@tenant/services/auth/firebase/FirebaseAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarProvider } from 'notistack';

const store = configureStore();

const App = ({ instance }) => (
  <AppContextProvider>
    <Provider store={store}>
      <AppThemeProvider>
        <AppStyleProvider>
          <AppLocaleProvider>
            <BrowserRouter history={history}>
              <FirebaseAuthProvider>
                <MsalProvider instance={instance}>
                  <AuthRoutes>
                    <CssBaseline />
                    <SnackbarProvider
                      maxSnack={3}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <AppLayout />
                    </SnackbarProvider>
                  </AuthRoutes>
                </MsalProvider>
              </FirebaseAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppStyleProvider>
      </AppThemeProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
