import React from 'react';
import './loader.css';
import PropTypes from 'prop-types';

const AppLoader = ({ customClass }) => {
  return (
    <div className={'app-loader ' + customClass}>
      <div className='loader-spin'>
        <span className='tenant-dot tenant-dot-spin'>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

AppLoader.propTypes = { customClass: PropTypes.string };

export default AppLoader;
