import moment from 'moment';
import { inputFormat, inputFormatDateTime } from '../constants/default';

export const getFormattedDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const formatDate = (value, format = inputFormat, parsingFormat) => {
  const valueISO = value ? new moment(value, inputFormat).toISOString() : null;
  if (parsingFormat) {
    return valueISO ? moment(valueISO, parsingFormat).format(format) : null;
  }
  return valueISO ? moment(valueISO).format(format) : null;
};

export const isoDateToDayAgo = (isoDate) => {
  return isoDate ? moment(isoDate).fromNow() : '';
};

export const formatDateFromISO = (value, format = inputFormat) => {
  return value ? moment(value).format(format) : null;
};
