import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Fonts } from 'shared/constants/AppEnums';
import CopyButton from './components/CopyButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DescriptionTooltip from '@tenant/core/App/DescriptionTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch } from 'react-redux';

import jwtAxios from '@tenant/services/auth/jwt-auth';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PointUsagePerProduct from './components/PointUsagePerProduct';
import { dateRanges } from '@tenant/utility/constants/default';
import PointUsageStatistic from './components/PointUsageStatistic';
import TenantSubscriptionDetail from './components/TenantSubscriptionDetail';
import IntlMessages from '@tenant/utility/IntlMessages';
import BundleUsage from './components/BundleUsage';
import useTenant from 'pages/apps/hooks/useTenant';
import BundleStepper from './components/Bundles/BundleStepper';
import EarlyAdoption from './components/Bundles/EarlyAdoption';
import BuyPoints from './components/Bundles/BuyPoints';
import { SUBSCRIPTION_TYPE } from './constants';
import AppInfoView from '@tenant/core/AppInfoView';
import RequestService from './components/Bundles/RequestService';
import Toggle from '@tenant/core/App/Toggle';
import { appIntl } from '@tenant/utility/helper/Utils';
import AppsContainer from '@tenant/core/AppsContainer';
import { resetStorageTransactions } from 'redux/actions';
import DataConllapse from './components/DataConllapse';
import TransactionSummary from './components/TransactionSummary';
import PointTransactions from './components/PointTransactions';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';
import { Button, Grow, IconButton } from '@mui/material';
import TenantDetailDashboard from 'pages/dashboards/tenantDetail';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const DEFAULT_XERO_DATE = 'Last year';

const TenantDetail = () => {
  const navigate = useNavigate();
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [isLoadingStatistic, setIsLoadingStatistic] = useState(false);
  const [tenant, setTenant] = useState({});
  const [conversationStatistic, setConversationStatistic] = useState();
  const [rangeDate, setRangeDate] = useState([
    new moment().startOf('month'),
    new moment().endOf('month'),
  ]);
  const [xeroRangeDate, setXeroRangeDate] = useState(
    dateRanges[DEFAULT_XERO_DATE],
  );
  const [selectedXeroDate, setSelectedXeroDate] = useState(DEFAULT_XERO_DATE);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [isAnalytic, setIsAnalytic] = useState(false);

  const subscription = useMemo(() => tenant?.Subscription ?? {}, [tenant]);

  const address = useMemo(() => {
    const addressList = [];
    for (let i = 1; i < 4; i++) {
      if (tenant['Address' + i]) {
        addressList.push(tenant['Address' + i]);
      }
    }
    return addressList.join(', ');
  }, [tenant]);
  const widgets = useMemo(() => {
    return [
      {
        id: 1,
        icon: '/assets/images/dashboard/icon_phone.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Public API',
        field: tenant.IsPublicApiAllowed,
      },
      {
        id: 2,
        icon: '/assets/images/dashboard/teblets.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Reporting Access',
        field: tenant.IsSqlPermissionAllowed,
      },
      {
        id: 3,
        icon: '/assets/images/dashboard/icon_desktop.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'SMS',
        field: tenant.IsSMSAllowed,
      },
      {
        id: 4,
        icon: '/assets/images/dashboard/icon-avg-cost.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Vehicle Tracking',
        field: tenant.IsUseTeltonika,
      },
      {
        id: 5,
        icon: '/assets/images/dashboard/icon-avg-cost.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Main Tenant',
        field: tenant.IsMainContractor,
      },
      {
        id: 6,
        icon: '/assets/images/dashboard/icon-avg-cost.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Subcontractor',
        field: tenant.IsSubcontractor,
        showLink: !!tenant.ParentTenantName,
        linkName: tenant.ParentTenantName,
        linkURL: '/tenant/detail/' + tenant.ParentTenantId,
      },
      {
        id: 7,
        icon: '/assets/images/dashboard/icon-avg-cost.svg',
        count: <Switch defaultChecked sx={{ pointerEvents: 'none' }} />,
        details: 'Refcom',
        field: tenant.IsRefcomEnabled,
      },
    ];
  }, [tenant]);

  const customerJourneyState = useMemo(
    () => tenant.CustomerJourneyStage ?? {},
    [tenant],
  );

  const { id } = useParams();
  const {
    toggleSharePoints,
    loadingPage,
    getTenantDetails,
    getTenantSubscription,
    loadingSubscription,
  } = useTenant();
  const { messages } = appIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const getTwilioUsersStatistic = async () => {
      try {
        setIsLoadingConversation(true);
        const data = await jwtAxios.get(
          `api/Twilio/Tenants/${id}/ConversationStatistic`,
        );
        setConversationStatistic(data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingConversation(false);
      }
    };

    getDetail();
    getTwilioUsersStatistic();
    getSubscriptionInfo();

    return () => {
      dispatch(resetStorageTransactions());
    };
  }, [id]);

  const goToList = (event) => {
    event.preventDefault();
    navigate('/tenant');
  };

  const gotoLink = (event, url) => {
    event.preventDefault();
    navigate(url);
  };

  const onChangeRangeDate = (range) => {
    setRangeDate(range);
  };

  const handleSelectionXeroDate = (data) => {
    setSelectedXeroDate(data || DEFAULT_XERO_DATE);
    setXeroRangeDate(dateRanges[data || DEFAULT_XERO_DATE]);
  };

  const callbackSubmitTenantDiscount = (data) => {
    setTenant((s) => ({ ...s, TenantDiscount: data }));
  };

  const onChangeSharePoints = useCallback(
    (checked) => {
      setTenant({
        ...tenant,
        IsUnsharedPointsTenant: !checked,
      });
      toggleSharePoints({ tenantId: id, unshare: !checked });
    },
    [tenant, id],
  );

  const getDetail = useCallback(() => {
    getTenantDetails(id, (data) => {
      setTenant({ ...data });
    });
  }, [id]);

  const getSubscriptionInfo = useCallback(() => {
    getTenantSubscription(id, (data) => {
      setSubscriptionInfo(data);
    });
  }, [id]);

  const pointPermissions = useMemo(() => {
    if (!subscriptionInfo?.ContractId) {
      return {
        allowBuyPoints: false,
        allowRequestServices: false,
        allowGetAdoption: false,
      };
    }

    if (
      subscriptionInfo?.SubscriptionActivationType ===
      SUBSCRIPTION_TYPE.GoCardless
    ) {
      return {
        allowBuyPoints: true,
        allowRequestServices: true,
        allowGetAdoption: true,
      };
    }

    if (
      subscriptionInfo?.SubscriptionActivationType === SUBSCRIPTION_TYPE.Stripe
    ) {
      return {
        allowBuyPoints: true,
        allowRequestServices: true,
        allowGetAdoption: false,
      };
    }
  }, [
    subscriptionInfo?.ContractId,
    subscriptionInfo?.SubscriptionActivationType,
  ]);

  const goToAnalytics = () => {
    navigate('/dashboards/tenant/' + id);
  };

  const switchMode = () => {
    setIsAnalytic((s) => !s);
  };

  return (
    <>
      {(loadingPage ||
        isLoadingStatistic ||
        isLoadingConversation ||
        loadingSubscription) && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color='primary' />
        </Backdrop>
      )}

      {isAnalytic ? (
        <AllowedAccess permissionIds={[AuthorizePermission['Analytic - View']]}>
          <Grow in={isAnalytic} timeout={200}>
            <Box>
              <TenantDetailDashboard
                tenantId={id}
                analyticModeBtn={
                  <AppTooltip title='Go back'>
                    <IconButton sx={{ ml: 2 }} onClick={switchMode}>
                      <ArrowBackIcon />
                    </IconButton>
                  </AppTooltip>
                }
                goBackBtn={
                  <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<KeyboardDoubleArrowLeftIcon />}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      boxShadow: 'none',
                      borderRadius: 0,
                      padding: 4,
                      textAlign: 'center',
                      minWidth: 150,
                      pr: 3,
                      textTransform: 'uppercase',
                    }}
                    onClick={switchMode}
                  >
                    Back
                  </Button>
                }
              />
            </Box>
          </Grow>
        </AllowedAccess>
      ) : (
        <Grow in={!isAnalytic}>
          <Box>
            <Box
              mb={3}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: { md: 'flex' },
                backgroundColor: '#fff',
                marginLeft: -5,
                marginRight: -5,
                marginTop: -5,
                p: 4,
                height: 50,
                position: 'sticky',
                zIndex: 8,
              }}
            >
              <Box>
                <Breadcrumbs
                  mt={4}
                  sx={{ mb: 4, pt: 2, pb: 2 }}
                  aria-label='breadcrumb'
                >
                  <Link
                    color='secondary'
                    underline='none'
                    href='#'
                    onClick={goToList}
                  >
                    Tenant
                  </Link>
                  <Typography color='text.primary'>Detail</Typography>
                </Breadcrumbs>
              </Box>
              <Box>
                <AllowedAccess
                  permissionIds={[AuthorizePermission['Analytic - View']]}
                >
                  <Button
                    variant='contained'
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      boxShadow: 'none',
                      borderRadius: 0,
                      padding: 4,
                      textAlign: 'center',
                      minWidth: 150,
                      border: 0,
                      pr: 3,
                      textTransform: 'uppercase',
                      backgroundColor: '#008394',
                    }}
                    onClick={switchMode}
                  >
                    Analytic Mode
                  </Button>
                </AllowedAccess>
              </Box>
            </Box>

            <Box
              sx={{
                height: 'calc(100vh - 125px)',
                m: -4,
                p: 4,
                overflow: 'auto',
              }}
            >
              <Grid
                mb={3}
                container
                spacing={8}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item sm={12} md={12} sx={{ textAlign: 'right' }}>
                  {/* <TenantDiscount
            tenantId={id}
            tenantDiscount={tenant?.TenantDiscount}
            callbackSubmit={callbackSubmitTenantDiscount}
          /> */}
                  {!!pointPermissions && (
                    <>
                      {pointPermissions.allowGetAdoption && (
                        <EarlyAdoption
                          contractId={subscriptionInfo?.ContractId}
                          handleSuccess={getSubscriptionInfo}
                          sxStyle={{ mr: 2, ml: 2 }}
                        />
                      )}
                      {pointPermissions.allowBuyPoints && (
                        <BuyPoints
                          tenantId={id}
                          contractId={subscriptionInfo?.ContractId}
                        />
                      )}
                      {pointPermissions.allowRequestServices && (
                        <RequestService tenantId={id} sxStyle={{ ml: 2 }} />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={8}>
                {/* Tenant Basic Information */}
                <Grid item xs={12} md={5} lg={6} xl={4}>
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Box
                        component='h3'
                        sx={{
                          mb: 2,
                          fontSize: 18,
                          fontWeight: Fonts.BOLD,
                        }}
                      >
                        {tenant.Name}
                      </Box>

                      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <List aria-label='secondary tenant-information'>
                          <Tooltip title={tenant.EmailAddress || '-'}>
                            <ListItem
                              sx={{ alignItems: 'baseline' }}
                              secondaryAction={
                                <CopyButton text={tenant.EmailAddress || '-'} />
                              }
                            >
                              <ListItemIcon sx={{ mr: 2 }}>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                  }}
                                >
                                  <IntlMessages id='common.email' />:
                                </Typography>
                              </ListItemIcon>
                              <ListItemText
                                primary={tenant.EmailAddress || '-'}
                                primaryTypographyProps={{
                                  sx: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  },
                                }}
                              />
                            </ListItem>
                          </Tooltip>
                          <Tooltip title={tenant.TenantId || '-'}>
                            <ListItem
                              sx={{ alignItems: 'baseline' }}
                              secondaryAction={
                                <CopyButton text={tenant.TenantId || '-'} />
                              }
                            >
                              <ListItemIcon sx={{ mr: 2 }}>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                  }}
                                >
                                  <IntlMessages id='formTask.tenant' />:
                                </Typography>
                              </ListItemIcon>
                              <ListItemText
                                primary={tenant.TenantId || '-'}
                                primaryTypographyProps={{
                                  sx: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  },
                                }}
                              />
                            </ListItem>
                          </Tooltip>

                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='common.culture' />:
                              </Typography>
                            </ListItemIcon>
                            <ListItemText primary={tenant.Culture || '-'} />
                          </ListItem>
                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='common.address' />:
                              </Typography>
                            </ListItemIcon>
                            {address ? (
                              <ListItemText primary={address} />
                            ) : (
                              <ListItemText primary={'-'} />
                            )}
                          </ListItem>
                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='common.salePerson' />:
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              primary={customerJourneyState?.SalesPerson || '-'}
                            />
                          </ListItem>
                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='common.team' />:
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              primary={customerJourneyState?.Team || '-'}
                            />
                          </ListItem>
                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='tenant.subscriptionType' />:
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              primary={subscriptionInfo?.ActivationType || '-'}
                            />
                          </ListItem>
                          <ListItem sx={{ alignItems: 'baseline' }}>
                            <ListItemIcon sx={{ mr: 2 }}>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                <IntlMessages id='bundleContract.clientGroup' />
                                :
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              primary={subscriptionInfo.ClientGroupName || '-'}
                            />
                          </ListItem>

                          {subscriptionInfo?.IsParentClient && (
                            <ListItem
                              sx={{ alignItems: 'baseline' }}
                              secondaryAction={
                                <Toggle
                                  disabled
                                  requiredConfirm
                                  checked={
                                    !subscriptionInfo.IsUnsharedPointsTenant
                                  }
                                  handleChange={onChangeSharePoints}
                                  confirmationContent={
                                    <>
                                      {subscriptionInfo.IsUnsharedPointsTenant
                                        ? messages['tenant.toggleSharePoint']
                                        : messages['tenant.toggleUnsharePoint']}
                                      <br />
                                      <Typography sx={{ fontWeight: 500 }}>
                                        {messages['tenant.confirmationContent']}
                                      </Typography>
                                    </>
                                  }
                                />
                              }
                            >
                              <ListItemIcon sx={{ mr: 2 }}>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                  }}
                                >
                                  <IntlMessages id='tenant.togglePointOwnership' />
                                  <DescriptionTooltip
                                    icon={
                                      <InfoOutlinedIcon sx={{ fontSize: 18 }} />
                                    }
                                    title={'tenant.toggleUnsharePoint'}
                                  />
                                </Typography>
                              </ListItemIcon>
                            </ListItem>
                          )}
                        </List>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Tenant Subscription Details */}
                <Grid item xs={12} md={7} lg={6} xl={8}>
                  <TenantSubscriptionDetail
                    subscription={subscription}
                    conversationStatistic={conversationStatistic}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <DataConllapse
                    title='tenant.pointTransactions'
                    headerContent={
                      <TransactionSummary
                        points={subscriptionInfo?.PointsBalance}
                        pointsUsage={subscriptionInfo?.PointsUsage}
                        subscription={subscriptionInfo}
                      />
                    }
                  >
                    <PointTransactions showInTenantDetail tenantId={id} />
                  </DataConllapse>
                </Grid>

                <Grid item xs={12} md={6}>
                  <PointUsagePerProduct tenantId={id} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PointUsageStatistic tenantId={id} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <BundleUsage tenantId={id} />
                </Grid>

                {/* User Trend Chart */}
                {/* <Grid item xs={12} md={8}>
          <UserUsage tenantId={id} />
        </Grid> */}

                {/* <Grid item xs={12} md={4}>
          <AppCard
            sx={{ height: '100%' }}
            contentStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <Box flex={1}>
              {widgets.map((e) => (
                <Box key={e.id} sx={{ mb: 1.5 }}>
                  <FormControlLabel
                    sx={{ pointerEvents: 'none' }}
                    control={<Switch checked={e.field ?? false} />}
                    label={e.details}
                  />
                  {e.showLink && (
                    <span style={{ marginLeft: -10, verticalAlign: 'middle' }}>
                      (
                      <Link
                        href='#'
                        color='secondary'
                        underline='none'
                        onClick={(event) => gotoLink(event, e.linkURL)}
                      >
                        {e.linkName}
                      </Link>
                      )
                    </span>
                  )}
                </Box>
              ))}
            </Box>
          </AppCard>
        </Grid> */}

                {/* {canViewXero && (
          <Grid item xs={12} md={6}>
            <AppCard
              title='Invoice'
              sxStyle={{
                height: '100%',
                minHeight: 350,
              }}
              action={
                <AppSelect
                  menus={Object.keys(dateRanges).filter(
                    (e) => e === 'This year' || e === 'Last year',
                  )}
                  defaultValue={selectedXeroDate}
                  onChange={handleSelectionXeroDate}
                />
              }
            >
              <XeroInvoices
                tenantId={id}
                startDate={formatDate(xeroRangeDate[0])}
                endDate={formatDate(xeroRangeDate[1])}
              />
            </AppCard>
          </Grid>
        )} */}

                {/* {tenant.IsUseTeltonika && (
          <Grid item xs={12} md={6}>
            <AppCard
              title='Vehicle Tracking'
              sxStyle={{
                height: '100%',
              }}
            >
              <TeltonikaList tenantId={id} />
            </AppCard>
          </Grid>
        )} */}

                {/* {(tenant.IsPublicApiAllowed || tenant.IsSqlPermissionAllowed) && (
          <SqlDataTenant
            tenantId={id}
            isPublicApiAllowed={tenant.IsPublicApiAllowed}
            isSqlPermissionAllowed={tenant.IsSqlPermissionAllowed}
            isUseTeltonika={tenant.IsUseTeltonika}
          />
        )} */}

                {/* <Grid item xs={12} md={6}>
          <ProfessionalServices tenantId={id} />
        </Grid> */}

                {/* {tenant.IsSMSAllowed && (
          <Grid item xs={12} md={12}>
            <SMSUsage tenantId={id} />
          </Grid>
        )} */}

                {/* {tenant.IsPublicApiAllowed && (
          <Grid item xs={12} md={12}>
            <PublicAPI tenantId={id} />
          </Grid>
        )} */}

                {/* <Grid item xs={12} md={12}>
          <EntityStatistic
            rangeDate={rangeDate}
            tenantId={id}
            setLoading={setIsLoadingStatistic}
          />
        </Grid> */}

                {/* {tenant?.IsUseAMF && (
          <Grid item xs={12} md={12}>
            <JlLiteStatistic tenantId={id} />
          </Grid>
        )} */}

                {/* {tenant.IsMainContractor && (
          <>
            <Grid item xs={12} md={12}>
              <SubcontractorMappingTrend tenantId={id} />
            </Grid>
            <Grid item xs={12} md={12}>
              <SubcontractorMapping tenantId={id} />
            </Grid>
          </>
        )} */}

                {/* {tenant.IsRefcomEnabled && (
          <Grid item xs={12} md={12}>
            <Transaction tenantId={id} />
          </Grid>
        )} */}

                {/* {canViewXero && (
          <Grid item xs={12} md={12}>
            <InvoiceProfessional tenantId={id} />
          </Grid>
        )} */}
              </Grid>
            </Box>
          </Box>
        </Grow>
      )}

      <AppInfoView />
    </>
  );
};

export const tenantDetailConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/tenant/detail/:id',
    element: <TenantDetail />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/tenant/detail/:id/contract/:contractId/buy-bundle',
    element: (
      <AppsContainer fullView>
        <BundleStepper />
      </AppsContainer>
    ),
  },
];

export default React.memo(TenantDetail);
