import React, { useState, useCallback, useRef, useEffect, } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';

import TenantMultiSelect from '@tenant/core/App/Select/TenantMultiSelect';
import ClientGroupMultiSelect from '@tenant/core/App/Select/ClientGroupMultiSelect';
import PaymentTypeMultiSelect from '@tenant/core/App/Select/PaymentTypeMultiSelect';
import IndustryMultiSelect from '@tenant/core/App/Select/IndustryMultiSelect';
import SalePersonMultiSelect from '@tenant/core/App/Select/SalePersonMultiSelect';
import AccountManagerMultiSelect from '@tenant/core/App/Select/AccountManagerMultiSelect';

const TenantFilterForm = ({
  isApplied,
  filterForm,
  handleChangeFilters,
}) => {

  const data = useRef({
    tenants: [],
    clientGroups: [],
    paymentTypes: [],
    industries: [],
    salePersons: [],
    accountManagers: [],
  });

  const handleChange = useCallback((filterKey, value) => {
    data.current = {
      ...data.current,
      [filterKey]: [...value],
    };
    handleChangeFilters(data.current);
  }, []);

  useEffect(() => {
    data.current = filterForm;
  }, [filterForm]);

  return (
    <>
      <Grid item xs={12} sm={4}>
        <TenantMultiSelect
          key={`tenant-filter-${uuidv4()}`}
          checkedTenants={isApplied ? filterForm['tenants'] : data.current['tenants']}
          handleSelectTenant={(data) => {
            handleChange('tenants', data);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ClientGroupMultiSelect
          key={`group-filter-${uuidv4()}`}
          checkedGroups={isApplied ? filterForm['clientGroups'] : data.current['clientGroups']}
          handleSelectGroup={(data) => {
            handleChange('clientGroups', data);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <PaymentTypeMultiSelect
          key={`payment-filter-${uuidv4()}`}
          checkedTypes={isApplied ? filterForm['paymentTypes'] : data.current['paymentTypes']}
          handleSelectType={(data) => {
            handleChange('paymentTypes', data);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <IndustryMultiSelect
          key={`industry-filter-${uuidv4()}`}
          checkedIndustries={isApplied ? filterForm['industries'] : data.current['industries']}
          handleSelectIndustry={(data) => {
            handleChange('industries', data);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <SalePersonMultiSelect
          key={`sale-person-filter-${uuidv4()}`}
          checkedPersons={isApplied ? filterForm['salePersons'] : data.current['salePersons']}
          handleSelectSalePerson={(data) => {
            handleChange('salePersons', data);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <AccountManagerMultiSelect
          key={`manager-filter-${uuidv4()}`}
          checkedManagers={isApplied ? filterForm['accountManagers'] : data.current['accountManagers']}
          handleSelectManager={(data) => {
            handleChange('accountManagers', data);
          }}
        />
      </Grid>
    </>

  );
};

export default React.memo(TenantFilterForm);

TenantFilterForm.propTypes = {
  filterForm: PropTypes.object,
  handleChangeFilters: PropTypes.func.isRequired,
};
