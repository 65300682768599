import React, { useCallback, } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { envList, statusData } from 'shared/constants/AppsContants';
import Tooltip from '@mui/material/Tooltip';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const DataList = ({
  data,
  loading,
  navigateLink,
  columns = [],
  rows = [],
  sortModel,
  handleSort,
  disableColumnMenu = false,
}) => {
  const defaultColumns = [
    {
      field: 'TenantName',
      headerName: 'Tenant',
      flex: 1,
      minWidth: 400,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value.name}>
          <NavLink
            className='MuiDataGrid-cellContent'
            to={`${navigateLink}${params.value.Id}`}
            style={{ textDecoration: 'none' }}
          >
            {params.value.name}
          </NavLink>
        </Tooltip>
      ),
    },
    {
      field: 'EnvironmentText',
      headerName: 'Environment',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Typography variant='body2'>{params.value.name}</Typography>
        </div>
      ),
    },
    {
      field: 'StatusText',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const { name = '', id = '' } = params.value || {};
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Brightness1Icon
              sx={{
                color:
                  statusData[id] !== 'undefined' ? statusData[id]?.Color : '',
                fontSize: 14,
                marginRight: 1,
              }}
            />
            {name}
          </span>
        );
      },
    },
    {
      field: 'RequestByText',
      headerName: 'Req By',
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Typography variant='body2'>{params.value.name}</Typography>
        </div>
      ),
    },
    {
      field: 'ApprovedByText',
      headerName: 'Approved By',
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Typography variant='body2'>{params.value.name}</Typography>
        </div>
      ),
    },
    {
      field: 'RequestDate',
      headerName: 'Req At',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Typography variant='body2'>{params.value.name}</Typography>
        </div>
      ),
    },
  ];

  const defaultRows = () => {
    if (data?.length) {
      const result = data.map((item, index) => {
        return {
          ...item,
          id: index,
          TenantName: {
            name: item.CompanyName || item.AzureResourceName || item.Name,
            TenantId: item.TenantId,
            Id: item.Id,
          },
          EnvironmentText: {
            name: envList.find((env) => env.Id === item.Environment)?.Name,
            id: item.Environment,
          },
          RequestByText: {
            name: item.RequesterEmail || 'N/A',
          },
          ApprovedByText: {
            name: item.ApprovedBy || 'N/A',
          },
          RequestDate: {
            name: item.RequestDate,
          },
          StatusText: {
            name:
              statusData[item.Status] !== 'undefined'
                ? statusData[item.Status]?.Name
                : '',
            id: item.Status,
          },
          ProductType: {
            name: item?.ProductTypeName || 'N/A',
          },
          ProductName: {
            name: item?.ProductName
              ? (
                item?.ProductModeName ? `${item?.ProductName} - ${item?.ProductModeName}` : item?.ProductName
              ) : 'N/A',
          },
          Points: {
            name: item?.Points ?? 0,
          },
          Email: {
            name: item?.EmailAddress || 'N/A',
          },
          Industry: {
            name: item?.Industry || 'N/A',
          },
          PointsBalance: {
            name: item?.PaymentType === 'GoCardless' ? item?.PointsBalance ?? 0 : '-',
          },
          PointsUsage: {
            name: item?.PaymentType === 'GoCardless' ? item?.PointsUsage ?? 0 : '-',
          },
          ClientGroupName: {
            name: item?.ClientGroupName || 'N/A',
          },
          PaymentType: {
            name: item?.PaymentType || 'N/A',
          },
        };
      });
      return result;
    }
    return [];
  };

  return (
    <DataGrid
      loading={loading}
      rows={rows?.length ? rows : defaultRows()}
      columns={columns?.length ? columns : defaultColumns}
      hideFooter
      disableColumnMenu={disableColumnMenu}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSort}
      localeText={{
        noRowsLabel: 'No Data',
      }}
      sx={{
        mt: -2,
        borderTop: 0,
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
          width: '0.6em',
          height: '0.6em',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    />
  );
};

DataList.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  navigateLink: PropTypes.string,
  columns: PropTypes.array,
  disableColumnMenu: PropTypes.bool,
  handleSort: PropTypes.func,
};

export default DataList;
