import {
  COMMON_STATUS_ENUM,
  statusData,
} from '../../../shared/constants/AppsContants';

export const MARKETPLACE_API = {
  TOGGLE_USAGE: '/MarketAppRequest/EnableMarketplace',
  GET_COMPANY_REGISTERED_APPS: '/MarketAppRequest/GetCompanyRegisteredApps',
  GET_TENANT_REGISTERED_APPS: '/MarketAppRequest/GetTenantRegisteredApps',
  GET_APP_REQUEST: '/MarketAppRequest/Gets',
  SUBMIT_APP_REQUEST: '/MarketAppRequest/Submit',
  CREATE_SUBSCRIBE_APP_REQUEST: '/MarketAppRequest/Create',
  UNSUBSCRIBE_APP: '/MarketAppRequest/UnSubscribe',
  EXPORT_APP_REQUEST: '/MarketAppRequest/ExportRequests',
};

export const MARKETPLACE_ROUTER_PATH = {
  REQUESTS: '/apps/marketplace/request',
  CREATE_SUBSCRIBE_REQUEST: '/apps/marketplace/create-subscribe-request',
  CREATE_UNSUBSCRIBE_REQUEST: '/apps/marketplace/create-unsubscribe-request',
};

export const APP_REQUEST_STATUS_ENUMS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const APP_REQUEST_STATUSES = {
  [APP_REQUEST_STATUS_ENUMS.PENDING]: {
    Name: 'Pending',
    Color: statusData[COMMON_STATUS_ENUM.PENDING].Color,
    Id: APP_REQUEST_STATUS_ENUMS.PENDING,
  },
  [APP_REQUEST_STATUS_ENUMS.APPROVED]: {
    Name: 'Approved',
    Color: statusData[COMMON_STATUS_ENUM.APPROVED].Color,
    Id: APP_REQUEST_STATUS_ENUMS.APPROVED,
  },
  [APP_REQUEST_STATUS_ENUMS.REJECTED]: {
    Name: 'Rejected',
    Color: statusData[COMMON_STATUS_ENUM.REJECTED].Color,
    Id: APP_REQUEST_STATUS_ENUMS.REJECTED,
  },
};

export const ACTION_TYPE_ENUMS = {
  APPROVE: 1,
  REJECT: 2,
};

export const ACTION_COLORS = {
  APPROVE: statusData[ACTION_TYPE_ENUMS.APPROVE].Color,
  REJECT: statusData[ACTION_TYPE_ENUMS.REJECT].Color,
};

export const REQUEST_TYPE_ENUMS = {
  SUBSCRIBE: 1,
  UNSUBSCRIBE: 2,
};
