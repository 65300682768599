import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector, } from 'react-redux';
import TuneIcon from '@mui/icons-material/Tune';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Popover,
  Stack,
  Typography,
  Button,
  Paper,
  Menu,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import TenantMultiSelect from '../App/Select/TenantMultiSelect';
import StatusMultiSelect from '../App/Select/StatusMultiSelect';
import TransactionTypeMultiSelect from '../App/Select/TransactionTypeMultiSelect';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: 5,
    border: `4px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const isEmptyFilters = (filters) => {
  return Object.values(filters)
    .map(value => {
      if (Array.isArray(value) && !value.length) return null;
      return value;
    })
    .every(value => !value);
};

const AppAdvancedFilters = ({
  sxStyle,
  anchorOrigin,
  transformOrigin,
  handleSave,
  handleReset,
  tenantFilterKey = 'tenants',
  statusFilterKey = 'statuses',
  transTypeFilterKey = 'transactionTypes',
  hasTenantFilter = false,
  hasStatusFilter = false,
  hasTransactionTypeFilter = false,
  filterList = {},
  statusFilterOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const filters = useRef(filterList);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeFilters = useCallback((filterKey, value) => {
    filters.current = {
      ...filters.current,
      [filterKey]: [...value],
    };
  }, []);

  useEffect(() => {
    filters.current = filterList;
  }, [filterList]);

  return (
    <>
      <StyledBadge
        color="primary"
        badgeContent={isEmptyFilters(filterList) ? null : ''}
      >
        <Button
          variant="outlined"
          size='small'
          startIcon={<TuneIcon />}
          onClick={handleClick}
        >
          Filters
        </Button>
      </StyledBadge>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          filters.current = {};
          handleClose();
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Stack
          sx={{
            m: 3,
            width: 300,
            height: '100%',
            ...sxStyle
          }}
          spacing={3}
        >
          {hasTenantFilter && (
            <TenantMultiSelect
              key={`tenant-filter-${uuidv4()}`}
              checkedTenants={filterList[tenantFilterKey] ?? []}
              handleSelectTenant={(data) => {
                handleChangeFilters(tenantFilterKey, data);
              }}
            />
          )}

          {hasStatusFilter && (
            <StatusMultiSelect
              key={`status-filter-${uuidv4()}`}
              options={statusFilterOptions}
              checkedStatuses={filterList[statusFilterKey] ?? []}
              handleSelectStatus={(data) => {
                handleChangeFilters(statusFilterKey, data);
              }}
            />
          )}

          {hasTransactionTypeFilter && (
            <TransactionTypeMultiSelect
              key={`type-filter-${uuidv4()}`}
              checkedTypes={filterList[transTypeFilterKey] ?? []}
              handleSelectType={(data) => {
                handleChangeFilters(transTypeFilterKey, data);
              }}
            />
          )}

          {/* hasRangeDateFilter */}

          <Box
            component={'div'}
            sx={{
              textAlign: 'right',
              mt: 3,
            }}>
            <Button
              size="small"
              color='secondary'
              sx={{ mr: 2 }}
              onClick={() => {
                !isEmptyFilters(filterList) && handleReset();
                filters.current = {};
                handleClose();
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (isEmptyFilters(filters.current) && isEmptyFilters(filterList)) return;
                handleSave?.(filters.current);
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

AppAdvancedFilters.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

AppAdvancedFilters.propTypes = {
  sxStyle: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  hasTenantFilter: PropTypes.bool,
  filterList: PropTypes.object,
  tenantFilterKey: PropTypes.string,
  statusFilterKey: PropTypes.string,
};

export default React.memo(AppAdvancedFilters);
