import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import ClientInfo from '../PublicAPI/ClientInfo';
import PublicAPI from '../PublicAPI/PublicAPI';

const PublicAPITab = ({tenantId}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ClientInfo tenantId={tenantId} />
      </Grid>
      <Grid item xs={12}>
        <PublicAPI tenantId={tenantId} />
      </Grid>
    </Grid>
  );
};

export default React.memo(PublicAPITab);

PublicAPITab.propTypes = {
  tenantId: PropTypes.string,
};
