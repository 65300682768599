import jwtAxios from '@tenant/services/auth/jwt-auth';
import { API_VEHICLE_TRACKING_REQUEST } from 'pages/vehicleTracking/request/constants';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_LIST_VEHICLE_TENANT,
  GET_LIST_DEVICE_CONTRACT_ITEM,
} from 'shared/constants/ActionTypes';

export const getListVehicleTenant = (id) => {
  if (!id) return { type: GET_LIST_VEHICLE_TENANT, payload: [] };
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    const response = await jwtAxios.get(
      API_VEHICLE_TRACKING_REQUEST.GET_VEHICLE_TENANT,
      {
        params: {
          tenantId: id,
        },
      },
    );

    if (response.status === 200) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_LIST_VEHICLE_TENANT, payload: response.data });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};

export const getListDraftInvoice = (id) => {
  return async (dispatch) => {
    try{
      const response = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.DEVICE_CONTRACT_ITEM,
        {
          params: {
            tenantId: id,
          },
        },
      );
  
      if (response.status === 200) {
        dispatch({ type: GET_LIST_DEVICE_CONTRACT_ITEM, payload: response.data });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error.response.data.Message || messages['message.somethingWentWrong'],
      });
    }
  };
};
