import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import { inputFormat } from '@tenant/utility/constants/default';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import {
  getKeyByValue,
  getFontSizeLabelChart,
  formatNumber,
} from '@tenant/utility/helper/Utils';

export const TRANSACTION_ENTITY_COLOR = [
  '#fa6a64',
  '#5a2476',
  '#755dff',
  '#91b776',
  '#356f61',
  '#a19b66',
  '#ff5a36',
  '#f1c232',
  '#2b2b2b',
  '#b5b3f4',
  '#bd33a4',
  '#e4717a',
];

const LineChartTransactionType = ({ data, dateGroupType }) => {
  const KEY_LIST =
    data?.length && data[0]?.Statistic ? Object.keys(data[0]?.Statistic) : null;
  const dataList =
    data?.length &&
    data.map((item) => {
      return {
        Key: item.Key,
        ...item.Statistic,
        Date:
          getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day'
            ? moment(item.Key, inputFormat).format('DD MMM')
            : moment(item.Key, inputFormat).format('MMM YYYY'),
      };
    });
  return (
    <ResponsiveContainer width='100%' height={350}>
      <LineChart
        data={dataList}
        dataset={data}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey='Date'
          tickLine={false}
          axisLine={false}
          padding={{ left: 20, right: 20 }}
          tick={{ fontSize: getFontSizeLabelChart(data?.length ?? 0) }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          formatter={(value) => formatNumber(value)}
        />
        <Legend verticalAlign='top' height={35} />
        <CartesianGrid stroke='#eee' horizontal={true} vertical={false} />
        {KEY_LIST?.length > 0
          ? KEY_LIST.map((e, index) => (
              <Line
                key={e}
                type='monotone'
                dataKey={e}
                stroke={TRANSACTION_ENTITY_COLOR[index]}
                dot={false}
                strokeWidth={2}
                activeDot={{ r: 4 }}
              />
            ))
          : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(LineChartTransactionType);

LineChartTransactionType.defaultProps = {
  data: [],
};

LineChartTransactionType.propTypes = {
  data: PropTypes.array,
};
