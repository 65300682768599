import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { onGetTenantIDList, setFieldSelect } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import usePermissionService from 'pages/permissions/hooks/usePermissionService';
import { CircularProgress } from '@mui/material';

const FIELD_NAME = 'roleList';
const KEY = 'RoleId';
const LABEL = 'Name';

const RoleSelect = (props) => {
  const {
    error,
    helperText,
    value,
    required,
    handleInputChange,
    excludes,
    textFieldProps,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const id = useId();
  const { roleList: data } = useSelector((s) => s.select);
  const { loading, getListRole } = usePermissionService();

  const onOpen = useCallback(() => {
    if (data?.length > 0) {
      return;
    }
    getListRole({
      onSuccess: (data) => {
        dispatch(setFieldSelect({ field: FIELD_NAME, data: data.Items }));
      },
    });
  }, [data]);

  return (
    <Autocomplete
      id={`role-select-${id}`}
      name={FIELD_NAME}
      options={
        data?.filter((e) => !excludes?.some((f) => f[KEY] === e[KEY])) ?? []
      }
      getOptionLabel={(option) => option[LABEL]}
      isOptionEqualToValue={(option, value) => {
        return option?.[KEY] === value?.[KEY];
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[KEY]}>
            {option[LABEL]}
          </li>
        );
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={error}
          helperText={helperText}
          placeholder='Select Role'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...textFieldProps}
        />
      )}
      onOpen={onOpen}
      value={value}
      {...rest}
    />
  );
};

export default React.memo(RoleSelect);
