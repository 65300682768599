import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import {
  FETCH_ERROR,
  SET_CONTRACT_SUMMARY,
} from 'shared/constants/ActionTypes';

import { API_ENDPOINT, CONTRACT_ENDPOINT } from '../contants/endpoints';
import useToast from '@tenant/utility/hooks/useToast';
import { setPurchaseInvoices } from 'redux/actions';

const useCompany = () => {
  const dispatch = useDispatch();
  const { showErrorMessage } = useToast();
  const [loadingPurchaseInvoices, setLoadingPurchaseInvoices] = useState(false);
  const [loadingContractSummary, setLoadingContractSummary] = useState(false);

  const objectToFormData = (obj, formData = new FormData(), parentKey = '') => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
          objectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
    return formData;
  };

  const handleApiRequest = async (apiFn, callback, errorMsg, callbackError) => {
    try {
      const { messages } = appIntl();
      const response = await apiFn();

      if (response.status === 200) {
        callback?.(response.data || []);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload:
            errorMsg ||
            response.data.Message ||
            messages['message.somethingWentWrong'],
        });
      }
    } catch (err) {
      callbackError?.(err?.response?.data || []);
      dispatch({
        type: FETCH_ERROR,
        payload: err?.response?.data?.Message || err.message,
      });
    }
  };

  const getListTenant = async (params, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(API_ENDPOINT.GET_TENANT, {
          params,
        }),
      callback,
    );
  };

  const getInitData = async (callback) => {
    await handleApiRequest(
      () => jwtAxios.get(API_ENDPOINT.GET_INIT_DATA),
      callback,
    );
  };

  const AddNewContract = async (payload, callback, callbackError) => {
    const formData = objectToFormData(payload);
    await handleApiRequest(
      () =>
        jwtAxios.post(API_ENDPOINT.ADD_COMPANY, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      callback,
      null,
      callbackError,
    );
  };

  const getContractId = async (companyId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(`${API_ENDPOINT.GET_CONTRACT_ID}?companyId=${companyId}`),
      callback,
    );
  };

  const getContracts = async (companyId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(`${API_ENDPOINT.GET_CONTRACTS}?companyId=${companyId}`),
      callback,
    );
  };

  const getTenantDetail = async (tenantId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(`${API_ENDPOINT.GET_TENANT_DETAIL_CONTRACT}`, {
          params: { tenantId },
        }),
      callback,
    );
  };

  const getGetContractItems = async (callback) => {
    await handleApiRequest(
      () => jwtAxios.get(API_ENDPOINT.GET_CONTRACT_ITEMS),
      callback,
    );
  };

  const getContractDetail = async (contractId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(
          `${API_ENDPOINT.GET_CONTRACT_DETAIL}?contractId=${contractId}`,
        ),
      callback,
    );
  };

  const getTenantByCompany = async (companyId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(
          `${API_ENDPOINT.GET_TENANT_BY_COMPANY}?companyId=${companyId}&hasNoContract=true`,
        ),
      callback,
    );
  };

  const getInvoices = async (contractId, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(`${API_ENDPOINT.GET_INVOICES}?contractId=${contractId}`),
      callback,
    );
  };

  const reactivateTenant = async ({
    contractId,
    onSuccess,
    onError,
    onFinally,
  }) => {
    try {
      const { data } = await jwtAxios.post(
        `${API_ENDPOINT.REACTIVATE_TENANT}?contractId=${contractId}`,
      );
      onSuccess?.(data);
    } catch (err) {
      console.error(err);
      onError?.(err);
    } finally {
      onFinally?.();
    }
  };

  const checkExistingTenant = async (tenantId, callback) => {
    try {
      const { messages } = appIntl();
      const response = await jwtAxios.get(
        `${API_ENDPOINT.CHECK_EXISTING_TENANT}?tenantId=${tenantId}`,
      );

      if (response.status === 200) {
        callback?.(response.data);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload:
            response.data.Message || messages['message.somethingWentWrong'],
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ERROR,
        payload: err?.response?.data?.Message || err.message,
      });
    }
  };

  const getTrackerUsers = async (searchTerm, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.get(
          `${API_ENDPOINT.GET_TRACER_USER}?searchTerm=${searchTerm}`,
        ),
      callback,
    );
  };

  const getTrackerUserTeams = async (callback) => {
    await handleApiRequest(
      () => jwtAxios.get(API_ENDPOINT.GET_TRACER_TEAM),
      callback,
    );
  };

  const createPreconditionCompany = async (
    payload,
    callback,
    callbackError,
  ) => {
    await handleApiRequest(
      () => jwtAxios.put(API_ENDPOINT.UPDATE_TENANT_PRECONDITION, payload),
      callback,
      null,
      callbackError,
    );
  };

  const editContract = async (payload, callback, callbackError) => {
    await handleApiRequest(
      () => jwtAxios.post(API_ENDPOINT.EDIT_CONTRACT, payload),
      callback,
      null,
      callbackError,
    );
  };

  const addTenantToCompany = async ({ payload, onSuccess, onError }) => {
    await handleApiRequest(
      () => jwtAxios.post(API_ENDPOINT.ADD_TENANT_TO_COMPANY, payload),
      onSuccess,
      null,
      onError,
    );
  };

  const removeTenantToCompany = async ({ payload, onSuccess, onError }) => {
    const { TenantId, CompanyId } = payload ?? {};
    await handleApiRequest(
      () =>
        jwtAxios.delete(
          API_ENDPOINT.REMOVE_TENANT_FROM_COMPANY +
            `?TenantId=${TenantId}&CompanyId=${CompanyId}`,
        ),
      onSuccess,
      null,
      onError,
    );
  };

  const changePrimaryTenant = async ({ payload, onSuccess, onError }) => {
    const { CurrentPrimaryTenantId, CompanyId, NewPrimaryTenantId } =
      payload ?? {};
    await handleApiRequest(
      () =>
        jwtAxios.put(
          API_ENDPOINT.CHANGE_PRIMARY_TENANT +
            `?CurrentPrimaryTenantId=${CurrentPrimaryTenantId}&CompanyId=${CompanyId}&NewPrimaryTenantId=${NewPrimaryTenantId}`,
        ),
      onSuccess,
      null,
      onError,
    );
  };

  const downloadAttachment = async (payload, callback) => {
    await handleApiRequest(
      () =>
        jwtAxios.post(API_ENDPOINT.DOWNLOAD_ATTACHMENT, payload, {
          responseType: 'arraybuffer',
        }),
      callback,
    );
  };

  const getCompany = async (id, callback, callbackError) => {
    await handleApiRequest(
      () => jwtAxios.get(`${API_ENDPOINT.GET_COMPANY}?id=${id}`),
      callback,
      null,
      callbackError,
    );
  };
  const updateCompany = async (payload, callback, callbackError) => {
    await handleApiRequest(
      () => jwtAxios.put(API_ENDPOINT.UPDATE_COMPANY, payload),
      callback,
      null,
      callbackError,
    );
  };

  const cancelRenewal = async (id, callback, callbackError) => {
    await handleApiRequest(
      () => jwtAxios.put(API_ENDPOINT.CANCEL_RENEWAL + `?contractId=${id}`),
      callback,
      null,
      callbackError,
    );
  };

  const cancelContract = async (id, params, callback, callbackError) => {
    await handleApiRequest(
      () => jwtAxios.patch(API_ENDPOINT.CANCEL_CONTRACT + `/${id}`, params),
      callback,
      null,
      callbackError,
    );
  };

  const getTenantByContract = async (contractId, callback) => {
    await handleApiRequest(
      () => jwtAxios.get(`api/Contract/${contractId}/GetContractTenants`),
      callback,
    );
  };

  const extendTrial = async ({ payload, onSuccess, onError }) => {
    try {
      const response = await jwtAxios.patch(API_ENDPOINT.EXTEND_TRIAL, payload);
      if (response?.status === 200) {
        onSuccess?.(response?.data);
        return;
      }
      showErrorMessage('Failed to extend trial');
      onError?.();
    } catch (err) {
      showErrorMessage('Failed to extend trial');
      onError?.();
    }
  };

  const getPurchaseInvoices = async ({
    contractId,
    cbSuccess,
    cbError,
  } = {}) => {
    try {
      setLoadingPurchaseInvoices(true);

      const response = await jwtAxios.get(API_ENDPOINT.GET_AUDIT_INVOICES, {
        params: {
          contractId,
        },
      });

      if (response?.status === 200) {
        cbSuccess?.(response?.data?.ObjectData ?? []);
        dispatch(setPurchaseInvoices(response?.data?.ObjectData ?? []));
        return;
      }

      showErrorMessage(response?.data?.ErrorMsg);
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingPurchaseInvoices(false);
    }
  };

  const generateDraftContract = async (id, callback, callbackError) => {
    try {
      const res = await jwtAxios.post(
        API_ENDPOINT.GENERATE_DRAFT_CONTRACT + `?contractId=${id}`,
      );
      if (res.status === 200) {
        callback(res.data);
      } else {
        callbackError('Generate draft contract failed');
      }
    } catch (err) {
      callbackError(err?.response?.data?.Message || err.message);
    }
  };

  const getContractSummary = async (contractId, cbSuccess, cbError) => {
    try {
      setLoadingContractSummary(true);
      const response = await jwtAxios.get(
        `${API_ENDPOINT.GET_CONTRACT_SUMMARY}`,
        {
          params: {
            contractId,
          },
        },
      );

      if (response.status === 200) {
        cbSuccess?.(response?.data || {});
        dispatch({
          type: SET_CONTRACT_SUMMARY,
          payload: {
            ...(response?.data || {}),
          },
        });
        return;
      }

      showErrorMessage(response?.data?.ErrorMsg);
      dispatch({
        type: SET_CONTRACT_SUMMARY,
        payload: null,
      });
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
      cbError?.();
    } finally {
      setLoadingContractSummary(false);
    }
  };

  const activeDraftContract = async (id, callback, callbackError) => {
    try {
      const response = await jwtAxios.post(
        CONTRACT_ENDPOINT.ACTIVE_DRAFT_CONTRACT + `?contractId=${id}`,
      );
      if (response.status === 200) {
        callback(response.data);
      } else {
        callbackError('Active draft contract failed');
      }
    } catch (err) {
      callbackError(err?.response?.data?.Message || err.message);
    }
  };

  const checkMultiTenantCompany = async (companyId) => {
    try {
      const response = await jwtAxios.post(
        `${CONTRACT_ENDPOINT.CHECK_MULTI_TENANT_COMPANY}?companyId=${companyId}`,
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw response.data;
      }
    } catch (err) {
      throw err?.response?.data?.Message || err.message;
    }
  };

  const changePaymentMethod = async (payload) => {
    try {
      const response = await jwtAxios.post(
        CONTRACT_ENDPOINT.CHANGE_PAYMENT_METHOD,
        payload,
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw response.data;
      }
    } catch (err) {
      throw err?.response?.data?.Message || err.message;
    }
  };

  return {
    getInitData,
    AddNewContract,
    getContracts,
    getListTenant,
    getTenantDetail,
    getGetContractItems,
    getContractDetail,
    getTenantByCompany,
    getInvoices,
    reactivateTenant,
    checkExistingTenant,
    getContractId,
    getTrackerUsers,
    getTrackerUserTeams,
    createPreconditionCompany,
    editContract,
    addTenantToCompany,
    removeTenantToCompany,
    changePrimaryTenant,
    downloadAttachment,
    getCompany,
    updateCompany,
    cancelRenewal,
    cancelContract,
    getTenantByContract,
    extendTrial,
    loadingPurchaseInvoices,
    getPurchaseInvoices,
    generateDraftContract,
    loadingContractSummary,
    getContractSummary,
    activeDraftContract,
    checkMultiTenantCompany,
    changePaymentMethod,
  };
};

export default useCompany;
