import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';
import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const Analytics = React.lazy(() => import('./Analytics'));
const IMAnalytics = React.lazy(() => import('./IMAnalytics'));
const Marketplace = React.lazy(() => import('./Marketplace'));

export const dashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/dashboards/analytics',
    element: (
      <AllowedAccess
        isPage404
        permissionIds={[AuthorizePermission['Analytic - View']]}
      >
        <Analytics />{' '}
      </AllowedAccess>
    ),
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/dashboards/twilio-conversation',
    element: (
      <AllowedAccess
        isPage404
        permissionIds={[AuthorizePermission['Analytic - View']]}
      >
        <IMAnalytics />
      </AllowedAccess>
    ),
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/dashboards/marketplace',
    element: <Marketplace />,
  },
];
