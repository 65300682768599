import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import AppTooltip from '@tenant/core/AppTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Code } from 'react-content-loader';
import { useIntl } from 'react-intl';
import AppCard from '@tenant/core/AppCard';
import { useDispatch, useSelector } from 'react-redux';
import StatGraphs from 'pages/dashboards/Analytics/Entity/StatGraphs';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { inputFormat } from '@tenant/utility/constants/default';
import PropTypes from 'prop-types';
import { onGetSubcontractorMappingTrend } from 'redux/actions';
import { SUBCONTRACTOR_MAPPING_KEY_NAME } from '@tenant/utility/constants/enum';

const MappingTrend = ({ tenantId = null }) => {
  const { data, endDate, startDate, dataRange, dateGroupType} =
    useSelector(({subcontractorMappingTrend}) => subcontractorMappingTrend);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [config, setConfig] = useState({ startDate, endDate, dataRange, dateGroupType });
  const [isLoading, setIsLoading] = useState(false);

  const onSelectionType = (data) => {
    const medium = {
      ...config,
      dateGroupType: data.dateGroupType,
      dataRange: data.keyName,
      startDate: moment(data.startDate, inputFormat).toISOString(),
      endDate: moment(data.endDate, inputFormat).toISOString()
    };
    setConfig(medium);
    getStatistic(medium);
  };

  const getStatistic = async ({ startDate, endDate, dataRange, dateGroupType } = config) => {
    try {
      setIsLoading(true);
      await dispatch(onGetSubcontractorMappingTrend({ tenantId, startDate, endDate, dataRange, dateGroupType }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.length <= 0) getStatistic();
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{paddingRight: 5 }}>
            {messages['subcontractor.countmappingtrend']}
          </span>
          <AppTooltip title={<p style={{ padding: 10 }}>{messages['subcontractor.countmappingtrend.tooltip']}</p>} arrow>
            <InfoOutlinedIcon sx={{ fontSize: 20 }}/>
          </AppTooltip>
        </Box>
      }
      action={
        <DateMenu
          defaultValue={dataRange}
          handleChangeValue={onSelectionType}
        />
      }
    >
      {isLoading && <Code />}
      {!isLoading && <StatGraphs data={data} dateGroupType={dateGroupType} nameMapping={SUBCONTRACTOR_MAPPING_KEY_NAME} />}
    </AppCard>
  );
};

MappingTrend.propTypes = { tenantId: PropTypes.string };

export default React.memo(MappingTrend);
