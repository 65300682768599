import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SnackbarContent, useSnackbar } from 'notistack';
import NotificationItem from './NotificationItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {
  Box,
  ListItem,
  Typography,
  IconButton,
  ListItemText,
  Divider,
} from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate, isoDateToDayAgo } from '@tenant/utility/helper/DateHelper';
import { LoadingButton } from '@mui/lab';
import AppTooltip from '../AppTooltip';
import { Link } from 'react-router-dom';
import { getUserAvatar } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';
import { dismissNotification } from 'redux/actions/Notification';

const NotifyToastTemplate = React.forwardRef((props, ref) => {
  const { item, id } = props || {};
  const dispatch = useDispatch();
  const { closeSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    closeSnackbar(id);
  }, [id]);

  const onDismiss = (notificationId) => {
    dispatch(dismissNotification({ notificationId }));
  };
  return (
    <SnackbarContent ref={ref} role='alert'>
      <ListItem
        sx={{
          background: '#fff',
          boxShadow:
            'rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px',
          width: 400,
          padding: '8px 20px',
          borderBottom: '1px solid #ddd',
          transition: '0.25s',
          borderRadius: 2,
          '&:hover': {
            backgroundColor: '#fafafa',
          },
        }}
        className='item-hover'
        secondaryAction={
          <AppTooltip title='Close'>
            <LoadingButton
              sx={{ padding: 0, minWidth: 'auto', color: '#888' }}
              edge='end'
              aria-label='close'
              onClick={onClose}
            >
              <ClearIcon />
            </LoadingButton>
          </AppTooltip>
        }
      >
        <ListItemAvatar>
          <AppTooltip title={item.SenderId}>
            <Avatar sx={{ backgroundColor: 'warning.main' }}>
              {getUserAvatar(item.SenderId)}
            </Avatar>
          </AppTooltip>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              component={'p'}
              sx={{
                mb: 0.5,
                color: (theme) => theme.palette.text.primary,
                mr: 1,
                display: 'flex',
                alignItems: 'center',
                '&:hover a': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Link
                to={item.Url}
                style={{
                  textDecoration: 'none',
                  color: '#072d3d',
                  transition: 'color .3s ease-in-out',
                  fontWeight: Fonts.MEDIUM,
                }}
                onClick={() => {
                  onDismiss(item.Id);
                  onClose();
                }}
              >
                {item.EntityType}
              </Link>
            </Box>
          }
          secondary={
            <Box
              component='div'
              dangerouslySetInnerHTML={{ __html: item.Content }}
            ></Box>
          }
          primaryTypographyProps={{
            component: 'div',
          }}
          secondaryTypographyProps={{
            component: 'div',
            sx: {
              paddingRight: 4,
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              lineClamp: '4',
              webkitBoxOrient: 'vertical',
            },
          }}
        />
      </ListItem>
    </SnackbarContent>
  );
});

export default React.memo(NotifyToastTemplate);

NotifyToastTemplate.propTypes = {};
