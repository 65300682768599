export const statusData = {
    Inactive: {
      Name: 'Inactive',
      Color: '#616161',
      Id: 0,
    },
    Online: {
      Name: 'Online',
      Color: '#339900',
      Id: 1,
    },
    Offline: {
      Name: 'Offline',
      Color: '#FF3300',
      Id: 2,
    },
  };