import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useContractService from '../../hooks/useContractService';
import useToast from '@tenant/utility/hooks/useToast';

const LockoutContractConfirm = ({
  visible,
  onClose,
  contractId,
  onGetContractDetail,
  tenants,
  callback,
}) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { lockoutContract } = useContractService();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const onSubmit = useCallback(() => {
    setLoadingBtn(true);
    lockoutContract({
      payload: { contractId },
      onSuccess: () => {
        showSuccessMessage('Lockout contract successfully');
        onClose?.();
        onGetContractDetail?.(contractId);
        callback?.();
      },
      onError: (err) => {
        const message =
          typeof err?.response?.data?.Message === 'string'
            ? err.response.data.Message
            : 'Lockout failed!';
        showErrorMessage(message);
      },
      onFinally: () => {
        setLoadingBtn(false);
      },
    });
  }, [lockoutContract, contractId]);

  const showTenants = useMemo(() => tenants?.length > 0, [tenants]);

  return (
    <Dialog
      open={visible}
      maxWidth='sm'
      fullWidth
      height={500}
      onClose={onClose}
    >
      <DialogTitle fontSize={16}>Lockout Contract?</DialogTitle>

      <DialogContent mt={4}>
        <DialogContentText id='alert-dialog-description'>
          All the Tenants of the Contract will be lockout. Are you certain that
          you want to proceed with this process?
        </DialogContentText>
        {showTenants && (
          <Box mt={2}>
            <Typography variant='h5' component='div'>
              Tenants will be lockout:
            </Typography>
            <List dense>
              {tenants?.map((tenant) => (
                <ListItem key={tenant.TenantId}>
                  <ListItemText
                    primary={
                      <Link
                        href={`/tenant/detail/${tenant.TenantId}`}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none' }}
                      >
                        {tenant.ContactName}
                      </Link>
                    }
                    secondary={tenant.TenantId}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loadingBtn}
          color='error'
          variant='contained'
          onClick={onSubmit}
        >
          Lockout
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LockoutContractConfirm);

LockoutContractConfirm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onGetContractDetail: PropTypes.func,
  contractId: PropTypes.string,
  tenants: PropTypes.array,
};
