import React, {useEffect, useState, useMemo} from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import {DataGrid} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TeltonikaList = ({tenantId, height, onSetVehicleTrackingRecords}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getList = async () => {
      try {
        if (!tenantId) {
          return;
        }
        setIsLoading(true);
        const data = await jwtAxios.get('api/Tenant/GetTenantDeviceInfos', {
          params: {
            TenantId: tenantId,
          },
        });

        if (!data.data) {
          return;
        }
        setData(data.data.Items);
        onSetVehicleTrackingRecords?.(data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    getList();
  }, [tenantId, onSetVehicleTrackingRecords]);

  const columns = useMemo(
    () => [
      {
        field: 'Imei',
        headerName: 'Imei',
        minWidth: 200,
        resizable: false,
        flex: 1,
      },
      {
        field: 'Serial',
        headerName: 'Serial',
        minWidth: 100,
        resizable: false,
        flex: 1,
      },
      {
        field: 'ActivityStatus',
        headerName: 'Status',
        renderCell: (params) => (
          <Typography
            sx={{
              color: params.value === 'Online' ? 'success.main' : 'error.main',
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'RequestDate',
        headerName: 'Request Date',
        minWidth: 125,
      },
      {
        field: 'SendDate',
        headerName: 'Send Date',
        minWidth: 125,
      },
    ],
    [],
  );

  return (
    <Box sx={{height: height ?? 300, width: '100%'}}>
      <DataGrid
        hideFooter
        disableColumnSelector
        loading={isLoading}
        getRowId={(row) => row.Imei}
        rows={data}
        columns={columns}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.6em',
            height: '0.6em',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      />
    </Box>
  );
};

export default React.memo(TeltonikaList);

TeltonikaList.propTypes = {
  tenantId: PropTypes.string.isRequired,
};
