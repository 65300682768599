import React from 'react';
import { Grid } from '@mui/material';
import Mapping from './components/Mapping';
import Trending from './components/Trending';
import TopCount from './components/TopCount';
import Industry from './components/Industry';
import Usage from './components/Usage';
import TenantTrend from './components/TenantTrend';
import MappingTrend from './components/MappingTrend';
import AppAnimate from '@tenant/core/AppAnimate';
import AppGridContainer from '@tenant/core/AppGridContainer';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import LazyLoad from 'react-lazy-load';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';

const Subcontractor = () => {
  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <AppGridContainer>
        <Grid item xs={12} md={12} xl={12}>
          <Usage />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Trending />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <LazyLoad offset={100}>
            <TenantTrend />
          </LazyLoad>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <LazyLoad offset={100}>
            <AppGridContainer>
              <Grid item xs={12} md={12} xl={6}>
                <TopCount />
              </Grid>
              <Grid item xs={12} md={12} xl={6}>
                <Industry />
              </Grid>
            </AppGridContainer>
          </LazyLoad>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <LazyLoad offset={100}>
            <MappingTrend />
          </LazyLoad>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <LazyLoad offset={100} height={600}>
            <Mapping />
          </LazyLoad>
        </Grid>
      </AppGridContainer>
    </AppAnimate>
  );
};

export const subcontractorListConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/subcontractor',
    element: (
      <AllowedAccess
        isPage404
        permissionIds={[AuthorizePermission['Analytic - View']]}
      >
        <Subcontractor />
      </AllowedAccess>
    ),
  },
];

export default Subcontractor;
