import * as yup from 'yup';
import { loadingKeys, extraLoadingKeys } from 'shared/constants/AppsContants';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { isDivisibleByHalf } from 'pages/tenantDetail/helpers/Bundles';

export const API = {
  ADD_PRODUCT_REQUEST: '/api/ProductRequest',
  GET_ALL_REQUESTS: '/api/ProductRequest/GetAll',
  GET_PRODUCT_TYPES: '/api/JLPoint/GetProductTypes',
  GET_PRODUCTS: '/api/JLPoint/GetProducts',
  APPROVE_REQUEST: '/api/ProductRequest/Approve',
  CANCEL_REQUEST: '/api/ProductRequest/Cancel',
  GET_TENANT_POINTS_USAGE_STATISTIC: '/api/JLPointDataAnalyzer/Tenant/GetTenantPointUsagePerProduct',
  GET_TENANT_POINTS_USAGE_OVER_PERIOD: '/api/JLPointDataAnalyzer/Tenant/GetTenantPointUsageOverPeriod',
  GET_POINTS_BALANCE: '/api/JLPoint/GetPointsBalance',
  GET_TENANTS: '/api/Tenant/Env',
  VERIFY_REQUEST_ELIGIBILITY: '/api/JLPoint/VerifyRequestEligibility',
};

export const INDEX_COLOR = ['#fa6a64', '#755dff'];

const REGREX_PASSWORD =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12}$/;

export const REQUEST_SCHEMA = yup.object({
  TenantId: yup.string().required('Tenant is required.'),
  ProductTypeId: yup.number().required('Product Type is required.'),
  ProductId: yup.number().required('Product is required.').nullable(),
  Count: yup.number()
    .required()
    .min(0.5, 'Quantity value must greater than 0'),
  WhitelistIps: yup.string().when('ProductId', {
    is: (v) => v === 12 || v === 13,
    then: (schema) => schema.required('WhitelistIps is required.')
  }),
});

export const MULTI_PRODUCT_FORM_SCHEMA = yup.object({
  TenantId: yup.string().required().label('Tenant'),
  Products: yup
    .array()
    .of(
      yup.object().shape({
        ProductTypeId: yup.number().required('Product Type is required.'),
        ProductId: yup.number().required('Product is required.').nullable(),
        Count: yup
          .number()
          .required()
          .min(0.5, 'Quantity value must greater than 0'),
        WhitelistIps: yup.string().when('ProductId', {
          is: (v) => v === 12 || v === 13,
          then: (schema) => schema.required('WhitelistIps is required.'),
        }),
      }),
    )
    .required('Must have product.'),
});

export const REQUEST_SCHEMA_PRODUCT = yup.object({
  ProductTypeId: yup.number().required('Product Type is required.'),
  ProductId: yup.number().required('Product is required.').nullable(),
  Count: yup.number()
    .required('Quantity value must be greater than or equal to 0.5')
    .min(0.5)
    .max(1000)
    .test(
      'divisible_by_half',
      'Please enter the value with an increment of 0.5',
      (value) => {
        return (isDivisibleByHalf(value));
      },
    )
    .label('Quantity'),
  WhitelistIps: yup.string().when('ProductId', {
    is: (v) => v === 12 || v === 13,
    then: (schema) => schema.required('WhitelistIps is required.'),
  }),
});

export const LoadingKeyDetail = `${extraLoadingKeys.productUsage}-${loadingKeys.getDetail}`;

export const ORDER_BY = {
  ProductType: {
    asc: 1,
    desc: 2,
  },
  ProductName: {
    asc: 3,
    desc: 4,
  },
  RequestDate: {
    asc: 5,
    desc: 6,
  },
};

export const PRODUCT_TYPE = {
  TRAININGS: 1,
  TECHNICAL_SUPPORT: 2,
  INTEGRATIONS: 3,
  AUTOMATIONS: 4,
  SMS_NOTIFICATIONS: 5,
  SUBCONTRACTOR: 6,
  OTHER: 7,
};

export const stepsCreatePS = [
  {
    name: 'Select a Tenant',
    step: 0,
    key: 'select-tenant',
    icon: <PeopleAltIcon />,
  },
  {
    name: 'Select Product Type & Product',
    step: 1,
    key: 'select-product',
    icon: <PlaylistAddIcon />,
  },
  {
    name: 'Confirm information',
    step: 2,
    key: 'confirm-subcontractor',
    icon: <DoneAllIcon />,
  },
];

export const PRODUCT_INTEGRATIONS = {
  JL_API: 12,
  REPORTING_DB: 13,
  ZAPIRER: 11,
};
