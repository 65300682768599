import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ConfirmPopup from '@tenant/core/ConfirmPopup';
import React from 'react';
import {
  REASON_CANCEL,
  TYPE_OF_CANCEL,
  TYPE_OF_CONFIRM,
} from '../../contants/enums';
import useCompany from '../../hooks/useCompany';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function PopUpConfirm(props) {
  const { contract_id } = useParams();
  const { open, handleClose, typeConfirm, contract, setContract } = props;
  const { cancelRenewal, cancelContract } = useCompany();

  const [typeCancelContract, setTypeCancelContract] = React.useState(1);
  const [reasonCancelContract, setReasonCancelContract] = React.useState(1);
  const [otherReason, setOtherReason] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChangeTypeCancelContract = (event) => setTypeCancelContract(event.target.value);
  const handleChangeReasonCancelContract = (event) => setReasonCancelContract(event.target.value);

  const handleConfirm = () => {
    switch (typeConfirm) {
      case TYPE_OF_CONFIRM.RENEWAL:
        handleCancelRenewal();
        break;
      case TYPE_OF_CONFIRM.CANCEL:
        handleCancelContract();
        break;
      default:
        break;
    }
  };

 const handleCancelRenewal = () => {
    setLoading(true);
    cancelRenewal(
      contract_id,
      () => {
        setContract({ ...contract, Status: 0 });
        handleClosePopup();
      },
      handleClosePopup
    );
  };

  const resetForm = () => {
    setTypeCancelContract(1);
    setReasonCancelContract(1);
    setOtherReason('');
  };

  const handleCancelContract = () => {
    setLoading(true);
    const params = {
      cancelAtPeriodEnd: typeCancelContract === TYPE_OF_CANCEL.PENDING,
      cancelReason:
        reasonCancelContract === '6'
          ? otherReason
          : REASON_CANCEL[reasonCancelContract],
    };

    cancelContract(
      contract_id,
      params,
      () => {
        setContract({
          ...contract,
          Status: typeCancelContract === TYPE_OF_CANCEL.PENDING ? 3 : 0,
          CanceledAt:
            typeCancelContract === TYPE_OF_CANCEL.PENDING
              ? contract?.EndDate
              : moment().format(),
          CancelReason: params.cancelReason,
        });
        handleClosePopup();
      },
      () => {
        handleClosePopup();
      },
    );
  };

  const handleClosePopup = () => {
    handleClose();
    resetForm();
    setLoading(false);
  };

  return (
    <ConfirmPopup
      open={open}
      handleClose={handleClosePopup}
      handleConfirm={handleConfirm}
      title={`Cancel ${typeConfirm === TYPE_OF_CONFIRM.CANCEL ? 'contract' : TYPE_OF_CONFIRM[typeConfirm]?.toLowerCase()}`}
      textCancel='No'
      textConfirm='Yes'
      maxWidth={typeConfirm === TYPE_OF_CONFIRM.CANCEL ? 'md' : 'sm'}
      fullWidth
      loading={loading}
    >
      {typeConfirm === TYPE_OF_CONFIRM.RENEWAL ? (
        <Typography>Are you sure you want to cancel renewal?</Typography>
      ) : typeConfirm === TYPE_OF_CONFIRM.CANCEL ? (
        <Stack spacing={6}>
          <Typography>
            Subscription End Date:
            <Typography fontWeight='500' component='span' sx={{ ml: 2 }}>
              {formatDate(moment(contract?.EndDate))}
            </Typography>
          </Typography>
          <Typography>
            Cancelled On:
            <Typography fontWeight='500' component='span' sx={{ ml: 2 }}>
              {typeCancelContract === TYPE_OF_CANCEL.PENDING
                ? formatDate(moment(contract?.EndDate))
                : formatDate(moment())}
            </Typography>
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Type Cancel Contract</InputLabel>
            <Select
              value={typeCancelContract}
              label='Type Cancel Contract'
              onChange={handleChangeTypeCancelContract}
            >
              <MenuItem value={TYPE_OF_CANCEL.IMMEDIATE}>
                Immediate Cancellation
              </MenuItem>
              <MenuItem value={TYPE_OF_CANCEL.PENDING}>
                Pending Cancellation
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Reason</InputLabel>
            <Select
              value={reasonCancelContract}
              label='Reason'
              onChange={handleChangeReasonCancelContract}
            >
              {Object.entries(REASON_CANCEL).map(([key, value]) => {
                return (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {reasonCancelContract === '6' && (
            <TextField
              fullWidth
              label='Other Reason'
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          )}
        </Stack>
      ) : null}
    </ConfirmPopup>
  );
}

export default PopUpConfirm;
