const orders = {
  Items: [
    {
      Id: 1,
      InvoiceNo: "INVOICE01",
      TenantId: "2e343ea6-3b4e-41a2-9656-005ba50cdbbe",
      CompanyName: "Lai's Company",
      Status: 1,
      OrderDate: "11/01/2024 04:57:48",
      OrderBy: "Lai Hua",
      TotalPoints: 50,
      PaidAmount: 1000.5,
    },
    {
      Id: 2,
      InvoiceNo: "INVOICE02",
      TenantId: "2e343ea6-3b4e-41a2-9656-005ba50cdbbe",
      CompanyName: "Lai's Company",
      Status: 2,
      OrderDate: "11/01/2024 04:57:48",
      OrderBy: "Lai Hua",
      TotalPoints: 70,
      PaidAmount: 100.88,
    }
  ],
  TotalCount: 10,
  PageIndex: 1,
  PageSize: 10
};

export default orders
