import React from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';

const TableSkeleton = ({ rowsNum, cellsNum = 5 }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      <TableCell component='th' scope='row'>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      {[...Array(cellsNum)].map((cell, idx) => (
        <TableCell key={idx}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>
      ))}
      {/* <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell> */}
    </TableRow>
  ));
};

export default TableSkeleton;
