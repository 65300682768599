import React, { useMemo, useState, isValidElement } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import AppCard from '@tenant/core/AppCard';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';


import IntlMessages from '@tenant/utility/IntlMessages';
import { LoadingButton } from '@mui/lab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const DataCollapse = ({
  title,
  children,
  actionExport,
  hasExport = false,
  isLoadingBtnDownLoad = false,
  headerContent,
  ...rest
}) => {
  const [open, setOpen] = useState(true);

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center' justifyContent={'space-between'}>
          <Box mr={2}>{<IntlMessages id={title} />}</Box>

          {headerContent && isValidElement(headerContent) && (
            headerContent
          )}
        </Box>
      }
      contentStyle={{
        pt: 0,
      }}
      action={
        <>
          {hasExport && (
            <LoadingButton
              variant='outlined'
              onClick={(e) => {
                e.preventDefault();
                actionExport();
              }}
              loading={isLoadingBtnDownLoad}
              startIcon={<FileDownloadIcon />}
            >
              Export
            </LoadingButton>
          )}

          {open ? (
            <IconButton onClick={() => setOpen(!open)} size='small'>
              <IoMdArrowDropdown />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpen(!open)} size='small'>
              <IoMdArrowDropup />
            </IconButton>
          )}
        </>
      }
      {...rest}
    >
      <Collapse in={open}>
        {children}
      </Collapse>
    </AppCard>
  )
};

DataCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  actionExport: PropTypes.func,
  hasExport: PropTypes.bool,
  isLoadingBtnDownLoad: PropTypes.bool,
  headerContent: PropTypes.node,
};

export default React.memo(DataCollapse);