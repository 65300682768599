import {
  GET_ANALYTICS_DATA,
  SET_DATA_ANALYTICS,
  SET_FILTER_DATA_ANALYTICS,
} from 'shared/constants/ActionTypes';

const initialState = {
  analyticsData: null,
  ecommerceData: null,
  crmData: null,
  cryptoData: null,
  metricsData: null,
  widgetsData: null,
  healthCare: null,
  academyData: null,
  entities: [],
  smsUsages: [],
  publicApiAnalytics: [],
  deviceInfoAnalytics: [],
  backOfficeStatistics: [],
  jlLiteStatistics: [],
  topTenantUsedJlLite: [],
  filter: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: action.payload,
      };

    case SET_DATA_ANALYTICS:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
      };

    case SET_FILTER_DATA_ANALYTICS: {
      const filterField = state.filter[action.payload.field] || {};
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: {
            ...filterField,
            ...action.payload.data,
          },
        },
      };
    }

    default:
      return state;
  }
};
export default dashboardReducer;
