import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useId,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

import { debounce } from '@tenant/utility/helper/Utils';
import useTenant from 'pages/apps/hooks/useTenant';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const TenantMultiSelect = ({
  limitTags = 2,
  checkedTenants,
  handleSelectTenant,
}) => {
  const [checkedValues, setCheckedValues] = useState(checkedTenants);
  const [tenants, setTenants] = useState([]);
  const [search, setSearch] = useState('');
  const [initialData, setInitialData] = useState([]);
  const id = useId();
  const { getDropdownTenants, loadingTenants } = useTenant();

  const getTenants = (params = {}) => {
    getDropdownTenants(params, (data) => {
      setTenants(data);

      if (!params?.searchTerm) setInitialData(data);
    });
  };

  const onInputTenantChange = debounce((e, value, reason) => {
    if (e?.type === 'change') {
      setTenants([]);
      getTenants({
        searchTerm: value,
      });
      setSearch(value);
    }
  }, 700);

  const onOpen = useCallback(() => {
    if (!!tenants.length || loadingTenants) return;

    getTenants();
  }, [tenants, loadingTenants]);

  const handleBlur = useCallback(() => {
    if (!!search) {
      setSearch('');
      setTenants(initialData);
    }
  }, [search, initialData]);

  return (
    <Box>
      <Box fontWeight='500' component='p' mb={2}>
        Tenant
      </Box>
      <Autocomplete
        multiple
        size='small'
        id={`tenant-multi-select-${id}`}
        limitTags={limitTags}
        options={tenants}
        disableCloseOnSelect
        disableClearable
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) =>
          option.TenantId === value.TenantId
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.TenantId}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
          </li>
        )}
        value={checkedValues}
        renderInput={(params) => (
          <TextField {...params} placeholder='Select Tenant(s)' />
        )}
        onOpen={onOpen}
        loading={loadingTenants}
        onInputChange={onInputTenantChange}
        onChange={(e, value) => {
          setCheckedValues(value);
          handleSelectTenant(value);
          if (!value.length && !loadingTenants) {
            setTenants([]);
            getTenants();
          }
        }}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default React.memo(TenantMultiSelect);
