import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import React, { useCallback, useState } from 'react';
import { convertTenantAddFormBeforeSave } from './helper';

const useTenantAddService = () => {
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [configGroup, setConfigGroup] = useState();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const getListCompanyInfo = useCallback(async (callback) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get(
        'api/Tenant/GetInitialCompanyDropdownInfo',
      );
      callback?.(data);
      setConfigGroup(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createTenant = useCallback(async (payload, callback) => {
    try {
      setLoadingBtn(true);
      const { data } = await jwtAxios.post(
        'api/Tenant',
        convertTenantAddFormBeforeSave(payload),
      );
      callback?.(data);
      showSuccessMessage('Create tenant successfully');
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const checkingPrimaryEmailExists = useCallback(
    async ({ params, onSuccess, onError }) => {
      try {
        const { email } = params || {};
        if (!email) {
          return;
        }
        setLoading(true);
        const { data } = await jwtAxios.post(
          'api/Subcontractor/CheckUserAvailability',
          {
            UserEmail: email,
          },
        );
        onSuccess?.(data);
      } catch (err) {
        onError?.(err);
        showErrorMessage();
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    //variables
    loading,
    loadingBtn,
    configGroup,
    // services
    //get
    getListCompanyInfo,
    //post
    createTenant,
    checkingPrimaryEmailExists,
  };
};

export default useTenantAddService;
