import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';

const steps = [
  {
    label: 'Deactivate User(s)',
  },
  {
    label: 'Decrease Max Number of Users',
  },
];

const activeStep = 1;

const StepRemoveUserSelectionConfirm = () => {
  return (
    <Stepper activeStep={activeStep} orientation='vertical'>
      {steps.map((step) => (
        <Step completed={false} active key={step.label}>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default React.memo(StepRemoveUserSelectionConfirm);

StepRemoveUserSelectionConfirm.propTypes = {};
