import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_BY_TOP,
  SET_FILTER_SUBCONTRACTOR_BY_TOP,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';

export const onGetSubcontractorByTop = (payload) => {
  const { messages } = appIntl();

  const { searchTerm, type, endDate, startDate, pageIndex, pageSize } = payload;

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await jwtAxios
      .post('/api/Subcontractor/CountByTop', {
        Type: type,
        EndDate: endDate,
        StartDate: startDate,
        PageSize: pageSize,
        PageIndex: pageIndex,
        SearchTerm: searchTerm
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: FETCH_SUCCESS
          });

          dispatch({
            type: SET_FILTER_SUBCONTRACTOR_BY_TOP,
            payload: {
              ...payload,
              total: data.data?.TotalCount || 0
            }
          });

          dispatch({
            type: GET_SUBCONTRACTOR_BY_TOP,
            payload: data.data?.Items || []
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
      }
    );
  };
};
