export const ENTITY_COLOR = {
  Job: '#fa6a64',
  Quote: '#5a2476',
  Visit: '#755dff',
  Invoice: '#373715',
  PurchaseOrder: '#3d4381',
  Asset: '#91b776',
  PPMContract: '#356f61',
  PPMQuote: '#a19b66',
  Supplier: '#ff5a36',
  Engineer: '#f1c232',
  Part: '#2b2b2b',
  Equipment: '#b5b3f4',
  CGroupedInvoice: '#bd33a4',
  PPMInvoice: '#e4717a',
};

export const INDEX_COLOR = ['#fa6a64', '#755dff', '#82ca9d', '#f1c232'];
