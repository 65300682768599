import moment from 'moment';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { appIntl } from '@tenant/utility/helper/Utils';
import { inputFormat } from '@tenant/utility/constants/default';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_MAPPING_TREND,
  SET_FILTER_SUBCONTRACTOR_MAPPING_TREND,
} from 'shared/constants/ActionTypes';

const transformData = (input) => {
  return (!Array.isArray(input) || !input.length) ? [] : input.map((item) => {
    const statistic = {
      Receive: item.Statistic.RecieveItemCount,
      Accept: item.Statistic.AcceptItemCount,
      Reject: item.Statistic.RejectItemCount,
      Transfer: item.Statistic.TransferItemCount,
      TransferPaperwork: item.Statistic.TransferPaperworkItemCount,
    };

    return {
      Date: moment(item.Date).format(inputFormat),
      Statistic: statistic
    };
  });
};

export const onGetSubcontractorMappingTrend = (payload) => {
  const { messages } = appIntl();
  const { tenantId, endDate, startDate, dateGroupType } = payload;

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await jwtAxios
      .post('/api/Subcontractor/MappingTrend', {
        TenantId: tenantId,
        EndDate: endDate,
        StartDate: startDate,
        DateGroupType: dateGroupType
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ 
            type: FETCH_SUCCESS 
          });

          dispatch({
            type: SET_FILTER_SUBCONTRACTOR_MAPPING_TREND,
            payload
          });

          dispatch({
            type: GET_SUBCONTRACTOR_MAPPING_TREND,
            payload: transformData(data.data || [])
          });


        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
      });
  };
};
