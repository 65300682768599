export const TENANT_API = {
  GET_TENANTS: '/api/Tenant/GetAll',
  GET_DETAIL: '/api/Tenant',
  GET_TENANTS_WITH_ENV: '/api/Tenant/Env',
  GET_SUPSCRIPTION: '/api/Tenant/SubscriptionInfo',
  TOGGLE_SHARE_POINTS: '/api/Tenant/ToggleSharePoint',
  GET_DROPDOWN_TENANT: '/api/Tenant/FilterTenantForDropdown',
  GET_TRANSACTIONS: '/api/JLPointDataAnalyzer/Tenant/GetTenantTransactions',
  CHECK_EARLY_ADOPTION: '/api/JLPoint/CheckEarlyAdoptionAvailability',
  GET_CLIENT_GROUPS: '/api/Tenant/SearchGroups',
  GET_INDUSTRIES: '/api/Tenant/SearchIndustries',
  GET_CSM_STAFFS: '/api/Tenant/SearchUsers',
  EXPORT_TENANTS: '/api/Tenant/Export',
  GET_SALE_PERSON_TRACKING: '/api/Contract/SearchSalesPersonTracking',
  GET_CUSTOMER_JOURNEY_STAGES: '/api/Contract/GetCustomerJourneyStages',
};