import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import {
  formatNumber,
  getFontSizeLabelChart,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';

const transformDate = (value, format = inputFormat) =>
  value ? moment(value).format(format) : null;

const SubcontractorTrendingChart = ({ height, data, dateGroupType }) => {
  const [chartData, setChartData] = useState([]);

  const isDayType = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day',
    [dateGroupType],
  );

  useEffect(() => {
    setChartData(
      data.map((e) => ({
        ...e,
        Key: transformDate(e.Date, isDayType ? 'DD MMM' : 'MMM YYYY'),
        SubPOOther: e.SubPOTotalCount - e.SubPOItemCount,
        ContractPOOther: e.ContractPOTotalCount - e.ContractPOItemCount,
        AllocationOther: e.AllocationTotalCount - e.AllocationItemCount,
        SubcontractorOther:
          e.SubcontractorTotalCount - e.SubcontractorItemCount,
      })),
    );
  }, [data]);

  return (
    <ResponsiveContainer width='100%' height={height ?? 350}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='Key'
          fontSize={getFontSizeLabelChart(data?.length ?? 0)}
        />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip formatter={(value) => formatNumber(value)} />
        <Legend verticalAlign='top' height={35} />
        <Bar
          dataKey='SubcontractorOther'
          name='Subcontractor'
          stackId='1'
          fill='#fba8a5'
        />
        <Bar
          dataKey='SubcontractorItemCount'
          name='Subcontractor Created'
          stackId='1'
          fill='#fa6a64'
        />
        <Bar dataKey='SubPOOther' stackId='2' name='Sub PO' fill='#9f9dff' />
        <Bar
          dataKey='SubPOItemCount'
          stackId='2'
          name='Sub PO Created'
          fill='#755dff'
        />
        <Bar
          dataKey='ContractPOOther'
          name='Contract PO'
          stackId='3'
          fill='#b3e4c9'
        />
        <Bar
          dataKey='ContractPOItemCount'
          name='Contract PO Created'
          stackId='3'
          fill='#82ca9d'
        />
        <Bar
          dataKey='AllocationOther'
          stackId='4'
          name='Allocation'
          fill='#f7e6b8'
        />
        <Bar
          dataKey='AllocationItemCount'
          stackId='4'
          name='Allocation Created'
          fill='#f1c232'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

SubcontractorTrendingChart.propTypes = {
  data: PropTypes.array.isRequired,
  dateGroupType: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(SubcontractorTrendingChart);
