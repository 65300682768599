export const TenantEntityEnum = {
  Job: 1,
  Quote: 2,
  Visit: 3,
  Invoice: 4,
  PurchaseOrder: 5,
  Asset: 6,
  PPMContract: 7,
  PPMQuote: 8,
  Supplier: 9,
  Engineer: 10,
  Part: 11,
  Equipment: 12,
  CGroupedInvoice: 13,
  PPMInvoice: 14,
  Customer: 15,
  Site: 16,
  InvoiceCredit: 17,
  PPMContractPO: 18,
  NonLoginUser: 19,
  UserWithBackoffice: 20,
  BothLogins: 21,
};

export const TenantEntityEnumKey = Object.fromEntries(
  Object.entries(TenantEntityEnum).map((a) => a.reverse()),
);
