import React, { useId, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PaymentTypeMultiSelect from '@tenant/core/App/Select/PaymentTypeMultiSelect';
import { SUBSCRIPTION_TYPES } from 'pages/tenantDetail/constants';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  SUBSCRIPTION_ACTIVATION_TYPE_ENUM,
  SubscriptionStatusEnum,
} from '@tenant/utility/constants/enum';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SubcontractorMappingFilter = ({
  onFilter,
  onReset,
  defaultSubStatues,
  defaultActivationTypes,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subStatuses, setSubStatuses] = useState(defaultSubStatues);
  const [activationTypes, setActivationTypes] = useState(
    defaultActivationTypes,
  );
  const isFilter = useMemo(
    () => !!subStatuses?.length || !!activationTypes?.length,
    [subStatuses, activationTypes],
  );
  const id = useId();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-describedby='filter-popover'
        aria-label='filter'
        onClick={handleClick}
      >
        <Badge variant='dot' invisible={!isFilter} color='primary'>
          <FilterAltOutlinedIcon />
        </Badge>
      </IconButton>
      <Popover
        id={'filter-popover' + id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement='bottom-end'
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
            bgcolor: 'background.paper',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            p: 3,
          }}
        >
          <Typography component={'div'} variant='h3' mb={4}>
            Filter
          </Typography>
          <Box
            mb={3}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <InputLabel shrink>Payment Type</InputLabel>
            <Autocomplete
              sx={{ maxWidth: 325 }}
              fullWidth
              multiple
              size='small'
              id={`payment-type-multi-select-${id}`}
              limitTags={1}
              options={Object.keys(SUBSCRIPTION_ACTIVATION_TYPE_ENUM)}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              value={activationTypes ?? []}
              renderInput={(params) => (
                <TextField {...params} placeholder='Select Payment Type(s)' />
              )}
              onChange={(e, value) => {
                setActivationTypes(value);
              }}
            />
          </Box>
          <Box
            mb={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <InputLabel shrink>Subscription Status</InputLabel>
            <Autocomplete
              sx={{ maxWidth: 325 }}
              fullWidth
              multiple
              size='small'
              id={`payment-type-multi-select-${id}`}
              limitTags={1}
              options={Object.values(SubscriptionStatusEnum)}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <Box
                  component={'li'}
                  {...props}
                  key={option}
                  sx={{ textTransform: 'uppercase' }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </Box>
              )}
              value={subStatuses ?? []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Select Subscription Status(s)'
                />
              )}
              onChange={(e, value) => {
                setSubStatuses(value);
              }}
            />
          </Box>
          <Box
            mt={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <Button
              disabled={!isFilter}
              size='small'
              color='secondary'
              variant='contained'
              sx={{ mr: 2 }}
              onClick={() => {
                onReset?.();
                setSubStatuses();
                setActivationTypes();
                handleClose();
              }}
            >
              Reset
            </Button>
            <Button
              size='small'
              variant='contained'
              onClick={() => {
                onFilter?.({ activationTypes, subStatuses });
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(SubcontractorMappingFilter);

SubcontractorMappingFilter.propTypes = {
  onFilter: PropTypes.func,
  onReset: PropTypes.func,
  defaultSubStatues: PropTypes.array,
  defaultActivationTypes: PropTypes.array,
};
