import { Typography } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import CheckIcon from '@mui/icons-material/Check';

import { statusData } from 'shared/constants/AppsContants';
import { numberToCurrency } from '@tenant/utility/helper/Utils';


export const getTableColumns = () => {
  return (
    [
      {
        field: 'ProductName',
        headerName: 'Product',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/apps/product-usage/all/${params.row.Id}`}
              style={{ textDecoration: 'none' }}
            >
              {params.value.name}
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'ProductType',
        headerName: 'Category',
        minWidth: 200,
        renderCell: (params) => (
          <div>
            <Typography variant='body2'>{params.value.name}</Typography>
          </div>
        ),
      },
      {
        field: 'Points',
        headerName: 'Points',
        minWidth: 100,
        sortable: false,
        type: 'number',
        renderCell: (params) => (
          <Typography
            variant='body2'
            color='error.main'
            fontWeight={'500'}
          >
            {params.value.name}
          </Typography>
        ),
      },
      {
        field: 'TenantName',
        headerName: 'Tenant',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/tenant/detail/${params.row.TenantId}`}
              style={{ textDecoration: 'none' }}
            >
              {params.value.name}
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'Status',
        headerName: 'Status',
        sortable: false,
        flex: 1,
        maxWidth: 120,
        renderCell: (params) => {
          const { label = '', id = '' } = params.value || {};
          return (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Brightness1Icon
                sx={{
                  color:
                    statusData[id] !== 'undefined' ? statusData[id]?.Color : '',
                  fontSize: 14,
                  mr: 1,
                }}
              />
              {label}
            </span>
          );
        },
      },
      {
        field: 'IsTestAccount',
        headerName: 'QC Test',
        minWidth: 110,
        sortable: false,
        align: 'center',
        renderCell: (params) => (
          <>
            {params.value.name ? <CheckIcon color='success' /> : '-'}
          </>
        ),
      },
      {
        field: 'RequestByText',
        headerName: 'Req By',
        minWidth: 150,
        sortable: false,
        renderCell: (params) => (
          <div>
            <Typography variant='body2'>{params.value.name}</Typography>
          </div>
        ),
      },
      {
        field: 'RequestDate',
        headerName: 'Req At',
        minWidth: 150,
        type: 'date',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => (
          <div>
            <Typography variant='body2'>{params.value.name}</Typography>
          </div>
        ),
      },
    ]
  );
};

export const getFilterColumns = () => {
  return (
    [
      {
        field: 'Tenant',
        headerName: 'Tenant',
        type: 'tenant',
        // additionalParams: {
        //   gocardlessOnly: true,
        // },
      },
      {
        field: 'ProductTypeName',
        headerName: 'Category',
      },
      {
        field: 'ProductWithMode',
        headerName: 'Product',
      },
      {
        field: 'RequestDate',
        headerName: 'Request Date',
        type: 'date'
      },
      {
        field: 'RequesterEmail',
        headerName: 'Request By',
      },
      {
        field: 'Status',
        headerName: 'Status',
        type: 'select',
        options: Object.values(statusData)
          .filter(item => item.Name !== 'Pending')
          .map(item => ({ Id: item.Id, Name: item.Name }))
      },
    ]
  )
}

export const groupData = (data) => {
  const grouped = _.groupBy(data, item => item.ProductType);
  const result = [];
  for (const key in grouped) {
    const totalCount = grouped[key].reduce((a, b) => a + Number(b.Count), 0) ?? 0;
    const totalPoints = grouped[key].reduce((a, b) => a + Number(b.Points), 0) ?? 0;

    result.push({
      ProductType: key,
      Products: grouped[key],
      TotalCount: totalCount.toFixed(2).replace(/(\.|,)00$/g, ''),
      TotalPoints: totalPoints.toFixed(2).replace(/(\.|,)00$/g, ''),
    });
  }
  return result;
};

export const calculateTotalRedeemed = (count, points) => {
  return isNaN(points) || isNaN(count)
    ? 0
    : (Number(points) * Number(count));
};

export const calculateRemainingPoints = (balance, pointRedeemed) => {
  return isNaN(balance) || isNaN(pointRedeemed)
    ? 0
    : (Number(balance) - Number(pointRedeemed)).toFixed(2).replace(/(\.|,)00$/g, '');
};

export const getTableRows = (data) => {
  if (!data?.length) return [];

  return data.map((item, index) => ({
    ...item,
    id: index,
    TenantName: {
      name: item.CompanyName || item.AzureResourceName || item.Name,
    },
    RequestByText: {
      name: item.RequesterEmail || 'N/A',
    },
    RequestDate: {
      name: item.RequestDate || '',
    },
    Points: {
      name: item?.Points ?? 0,
    },
    ProductType: {
      name: item?.ProductTypeName || 'N/A',
    },
    ProductName: {
      name: item?.ProductName
        ? (
          item?.ProductModeName ? `${item?.ProductName} - ${item?.ProductModeName}` : item?.ProductName
        ) : 'N/A',
    },
    IsTestAccount: {
      name: item?.IsTestAccount || false,
    },
    Status: {
      label:
        statusData[item.Status] !== 'undefined'
          ? statusData[item.Status]?.Name
          : '',
      id: item.Status,
    },
  })
  );
};

export const parseDebit = (number) => {
  if (isNaN(number) || typeof number !== 'number' || number === 0) return 0;

  const rounded = number.toFixed(2);

  return (`-${Math.abs(rounded)}`).replace(/(\.|,)00$/g, '');
};

export const parseCredit = (number) => {
  if (isNaN(number) || typeof number !== 'number' || number === 0) return 0;

  const rounded = number.toFixed(2);

  return (`+${Math.abs(rounded)}`).replace(/(\.|,)00$/g, '');
};

export const getWarningMessageId = (data) => {
  if (!data) return '';

  if (data.HasOwnershipWarning) {
    return 'bundleContract.hasOwnershipWarning';
  }

  if (data.HasOwnershipDisabledWarning) {
    return 'bundleContract.hasOwnershipDisabledWarning';
  }

  if (data.HasNoGoCardlessParentWarning) {
    return 'bundleContract.hasNoGoCardlessParentWarning';
  }

  return '';
};

export const parseDebitAmount = (value, currency = 'GBP') => {
  if (isNaN(value) || typeof value !== 'number' || value === 0) return '-';

  const amount = numberToCurrency(value, 2, currency);

  return `-${amount}`;
};

export const parseCreditAmount = (value, currency = 'GBP') => {
  if (isNaN(value) || typeof value !== 'number' || value === 0) return '-';

  const amount = numberToCurrency(value, 2, currency);

  return `+${amount}`;
};
