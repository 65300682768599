export const ENTITY_ENUM = {
  Job: 1,
  Quote: 2,
  Visit: 3,
  Invoice: 4,
  PurchaseOrder: 5,
  Asset: 6,
  PPMContract: 7,
  PPMQuote: 8,
  Supplier: 9,
  Engineer: 10,
  Part: 11,
  Equipment: 12,
  CGroupedInvoice: 13,
  PPMInvoice: 14,
};

export const ENTITY_ENUM_KEY = Object.fromEntries(
  Object.entries(ENTITY_ENUM).map((a) => a.reverse()),
);

export const SUBCONTRACTOR_KEY_NAME = {
  Subcontractor: 'Subcontractor',
  SubPO: 'Subcontractor PurchaseOrder',
  Allocation: 'Subcontractor Job Allocation',
  ContractPO: 'Contract PurchaseOrder',
};

export const SUBCONTRACTOR_MAPPING_KEY_NAME = {
  Receive: 'Receive',
  Accept: 'Accept',
  Reject: 'Reject',
  Transfer: 'Transfer',
  TransferPaperwork: 'Transfer Paperwork',
};

export const SUBCONTRACTOR_NAME_KEY = Object.fromEntries(
  Object.entries(SUBCONTRACTOR_KEY_NAME).map((a) => a.reverse()),
);

export const ENTITY_SUBCONTRACTOR_ENUM = {
  Subcontractor: 1,
  'Subcontractor Job Allocation': 2,
  'Subcontractor PurchaseOrder': 3,
  'Contract PurchaseOrder': 4,
};

export const ENTITY_SUBCONTRACTOR_ENUM_KEY = Object.fromEntries(
  Object.entries(ENTITY_SUBCONTRACTOR_ENUM).map((a) => a.reverse()),
);

export const ENV_ENUM = {
  Uat: 1,
  Live: 2,
};

export const ENV_ENUM_KEY = Object.fromEntries(
  Object.entries(ENV_ENUM).map((a) => a.reverse()),
);

export const REQUEST_STATUS_ENUM = {
  All: 0,
  Success: 1,
  Failure: 2,
  'Rate Limit': 3,
};

export const REQUEST_STATUS_ENUM_KEY = Object.fromEntries(
  Object.entries(REQUEST_STATUS_ENUM).map((a) => a.reverse()),
);

export const STATISTIC_DATE_GROUP_TYPE = {
  Day: 0,
  Month: 1,
  Year: 2,
};

export const TASK_TYPE = {
  PublicAPI: 1,
  ReportingAccess: 2,
  ProductUsage: 3,
};

export const TRANSACTION_TYPE_ENUM = [
  {
    Id: 0,
    Description: 'Initial',
  },
  {
    Id: 1,
    Description: 'Addition',
  },
  {
    Id: 2,
    Description: 'Removal',
  },
  {
    Id: 3,
    Description: 'Decant',
  },
  {
    Id: 4,
    Description: 'Revert',
  },
  {
    Id: 5,
    Description: 'Correction',
  },
  {
    Id: 11,
    Description: 'Error Addition',
  },
  {
    Id: 12,
    Description: 'Error Removal',
  },
];

export const MODULES_ENUM = {
  Customer: 0,
  Site: 1,
  Quote: 2,
  Asset: 3,
  PurchaseOrder: 4,
};

export const MODULES_LIST = Object.keys(MODULES_ENUM);

export const MODULES_ENUM_KEY = Object.fromEntries(
  Object.entries(MODULES_ENUM).map((a) => a.reverse()),
);

export const ACTION_ENUM = {
  Other: 0,
  Create: 1,
  Read: 2,
  Update: 3,
  Delete: 4,
  Search: 5,
};

export const REQUEST_STATUS = {
  Pending: 0,
  Cancelled: 2,
  Approved: 1,
};

export const SUBSCRIPTION_ACTIVATION_TYPE_ENUM = {
  None: 0,
  Stripe: 1,
  GoCardless: 2,
};

export const ORDER_DIRECTION = {
  asc: 1,
  desc: 2,
};

export const ORDER_DIRECTION_VALUE = Object.fromEntries(
  Object.entries(ORDER_DIRECTION).map((a) => a.reverse()),
);

export const TRANSACTION_TYPE = {
  Burned: 0,
  Refunded: 1,
  Purchased: 2,
  Revoked: 3,
  Expired: 4,
};

export const TRANSACTION_TYPES = Object.keys(TRANSACTION_TYPE).map((item) => ({
  Id: TRANSACTION_TYPE[item],
  Name: item,
}));

export const OPERATOR_ENUM = {
  '=': 0,
  '<': 1,
  '<=': 2,
  '>=': 3,
  '>': 4,
};

export const PAYMENT_METHOD_ENUM = {
  None: 0,
  Stripe: 1,
  GoCardless: 2,
};

export const SUBSCRIPTION_STATUS_ENUM = {
  Active: 0,
  Canceled: 1,
  'Pending Cancel': 2,
  'Pending Activation': 3,
  Trialing: 4,
  'Locked Out': 5,
};

export const PACKAGE_TYPE_ENUM = {
  Basic: 1,
  Premium: 2,
  Enterprise: 3,
};

export const SubscriptionStatusEnum = {
  Active: 'active',
  Cancelled: 'canceled',
  'Pending Cancellation': 'pending_cancel',
  'Pending Activation': 'pending_activation',
  Trialing: 'trialing',
  'Locked Out': 'locked_out',
};

export const PAYMENT_METHODS = {
  0: 'None',
  1: 'Stripe',
  2: 'GoCardless',
  3: 'Online',
};

export const CONTRACT_ITEM_TYPE = {
  License: 'License',
  Subcontractor: 'Subcontractor',
  JLPoint: 'JLPoint',
};

export const PAYMENT_INVOICE_STATUS = {
  None: 0,
  'Waiting For Payment': 1,
  'Payment Completed': 2,
  'Payment Failed': 3,
};
