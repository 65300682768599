import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppGridContainer from '@tenant/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { inputFormat } from 'pages/tenantDetail/constants';
import XeroInvoiceTable from './XeroInvoiceTable';
import { Alert, Typography } from '@mui/material';

const StepAddInvoice = ({ detail, onChangeDiscount }) => {
  return (
    <DialogContent>
      <Box mb={2} m={-4}>
        <AppGridContainer spacing={5}>
          <Grid item xs={12} lg={12}>
            <Box fontWeight={550} component='span' mr={2}>
              Access License Fee:
            </Box>
            {numberToCurrency(detail?.Total ?? 0, 0, 'GBP')}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box fontWeight={550} component='span' mr={2}>
              Subscription Date:
            </Box>
            {detail?.Start
              ? moment(detail?.Start, inputFormatDateTime).format(inputFormat)
              : ''}{' '}
            -{' '}
            {detail?.End
              ? moment(detail?.End, inputFormatDateTime).format(inputFormat)
              : ''}{' '}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box fontWeight={550} component='span' mr={2}>
              Duration:
            </Box>
            {detail?.Quantity} Month
          </Grid>
          <Grid item xs={12} lg={12}>
            <XeroInvoiceTable
              invoice={detail}
              onChangeDiscount={onChangeDiscount}
            />
          </Grid>
        </AppGridContainer>
      </Box>
    </DialogContent>
  );
};

export default React.memo(StepAddInvoice);
StepAddInvoice.propTypes = {
  detail: PropTypes.object,
};
