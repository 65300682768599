import React, { useState, useEffect, useCallback } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

import { API, NEW_ACTIVATION_COLUMNS } from '../constants';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import TableChart from '@tenant/core/App/Chart/TableChart';

const rowsPerPage = 10;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} component={Paper} />
  )),
  Table: (props) => <Table {...props} stickyHeader />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      left: 2,
    },
  }),
);

const NewActivation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const classes = useStyles();

  const getList = useCallback(
    async ({ keyName, pageIndex = 1 } = {}) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(keyName || rangeDate);
        const { data = {} } = await jwtAxios.get(API.GET_NEW_ACTIVATION, {
          params: {
            FromDate: start,
            ToDate: end,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
          },
        });

        if (data?.Success) {
          if (pageIndex === 1) {
            setData(data?.ObjectData || []);
          } else {
            setData((s) => [...s, ...(data?.ObjectData || [])]);
          }

          setTotal(data?.ObjectData.length);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate],
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    setPage(1);
    getList({ keyName });
  };

  const handleReachedEnd = () => {
    if (total === 0) {
      return;
    }

    getList({ pageIndex: page + 1 });
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>New Activation</Box>
        </Box>
      }
      action={
        <DateMenu
          defaultValue={rangeDate}
          handleChangeValue={handleChangeDateRange}
        />
      }
      sxStyle={{
        position: 'relative',
      }}
    >
      {isLoading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}

      {data.length ? (
        <TableVirtuoso
          style={{ height: 325 }}
          data={data}
          components={TableComponents}
          endReached={handleReachedEnd}
          fixedHeaderContent={() => (
            <TableRow>
              <TableCell
                sx={{
                  minWidth: 100,
                  maxWidth: 200,
                  backgroundColor: '#FFFFFF',
                }}
              >
                Tenant
              </TableCell>
              <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>
                Contract Signed Date
              </TableCell>
              <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>
                Subscription End
              </TableCell>
            </TableRow>
          )}
          itemContent={(idx, item) => (
            <React.Fragment key={item?.TenantId || idx}>
              <TableCell sx={{ minWidth: 100, maxWidth: 200 }}>
                <NavLink
                  className='MuiDataGrid-cellContent'
                  to={`/tenant/detail/${item.TenantId}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Tooltip title={item.TenantName}>
                    <Typography
                      variant='body2'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.TenantName}
                    </Typography>
                  </Tooltip>
                </NavLink>
              </TableCell>
              <TableCell align='right'>
                {moment(item.ContractSignedDate).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell align='right'>
                {moment(item.ContractEndDate).format('DD/MM/YYYY')}
              </TableCell>
            </React.Fragment>
          )}
        />
      ) : (
        <TableChart
          rows={[]}
          columns={NEW_ACTIVATION_COLUMNS}
          uniqueProp={''}
        />
      )}
    </AppCard>
  );
};

export default React.memo(NewActivation);
