import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppGridContainer from '@tenant/core/AppGridContainer';
import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import RoleSelect from '@tenant/core/App/Select/RoleSelect';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { grey } from '@mui/material/colors';
import usePermissionService from '../hooks/usePermissionService';
import AppLoader from '@tenant/core/AppLoader';
import useNextTick from '@tenant/utility/hooks/useNextTick';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const UserRoleAddForm = ({
  values = {},
  setFieldValue = () => {},
  validateField = () => {},
  errors = {},
  disabledEmail,
  disabledRole,
}) => {
  const [existRoles, setExistRoles] = useState();
  const { getUserRoles, loading } = usePermissionService();
  const nextTick = useNextTick();

  const onSetFieldValue = useCallback(async (field, value, trigger = true) => {
    setFieldValue(field, value, false);
    if (trigger) {
      await nextTick();
      validateField(field);
    }
  }, []);

  const onBlurEmail = (email) => {
    if (email) {
      const splitEmail = email.split(/\n/).filter((e) => !!e.trim());
      setFieldValue(
        'UserIds',
        splitEmail.map((e) => e.trim()),
        false,
      );
    }
  };

  return (
    <AppGridContainer mt={1}>
      {loading && <AppLoader />}
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          disabled={disabledEmail}
          value={values.UserId ?? ''}
          rows={5}
          name='UserId'
          label='Email'
          variant='outlined'
          required
          error={!!errors.UserId}
          helperText={!disabledEmail && errors.UserId}
          sx={{
            '& input.Mui-disabled': {
              backgroundColor: grey[50],
            },
          }}
          onChange={(e) => {
            onSetFieldValue('UserId', e.target.value);
          }}
          onBlur={(e) => onBlurEmail(values.UserId)}
        />
      </Grid>
      <Grid item xs={12}>
        <RoleSelect
          disabled={disabledRole}
          multiple
          disableCloseOnSelect
          required
          limitTags={3}
          value={values.Roles ?? []}
          helperText={errors.RoleIds}
          error={!!errors.RoleIds}
          name={'RoleIds'}
          textFieldProps={{ label: 'Roles' }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.Name}
            </li>
          )}
          onChange={(e, value) => {
            onSetFieldValue('Roles', value);
            onSetFieldValue(
              'RoleIds',
              value?.length ? value?.map((e) => e.RoleId) : undefined,
            );
          }}
        />
      </Grid>
    </AppGridContainer>
  );
};

export default React.memo(UserRoleAddForm);

UserRoleAddForm.propTypes = {};
