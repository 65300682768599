import React, { useEffect, useMemo, useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Stack } from '@mui/material';

import { formatDate } from '@tenant/utility/helper/DateHelper';
import moment from 'moment';
import { numberToPercent } from '@tenant/utility/helper/Utils';

const TenantSubscriptionDetail = ({
  tenant,
  subscription,
  conversationStatistic,
  tenantDiscount,
}) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box>
          <Stack
            direction='column'
            spacing={4}
            divider={<Divider orientation='horizontal' flexItem />}
          >
            <Grid container spacing={2} px={3}>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Payment Type'
                  secondary={subscription.PaymentType || '-'}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Subscription Status'
                  secondary={subscription.SubscriptionStatus || '-'}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Subscription Start'
                  secondary={
                    subscription.SubscriptionStart
                      ? formatDate(moment(subscription.SubscriptionStart))
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Subscription End'
                  secondary={
                    subscription.SubscriptionEnd
                      ? formatDate(moment(subscription.SubscriptionEnd))
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary={'Stripe Id'}
                  secondary={subscription.StripeId || '-'}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Charge Type'
                  secondary={subscription.ChargeType || '-'}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Invoice Email Address'
                  secondary={subscription.InvoiceEmailAddress || '-'}
                  secondaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Joblogic Mode'
                  secondary={subscription.JoblogicMode || '-'}
                  sx={{ flex: 'none' }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Referral Code'
                  secondary={subscription.ReferralCode || '-'}
                  sx={{ flex: 'none' }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Sign Up Date'
                  secondary={
                    subscription.SignUpDate
                      ? formatDate(moment(subscription.SignUpDate))
                      : '-'
                  }
                  sx={{ flex: 'none' }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Cancelled On'
                  secondary={
                    subscription.CancelledOn
                      ? formatDate(moment(subscription.CancelledOn))
                      : '-'
                  }
                  sx={{ flex: 'none' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Total Users'
                  secondary={conversationStatistic?.TotalUsers || '-'}
                  sx={{ flex: 'none' }}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Number Of Users'
                  secondary={subscription.NumberOfUsers || '-'}
                  sx={{ flex: 'none' }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Channel Count'
                  secondary={conversationStatistic?.ChannelCount || '-'}
                  sx={{ flex: 'none' }}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Discount'
                  secondary={
                    <>
                      {tenantDiscount ? (
                        <>
                          {numberToPercent(+tenantDiscount.Total || 0) +
                            ` (${tenantDiscount.StartDate} - ${tenantDiscount.EndDate})`}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  }
                  sx={{ flex: 'none' }}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Total Discount'
                  secondary={
                    tenantDiscount?.Total?.toString()
                      ? tenantDiscount?.Total + '%'
                      : '-'
                  }
                  sx={{flex: 'none'}}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='Start Date'
                  secondary={tenantDiscount?.StartDate || '-'}
                  sx={{flex: 'none'}}
                />
              </Grid>
              <Grid item xs={3} justifyContent='center'>
                <ListItemText
                  primary='End Date'
                  secondary={tenantDiscount?.EndDate || '-'}
                  sx={{flex: 'none'}}
                />
              </Grid>
            </Grid> */}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

TenantSubscriptionDetail.propTypes = {
  subscription: PropTypes.object.isRequired,
  conversationStatistic: PropTypes.object,
};

export default React.memo(TenantSubscriptionDetail);
