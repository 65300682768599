export const isNumber = (value) => {
  return !isNaN(value) && typeof value === 'number';
};

export const calculateTotalPrices = (discountedTotalPrices, tax) => {
  if (!isNumber(discountedTotalPrices) || !isNumber(tax)) return 0;

  return Number(discountedTotalPrices) * (1 + Number(tax / 100));
};

export const calculateTotalPayment = (discountedTotalPrices, taxAmount) => {
  if (!isNumber(discountedTotalPrices) || !isNumber(taxAmount)) return 0;

  const roundedDiscount = Math.round(discountedTotalPrices * 100) / 100;
  const roundedTax = Math.round(taxAmount * 100) / 100;

  return roundedDiscount + roundedTax;
};

export const calculateDiscountedPrices = (totalPrice, discount) => {
  if (!isNumber(totalPrice) || !isNumber(discount)) return 0;

  return Math.round((Number(totalPrice) * (1 - Number(discount / 100))) * 100) / 100;
};

export const isDivisibleByHalf = (number) => {
  return Number(number) % 0.5 === 0;
};

export const calculateTaxAmount = (discountedTotalPrices, tax) => {
  if (!isNumber(discountedTotalPrices) || !isNumber(tax)) return 0;

  return Math.round((Number(discountedTotalPrices) * Number(tax / 100)) * 100) / 100;
};

export const calculateCancelledAmount = (bundles) => {
  const cancelled = (bundles ?? []).filter(b => !!b.IsCancelled);
  const revokedPoints = cancelled.reduce((total, bundle) => (total + bundle.Points), 0);
  const refunedAmount = cancelled.reduce((total, bundle) => (total + bundle.Price), 0);

  return {
    revokedPoints,
    refunedAmount,
  };
};