import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useAuthUser, useAuthMethod} from './AuthHooks';
import AppLoader from '../core/AppLoader';
import {useMsal} from '@azure/msal-react';
import {EventType} from '@azure/msal-browser';
import {setAuthToken} from '../services/auth/jwt-auth';
import {loginRequest} from '../../authConfig';
import useRefreshToken from './hooks/useRefreshToken';
import {b2cPolicies} from 'authConfig';

const AuthRoutes = ({children}) => {
  const {isLoading, isAzure} = useAuthUser();
  const {logoutAD, logoutFirebase} = useAuthMethod();
  const {processRefreshToken} = useRefreshToken();

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const {instance} = useMsal();

  const compareIssuingPolicy = (idTokenClaims, policyToCompare) => {
    let tfpMatches =
      idTokenClaims.hasOwnProperty('tfp') &&
      idTokenClaims['tfp'].toLowerCase() === policyToCompare.toLowerCase();
    let acrMatches =
      idTokenClaims.hasOwnProperty('acr') &&
      idTokenClaims['acr'].toLowerCase() === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
  };

  const callbackFailure = useCallback(() => {
    isAzure ? logoutAD() : logoutFirebase();
    localStorage.removeItem('contractor_portal_logout');
  }, [isAzure]);

  useEffect(() => {
    processRefreshToken();
  }, [processRefreshToken]);

  useEffect(() => {
    const checkLogout = () => {
      const logout = localStorage.getItem('contractor_portal_logout');
      // check refresh token before logout
      if (logout) {
        processRefreshToken(callbackFailure);
      }
    };

    window.addEventListener('storage', checkLogout);

    return () => {
      window.removeEventListener('storage', checkLogout);
    };
  });

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        if (event.payload.accessToken) {
          setAuthToken(event.payload.accessToken);
        }
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.editProfile,
          )
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                compareIssuingPolicy(
                  account.idTokenClaims,
                  b2cPolicies.names.signUpSignIn,
                ),
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword,
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: loginRequest.scopes,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event?.error?.errorMessage?.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
