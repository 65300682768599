import { RANGE_DATE } from 'pages/tenantDetail/constants';
import {
  DISABLED_BUNDLE_NEXT_STEP,
  SET_RECOMMENDED_BUNDLES,
  SET_BUNDLES,
  SET_BUNDLE_ORDERS,
  SET_BUNDLE_ORDERS_FILTERS,
  SET_TRANSACTION_FILTERS,
  SET_BUNDLE_ORDERS_LIST_PARAMS,
  SET_IS_BACK_FROM_ORDER_DETAILS,
  SET_IS_ADDED_NEW_ORDER,
  SET_IS_CANCELLED_ORDER,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_PARAMS,
  SET_IS_INIT_TRANSACTIONS,
  SET_LOADING_TRANSACTIONS,
} from 'shared/constants/ActionTypes';

const initialState = {
  disabledNext: false,
  storageRecommendedBundles: [],
  bundles: [],
  bundleOrders: null,
  filterList: {
    tenants: [],
    statuses: [],
  },
  transactionFilterList: {
    tenants: [],
    transactionTypes: [],
  },
  bundleOrdersListParams: {
    pageIndex: 1,
    pageSize: 10,
    searchTerm: ''
  },
  isBackFromOrderDetails: false,
  isAddedNewOrder: false,
  isCancelledOrder: false,
  transactions: [],
  transactionsParams: {
    pageIndex: 1,
    pageSize: 10,
    rangeDate: RANGE_DATE.THIS_YEAR,
  },
  isInitTransactions: true,
  loadingTransactions: false,
};

const bundleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DISABLED_BUNDLE_NEXT_STEP: {
      return {
        ...state,
        disabledNext: payload,
      };
    }
    case SET_RECOMMENDED_BUNDLES: {
      return {
        ...state,
        storageRecommendedBundles: payload,
      };
    }
    case SET_BUNDLES: {
      return {
        ...state,
        bundles: payload,
      };
    }
    case SET_BUNDLE_ORDERS:
      return {
        ...state,
        bundleOrders: payload
      }
    case SET_BUNDLE_ORDERS_FILTERS:
      return {
        ...state,
        filterList: payload,
      };
    case SET_TRANSACTION_FILTERS:
      return {
        ...state,
        transactionFilterList: payload,
      };
    case SET_BUNDLE_ORDERS_LIST_PARAMS:
      return {
        ...state,
        bundleOrdersListParams: payload,
      };
    case SET_IS_BACK_FROM_ORDER_DETAILS:
      return {
        ...state,
        isBackFromOrderDetails: payload,
      };
    case SET_IS_ADDED_NEW_ORDER:
      return {
        ...state,
        isAddedNewOrder: payload,
      };
    case SET_IS_CANCELLED_ORDER:
      return {
        ...state,
        isCancelledOrder: payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case SET_IS_INIT_TRANSACTIONS:
      return {
        ...state,
        isInitTransactions: payload,
      };
    case SET_TRANSACTIONS_PARAMS:
      return {
        ...state,
        transactionsParams: payload,
      };
    case SET_LOADING_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: payload,
      };
    default:
      return state;
  }
};

export default bundleReducer;
