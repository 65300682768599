import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateProfile,
  onAuthStateChanged
} from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDP7ZO0ImjHMt_S7R7f1ih4prbstYhn2Xc",
  authDomain: "joblogic-e76c2.firebaseapp.com",
  databaseURL: "https://joblogic-e76c2-default-rtdb.firebaseio.com",
  projectId: "joblogic-e76c2",
  storageBucket: "joblogic-e76c2.appspot.com",
  messagingSenderId: "36401120538",
  appId: "1:36401120538:web:bb9575c1e796b97b0ed8a3",
  measurementId: "G-PQGMG8KSTM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

export {
  db,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
  onAuthStateChanged,
  signOut,
};
