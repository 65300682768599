import React, { useEffect, useMemo, useState } from 'react';
import AppCard from '@tenant/core/AppCard';
import { Grid, ListItemText } from '@mui/material';
import LazyLoad from 'react-lazy-load';
import {
  formatNumber,
  getKeyByValue,
  numberToCurrency,
} from '@tenant/utility/helper/Utils';
import SMSUsage from './SMSUsage';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import moment from 'moment';
import { dateGroupTypes, dateRanges } from '@tenant/utility/constants/default';
import { inputFormat } from 'pages/tenantDetail/constants';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldTenantDetail } from 'redux/actions';

const DEFAULT_TYPE = 'This year';
const FIELD_NAME = 'smsUsages';

const SMSUsageStatistic = ({ tenantId }) => {
  const dispatch = useDispatch();
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD_NAME] ?? null,
    [tenant],
  );
  const { data = [], filter } = storeTenant ?? {};
  const {
    startDate = new moment().startOf('year').format(inputFormat),
    endDate = new moment().endOf('year').format(inputFormat),
    dateGroupType = dateGroupTypes[DEFAULT_TYPE],
    selectedType = DEFAULT_TYPE,
    dataLoaded,
  } = filter ?? {};

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectionType = (data) => {
    const { startDate, endDate, dateGroupType, keyName } = data || {};

    getStatistic({
      startDate,
      endDate,
      dateGroupType,
      selectedType: keyName,
    });
  };

  const getStatistic = async (filter) => {
    try {
      if (!tenantId) {
        return;
      }
      const { startDate, endDate, dateGroupType, selectedType } = filter || {};
      setIsLoading(true);
      const data = await jwtAxios.get(
        `/api/Twilio/Tenant/${tenantId}/SmsUsage`,
        {
          params: {
            dateGroupType,
            tenantId,
            startDate,
            endDate,
            selectedType,
          },
        },
      );
      if (!data.data) {
        return;
      }
      const isDay =
        getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day';

      dispatch(
        setFieldTenantDetail({
          tenantId,
          field: FIELD_NAME,
          data:
            data.data?.Statistic?.map((e) => ({
              ...e,
              Key: new moment(e.Key, inputFormat).format(
                isDay ? 'DD MMM' : 'MMM YYYY',
              ),
            })) ?? [],
          filter: {
            startDate,
            endDate,
            dateGroupType,
            selectedType,
            dataLoaded: true,
          },
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataLoaded) {
      return;
    }
    getStatistic({
      dateGroupType,
      startDate,
      endDate,
    });
  }, []);

  return (
    <AppCard
      title='SMS & Segment'
      action={
        <DateMenu
          defaultValue={selectedType}
          handleChangeValue={handleSelectionType}
        />
      }
    >
      <Grid container justifyContent={'stretch'} spacing={4}>
        <Grid item sm={12} md={4}>
          <AppCard sxStyle={{ textAlign: 'center' }}>
            <ListItemText
              primary='Total SMS'
              secondary={formatNumber(
                data.reduce((a, b) => a + +b.SmsUsage, 0),
              )}
              primaryTypographyProps={{
                fontWeight: 'bold',
                color: 'primary.main',
              }}
            />
          </AppCard>
        </Grid>
        <Grid item sm={12} md={4}>
          <AppCard sxStyle={{ textAlign: 'center' }}>
            <ListItemText
              primary='Total Segment'
              secondary={formatNumber(
                data.reduce((a, b) => a + +b.SegmentUsage, 0),
              )}
              primaryTypographyProps={{
                fontWeight: 'bold',
                color: 'secondary.main',
              }}
            />
          </AppCard>
        </Grid>
        <Grid item sm={12} md={4}>
          <AppCard sxStyle={{ textAlign: 'center' }}>
            <ListItemText
              primary='Total Cost'
              secondary={numberToCurrency(
                data.reduce((a, b) => a + +b.Price, 0),
                2,
                'GBP',
              )}
              primaryTypographyProps={{
                fontWeight: 'bold',
                color: 'success.main',
              }}
            />
          </AppCard>
        </Grid>
        <Grid item sm={12} xl={12}>
          <LazyLoad offset={100}>
            <SMSUsage data={data} isLoading={isLoading} />
          </LazyLoad>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default React.memo(SMSUsageStatistic);

SMSUsageStatistic.propTypes = {};
