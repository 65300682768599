import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { debounce } from '@tenant/utility/helper/Utils';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';

import useBundle from 'pages/tenantDetail/hooks/useBundle';

const BundleSelect = (props) => {
  const {
    error = false,
    helperText = '',
    value,
    variant,
    ...rest
  } = props;
  const { bundles } = useSelector(({ bundle }) => bundle);
  const {
    loadingBundle,
    getBundles,
  } = useBundle();
  const id = useId();

  const onOpen = useCallback(() => {
    if (!!bundles.length) return;

    getBundles();
  }, [bundles]);

  return (
    <Autocomplete
      id={`bundle-select-${id}`}
      name='bundle'
      disableClearable
      options={bundles || []}
      getOptionLabel={(option) => option.Name}
      isOptionEqualToValue={(option, value) => {
        return option?.Id === value?.Id;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.Id}>
            {option.Name}
          </li>
        );
      }}
      loading={loadingBundle}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          error={error}
          helperText={helperText}
          placeholder='Bundle'
        />
      )}
      onOpen={onOpen}
      value={value}
      {...rest}
    />
  );
};

export default React.memo(BundleSelect);
