import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { numberToCurrency, roundNumber } from '@tenant/utility/helper/Utils';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import DiscountTextField from '@tenant/core/App/TextField/DiscountTextField';

const XeroInvoiceInformation = ({
  xeroForm,
  onChangeXeroForm,
  onChangeDiscount,
}) => {
  const theme = useTheme();
  const isSmallerMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isEditDiscount, setIsEditDiscount] = useState(false);
  const [isEditAmount, setIsEditAmount] = useState(false);

  const columns = useMemo(
    () => [
      {
        field: 'Description',
        headerName: 'Description',
        width: 250,
        flex: isSmallerMediumScreen ? 1 : undefined,
        sortable: false,
        renderCell: ({ value }) => (
          <span style={{ whiteSpace: 'pre-line' }}>{value ?? '-'}</span>
        ),
      },
      {
        field: 'Quantity',
        headerName: 'Quantity',
        width: 75,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: ({ value }) => value ?? '-',
      },
      {
        field: 'Discount',
        headerName: 'Discount ' + (xeroForm?.IsPercent ? '(%)' : '(£)'),
        width: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderHeader: () => (
          <>
            Discount{' '}
            <AppGlossary
              title={
                'Discounts can be entered as a numeric value or a percentage. Include the % symbol for a percentage discount.'
              }
            />
          </>
        ),
        renderCell: (params) =>
          isEditDiscount ? (
            <DiscountTextField
              autoFocus
              size='small'
              value={
                xeroForm?.DiscountAmount || xeroForm?.DiscountPercent
                  ? xeroForm.DiscountAmount || xeroForm?.DiscountPercent + '%'
                  : ''
              }
              handleBlur={(value, isPercent) => {
                const quantity = params.row?.Quantity ?? 1;
                const discountAmount =
                  value > params.row?.Amount * quantity
                    ? params.row?.Amount * quantity
                    : value;
                setIsEditDiscount(false);
                onChangeDiscount({
                  DiscountAmount: !isPercent
                    ? roundNumber(discountAmount)
                    : undefined,
                  DiscountPercent: isPercent
                    ? roundNumber(params.row?.DiscountPercent)
                    : undefined,
                  Discount: isPercent ? value : discountAmount,
                });
              }}
              handleChange={(value, isPercent) => {
                onChangeXeroForm({
                  DiscountAmount: !isPercent ? value : undefined,
                  DiscountPercent: isPercent ? value : undefined,
                  Discount: value,
                  IsPercent: isPercent,
                });
              }}
              handleFocus={() => {
                const discountAmount = params.row.DiscountAmount
                  ? roundNumber(params.row.DiscountAmount, 2)
                  : undefined;
                onChangeXeroForm({
                  DiscountAmount: discountAmount,
                });
              }}
            />
          ) : (
            <Typography
              color='primary'
              fontSize={'inherit'}
              sx={{
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
              }}
            >
              {params.row.IsPercent
                ? (params.value ?? 0) + '%'
                : numberToCurrency(+params.value || 0, 2, 'GBP')}
            </Typography>
          ),
      },
      {
        field: 'TaxAmount',
        headerName: 'Tax Amount',
        width: 100,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params) =>
          numberToCurrency(
            ((params.row.TaxRate ?? 0) / 100) * (params.row.PerUserPrice ?? 0),
            2,
            'GBP',
          ),
      },
      {
        field: 'Amount',
        headerName: 'Amount',
        width: 125,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params) =>
          isEditAmount ? (
            <TextField
              value={params.value ?? ''}
              autoFocus
              type='number'
              size='small'
              onBlur={() => setIsEditAmount(false)}
              onChange={(e) => onChangeXeroForm({ Amount: e.target.value })}
            />
          ) : (
            numberToCurrency(+params.value || 0, 2, 'GBP')
          ),
      },
      {
        field: 'AmountDue',
        headerName: 'Amount Due',
        width: 125,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params) => numberToCurrency(+params.value || 0, 2, 'GBP'),
      },
    ],
    [
      xeroForm,
      isSmallerMediumScreen,
      isEditDiscount,
      isEditAmount,
      onChangeXeroForm,
    ],
  );

  const data = useMemo(
    () => [
      {
        id: 'SC',
        ...xeroForm,
      },
    ],
    [xeroForm],
  );

  const handleOnCellClick = useCallback((value) => {
    const isDiscountClick = value.field === 'Discount';

    if (isDiscountClick) {
      setIsEditDiscount(true);
    }
  }, []);

  return (
    <DataGrid
      disableDensitySelector
      disableSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      onRowClick={(params, event) => event.stopPropagation()}
      rows={data}
      columns={columns}
      onCellClick={handleOnCellClick}
      sx={{
        height: 125,
        '[class^=MuiDataGrid]': {
          '[class^=MuiDataGrid-cell]': { borderBottom: 'none' },
        },
      }}
    />
  );
};

export default React.memo(XeroInvoiceInformation);

XeroInvoiceInformation.propTypes = {
  onChangeXeroForm: PropTypes.func,
  onChangeDiscount: PropTypes.func,
  xeroForm: PropTypes.object,
};
