import {
  GET_TOTAL_COUNT_NOTIFICATION,
  GET_LIST_NOTIFICATION,
  SET_FIELD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_ALL_NOTIFICATION,
} from 'shared/constants/ActionTypes';

const initialState = {
  totalCount: 0,
  notifications: [],
  receiveNotifications: [],
  loading: false,
  loadingBtn: false,
  dataLoaded: false,
  loadingIdBtn: '',
};

export default function (state = initialState, { payload, type }) {
  switch (type) {
    case GET_TOTAL_COUNT_NOTIFICATION:
      return {
        ...state,
        totalCount: payload,
      };

    case GET_LIST_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
        dataLoaded: true,
      };

    case SET_FIELD_NOTIFICATION:
      return {
        ...state,
        ...payload,
      };

    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications?.filter(
          (e) => e.Id !== payload.notificationId,
        ),
        totalCount: state.totalCount - 1,
      };

    case DISMISS_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: [],
        totalCount: 0,
      };

    default:
      return state;
  }
}
