import {
  GET_SUBCONTRACTOR_INDUSTRY,
  SET_FILTER_SUBCONTRACTOR_INDUSTRY,
} from 'shared/constants/ActionTypes';

const initialState = {
  data: [],
  type: 1
};

const subcontractorIndustryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCONTRACTOR_INDUSTRY: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case SET_FILTER_SUBCONTRACTOR_INDUSTRY: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default subcontractorIndustryReducer;
