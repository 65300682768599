import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';

import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import { BUNDLE } from '../constants';
import { BUNDLE_API } from '../constants/Api';
import { SET_BUNDLES, SET_RECOMMENDED_BUNDLES } from 'shared/constants/ActionTypes';

const useBundle = () => {
  const { showSuccessMessage, showErrorMessage } = useToast();
  const [loadingBundle, setLoadingBundle] = useState(false);
  const [loadingTableBundles, setLoadingTableBundles] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const dispatch = useDispatch();

  const getBundles = async (callback) => {
    try {
      setLoadingBundle(true);

      const response = await jwtAxios.get(BUNDLE_API.GET_BUNDLES);

      if (response.status === 200) {
        setLoadingBundle(false);
        const data = response?.data
          .filter(item => item.Id !== BUNDLE.EarlyAdoption)
          .map(item => ({
            ...item,
            Name: item.Id === BUNDLE.Topup ? 'Zero' : item.Name,
          }))
        callback?.(data || []);
        dispatch({ type: SET_BUNDLES, payload: data });
        return;
      };

      setLoadingBundle(false);
      showErrorMessage();

    } catch (err) {
      setLoadingBundle(false);
      showErrorMessage(err?.message);
    }
  };

  const getRecommendedBundlesByTenant = async (tenantId, callback) => {
    try {
      setLoadingTableBundles(true);

      // const response = await jwtAxios.get(`${BUNDLE_API.GET_RECOMMENDED_BUNDLE}/${tenantId}`);
      const response = await jwtAxios.get(`${BUNDLE_API.GET_RECOMMENDED_BUNDLES}`);

      if (response?.status === 200) {
        const data = response?.data.map(b => ({ ...b, id: uuidv4() }));
        callback?.(data);
        dispatch({ type: SET_RECOMMENDED_BUNDLES, payload: data });
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingTableBundles(false);
    }
  };

  const purchasePoints = async ({ payload, successMsg, cbSuccess, cbError }) => {
    try {
      if (!payload) return;

      setIsSubmittingForm(true);
      const response = await jwtAxios.post(`${BUNDLE_API.PURCHASE_POINTS}`, {
        ...payload,
      });

      if (response?.status === 200 && response?.data?.Success) {
        showSuccessMessage(successMsg);
        cbSuccess?.(response?.data?.ObjectData);
        return;
      };
      showErrorMessage(response?.data?.ErrorMsg);
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
      cbError?.();
    } finally {
      setIsSubmittingForm(false);
    }
  };

  return {
    loadingBundle,
    loadingTableBundles,
    isSubmittingForm,
    getBundles,
    getRecommendedBundlesByTenant,
    purchasePoints,
  };
};

export default useBundle;
