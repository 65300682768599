import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AppCard from '@tenant/core/AppCard';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { API } from 'pages/apps/ProductUsage/constants';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from '../constants';
import { groupData } from 'pages/apps/ProductUsage/helpers';
import { Grid } from '@mui/material';
import TableChart from '@tenant/core/App/Chart/TableChart';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const PointUsagePerProduct = ({ tenantId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);

  const columns = useMemo(
    () => [
      {
        field: 'ProductType',
        headerName: 'Category',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Typography> {params.value}</Typography>
        ),
      },
      {
        field: 'TotalPoints',
        headerName: 'Points',
        sortable: false,
        type: "number",
        minWidth: 120,
        renderCell: (params) => (
          <HtmlTooltip
            title={
              <Fragment>
                <Box>
                  {params.row.Products.map((e) => (
                    <Grid container key={e.ProductId}>
                      {e.ProductMode ? (
                        <Grid item xs={10}>
                          <Typography>{e.ProductName} - {e.ProductMode}</Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={10}>
                          <Typography> {e.ProductName}</Typography>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <Typography> {e.Points}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Fragment>
            }
            placement="left-start"
          >
            <Typography
              sx={{ color: 'success.main' }}
            >
              {params.value}
            </Typography>
          </HtmlTooltip>
        ),
      },
      {
        field: 'TotalCount',
        headerName: 'Quantity',
        sortable: false,
        type: "number",
        minWidth: 120,
        renderCell: (params) => (
          <HtmlTooltip
            title={
              <Fragment>
                <Box>
                  {params.row.Products.map((e) => (
                    <Grid container key={e.ProductId}>
                      {e.ProductMode ? (
                        <Grid item xs={10}>
                          <Typography>{e.ProductName} - {e.ProductMode}</Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={10}>
                          <Typography> {e.ProductName}</Typography>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <Typography> {e.Count}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Fragment>
            }
            placement="right-start"
          >
            <Typography
              sx={{ color: 'primary.main' }}
            >
              {params.value}
            </Typography>
          </HtmlTooltip>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    getList();
  }, [tenantId]);

  const getList = useCallback(
    async (selectedKey) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(selectedKey || rangeDate);
        const response = await jwtAxios.get(API.GET_TENANT_POINTS_USAGE_STATISTIC, {
          params: {
            TenantId: tenantId,
            StartDate: start,
            EndDate: end,
          },
        });

        if (!Array.isArray(response?.data?.ObjectData)) { return; }
        const grouped = groupData(response?.data?.ObjectData);
        setData(grouped);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate]
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    getList(keyName);
  };

  return (
    <>
      <AppCard
        title={
          <Box display='flex' alignItems='center'>
            <Box mr={2}>Points Usage Per Category</Box>
          </Box>
        }
        sxStyle={{
          height: '100%',
        }}
        action={
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleChangeDateRange}
          />
        }
      >
        <TableChart
          isLoading={isLoading}
          rows={data}
          columns={columns}
          uniqueProp={'ProductType'}
        />
      </AppCard>
    </>
  );
};

PointUsagePerProduct.propTypes = {
  tenantId: PropTypes.string.isRequired,
};

export default React.memo(PointUsagePerProduct);
