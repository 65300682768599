import React, { useEffect, useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  LineChart,
} from 'recharts';
import PropTypes from 'prop-types';
import {
  REQUEST_STATUS_ENUM_KEY,
  REQUEST_STATUS_ENUM,
  STATISTIC_DATE_GROUP_TYPE,
} from '@tenant/utility/constants/enum';
import {
  formatNumber,
  getFontSizeLabelChart,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';
import { formatDate } from '@tenant/utility/helper/DateHelper';

const COLOR = {
  All: '#222222',
  Success: '#7ccc63',
  Failure: '#d41243',
  'Rate Limit': '#bd33a4',
};

const PublicAPIChart = ({ data, dateGroupType }) => {
  const [chartData, setChartData] = useState([]);
  const status = useMemo(
    () =>
      Object.keys(REQUEST_STATUS_ENUM).map((key) => REQUEST_STATUS_ENUM[key]),
    [],
  );
  const isDay = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day',
    [dateGroupType],
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const newChartData = data.map((e) => ({
      Key: isDay ? formatDate(e.Key, 'DD MMM') : formatDate(e.Key, 'MMM YYYY'),
      [REQUEST_STATUS_ENUM_KEY[0]]:
        e.RequestStatistic?.find(
          (j) => j.RequestStatusDescription === REQUEST_STATUS_ENUM_KEY[0],
        )?.Count ?? 0,
      [REQUEST_STATUS_ENUM_KEY[1]]:
        e.RequestStatistic?.find(
          (j) => j.RequestStatusDescription === REQUEST_STATUS_ENUM_KEY[1],
        )?.Count ?? 0,
      [REQUEST_STATUS_ENUM_KEY[2]]:
        e.RequestStatistic?.find(
          (j) => j.RequestStatusDescription === REQUEST_STATUS_ENUM_KEY[2],
        )?.Count ?? 0,
      [REQUEST_STATUS_ENUM_KEY[3]]:
        e.RequestStatistic?.find(
          (j) => j.RequestStatusDescription === REQUEST_STATUS_ENUM_KEY[3],
        )?.Count ?? 0,
    }));
    setChartData(newChartData);
  }, [data, isDay]);

  return (
    <ResponsiveContainer width='100%' height={350}>
      <LineChart
        data={chartData}
        dataset={data}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey='Key'
          tickLine={false}
          axisLine={false}
          padding={{ left: 20, right: 20 }}
          fontSize={getFontSizeLabelChart(data?.length)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          formatter={(value) => formatNumber(value)}
        />
        <Legend verticalAlign='top' height={35} />
        <CartesianGrid stroke='#eee' horizontal={true} vertical={false} />
        {status?.length > 0
          ? status.map((e) => (
              <Line
                key={e}
                type='monotone'
                dataKey={REQUEST_STATUS_ENUM_KEY[e]}
                stroke={COLOR[REQUEST_STATUS_ENUM_KEY[e]]}
                dot={false}
                strokeWidth={2}
                activeDot={{ r: 4 }}
              />
            ))
          : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(PublicAPIChart);

PublicAPIChart.defaultProps = {
  data: [],
};

PublicAPIChart.propTypes = {
  data: PropTypes.array,
  dateGroupType: PropTypes.number,
};
