import axios from 'axios';
import {loginRequest} from 'authConfig';
import {msalInstance} from 'msalIntance';
import { convertToJSON } from '@tenant/utility/Utils';

const {REACT_APP_HOST} = process.env;

const jwtAxios = axios.create({
  baseURL: REACT_APP_HOST, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});

jwtAxios.interceptors.request.use(
  (config) => {
    const TOKEN = localStorage.getItem('token');

    if (TOKEN) {
      config.headers['Authorization'] = `Bearer ${TOKEN}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

jwtAxios.interceptors.response.use(
  (res) => {
    try {
      if (typeof res?.data === 'string') {
        res.data = convertToJSON(res.data);
      }
      return res;
    } catch {
      throw new Error('Response parse error.');
    }
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
    }
    if (err?.response?.status === 401) {
      originalRequest._retry = true;
      //place your reentry code
      localStorage.setItem('contractor_portal_logout', true);
      const account = msalInstance.getActiveAccount();
      // await window.dispatchEvent(new Event('storage'));
      const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: account,
      };
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest,
      );
      const accessToken = accessTokenResponse.accessToken;
      if (accessToken) {
        setAuthToken(accessToken);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        originalRequest.headers.Accept = 'application/json';
        originalRequest.headers['Content-Type'] = 'application/json';
      } else {
        console.error('Invalid 401');
      }
      return jwtAxios(originalRequest);
    }

    err.message = err?.response?.data && typeof(err?.response?.data) === 'string' && err?.response?.data || err?.response?.data?.title  || err?.message;
    return Promise.reject(err);
  },
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    jwtAxios.defaults.headers.common['Accept'] = 'application/json';
    jwtAxios.defaults.headers.common['Content-Type'] = 'application/json';
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
