export const convertTenantAddFormBeforeSave = (form = {}) => {
  return {
    ...form,
    Country: undefined,
    Group: undefined,
    Industry: undefined,
    MembershipSchemes: undefined,
    NumberOfUsers: undefined,
    SeedTemplate: undefined,
    TypeOfCustomers: undefined,
    IndustryId: form.Industry?.Key,
    NumberOfUserId: form.NumberOfUser?.Key,
    InitialTenantTemplateId: form.SeedTemplate?.Id,
    CountryCode: form.Country?.code,
    TypeOfCustomersId: form.TypeOfCustomers?.Key,
    ReferralCode: form.MembershipSchemes?.ReferralCode,
    TrackerGroupId: form.Group?.Id,
  };
};
