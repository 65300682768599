import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import useModal from '@tenant/utility/hooks/useModal';
import { IconButton, Tooltip } from '@mui/material';
import useMappingService from '../hooks/useMappingService';
import { LoadingButton } from '@mui/lab';
import AddCardIcon from '@mui/icons-material/AddCard';
import StepAddInvoice from './StepAddInvoice';
import AppLoader from '@tenant/core/AppLoader';
import { Code } from 'react-content-loader';
import { checkIsPercentage } from '@tenant/utility/helper/Utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddLicenseButton = ({ mapping, onGetExistingMappings }) => {
  const [detail, setDetail] = useState();
  const [contract, setContract] = useState();
  const { visible, onShow, onClose } = useModal();
  const {
    visible: visibleConfirm,
    onShow: onShowConfirm,
    onClose: onCloseConfirm,
  } = useModal();
  const {
    loadingBtn,
    generateInvoice,
    generateInvoiceByMappingId,
    getInvoiceInfo,
    getInvoiceInfoByMappingId,
    loading,
    getContractByTenant,
  } = useMappingService();

  const handleShow = useCallback(async () => {
    onShow();
    if (detail) {
      return;
    }

    if (mapping?.MainTenantId && !contract) {
      await getContractByTenant({
        tenantId: mapping.MainTenantId,
        onSuccess: (data) => {
          setContract(data?.[0]);
        },
      });
    }

    if (mapping?.RequestId) {
      getInvoiceInfo({ requestId: mapping?.RequestId }, (data) => {
        setDetail(data ? { ...data } : undefined);
      });
      return;
    }

    getInvoiceInfoByMappingId(
      { mappingId: mapping?.MappingId, mainTenantId: mapping?.MainTenantId },
      (data) => {
        setDetail(data ? { ...data } : undefined);
      },
    );
  }, [mapping?.RequestId, getInvoiceInfo, detail]);

  const onChangeDiscount = useCallback((newDiscount) => {
    const isPercent = checkIsPercentage(newDiscount);
    setDetail((s) => ({
      ...s,
      Discount: newDiscount,
      isPercent,
    }));
  }, []);

  return (
    <>
      {mapping?.RequestId || (mapping?.MainTenantId && mapping?.MappingId) ? (
        <Tooltip title='Add Access License'>
          <IconButton color='primary' onClick={handleShow}>
            <AddCardIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton color='primary' disabled>
          <AddCardIcon />
        </IconButton>
      )}

      {visibleConfirm && (
        <Dialog
          fullWidth
          maxWidth='sm'
          open={visibleConfirm}
          TransitionComponent={Transition}
          keepMounted
          onClose={onCloseConfirm}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle fontSize={16}>Confirm Add Access License?</DialogTitle>
          <DialogContent>
            <DialogContentText mt={2}>
              {!!mapping && (
                <>
                  The Main contractor{' '}
                  <Box
                    component={'span'}
                    sx={{ color: 'primary.main', fontWeight: 550 }}
                  >
                    {mapping?.MainTenantName}
                  </Box>{' '}
                  will pay the access license fee for Subcontractor Tenant{' '}
                  <Box
                    component={'span'}
                    sx={{ color: 'secondary.main', fontWeight: 550 }}
                  >
                    {mapping?.SubTenantName}
                  </Box>
                  .
                </>
              )}{' '}
              Are you sure to add access license ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='secondary' variant='text' onClick={onCloseConfirm}>
              No
            </Button>
            <LoadingButton
              loading={loadingBtn}
              variant='contained'
              onClick={() => {
                if (mapping?.RequestId) {
                  generateInvoice(
                    {
                      RequestId: mapping?.RequestId,
                      Discount: detail?.Discount,
                      ContractId: contract?.ContractId,
                      isPercent: detail?.isPercent,
                    },
                    (data) => {
                      onCloseConfirm();
                      onClose();
                      onGetExistingMappings(mapping?.MainTenantId);
                    },
                  );
                } else {
                  generateInvoiceByMappingId(
                    {
                      MappingId: mapping?.MappingId,
                      MainTenantId: mapping?.MainTenantId,
                      Discount: detail?.Discount,
                      ContractId: contract?.ContractId,
                      isPercent: detail?.isPercent,
                    },
                    (data) => {
                      onCloseConfirm();
                      onClose();
                      onGetExistingMappings(mapping?.MainTenantId);
                    },
                  );
                }
              }}
              color='primary'
            >
              Yes, Add Access License!
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {visible && (
        <Dialog
          fullWidth
          maxWidth='md'
          open={visible && !visibleConfirm}
          TransitionComponent={Transition}
          keepMounted
          onClose={onClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle fontSize={16}>Add Access License?</DialogTitle>
          <DialogContent>
            {loading ? (
              <Code />
            ) : (
              <StepAddInvoice
                detail={detail}
                onChangeDiscount={onChangeDiscount}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={onClose}>
              Close
            </Button>
            <LoadingButton
              disabled={loading || !detail?.Quantity}
              loading={loadingBtn}
              variant='contained'
              color='primary'
              onClick={onShowConfirm}
            >
              Add Access License
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(AddLicenseButton);

AddLicenseButton.propTypes = {
  mapping: PropTypes.object,
  onGetExistingMappings: PropTypes.func,
};
