import React from 'react';
import PropTypes from 'prop-types';
import AppGridContainer from '@tenant/core/AppGridContainer';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  getFullAddressTenant,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';
import {
  SUBSCRIPTION_ACTIVATION_TYPE_ENUM,
  SubscriptionStatusEnum,
} from '@tenant/utility/constants/enum';
import AppCard from '@tenant/core/AppCard';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import { inputFormat } from '@tenant/utility/constants/default';
import { Box } from '@mui/material';

const InformationTenantDescription = ({ tenant, header, sxStyle }) => {
  return (
    <AppCard
      sxStyle={{ border: '1px solid #ddd', boxShadow: 'none', ...sxStyle }}
    >
      <Typography gutterBottom variant='h5' component='div'>
        {header || 'Tenant Information'}
      </Typography>
      <AppGridContainer>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText primary='ID' secondary={tenant.TenantId || '-'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText
            primary='Subscription Start'
            secondary={
              tenant.StartDate ? formatDate(tenant.StartDate, inputFormat) : '-'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText
            primary='Subscription End'
            secondary={
              tenant.EndDate ? formatDate(tenant.EndDate, inputFormat) : '-'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText
            primary='Subscription Status'
            secondary={
              tenant.SubscriptionStatus ? (
                <Box
                  component={'span'}
                  color={
                    tenant.SubscriptionStatus !==
                      SubscriptionStatusEnum['Active'] && 'error.main'
                  }
                  sx={{ textTransform: 'uppercase' }}
                >
                  {tenant.SubscriptionStatus}
                </Box>
              ) : (
                '-'
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText
            primary='Payment Type'
            secondary={
              tenant.SubscriptionActivationType
                ? getKeyByValue(
                    SUBSCRIPTION_ACTIVATION_TYPE_ENUM,
                    tenant.SubscriptionActivationType,
                  )
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <ListItemText
            primary='Email Address'
            secondary={tenant.EmailAddress || '-'}
          />
        </Grid>
      </AppGridContainer>
    </AppCard>
  );
};

export default React.memo(InformationTenantDescription);

InformationTenantDescription.propTypes = {
  tenant: PropTypes.object.isRequired,
  header: PropTypes.string,
  sxStyle: PropTypes.object,
};
