const useInvoice = () => {
  const formatDiscountValue = (discount, Quantity, UnitPrice) => {
    const Discount = discount ?? 0;
    const isPercent =
      typeof Discount === 'string'
        ? Discount.includes('%')
        : Discount.toString().includes('%');
    if (isPercent) {
      return (
        (Quantity * UnitPrice * parseFloat(Discount.replace('%', ''))) / 100
      );
    }
    return parseFloat(Discount) || 0;
  };

  const calculatorLineByQuantity = (line, quantity) => {
    const Amount = line.UnitPrice * quantity;
    const discountValue = formatDiscountValue(
      line?.Discount,
      quantity,
      line?.UnitPrice,
    );
    const TaxAmount =
      ((quantity * line.UnitPrice - discountValue) * line.TaxRate) / 100;
    return {
      ...line,
      Quantity: quantity,
      Amount,
      TaxAmount,
      AmountDue: Amount + TaxAmount - discountValue,
    };
  };
  const calculatorLineByUnitPrice = (line, unitPrice) => {
    const Amount = line.Quantity * unitPrice;
    const discountValue = formatDiscountValue(
      line?.Discount,
      line.Quantity,
      unitPrice,
    );
    const TaxAmount =
      ((line.Quantity * unitPrice - discountValue) * line.TaxRate) / 100;
    if (Amount + TaxAmount - discountValue < 0)
      return { ...line, UnitPrice: unitPrice, AmountDue: 0, TaxAmount: 0 };
    return {
      ...line,
      UnitPrice: unitPrice,
      Amount,
      TaxAmount,
      AmountDue: Amount + TaxAmount - discountValue,
    };
  };
  const calculatorLineByDiscount = (line, discount) => {
    const Amount = line.Quantity * line.UnitPrice;
    let discountValue = formatDiscountValue(
      discount,
      line.Quantity,
      line.UnitPrice,
    );
    if(isNaN(discountValue) || discountValue === undefined || discountValue === null){
      discountValue = 0;
    }
    const TaxAmount =
      ((line.Quantity * line.UnitPrice - discountValue) * line.TaxRate) / 100;
    if (Amount + TaxAmount - discountValue < 0)
      return {
        ...line,
        Discount: discount,
        DiscountValue: TaxAmount < 0 ? Amount : Amount + TaxAmount,
        AmountDue: 0,
        TaxAmount: 0,
      };
    return {
      ...line,
      Discount: discount,
      DiscountValue: discountValue,
      Amount,
      TaxAmount,
      AmountDue: Amount + TaxAmount - discountValue,
    };
  };
  const calculatorLineByTaxRate = (line, taxRate) => {
    const Amount = line.Quantity * line.UnitPrice;
    const discountValue = formatDiscountValue(
      line?.Discount,
      line.Quantity,
      line.UnitPrice,
    );
    const TaxAmount =
      ((line.Quantity * line.UnitPrice - discountValue) * taxRate) / 100;
    return {
      ...line,
      Amount,
      TaxRate: taxRate,
      TaxAmount,
      AmountDue: Amount + TaxAmount - discountValue,
    };
  };

  const calculatorLineByAmount = (line, amount) => {
    const UnitPrice = amount / line.Quantity;
    const discountValue = formatDiscountValue(
      line?.Discount,
      line.Quantity,
      UnitPrice,
    );
    const TaxAmount =
      (line.TaxRate * (UnitPrice * line.Quantity - discountValue)) / 100;

    return {
      ...line,
      Amount: amount,
      UnitPrice,
      TaxAmount,
      AmountDue: amount + TaxAmount - discountValue,
    };
  };

  const calculatorLineByAmountDue = (line, amountDue) => {
    const UnitPrice =
      (amountDue / (1 + line.TaxRate / 100) + discountValue) / line.Quantity;
    const discountValue = formatDiscountValue(
      line?.Discount,
      line.Quantity,
      UnitPrice,
    );
    const TaxAmount =
      (line.TaxRate * (UnitPrice * line.Quantity - discountValue)) / 100;
    return {
      ...line,
      UnitPrice,
      TaxAmount,
      AmountDue: amountDue,
    };
  };

  return {
    calculatorLineByQuantity,
    calculatorLineByUnitPrice,
    calculatorLineByDiscount,
    calculatorLineByTaxRate,
  };
};

export default useInvoice;
