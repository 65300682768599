import {
    GET_LIST_VEHICLE_TENANT,
    GET_LIST_DEVICE_CONTRACT_ITEM,
  } from 'shared/constants/ActionTypes';
  
  const initialState = {
    listVehiclesTenant: {
      TenantId: 0,
      Items: [],
    },
    listDeviceContractItem: []
  };
  
  const vehicleTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LIST_VEHICLE_TENANT:
        return {
          ...state,
          listVehiclesTenant: action.payload,
        };
      case GET_LIST_DEVICE_CONTRACT_ITEM:
        return {
          ...state,
          listDeviceContractItem: action.payload,
        };
      default:
        return state;
    }
  };
  export default vehicleTrackingReducer;
  