import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AddCardIcon from '@mui/icons-material/AddCard';

export const defaultValue = {
  Type: 'License',
  Package: 500,
};

export const RootURL = '/apps/mapping-manage';

export const steps = [
  {
    name: 'Select a Main Contractor',
    step: 0,
    key: 'select-subcontractor',
    icon: <SettingsIcon />,
  },
  {
    name: 'Mapping List',
    step: 1,
    key: 'select-mapping',
    icon: <GroupAddIcon />,
  },
];
