import React from 'react';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Box from '@mui/material/Box';
import { statusData } from 'shared/constants/AppsContants';
import PropTypes from 'prop-types';

const Status = ({ status, statusName, statusDataCustom }) => {
  return (
    <Box component='span' display='flex' alignItems='center'>
      <Brightness1Icon
        sx={{
          color: statusDataCustom
            ? statusDataCustom[status]?.Color
            : statusData[status]?.Color,
          fontSize: 14,
          marginRight: 1,
        }}
      />
      {statusName ||
        (statusDataCustom
          ? statusDataCustom[status]?.Name
          : statusData[status]?.Name)}
    </Box>
  );
};

Status.propTypes = {
  status: PropTypes.number.isRequired,
  statusName: PropTypes.string,
  statusDataCustom: PropTypes.object,
};

export default React.memo(Status);
