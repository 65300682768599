import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import { Box } from '@mui/material';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from './Form';
import { Formik } from 'formik';
import useTenantAddService from './useTenantAddService';
import AppLoader from '@tenant/core/AppLoader';
import { LoadingButton } from '@mui/lab';
import {
  validationSchema,
  validationSchemaMembershipNumber,
} from './constants';
import { deepClone } from '@mui/x-data-grid/utils/utils';

const initialValues = {
  Region: 'UK',
  Country: { code: 'GB', label: 'United Kingdom', phone: '44' },
};

const PATH = '/tenant/add';

const TenantAdd = () => {
  const [savedForm, setSavedForm] = useState();
  const { getListCompanyInfo, createTenant, configGroup, loading, loadingBtn } =
    useTenantAddService();
  const navigate = useNavigate();
  const [disabledMemberShip, setDisabledMemberShip] = useState(false);
  const location = useLocation();

  const onClickBackButton = useCallback((values) => {
    navigate(-1);
    setSavedForm(deepClone(values));
  }, []);

  const goToDetail = useCallback((companyId) => {
    if (companyId) {
      navigate(`/company/${companyId}`);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === PATH && !configGroup) {
      getListCompanyInfo();
    }
  }, [getListCompanyInfo, location.pathname]);

  return (
    <>
      {(loading || loadingBtn) && <AppLoader />}
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={savedForm || initialValues}
        validationSchema={
          disabledMemberShip
            ? validationSchema
            : validationSchema.concat(validationSchemaMembershipNumber)
        }
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          createTenant(
            {
              ...data,
              MembershipNumber: data?.MembershipNumber?.toString() ?? undefined,
            },
            (result) => {
              goToDetail(result?.CompanyId);
              resetForm();
            },
          );
        }}
      >
        {({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          validateField,
        }) => (
          <>
            <AppsHeader>
              <Box
                sx={{
                  cursor: 'pointer',
                }}
                component='span'
                mr={{ xs: 2, sm: 4 }}
                onClick={() => onClickBackButton(values)}
              >
                <AppTooltip title={<IntlMessages id='common.back' />}>
                  <ArrowBackIcon
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                </AppTooltip>
              </Box>
            </AppsHeader>

            <Box
              p={4}
              pb={8}
              sx={{
                overflow: 'auto',
                height: 'calc(100% - 100px)',
                position: 'relative',
              }}
            >
              <Form
                values={values}
                setFieldValue={setFieldValue}
                validateField={validateField}
                errors={errors}
                industries={configGroup?.Industries}
                numberOfUsers={configGroup?.NumberOfUsers}
                typeOfCustomers={configGroup?.TypeOfCustomers}
                templates={configGroup?.Templates}
                membershipSchemes={configGroup?.MembershipSchemes}
                trackerClientGroups={configGroup?.TrackerClientGroups}
                setDisabledMemberShip={setDisabledMemberShip}
              />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#f4f7fe',
                px: 5,
                py: 2,
              }}
            >
              <LoadingButton
                color='secondary'
                onClick={() => onClickBackButton(values)}
                sx={{ mr: 2 }}
              >
                Back
              </LoadingButton>
              <LoadingButton
                variant='contained'
                loading={loadingBtn}
                onClick={submitForm}
              >
                Create
              </LoadingButton>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

export default React.memo(TenantAdd);

TenantAdd.propTypes = {};
