import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';


import { API } from '../constants';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { BUNDLE_OBJECT } from 'pages/tenantDetail/constants';
import IntlMessages from '@tenant/utility/IntlMessages';


const rowsPerPage = 10;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} component={Paper} />),
  Table: (props) => <Table {...props} stickyHeader />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const columns = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 150,
    headerName: 'Company',
    sortable: false,
  },
  {
    field: 'Micro',
    maxWidth: 70,
    headerName: BUNDLE_OBJECT.Micro.Label,
    sortable: false,
  },
  {
    field: 'Bronze',
    maxWidth: 70,
    headerName: BUNDLE_OBJECT.Bronze.Label,
    sortable: false,
  },
  {
    field: 'Silver',
    maxWidth: 70,
    headerName: BUNDLE_OBJECT.Silver.Label,
    sortable: false,
  },
  {
    field: 'Gold',
    maxWidth: 80,
    headerName: BUNDLE_OBJECT.Gold.Label,
    sortable: false,
  },
  {
    field: 'Platinum',
    maxWidth: 80,
    headerName: BUNDLE_OBJECT.Platinum.Label,
    sortable: false,
  },
  {
    field: 'AddOn',
    maxWidth: 80,
    headerName: BUNDLE_OBJECT.Topup.Label,
    sortable: false,
  },
  {
    field: 'EarlyAdoption',
    minWidth: 120,
    headerName: BUNDLE_OBJECT.EarlyAdoption.Label,
    sortable: false,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      left: 2,
    }
  })
);

const TenantPurchasesStatistic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async ({ keyName, pageIndex = 1, } = {}) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(keyName || rangeDate);
        const response = await jwtAxios.get(API.GET_COMPANY_PURCHASE_DISTRIBUTION, {
          params: {
            FromDate: start,
            ToDate: end,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
          }
        });

        if (response?.status === 200 && response?.data?.Success) {

          const data = generateChartData(response?.data?.ObjectData?.Items);
          if (pageIndex === 1) {
            setData(data);
          } else {
            setData((s) => [...s, ...data]);
          }

          setTotal(response?.data?.ObjectData?.Total);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate]
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    setPage(1);
    getList({ keyName });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) return;

    getList({ pageIndex: page + 1 });
    setPage((prevPage) => prevPage + 1);
  };

  const generateChartData = useCallback((raw = []) => {
    return raw.map(data => {
      const micro = getPurchasedBundleData(BUNDLE_OBJECT.Micro.Label, data.PurchaseDistribution);
      const bronze = getPurchasedBundleData(BUNDLE_OBJECT.Bronze.Label, data.PurchaseDistribution);
      const gold = getPurchasedBundleData(BUNDLE_OBJECT.Gold.Label, data.PurchaseDistribution);
      const silver = getPurchasedBundleData(BUNDLE_OBJECT.Silver.Label, data.PurchaseDistribution);
      const addOn = getPurchasedBundleData(BUNDLE_OBJECT.Topup.Label, data.PurchaseDistribution);
      const earlyAdoption = getPurchasedBundleData(BUNDLE_OBJECT.EarlyAdoption.Label, data.PurchaseDistribution);
      const platinum = getPurchasedBundleData(BUNDLE_OBJECT.Platinum.Label, data.PurchaseDistribution);

      return {
        CompanyId: data.TenantId,
        TotalPurchases: data.TotalPurchases ?? 0,
        CompanyName: data.CompanyName || 'N/A',
        ...micro,
        ...bronze,
        ...gold,
        ...silver,
        ...addOn,
        ...earlyAdoption,
        ...platinum,
      }
    })
  }, []);

  const getPurchasedBundleData = useCallback((bundle, purchased) => {
    const data = purchased.find(b => b.BundleName.trim() === bundle);
    const keyName = bundle.replace(/\s/g, '');

    return {
      [keyName]: data ? data.Count : 0
    };
  }, []);

  return (
    <>
      <AppCard
        title={
          <Box display='flex' alignItems='center'>
            <Box mr={2}><IntlMessages id='dashboard.topCompnanyUsingBundles' /></Box>
          </Box>
        }
        action={
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleChangeDateRange}
          />
        }
        sxStyle={{
          position: 'relative'
        }}
      >
        {isLoading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!!data.length ? (
          <TableVirtuoso
            style={{ height: 325 }}
            data={data}
            components={TableComponents}
            endReached={handleReachedEnd}
            fixedHeaderContent={() => (
              <TableRow>
                <TableCell sx={{ minWidth: 150, maxWidth: 150, backgroundColor: '#FFFFFF' }}>
                  <IntlMessages id='common.company' />
                </TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>{BUNDLE_OBJECT.Micro.Label}</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>{BUNDLE_OBJECT.Bronze.Label}</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>{BUNDLE_OBJECT.Silver.Label}</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>{BUNDLE_OBJECT.Gold.Label}</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>{BUNDLE_OBJECT.Platinum.Label}</TableCell>
                <TableCell
                  align='right'
                  sx={{
                    minWidth: 80,
                    maxWidth: 80,
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  {BUNDLE_OBJECT.Topup.Label}
                </TableCell>
                <TableCell align='right'
                  sx={{
                    backgroundColor: '#FFFFFF',
                    minWidth: 120,
                  }}
                >
                  {BUNDLE_OBJECT.EarlyAdoption.Label}
                </TableCell>
              </TableRow>
            )}
            itemContent={(idx, item) => (
              <React.Fragment key={item?.CompanyId || idx}>
                <>
                  <TableCell sx={{ minWidth: 150, maxWidth: 150, }}>
                    <NavLink
                      className='MuiDataGrid-cellContent'
                      to={`/tenant/detail/${item.CompanyId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Tooltip title={item?.CompanyName}>
                        <Typography
                          variant='body2'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.CompanyName}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.[BUNDLE_OBJECT.Micro.Label]}
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.[BUNDLE_OBJECT.Bronze.Label]}
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.[BUNDLE_OBJECT.Silver.Label]}
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.[BUNDLE_OBJECT.Gold.Label]}
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.[BUNDLE_OBJECT.Platinum.Label]}
                  </TableCell>
                  <TableCell align='right'
                    sx={{
                      minWidth: 80,
                      maxWidth: 80,
                      color: 'primary.main',
                      fontWeight: 500,
                    }}
                  >
                    {item?.[BUNDLE_OBJECT.Topup.Label.replace(/\s/g, '')]}
                  </TableCell>
                  <TableCell align='right'
                    sx={{
                      minWidth: 120,
                      color: 'primary.main',
                      fontWeight: 500,
                    }}
                  >
                    {item?.[BUNDLE_OBJECT.EarlyAdoption.Label.replace(/\s/g, '')]}
                  </TableCell>
                </>

              </React.Fragment>
            )}
          />
        ) : (
          <TableChart
            rows={[]}
            columns={columns}
            uniqueProp={''}
          />
        )}
      </AppCard>
    </>
  );
};

export default React.memo(TenantPurchasesStatistic);
