import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useFormik, FormikProvider, } from 'formik';
import {
  Slide,
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, } from 'react-router-dom';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';

import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppsContent from '@tenant/core/AppsContainer/AppsContent';
import AppLoader from '@tenant/core/AppLoader';
import FormHeader from 'pages/apps/components/FormHeader';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ColorlibConnector, ColorlibStepIconRoot } from 'pages/tenantDetail/styled/stepperStyled';
import StepSelectBundle from './StepSelectBundle';
import StepConfirm from './StepConfirm';
import useBundle from 'pages/tenantDetail/hooks/useBundle';
import { BUNDLE } from 'pages/tenantDetail/constants';
import ConfirmDialog from './ConfirmDialog';
import { appIntl } from '@tenant/utility/helper/Utils';
import StepSelectTenant from './StepSelectTenant';
import { SET_IS_ADDED_NEW_ORDER, SET_IS_BACK_FROM_ORDER_DETAILS } from 'shared/constants/ActionTypes';
import { setContractSummary } from 'redux/actions';

const stepBuyPoints = [
  {
    name: 'Select a Bundle',
    step: 0,
    key: 'select-bundle',
    icon: <PostAddIcon />,
  },
  {
    name: 'Confirm information',
    step: 1,
    key: 'confirm-information',
    icon: <DoneAllIcon />,
  },
];

const stepsAddNewOrder = [
  {
    name: 'Select a Tenant',
    step: 0,
    key: 'select-tenant',
    icon: <PeopleAltIcon fontSize='small' />,
  },
  {
    name: 'Select a Bundle',
    step: 1,
    key: 'select-bundle',
    icon: <PostAddIcon fontSize='small' />,
  },
  {
    name: 'Confirm information',
    step: 2,
    key: 'confirm-information',
    icon: <DoneAllIcon fontSize='small' />,
  },
];

const ColorlibStepIcon = (props) => {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
};

const BundleStepper = () => {
  const [formData, setFormData] = useState({
    tenantId: '',
    selectedBundles: [],
    allBundles: [],
    note: '',
    isGenerateInvoice: true,
    contractId: '',
  });
  const { id, contractId, companyId } = useParams();
  const navigate = useNavigate();
  const {
    disabledNext,
  } = useSelector(({ bundle }) => bundle);
  const {
    purchasePoints,
    isSubmittingForm,
  } = useBundle();
  const { messages } = appIntl();
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepper, setStepper] = useState([]);
  const dispatch = useDispatch();
  const { subscriptionInfo, } = useSelector(({ tenant }) => tenant);

  const isLastStep = useMemo(
    () => (activeStep === stepper.length - 1),
    [activeStep]
  );

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const formSelectBundle = useFormik({
    initialValues: {
      totalPurchasedPoints: 0,
      totalPrices: 0,
      allBundles: [],
      selectedBundles: [],
      discountedTotalPrices: 0,
      totalDiscount: 0,
      totalPayment: 0,
      tax: 0,
      taxAmount: 0,
    },
    onSubmit: (values) => {
      setFormData((s) => ({ ...s, ...values }));
      handleNext();
    },
  });

  const formConfirm = useFormik({
    initialValues: {
      note: '',
    },
    onSubmit: (values) => {
      setFormData((s) => ({ ...s, ...values }));
      handleOpenDialog();
    },
  });

  const formSelectTenant = useFormik({
    initialValues: {
      selectedTenant: null,
      tenantId: '',
      contractId: '',
    },
    validationSchema: yup.object({
      tenantId: yup.string().required().label('Tenant'),
    }),
    onSubmit: (values) => {
      setFormData((s) => ({ ...s, ...values }));
      handleNext();
    },
  });

  const handleSubmit = useCallback(() => {
    const stepKey = stepper.find((e) => e.step === activeStep)?.key || '';

    switch (stepKey) {
      case 'select-bundle': formSelectBundle.handleSubmit(); break;
      case 'confirm-information': formConfirm.handleSubmit(); break;
      case 'select-tenant': formSelectTenant.handleSubmit(); break;
      default: break;
    }
  }, [activeStep, stepper]);

  const handleFormSubmit = () => {
    handleCloseDialog();

    const bundles = formData.allBundles.map(b => ({
      BundleId: b.Id,
      Points: b.Id === BUNDLE.Topup ? b.Points : 0,
      DiscountPercent: b.DiscountPercent,
      Quantity: b.Quantity,
      Note: b.Note,
    }));
    const payload = {
      TenantId: formData.tenantId || id,
      ContractId: contractId || subscriptionInfo?.ContractId,
      Note: formData.note ?? '',
      BundlePurchases: bundles,
      GenerateXeroInvoice: formData.isGenerateInvoice,
    };

    purchasePoints({
      payload,
      successMsg: messages['message.buyBundleSuccess'],
      cbSuccess: (orderId) => {
        dispatch({ type: SET_IS_ADDED_NEW_ORDER, payload: true });
        if (!!id) {
          return navigate(
            `/apps/bundle-orders/all/${orderId}`,
            {
              state:
              {
                fromTenant: true,
                tenantId: id,
              }
            });
        }

        if (!!companyId) {
          return navigate(`/apps/bundle-orders/all/${orderId}`, { state: { fromCompany: true } });
        }

        return navigate(`/apps/bundle-orders/all/${orderId}`);
      },
    });
  };

  const getStepper = useCallback(() => {
    if (id || contractId) {
      setStepper(stepBuyPoints);
      return;
    }

    setStepper(stepsAddNewOrder);
  }, [id, contractId]);

  const getStepContent = (activeStep, steps) => {
    if (!steps.length) return;

    const stepKey = steps.find(e => e.step === activeStep)?.key || '';

    switch (stepKey) {
      case 'select-bundle':
        return (
          <FormikProvider value={formSelectBundle}>
            <StepSelectBundle {...formSelectBundle} />
          </FormikProvider>
        );
      case 'confirm-information':
        return (
          <StepConfirm
            formik={formConfirm}
            formData={formData}
            handleChangeCheckbox={(value) =>
              setFormData((s) => ({ ...s, isGenerateInvoice: value }))
            }
          />
        );
      case 'select-tenant':
        return (
          <StepSelectTenant {...formSelectTenant} />
        );
      default:
        return 'Unknown step';
    }
  };

  const handlePrev = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep]);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep]);

  const handleGoBack = useCallback(() => {
    !id && dispatch({ type: SET_IS_BACK_FROM_ORDER_DETAILS, payload: true });
  }, [id]);

  const goBackLink = useMemo(
    () => {
      if (!!id) return `/tenant/detail/${id}`;
      if (!!companyId) return `/company/${companyId}`;

      return '/apps/bundle-orders/all';
    },
    [id, companyId]
  );

  useEffect(() => {
    getStepper();

    return () => {
      dispatch(setContractSummary(null));
    }
  }, []);

  return (
    <>
      <Slide direction='left' in mountOnEnter timeout={500}>
        <Box
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <AppsHeader>
            <FormHeader
              goBackLink={goBackLink}
              leftSideContent={
                <Typography
                  component='h2'
                  variant='h2'
                  sx={{
                    overflow: 'hidden',
                    marginRight: 3.5,
                    fontSize: 14,
                    mb: { xs: 3, sm: 0 },
                    color: 'primary.main',
                  }}
                >
                  <IntlMessages id={id || contractId ? 'bundleContract.upgrade' : 'bundle.addNewOrder'} />
                </Typography>
              }
              handleGoBack={handleGoBack}
            />
          </AppsHeader>

          <AppsContent>
            {isSubmittingForm && <AppLoader />}
            <>
              <Box>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                >
                  {stepper.map((item) => (
                    <Step key={item.step}>
                      <StepLabel
                        StepIconProps={{
                          steps: stepper,
                          icon: item.icon,
                        }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        {item.name}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box
                  sx={{
                    padding: '12px 20px',
                    minHeight: '100%',
                    height: 'calc(100vh - 350px)',
                    overflow: 'auto'
                  }}
                >
                  {getStepContent(activeStep, stepper)}
                </Box>
              </Box>

              <MobileStepper
                variant='progress'
                steps={stepper.length}
                position='static'
                activeStep={activeStep}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  left: 0,
                  height: '50px',
                }}
                nextButton={
                  <Button
                    size='small'
                    onClick={handleSubmit}
                    disabled={isSubmittingForm || disabledNext}
                  >
                    {isLastStep ? 'Submit' : 'Next'}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size='small'
                    onClick={handlePrev}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </>
          </AppsContent>
        </Box>
      </Slide>

      <ConfirmDialog
        open={open}
        totalPurchasedPoints={formData.totalPurchasedPoints}
        isGenerateInvoice={formData.isGenerateInvoice}
        handleClose={handleCloseDialog}
        handleConfirm={handleFormSubmit}
        handleChangeCheckbox={(value) =>
          setFormData((s) => ({ ...s, isGenerateInvoice: value }))
        }
      />
    </>
  );
}

export default BundleStepper;