import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Slide,
  Box,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  Button,
} from '@mui/material';

import RequestForm from './RequestForm';
import useVehicleTracking from '../../hooks/useVehicleTracking';
import { onGetListRequestWorkflow } from 'redux/actions';
import moment from 'moment';
import { ColorlibStepIcon } from './ColorlibStepIcon';
import { ColorlibConnector } from 'pages/apps/Company/styles/stepperStyle';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import DraftInvoice from './DraftInvoice';
import AppLoader from '@tenant/core/AppLoader';

const validationSchema = yup.object({
  TenantId: yup.string().required(),
  RequestDate: yup.date().required(),
  Address: yup.string().required(),
  CustomerEmail: yup
    .string()
    .when('ShouldSendEmail', (shouldSendEmail) =>
      shouldSendEmail
        ? yup
            .string()
            .required('Email field is required.')
            .email('Invalid email format.')
        : yup.string(),
    ),
  // ListVehicle: yup.array().min(1),
});

const steps = ['Create Request', 'Draft Invoice'];

const RequestAdd = ({ callBack }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { createRequest } = useVehicleTracking();
  const { vehicleTracking } = useSelector((state) => state.requestWorkflow);
  const listDeviceContractItem = useSelector(
    ({ vehicleTracking }) => vehicleTracking.listDeviceContractItem,
  );

  const { pageIndex, pageSize, searchTerm, status, tenantIds } =
    vehicleTracking || {};
  const [activeStep, setActiveStep] = useState(0);
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      TenantId: '',
      RequestDate: moment(),
      ListVehicle: [],
      Invoices: [],
      Address: '',
      IsGenerateInvoice: true,
      ShouldSendEmail: false,
      CustomerEmail: '',
    }),
    [],
  );

  const onSubmit = useCallback(
    async ({ data, setSubmitting }) => {
      setLoading(true);
      const payload = {
        RequestDate: moment(data.RequestDate).format('DD/MM/YYYY'),
        NumberOfDevice: data.ListVehicle.length,
        TenantId: data.TenantId,
        Address: data.Address,
        TrackingDeviceContractItems: data.Invoices.map((item) => {
          return {
            ContractItemId: item.Id,
            Description: item.Description,
            Discount: item?.DiscountValue,
            Quantity: item.Quantity,
            Price: item.UnitAmount,
            SubTotal: item.Amount,
            Total: item.LineTotal,
            Vat: item.Vat,
            TaxAmount: item.TaxAmount,
            XeroItemId: item.XeroItemId,
            Code: item.Code,
            Currency: item.Currency,
            Frequency: item.Frequency,
            PackageType: item.PackageType,
            ProductName: item.ProductName,
            Type: item.Type,
          };
        }),
        VehicleIds: data.ListVehicle,
        IsGenerateInvoice: data.IsGenerateInvoice,
        ShouldSendEmail: data.ShouldSendEmail,
        CustomerEmail: data.CustomerEmail,
      };
      createRequest(payload, (item) => {
        if (item) {
          navigate('/vehicle-tracking/' + item.Id);
        }
        setSubmitting(false);
        setLoading(false);
        dispatch(
          onGetListRequestWorkflow({
            url: 'api/DeviceRequest',
            field: 'vehicleTracking',
            config: {
              params: {
                status,
                pageIndex: pageIndex || 1,
                pageSize: pageSize || 10,
                searchTerm,
                tenantIds,
              },
              paramsSerializer: { indexes: null },
            },
          }),
        );
      });
    },
    [callBack],
  );

  const handleSubmit = ({ data, setSubmitting, resetForm }) => {
    if (isLastStep) {
      onSubmit({ data, setSubmitting, resetForm });
    } else {
      setActiveStep(activeStep + 1);
      formikRef.current.setTouched({});
      formikRef.current.setSubmitting(false);
    }
  };

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    if (activeStep === 0) {
      const Quantity = formikRef.current.values.ListVehicle.length;
      const Invoices = listDeviceContractItem.map((item) => {
        const qty = Quantity;
        return {
          Id: item.Id,
          Description: item.Description,
          UnitAmount: item.Amount,
          Quantity: qty,
          TaxRate: item.Vat,
          TaxAmount: (item.Vat * item.Amount * qty) / 100,
          Amount: item.Amount * qty,
          LineTotal: item.Amount * qty * (1 + item.Vat / 100),
          XeroItemId: item.XeroItemId,
          Code: item.Code,
          Currency: item.Currency,
          Frequency: item.Frequency,
          PackageType: item.PackageType,
          ProductName: item.ProductName,
          Type: item.Type,
        };
      });
      formikRef.current.setFieldValue('Invoices', Invoices);
    }
    formikRef.current.handleSubmit();
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/vehicle-tracking/request');
    }
    formikRef.current.setSubmitting(false);
    setActiveStep(activeStep - 1);
  };

  return (
    <Slide direction='left' in mountOnEnter timeout={500}>
      <Box
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {loading && <AppLoader />}
        <Stepper
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          sx={{ mt: 2, px: 16 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmit({ data, setSubmitting, resetForm });
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            setSubmitting,
            setFieldError,
            setFieldTouched,
          }) => {
            const haveSelectedVehicle = values.ListVehicle.length > 0;
            const haveInvoicesEdit = values.Invoices.some((item) => item.IsEdit);

            return (
              <>
                <Form style={{ height: '100%' }} noValidate autoComplete='off'>
                  <Box
                    sx={{
                      minHeight: '100%',
                      p: 4,
                    }}
                  >
                    <RequestForm
                      isSubmitting={isSubmitting}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setSubmitting={setSubmitting}
                      activeStep={activeStep === 0}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />

                    <DraftInvoice
                      isSubmitting={isSubmitting}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      activeStep={activeStep === 1}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <MobileStepper
                      variant='progress'
                      steps={steps.length}
                      position='static'
                      activeStep={activeStep}
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        height: '50px',
                        zIndex: 10,
                      }}
                      nextButton={
                        <Button
                          size='small'
                          disabled={isSubmitting || !haveSelectedVehicle || haveInvoicesEdit}
                          onClick={handleNext}
                        >
                          {isLastStep ? 'Submit' : 'Next'}
                          <KeyboardArrowRight />
                        </Button>
                      }
                      backButton={
                        <Button size='small' onClick={handleBack}>
                          <KeyboardArrowLeft />
                          Back
                        </Button>
                      }
                    />
                  </Box>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
    </Slide>
  );
};

export default React.memo(RequestAdd);

RequestAdd.propTypes = {
  selectedDate: PropTypes.string,
};
