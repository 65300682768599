import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import Transaction from 'pages/dashboards/Analytics/Transaction';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import LazyLoad from 'react-lazy-load';
import Tabs from './components/Tabs';
import { TabPanel } from '@mui/lab';
import { TAB_ENUM } from './constants';
import AppLoader from '@tenant/core/AppLoader';
import SMSUsageStatistic from './components/SMS/SMSUsageStatistic';
import VehicleTrackingStatistic from './components/Tabs/VehicleTrackingTab';
import PublicAPITab from './components/Tabs/PublicAPITab';
import ReportingAccessTab from './components/Tabs/ReportingAccessTab';
import InformationTab from './components/Tabs/InformationTab';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldTenantDetail } from 'redux/actions';
import InvoiceTab from './components/Tabs/InvoiceTab';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';

const FIELD_NAME = 'TENANT_DETAIL';

const TenantDetailDashboard = ({
  tenantId,
  analyticModeBtn,
  goBackBtn,
  isReverseHeader,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState({});
  const [forceTab, setForceTab] = useState();
  const subscription = useMemo(
    () => tenant.Subscription ?? {},
    [tenant.Subscription],
  );
  const customerJourneyStage = useMemo(
    () => tenant.CustomerJourneyStage ?? {},
    [tenant.CustomerJourneyStage],
  );
  const { id: idRoute } = useParams();
  const id = useMemo(() => tenantId || idRoute, [tenantId, idRoute]);
  const tenantDetail = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenantDetail?.[id]?.[FIELD_NAME] ?? null,
    [tenant],
  );
  const { data } = storeTenant ?? {};
  const dispatch = useDispatch();

  useEffect(() => {
    const getDetail = async () => {
      try {
        setIsLoading(true);
        const { data } = await jwtAxios.get('api/Tenant/analytic/' + id);
        const newTenant = {
          ...data,
        };

        setTenant(newTenant);
        dispatch(
          setFieldTenantDetail({
            tenantId: id,
            field: FIELD_NAME,
            data: newTenant,
          }),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (data) {
      setTenant(data);
      return;
    }

    getDetail();
  }, [id]);

  const goToList = (event) => {
    event.preventDefault();
    navigate('/tenant');
  };

  return (
    <Box sx={{ m: '-20px' }}>
      {isLoading && <AppLoader />}

      <Tabs
        tenant={tenant}
        isReverse={isReverseHeader}
        breadcrumbs={
          !tenantId ? (
            <Breadcrumbs sx={{ p: 4 }} aria-label='breadcrumb'>
              <Typography color='text.secondary'>Analytics</Typography>
              <Link
                color='secondary'
                underline='none'
                href='#'
                onClick={goToList}
              >
                Tenant
              </Link>
              <Typography
                color='text.primary'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 250,
                }}
              >
                {tenant?.Name || 'Detail'}
              </Typography>
            </Breadcrumbs>
          ) : (
            goBackBtn
          )
        }
        forceTab={forceTab}
        onChange={() => setForceTab()}
      >
        <TabPanel value={TAB_ENUM['Information']}>
          <InformationTab
            tenant={tenant}
            subscription={subscription}
            customerJourneyStage={customerJourneyStage}
            tenantId={id}
            analyticModeBtn={analyticModeBtn}
          />
        </TabPanel>

        <TabPanel value={TAB_ENUM['Public API']}>
          <Grid item xs={12} md={12}>
            <LazyLoad offset={100}>
              <PublicAPITab tenantId={id} />
            </LazyLoad>
          </Grid>
        </TabPanel>

        <TabPanel value={TAB_ENUM['Reporting Access']}>
          <ReportingAccessTab tenantId={id} />
        </TabPanel>

        <TabPanel value={TAB_ENUM['Vehicle Tracking']}>
          <VehicleTrackingStatistic tenantId={id} />
        </TabPanel>

        <TabPanel value={TAB_ENUM['Invoice']}>
          <InvoiceTab tenantId={id} />
        </TabPanel>

        {/* <TabPanel value={TAB_ENUM['Subcontractor']}>
          <SubcontractorTab />
        </TabPanel> */}

        <TabPanel value={TAB_ENUM['Refcom']}>
          <Grid item xs={12} md={12}>
            <LazyLoad offset={100}>
              <Transaction tenantId={id} />
            </LazyLoad>
          </Grid>
        </TabPanel>

        <TabPanel value={TAB_ENUM['SMS']}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <SMSUsageStatistic tenantId={id} />
            </Grid>
          </Grid>
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export const tenantDetailDashboardConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: 'dashboards/tenant/:id',
    element: (
      <AllowedAccess
        isPage404
        permissionIds={[AuthorizePermission['Analytic - View']]}
      >
        <TenantDetailDashboard isReverseHeader />
      </AllowedAccess>
    ),
  },
];

export default React.memo(TenantDetailDashboard);
