import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useSelector } from 'react-redux';

import EmphasicContent from 'pages/apps/components/EmphasicContent';
import IntlMessages from '@tenant/utility/IntlMessages';

const ConfirmDialog = ({
  open,
  totalPurchasedPoints,
  isGenerateInvoice,
  handleClose,
  handleConfirm,
  handleChangeCheckbox,
}) => {
  const { contractSummary } = useSelector(({ company }) => company);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='draggable-dialog-title'
    >
      <DialogTitle style={{ fontSize: 20 }} id='draggable-dialog-title'>
        Confirm
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText>
          Are you sure you want to proceed with purchasing{' '}
          <EmphasicContent content={totalPurchasedPoints} color='success.main' /> points for the contract{' '}
          <EmphasicContent content={contractSummary?.ContractName} color='primary.main' /> ?
          This action will increase their point allocation.
        </DialogContentText>

        <FormControlLabel
          control={<Checkbox checked={isGenerateInvoice} color='warning' />}
          label={
            <Typography variant='h5'>
              <IntlMessages id='bundle.generateXeroInvoice' />
            </Typography>
          }
          onChange={(e) => handleChangeCheckbox(e.target.checked)}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 20px 24px' }}>
        <Button onClick={handleClose} color='error'>
          No
        </Button>
        <Button onClick={handleConfirm} variant='contained'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
