import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  disabledSelect: {
    backgroundColor: '#f8f7f7',
  },
}));
const SelectField = (props) => {
  const {
    label,
    data,
    setValue,
    ValueId = 'Id',
    ValueText = 'Description',
    disabled,
    onChange,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  let { value: selectedValue, name } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const classes = useStyles();

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const handleChange = (event) => {
    setValue(name, event.target.value);
  };

  const isDisable = (item) => {
    return item?.IsDisabled ? item.IsDisabled : false;
  };

  return (
    <FormControl fullWidth {...rest} error={isError}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        label={label}
        id={`select-${name}`}
        {...field}
        value={selectedValue ? selectedValue : ''}
        onChange={onChange ? onChange : handleChange}
        className={disabled ? classes.disabledSelect : ''}
        disabled={disabled}
      >
        {data.map((item, index) => (
          <MenuItem
            key={index}
            value={item[ValueId]}
            disabled={isDisable(item)}
          >
            {item[ValueText]}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
};

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
