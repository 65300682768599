import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_TRENDING,
  SET_FILTER_SUBCONTRACTOR_TRENDING,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { appIntl } from '@tenant/utility/helper/Utils';

export const onGetSubcontractorTrending = (payload) => {
  const { messages } = appIntl();

  const { type, endDate, startDate, dateGroupType } = payload;

  const transformData = (input) =>
    !Array.isArray(input) || !input.length
      ? []
      : input.map((item) => ({ ...item.Statistic, Date: item.Date }));

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await jwtAxios
      .post('/api/Subcontractor/CountByMonth', {
        DateGroupType: dateGroupType,
        StartDate: startDate,
        EndDate: endDate,
        Types: type,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: FETCH_SUCCESS
          });

          dispatch({
            type: SET_FILTER_SUBCONTRACTOR_TRENDING,
            payload
          });

          dispatch({
            type: GET_SUBCONTRACTOR_TRENDING,
            payload: transformData(data.data || [])
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
      }
    );
  };
};
