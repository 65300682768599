import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  ListItemText,
} from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import AppLoader from '@tenant/core/AppLoader';
import useToast from '@tenant/utility/hooks/useToast';
import IntlMessages from '@tenant/utility/IntlMessages';
import {
  CONTRACT_LENGTH_ENUM,
  CONTRACT_LENGTH_MONTH_NUMBER,
  PACKAGE_TYPE_BY_CONTRACT_ENUM,
  PAYMENT_METHODS_ENUM_OBJ,
  PAYMENT_METHODS_ENUM_OBJECT,
} from '../../contants/enums';
import { SelectField } from '../../formFields';
import AddFormModel from '../../formModel/AddFormModel';
import useCompany from '../../hooks/useCompany';

const { formField } = AddFormModel;
const { Country, ContractLength, PackageType, PaymentMethod, PaymentTerms } =
  formField;

const validationSchema = yup.object().shape({
  [Country.name]: yup
    .object()
    .nullable()
    .required(`${Country.requiredErrorMsg}`),
  [ContractLength.name]: yup
    .string()
    .nullable()
    .required(`${ContractLength.requiredErrorMsg}`),
  [PackageType.name]: yup
    .string()
    .nullable()
    .required(`${PackageType.requiredErrorMsg}`),
  [PaymentMethod.name]: yup
    .string()
    .nullable()
    .required(`${PaymentMethod.requiredErrorMsg}`),
  [PaymentTerms.name]: yup
    .string()
    .nullable()
    .required(`${PaymentTerms.requiredErrorMsg}`),
});

const ChangePaymentMethodConfirm = ({
  visible,
  onClose,
  onSuccess,
  contract,
  contractId,
}) => {
  const { changePaymentMethod } = useCompany();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const isMonthlyContract =
    contract?.ContractLength?.toString() === CONTRACT_LENGTH_MONTH_NUMBER;

  const initialValues = {
    Country: { label: contract.Country },
    ContractLength: isMonthlyContract ? null : CONTRACT_LENGTH_MONTH_NUMBER,
    PackageType: null,
    PaymentMethod: null,
    PaymentTerms: null,
  };

  const onSubmit = (data, actions) => {
    const payload = {
      ContractId: contractId,
      CompanyId: contract.CompanyId,
      PackageType: parseInt(data.PackageType),
      PaymentTermsId: data.PaymentTerms,
      PaymentMethod: parseInt(data.PaymentMethod),
      ContractLength: parseInt(data.ContractLength),
      Country: data.Country?.label,
    };
    changePaymentMethod(payload)
      .then(() => {
        showSuccessMessage('Change payment method successfully');
        actions.setSubmitting(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        showErrorMessage(err);
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog
      open={visible}
      aria-labelledby='remove-max-user-dialog-title'
      aria-describedby='remove-max-user-dialog-description'
      maxWidth='sm'
      fullWidth
      height={500}
    >
      <DialogTitle id='change-payment-method-dialog-title' fontSize={16}>
        Change Payment Method
        {contract?.ContractLength
          ? isMonthlyContract
            ? ' from Monthly to Annually'
            : ' from Annually to Monthly'
          : ''}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <ChangePaymentMethodForm
          onClose={onClose}
          contract={contract}
          isMonthlyContract={isMonthlyContract}
        />
      </Formik>
    </Dialog>
  );
};

const ChangePaymentMethodForm = ({ onClose, contract, isMonthlyContract }) => {
  const {
    values: formData,
    setFieldValue,
    errors,
    isSubmitting,
  } = useFormikContext();

  const paymentTerms = useSelector(({ company }) => company.paymentTerms);

  const packageTypeOptions = useMemo(() => {
    return PACKAGE_TYPE_BY_CONTRACT_ENUM(formData.ContractLength);
  }, [formData.ContractLength]);

  const contractLengthOptions = useMemo(() => {
    return CONTRACT_LENGTH_ENUM.filter((item) =>
      isMonthlyContract
        ? item.Id !== CONTRACT_LENGTH_MONTH_NUMBER
        : item.Id === CONTRACT_LENGTH_MONTH_NUMBER,
    );
  }, [isMonthlyContract]);

  useEffect(() => {
    isMonthlyContract
      ? setFieldValue(PaymentMethod.name, PAYMENT_METHODS_ENUM_OBJ.GoCardless)
      : setFieldValue(PaymentMethod.name, PAYMENT_METHODS_ENUM_OBJ.Stripe);

    // If ContractLength is Monthly, auto set PaymentTerms to 100% up-front
    if (!isMonthlyContract) {
      const foundPaymentTerms = paymentTerms.find((item) =>
        item.Description.includes('100% up-front'),
      );
      if (foundPaymentTerms)
        setFieldValue(PaymentTerms.name, foundPaymentTerms?.Id);
    }
  }, [isMonthlyContract]);

  useEffect(() => {
    const existPackage = packageTypeOptions.find(
      (item) => item.Id === formData.PackageType?.toString(),
    );
    if (!existPackage) setFieldValue(PackageType.name, '');
  }, [formData.ContractLength]);

  return (
    <Form>
      {isSubmitting && <AppLoader />}
      <DialogContent sx={{ p: '15px 24px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant='h3'
              sx={{
                fontSize: '14px',
                borderBottom: '1px solid #ddd',
                paddingBottom: '8px',
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <IntlMessages id='contractLayer.client' />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <ListItemText
              primary='Contact Name'
              secondary={contract?.Contacts?.[0]?.ContactName || '-'}
              primaryTypographyProps={{
                sx: {
                  fontSize: '14px',
                  marginBottom: '4px',
                },
              }}
              secondaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.primary,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ListItemText
              primary='Country'
              secondary={formData?.Country?.label || '-'}
              primaryTypographyProps={{
                sx: {
                  fontSize: '14px',
                  marginBottom: '4px',
                },
              }}
              secondaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} pt={8}>
          <Grid item xs={12}>
            <Typography
              variant='h3'
              sx={{
                fontSize: '14px',
                borderBottom: '1px solid #ddd',
                paddingBottom: '8px',
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <IntlMessages id='contractLayer.subscription' />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectField
              name={ContractLength.name}
              label={ContractLength.label}
              data={contractLengthOptions}
              setValue={setFieldValue}
              disabled={!isMonthlyContract}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ListItemText
              primary='Payment Method'
              secondary={
                PAYMENT_METHODS_ENUM_OBJECT?.[formData.PaymentMethod] || '-'
              }
              primaryTypographyProps={{
                sx: {
                  fontSize: '12px',
                  marginBottom: '4px',
                  color: (theme) => theme.palette.text.secondary,
                },
              }}
              secondaryTypographyProps={{
                sx: {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectField
              name={PackageType.name}
              label={PackageType.label}
              data={packageTypeOptions}
              setValue={setFieldValue}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectField
              name={PaymentTerms.name}
              label={PaymentTerms.label}
              data={paymentTerms}
              setValue={setFieldValue}
              disabled={!isMonthlyContract}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '8px 24px' }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          type='submit'
          loading={isSubmitting}
          color='primary'
          variant='contained'
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

export default ChangePaymentMethodConfirm;

ChangePaymentMethodForm.propTypes = {
  onClose: PropTypes.func,
  contract: PropTypes.object,
};

ChangePaymentMethodConfirm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  contract: PropTypes.object,
  contractId: PropTypes.string,
};
