import {
  useFirebase,
  useFirebaseActions,
  useADActions,
} from '../services/auth/firebase/FirebaseAuthProvider';
import { getUserFromFirebase } from './helper/AuthHelper';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

export const useAuthUser = () => {
  const { isLoading, isAuthenticated, user } = useFirebase();
  const { instance } = useMsal();
  const isAuthenticatedAzure = useIsAuthenticated();
  const userFirebase = getUserFromFirebase(user) ?? {};
  const userAzure = instance.getActiveAccount() ?? {};

  const isPointPermission = userAzure?.idTokenClaims?.groups
    ? userAzure.idTokenClaims.groups.includes(
        process.env.REACT_APP_POINT_PERMISSION,
      )
    : false;

  return {
    isLoading,
    isAuthenticated: isAuthenticatedAzure || isAuthenticated,
    user: isAuthenticatedAzure ? userAzure : userFirebase,
    isAzure: isAuthenticatedAzure,
    isPointPermission: isPointPermission,
  };
};

export const useAuthMethod = () => {
  const {
    registerUserWithEmailAndPassword,
    logInWithPopup,
    logout: logoutAD,
  } = useADActions();
  const { logInWithEmailAndPassword, logout: logoutFirebase } =
    useFirebaseActions();

  return {
    logInWithEmailAndPassword,
    registerUserWithEmailAndPassword,
    logInWithPopup,
    logoutAD,
    logoutFirebase,
  };
};
