import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Tooltip,
  Box,
} from '@mui/material';
import moment from 'moment';

import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import DataListContainer from 'pages/apps/components/DataListContainer';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import useTenant from 'pages/apps/hooks/useTenant';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { parseCredit, parseDebit } from 'pages/apps/ProductUsage/helpers';
import {
  SET_IS_INIT_TRANSACTIONS,
  SET_TRANSACTIONS_PARAMS,
  SET_TRANSACTION_FILTERS,
} from 'shared/constants/ActionTypes';

const TRANSACTION_TYPE_MODEL = {
  Burned: {
    label: 'Burned',
    color: 'error.main',
    isShowTenant: true,
    isShowAmount: false,
    parseValue: (value) => parseDebit(value),
  },
  Refunded: {
    label: 'Refunded',
    color: 'success.main',
    isShowTenant: true,
    isShowAmount: false,
    parseValue: (value) => parseCredit(value),
  },
  Purchased: {
    label: 'Purchased',
    color: 'success.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseCredit(value),
  },
  Revoked: {
    label: 'Revoked',
    color: 'error.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseDebit(value),
  },
  Expired: {
    label: 'Expired',
    color: 'error.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseDebit(value),
  },
};

const columns = [
  {
    field: 'Action',
    headerName: 'Type',
    minWidth: 90,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          {params.value || '-'}
        </>
      )
    },
  },
  {
    field: 'CompanyName',
    headerName: 'Tenant',
    flex: 1,
    minWidth: 230,
    sortable: false,
    renderCell: (params) => {
      const { isShowTenant } = TRANSACTION_TYPE_MODEL?.[params.row.Action] || true;
      return (
        <Tooltip
          title={isShowTenant ? params.value : '-'}
        >
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {isShowTenant ? params.value : '-'}
          </Typography>
        </Tooltip>
      )
    },
  },
  {
    field: 'Detail',
    headerName: 'Detail',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography
          variant='body2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'Points',
    headerName: 'Points',
    type: 'number',
    flex: 1,
    minWidth: 80,
    renderCell: (params) => {
      const { color, parseValue } = TRANSACTION_TYPE_MODEL?.[params.row.Action] || {};
      return (
        <Typography variant='body2' color={color || ''}>
          {parseValue?.(params.value) || params.value}
        </Typography>
      )
    },
  },
  {
    field: 'Paid',
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      const { isShowAmount } = TRANSACTION_TYPE_MODEL?.[params.row.Action] || true;
      return (
        <Typography variant='body2' color={'primary.main'}>
          {isShowAmount ? numberToCurrency(params.value ?? 0, 2, 'GBP') : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'DiscountPercent',
    headerName: 'Discount (%)',
    type: 'number',
    flex: 1,
    minWidth: 110,
    renderCell: (params) => (
      <Typography variant='body2' color={'primary.main'}>
        {params.value ? `${params.value}%` : '-'}
      </Typography>
    ),
  },
  {
    field: 'RequestBy',
    headerName: 'Req By',
    flex: 1,
    minWidth: 170,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography
          variant='body2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'TransactionDate',
    headerName: 'Date',
    flex: 1,
    type: 'date',
    align: 'right',
    headerAlign: 'right',
    minWidth: 140,
    renderCell: (params) => (
      <Typography variant='body2'>
        {
          params.value
            ? formatDate(moment(params.value), inputFormatDateTime)
            : 'N/A'
        }
      </Typography>
    ),
  },
];

const initialFilterList = {
  tenants: [],
  transactionTypes: [],
};

const PointTransactions = ({
  tenantId,
  contractId,
  showInTenantDetail = false,
}) => {
  const {
    transactionFilterList,
    transactions,
    transactionsParams,
    isInitTransactions,
    loadingTransactions,
  } = useSelector(({ bundle }) => bundle);
  const { getTransactions, } = useTenant();
  const dispatch = useDispatch();

  const rangeDate = useMemo(
    () => (transactionsParams?.rangeDate || RANGE_DATE.THIS_YEAR),
    [transactionsParams?.rangeDate]
  );

  const data = useMemo(
    () => (transactions?.Items ?? []),
    [transactions?.Items]
  );

  const total = useMemo(
    () => (transactions?.Total ?? 0),
    [transactions?.Total]
  );

  const getContractTransactions = useCallback(
    (params) => {
      const {
        pageIndex = 1,
        pageSize = 10,
        filterList,
        keyName,
      } = params || {};
      const { start, end } = getRangeDateByKeyName(keyName || rangeDate);

      getTransactions({ ...params, contractId, tenantId, start, end, });

      !showInTenantDetail && isInitTransactions && dispatch({
        type: SET_IS_INIT_TRANSACTIONS,
        payload: false,
      });

      if (!params) return;

      dispatch({
        type: SET_TRANSACTION_FILTERS,
        payload: filterList
      });

      dispatch({
        type: SET_TRANSACTIONS_PARAMS,
        payload: {
          pageIndex,
          pageSize,
          rangeDate: keyName || rangeDate,
        }
      });
    },
    [rangeDate]
  );

  const rows = useMemo(
    () => (!!data?.length
      ? data.map((item, idx) => ({
        ...item,
        id: idx,
      }))
      : []),
    [data]
  );

  const handleSortData = useCallback((payload) => {
    getContractTransactions({
      ...payload,
    });
  }, []);

  return (
    <Box>
      <DataListContainer
        hasDateMenu
        hasTenantFilter
        hasTransactionTypeFilter
        disableColumnMenu
        columns={columns}
        rows={rows ?? []}
        isLoadingList={loadingTransactions}
        total={total}
        data={data}
        rangeDate={rangeDate}
        handleGetData={getContractTransactions}
        handleSortData={handleSortData}
        rowsPerPage={10}
        filterList={transactionFilterList}
        initialFilterList={initialFilterList}
        listParams={transactionsParams}
        keyOrderBy='transactions'
        isInitData={isInitTransactions}
      />
    </Box>
  )
};

PointTransactions.propTypes = {
  contractId: PropTypes.string,
  tenantId: PropTypes.string,
  showInTenantDetail: PropTypes.bool,
};

export default React.memo(PointTransactions);