import { useState } from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import { API_VEHICLE_TRACKING_REQUEST } from '../constants';

const useVehicleTracking = () => {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useToast();

  const createRequest = async (form, callback) => {
    try {
      setIsLoadingBtn(true);
      const res = await jwtAxios.post(
        API_VEHICLE_TRACKING_REQUEST.CREATE,
        form,
      );
      callback?.({ ...res.data });
      showSuccessMessage();
    } catch (err) {
      showErrorMessage(err?.response?.data?.Message);
    } finally {
      setIsLoadingBtn(false);
      callback?.();
    }
  };

  const approveItem = async (form, callback) => {
    try {
      if (!form?.Id) {
        return;
      }
      setIsLoadingBtn(true);

      const params = {
        DeviceRequestId: form.Id,
        DeviceMapping: form.RequestDetails.map((item) => ({
          DeviceRequestDetailId: item.Id,
          IMEI: item.IMEI,
          ICCID: item.iccid,
        })),
        OrderTracking: form?.OrderTrackingNumber,
        SentDate: form?.SentDate,
      };
      const response = await jwtAxios.post(
        API_VEHICLE_TRACKING_REQUEST.APPROVE(form.Id),
        params,
      );
      if (response.status === 200) {
        callback?.();
      }
      showSuccessMessage();
    } catch (err) {
      showErrorMessage(err?.response?.data?.Message);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const saveItemToDraft = async (form, callback) => {
    try {
      if (!form?.Id) {
        return;
      }
      setIsLoadingBtn(true);

      const params = {
        Id: form.Id,
        DeviceRequestDetails: form.RequestDetails.map((item) => ({
          DeviceRequestDetailId: item.Id,
          IMEI: item.IMEI,
          ICCID: item.iccid,
        })),
      };
      const response = await jwtAxios.put(
        API_VEHICLE_TRACKING_REQUEST.UPDATE_DETAIL(form.Id),
        params,
      );
      if (response.status === 200) {
        callback?.();
      }
      showSuccessMessage();
    } catch (err) {
      showErrorMessage(err?.response?.data?.Message);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const cancelRequest = async (form, callback) => {
    try {
      if (!form?.Id) {
        return;
      }
      setIsLoadingBtn(true);

      const { data } = await jwtAxios.post(
        API_VEHICLE_TRACKING_REQUEST.CANCEL(form.Id),
        {
          DeviceRequestId: form.Id,
          CancelerName: '',
          CancelerEmail: '',
          CancelReason: '',
        },
      );
      callback?.(data);
      showSuccessMessage();
    } catch (err) {
      showErrorMessage(err?.response?.data?.Message);
      console.error(err);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const getDetail = async (id, callback) => {
    try {
      if (!id) {
        return;
      }
      setIsLoadingData(true);
      const { data } = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.GET_DETAIL(id),
      );
      callback?.(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getListVehicleTenant = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.GET_VEHICLE_TENANT,
        {
          params: {
            tenantId: id,
          },
        },
      );
      onSuccess?.(data);
    } catch (err) {
      onError(err);
    }
  };

  const getListDraftInvoice = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.DEVICE_CONTRACT_ITEM,
        {
          params: {
            tenantId: id,
          },
        },
      );
      onSuccess?.(data);
    } catch (err) {
      onError(err.response.data.Message);
    }
  };

  const createDeviceRequestAllowed = async ({ id, onSuccess, onError }) => {
    try {
      setIsLoadingData(true);
      const res = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.CREATE_DEVICE_REQUEST_ALLOWED,
        {
          params: {
            tenantId: id,
          },
        },
      );
      if (res.status === 200) {
        onSuccess?.();
      } else {
        onError?.(res?.data?.Message || 'Tenant cannot create device request');
      }
    } catch (err) {
      onError?.(err?.response?.data?.Message || 'Tenant cannot create device request');
    } finally {
      setIsLoadingData(false);
    }
  };

  const addVehicle = async ({ id, payload, onSuccess, onError }) => {
    try {
      setIsLoadingData(true);
      const res = await jwtAxios.post(
        API_VEHICLE_TRACKING_REQUEST.ADD_VEHICLE(id),
        payload,
      );
      if (res.status === 200) {
        onSuccess?.(res.data);
      } else {
        onError?.(res.data.Message);
      }
    } catch (err) {
      onError?.(err.response.data.Message);
    } finally {
      setIsLoadingData(false);
    }
  };

  const sentDevice = async ({ id, payload, onSuccess, onError }) => {
    try {
      setIsLoadingBtn(true);
      const res = await jwtAxios.post(
        API_VEHICLE_TRACKING_REQUEST.SENT_DEVICE(id),
        payload,
      );
      if (res.status === 200) {
        onSuccess?.(res.data);
      } else {
        onError?.(res.data.Message);
      }
    } catch (err) {
      onError?.(err.response.data.Message);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const getDefaultCustomerEmail = async ({ tenantId, requestId = null, onSuccess, onError }) => {
    try {
      const { data } = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.GET_CUSTOMER_EMAIL,
        {
          params: {
            tenantId,
            requestId,
          },
        },
      );
      onSuccess?.(data);
    } catch (err) {
      onError(err);
    }
  };

  return {
    isLoadingBtn,
    approveItem,
    createRequest,
    cancelRequest,
    getDetail,
    isLoadingData,
    getListVehicleTenant,
    createDeviceRequestAllowed,
    getListDraftInvoice,
    addVehicle,
    sentDevice,
    saveItemToDraft,
    getDefaultCustomerEmail,
  };
};

export default useVehicleTracking;
