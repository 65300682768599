import React, { useMemo, } from 'react';
import PropTypes from 'prop-types';

import IntlMessages from '@tenant/utility/IntlMessages';
import { Divider, Stack, Typography } from '@mui/material';

const TransactionSummary = ({
  points = 0,
  pointsUsage = 0,
  subscription,
}) => {

  const showParent = useMemo(
    () => (!subscription?.IsParentClient && subscription?.ParentTenantId && subscription?.GroupId),
    [subscription]
  );

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 'medium' }} />}
      spacing={2}
    >
      <Typography>
        <Typography
          component='span'
          sx={{
            fontWeight: 500,
          }}
        >
          <IntlMessages id='productForm.balance' />:
        </Typography>
        <Typography
          component='span'
          sx={{
            color: 'success.main',
            fontWeight: 700,
            ml: 2,
          }}
        >
          {points}
        </Typography>
      </Typography>

      <Typography>
        <Typography
          component='span'
          sx={{
            fontWeight: 500,
          }}
        >
          <IntlMessages id='tenant.pointsUsage' />:
        </Typography>
        <Typography
          component='span'
          sx={{
            color: 'error.main',
            fontWeight: 700,
            ml: 2,
          }}
        >
          {pointsUsage}
        </Typography>
      </Typography>

      {showParent && (
        <Typography>
          <Typography
            component='span'
            sx={{
              fontWeight: 500,
            }}
          >
            <IntlMessages id='tenant.parentTenant' />:
          </Typography>
          <Typography
            component='span'
            sx={{
              fontWeight: 500,
              ml: 2,
            }}
          >
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/tenant/detail/${subscription.ParentTenantId}`}
            >
              {subscription.ParentTenantName}
            </NavLink>
          </Typography>
        </Typography>
      )}
    </Stack>
  );
};

TransactionSummary.propTypes = {
  points: PropTypes.number,
  pointsUsage: PropTypes.number,
  subscription: PropTypes.object,
};

export default React.memo(TransactionSummary);