import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { debounce } from '@tenant/utility/helper/Utils';
import useToast from '@tenant/utility/hooks/useToast';
import useTenant from 'pages/apps/hooks/useTenant';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const TenantFilter = ({ additionalParams, checkedTenants, handleToggle, disabled = false }) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const [tenants, setTenants] = useState([]);
  const { showErrorMessage } = useToast();
  const { loadingTenants, getDropdownTenants, getListTenantsWithEnv, } = useTenant();

  useEffect(() => {
    if (checkedTenants.length) {
      setCheckedValues(checkedTenants);
      return;
    }
    setCheckedValues([]);
  }, [checkedTenants]);

  const getTenants = (params) => {
    if (additionalParams) {
      console.log(additionalParams)
      getDropdownTenants({
        ...additionalParams,
        searchTerm: params?.searchTerm,
      }, (data) => {
        setTenants(data);
      });
      return;
    }

    getListTenantsWithEnv(params, (data) => {
      setTenants(data);
    });
  };

  const onSearchTenant = debounce((event, value) => {
    if (event?.type === 'change') {
      const params = {
        searchTerm: value,
      };
      getTenants(params);
    }
  }, 700);

  const onChangeTenant = (e, value) => {
    setCheckedValues([...value]);
    handleToggle(e, value);
  };

  const onOpen = useCallback(() => {
    setTenants([]);
    const params = {
      ...additionalParams,
    };
    getTenants(params);
  }, []);

  return (
    <Autocomplete
      multiple
      id='checkboxes-tags-demo'
      limitTags={2}
      options={tenants}
      disableCloseOnSelect
      getOptionLabel={(option) => option.Name}
      isOptionEqualToValue={(option, value) =>
        option.TenantId === value.TenantId
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.TenantId}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.Name}
        </li>
      )}
      value={checkedValues}
      style={{ maxWidth: 360 }}
      renderInput={(params) => <TextField {...params} label='Tenants' />}
      onOpen={onOpen}
      loading={loadingTenants}
      onInputChange={onSearchTenant}
      onChange={(e, value) => onChangeTenant(e, value)}
      disabled={disabled}
    />
  );
};

export default React.memo(TenantFilter);
