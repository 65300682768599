import React, { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import {
  Slide,
  Button,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import useVehicleTracking from '../hooks/useVehicleTracking';
import { inputFormat } from '@tenant/utility/constants/default';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ResquestSent = ({ detail, callBack }) => {
  const [open, setOpen] = React.useState(false);
  const { isLoadingBtn, sentDevice } = useVehicleTracking();

  const [data, setData] = React.useState({
    DeviceRequestId: detail.Id,
    DeliveryTrackingNumber: '',
    SentDate: '',
    ShouldSendEmail: false,
    CustomerEmail: null,
  });
  const [error, setError] = React.useState(null);

  const handleChangeOrderTrackingNumber = (e) => {
    setData({ ...data, DeliveryTrackingNumber: e.target.value });
  };

  const handleChangeSentDate = (date) => {
    setData({
      ...data,
      SentDate: moment(date, 'DD/MM/YYYY').format(inputFormat),
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSent = () => {
    if (error) {
      return;
    }
    sentDevice({
      id: detail.Id,
      payload: data,
      onSuccess: () => {
        handleClose();
        callBack?.({
          ...data,
          SendDate: moment(data.SentDate, 'DD/MM/YYYY').toLocaleString(),
        });
      },
      orError: () => {
        handleClose();
      },
    });
  };

  const handleChangeCustomerEmail = (e) => {
    setData({ ...data, CustomerEmail: e.target.value });

    if (!emailRegex.test(e.target.value)) {
      setError({
        Email: true,
      });
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      ...detail,
    }));
  }, [detail]);

  return (
    <>
      <LoadingButton
        variant='contained'
        color='success'
        onClick={handleClickOpen}
      >
        Sent Device
      </LoadingButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle sx={{ fontSize: 20 }}>{'Sent device'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Order tracking number'
            fullWidth
            value={data?.DeliveryTrackingNumber}
            onChange={handleChangeOrderTrackingNumber}
            sx={{ my: 4 }}
          />

          <DatePicker
            fullWidth
            autoOk
            label='Sent date'
            inputFormat='dd/MM/yyyy'
            value={
              data?.SentDate ? new moment(data?.SentDate, inputFormat) : null
            }
            renderInput={(params) => (
              <TextField {...params} sx={{ width: '100%' }} />
            )}
            onChange={handleChangeSentDate}
          />
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={<Checkbox checked={data.ShouldSendEmail} />}
              label='Send email to customer'
              onChange={(e) => {
                if (e.target.checked) {
                  setData({ ...data, ShouldSendEmail: e.target.checked });
                } else {
                  setData({
                    ...data,
                    ShouldSendEmail: e.target.checked,
                    CustomerEmail: null,
                  });
                }
              }}
              sx={{ my: 1 }}
            />
          </Grid>
          {data.ShouldSendEmail && (
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                variant='outlined'
                label={'Customer Email'}
                value={data.CustomerEmail}
                fullWidth
                sx={{ my: 2 }}
                placeholder='Please enter your email'
                error={error}
                helperText={
                  error?.Email ? 'Please enter a valid email address' : ''
                }
                onChange={handleChangeCustomerEmail}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose}>
            No
          </Button>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleSent}
            loading={isLoadingBtn}
            disabled={isLoadingBtn}
          >
            Sent
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResquestSent.propTypes = {
  item: PropTypes.object.isRequired,
  callBack: PropTypes.func,
  detail: PropTypes.object,
};

export default React.memo(ResquestSent);
