import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AppCard from '@tenant/core/AppCard';
import Box from '@mui/material/Box';

import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from '../constants';
import IntlMessages from '@tenant/utility/IntlMessages';
import { BUNDLE_API } from '../constants/Api';
import TableChart from '@tenant/core/App/Chart/TableChart';

const BundleUsage = ({ tenantId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);

  const columns = useMemo(
    () => [
      {
        field: 'BundleName',
        headerName: 'Bundle',
        // minWidth: 200,
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Typography> {params.value}</Typography>
        ),
      },
      {
        field: 'TotalPointsPurchased',
        sortable: false,
        minWidth: 120,
        type: 'number',
        renderCell: (params) => (
          <Typography
            sx={{ color: 'success.main' }}
          >
            {params.value}
          </Typography>
        ),
        renderHeader: () => (
          <Typography
            variant='h6'
            component='h6'
          >
            <IntlMessages id='bundle.totalPoints' />
          </Typography>
        ),
      },
      {
        field: 'TotalPurchases',
        headerName: 'Total Purchases',
        sortable: false,
        minWidth: 120,
        type: 'number',
        renderCell: (params) => (
          <Typography
            sx={{ color: 'primary.main' }}
          >
            {params.value}
          </Typography>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    getList();
  }, [tenantId]);

  const getList = useCallback(
    async (selectedKey) => {
      try {
        setIsLoading(true);

        const { start, end, } = getRangeDateByKeyName(selectedKey || rangeDate);
        const response = await jwtAxios.get(BUNDLE_API.GET_BUNDLE_SALES, {
          params: {
            TenantId: tenantId,
            FromDate: start,
            ToDate: end,
          }
        });

        if (!Array.isArray(response?.data?.ObjectData)) {
          return;
        }

        setData(response?.data?.ObjectData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate]
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    getList(keyName);
  };

  return (
    <>
      <AppCard
        title={
          <Box display='flex' alignItems='center'>
            <Box mr={2}>Bundle Usage</Box>
          </Box>
        }
        sxStyle={{
          height: '100%',
        }}
        action={
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleChangeDateRange}
          />
        }
      >
        <TableChart
          isLoading={isLoading}
          rows={data}
          columns={columns}
          uniqueProp={'BundleId'}
        />
      </AppCard>
    </>
  );
};

BundleUsage.propTypes = {
  tenantId: PropTypes.string.isRequired,
};

export default React.memo(BundleUsage);
