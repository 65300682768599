import React from 'react';
import {Hidden, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch} from 'react-redux';
import {toggleNavCollapsed} from 'redux/actions';

const SideMenuMobile = () => {
  const dispatch = useDispatch();
  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  return (
    <Hidden lgUp>
      <IconButton onClick={handleToggleDrawer}>
        <MenuIcon />
      </IconButton>
    </Hidden>
  );
};

export default React.memo(SideMenuMobile);

SideMenuMobile.propTypes = {};
