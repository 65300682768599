import {
  SET_COMPANY_MARKETPLACE,
  GET_APP_REQUESTS,
} from 'shared/constants/ActionTypes';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '../../@tenant/utility/constants/default';

const initialState = {
  currentCompanyMarketplace: {
    CompanyId: '',
    Enable: false,
  },
  appRequests: () => ({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    totalCount: 0,
  }),
};

const MARKETPLACE_ACTION_OBJECT = {
  [SET_COMPANY_MARKETPLACE]: 'currentCompanyMarketplace',
  [GET_APP_REQUESTS]: 'appRequests',
};

const marketplaceReducer = (state = initialState, { type, payload }) => {
  const property = MARKETPLACE_ACTION_OBJECT[type];
  if (!property) {
    return { ...state };
  }
  return { ...state, [property]: { ...state[property], ...payload } };
};

export default marketplaceReducer;
