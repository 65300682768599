import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_MAPPING,
  SET_FILTER_SUBCONTRACTOR_MAPPING
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';

export const onGetSubcontractorMapping = (tenantId) => {
  const { messages } = appIntl();

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    const url = tenantId 
      ? '/api/Subcontractor/MappingStatisticByTenantId' 
      : '/api/Subcontractor/MappingStatistic';

    const params = tenantId ? { TenantId: tenantId } : {};

    await jwtAxios
      .post(url, params)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: FETCH_SUCCESS,
          });

          dispatch({
            type: GET_SUBCONTRACTOR_MAPPING,
            payload: data.data || [],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message,
        });
      });
  };
};
