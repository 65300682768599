import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const NumberUserSelect = ({ error, initialData, helperText, ...props }) => {
  return (
    <Autocomplete
      options={initialData || []}
      autoHighlight
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.Value}
        </Box>
      )}
      getOptionLabel={(option) => option.Value}
      isOptionEqualToValue={(option, value) => {
        return option?.Key === value?.Key;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            placeholder: 'Select a number of user',
          }}
        />
      )}
      {...props}
    />
  );
};

export default React.memo(NumberUserSelect);

NumberUserSelect.propTypes = {};
