import React, { useState, useEffect, useCallback, useMemo } from 'react';
import UserUsageChart from '@tenant/core/App/Chart/UserUsageChart';
import AppCard from '@tenant/core/AppCard';
import { useIntl } from 'react-intl';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { Code } from 'react-content-loader';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import DescriptionTooltip from '@tenant/core/App/DescriptionTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setFieldTenantDetail } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';

const DEFAULT_RANGE_NAME = 'This year';
const FIELD = 'USER_TREND';

const UserUsage = ({ tenantId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD] ?? null,
    [tenant],
  );
  const { data: storeData, filter: storeFilter } = storeTenant ?? {};

  const getStatistic = useCallback(
    async ({ startDate, endDate, selectedType, dateGroupType }) => {
      try {
        if (!tenantId) {
          return;
        }
        setIsLoading(true);
        const data = await jwtAxios.get(
          'api/Tenant/GetBackOfficeStatisticByTenant',
          {
            params: {
              tenantId,
              startDate,
              endDate,
              dateGroupType,
            },
          },
        );
        if (!data.data) {
          return;
        }
        dispatch(
          setFieldTenantDetail({
            tenantId,
            field: FIELD,
            data: data.data?.Statistic ?? [],
            filter: { selectedType, startDate, endDate, dateGroupType },
          }),
        );
        setData(data.data?.Statistic ?? []);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, tenantId],
  );

  useEffect(() => {
    const { start, end, dateGroupType } =
      getRangeDateByKeyName(DEFAULT_RANGE_NAME);

    if (storeData) {
      setData(storeData);
      return;
    }

    getStatistic({ startDate: start, endDate: end, dateGroupType });
  }, [tenantId]);

  const handleSelectionType = useCallback((data) => {
    const { startDate, endDate, keyName, dateGroupType } = data ?? {};
    getStatistic({ startDate, endDate, selectedType: keyName, dateGroupType });
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>{messages['dashboard.analytics.user_trend']}</Box>
          <Box>
            <DescriptionTooltip
              icon={<InfoOutlinedIcon sx={{ fontSize: 20 }} />}
              title={'analytics.userUsage.tooltip.caption'}
            />
          </Box>
        </Box>
      }
      action={
        <DateMenu
          defaultValue={storeFilter?.selectedType ?? DEFAULT_RANGE_NAME}
          handleChangeValue={handleSelectionType}
        />
      }
    >
      {isLoading && <Code />}

      {!isLoading && (
        <UserUsageChart
          data={data}
          height={275}
          dateGroupType={storeFilter?.dateGroupType}
        />
      )}
    </AppCard>
  );
};

UserUsage.propTypes = {
  tenantId: PropTypes.string.isRequired,
};

export default React.memo(UserUsage);
