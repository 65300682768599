import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useModal from '@tenant/utility/hooks/useModal';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import AppGridContainer from '@tenant/core/AppGridContainer';
import usePermissionService from '../hooks/usePermissionService';

const initialValuesForm = {
  Name: '',
  Description: '',
};

const validationSchema = yup.object({
  Name: yup.string().required().max(255),
});

const RoleAddModal = ({ visible, onClose, role, callbackSubmit }) => {
  const { loadingBtn, createRole, updateRole } = usePermissionService();

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      const callback = () => {
        onClose();
        setSubmitting(false);
        callbackSubmit?.();
      };

      setSubmitting(true);
      if (role) {
        updateRole(
          {
            ...data,
            Permissions: undefined,
          },
          callback,
        );
        return;
      }
      createRole(data, callback);
    },
    [role, createRole, updateRole],
  );

  return (
    <Dialog
      fullWidth
      open={visible}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='sm'
    >
      <Formik
        id='add-role-form'
        initialValues={role ? role : initialValuesForm}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
          errors,
          submitForm,
        }) => (
          <>
            <DialogTitle
              id='alert-dialog-title'
              fontSize={18}
              display={'flex'}
              justifyContent={'space-between'}
            >
              {role ? 'Update' : 'Add'} Role
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <AppGridContainer container>
                <Grid item xs={12} mt={4}>
                  <TextField
                    value={values.Name ?? ''}
                    fullWidth
                    name='Name'
                    label='Name'
                    variant='outlined'
                    required
                    error={!!errors.Name}
                    helperText={errors.Name}
                    onChange={(e) => setFieldValue('Name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    value={values.Description ?? ''}
                    fullWidth
                    name='Description'
                    label='Description'
                    variant='outlined'
                    error={!!errors.Description}
                    helperText={errors.Description}
                    rows={4}
                    onChange={(e) =>
                      setFieldValue('Description', e.target.value)
                    }
                  />
                </Grid>
              </AppGridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color='secondary'>
                Close
              </Button>
              <LoadingButton
                form='add-role-form'
                variant='contained'
                onClick={submitForm}
                loading={loadingBtn}
              >
                {role ? 'Update' : 'Add'} Role
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(RoleAddModal);

RoleAddModal.propTypes = {};
