import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';


import { API } from '../constants';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import TableChart from '@tenant/core/App/Chart/TableChart';

const rowsPerPage = 10;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} component={Paper} />),
  Table: (props) => <Table {...props} stickyHeader />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const columns = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 250,
    headerName: 'Tenant',
    sortable: false,
  },
  {
    field: 'SubscriptionStart',
    minWidth: 180,
    headerName: 'Subscription Start',
    sortable: false,
  },
  {
    field: 'SubscriptionEnd',
    minWidth: 130,
    headerName: 'Subscription End',
    sortable: false,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      left: 2,
    }
  })
);

const ReachExpiryStatistic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async ({ keyName, pageIndex = 1, } = {}) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(keyName || rangeDate);
        const response = await jwtAxios.get(API.GET_REACH_EXPIRY_STATISTIC, {
          params: {
            FromDate: start,
            ToDate: end,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
          }
        });

        if (response?.status === 200 && response?.data?.Success) {
          if (pageIndex === 1) {
            setData(response?.data?.ObjectData?.Items || []);
          } else {
            setData((s) => [...s, ...response?.data?.ObjectData?.Items || []]);
          }

          setTotal(response?.data?.ObjectData?.Total);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate]
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    setPage(1);
    getList({ keyName });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) return;

    getList({ pageIndex: page + 1 });
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <AppCard
        title={
          <Box display='flex' alignItems='center'>
            <Box mr={2}>Tenants Reaching Expiry</Box>
          </Box>
        }
        action={
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleChangeDateRange}
          />
        }
        sxStyle={{
          position: 'relative'
        }}
      >
        {isLoading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!!data.length ? (
          <TableVirtuoso
            style={{ height: 325 }}
            data={data}
            components={TableComponents}
            endReached={handleReachedEnd}
            fixedHeaderContent={() => (
              <TableRow>
                <TableCell sx={{ minWidth: 250, maxWidth: 250, backgroundColor: '#FFFFFF' }}>Tenant</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>Subscription Start</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>Subscription End</TableCell>
              </TableRow>
            )}
            itemContent={(idx, item) => (
              <React.Fragment key={item?.TenantId || idx}>
                <>
                  <TableCell sx={{ minWidth: 250, maxWidth: 250, }}>
                    <NavLink
                      className='MuiDataGrid-cellContent'
                      to={`/tenant/detail/${item.TenantId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Tooltip title={item?.CompanyName}>
                        <Typography
                          variant='body2'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.CompanyName}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  </TableCell>
                  <TableCell align='right'>
                    {item?.SubscriptionEnd}
                  </TableCell>
                  <TableCell align='right'>
                    {item?.SubscriptionStart}
                  </TableCell>
                </>

              </React.Fragment>
            )}
          />
        ) : (
          <TableChart
            rows={[]}
            columns={columns}
            uniqueProp={''}
          />
        )}
      </AppCard>
    </>
  );
};

export default React.memo(ReachExpiryStatistic);
