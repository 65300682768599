import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppLoader from '@tenant/core/AppLoader';
import AppTooltip from '@tenant/core/AppTooltip';
import AppsContent from '@tenant/core/AppsContainer/AppsContent';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import IntlMessages from '@tenant/utility/IntlMessages';
import ResquestDescription from './ResquestDescription';
import ResquestCancelled from '../ResquestCancelled';
import useVehicleTracking from '../../hooks/useVehicleTracking';
import {
  updateListRequestWorkflow,
  onGetListRequestWorkflow,
} from 'redux/actions';
import { statusVehicleData } from 'shared/constants/AppsContants';
import StatusToggleButton from './StatusToggleButton';
import ResquestSent from '../ResquestSent';
import axios from 'axios';
import { API_VEHICLE_TRACKING_REQUEST, BASE_URL_SIM } from '../../constants';
import ResquestSaveDraft from '../ResquestSaveDraft';
import useToast from '@tenant/utility/hooks/useToast';

const RequestDetails = () => {
  const { id } = useParams();
  const {
    isLoadingBtn,
    isLoadingData,
    approveItem,
    getDetail,
    saveItemToDraft,
    getDefaultCustomerEmail,
  } = useVehicleTracking();
  const { showErrorMessage } = useToast();

  const { vehicleTracking } = useSelector((state) => state.requestWorkflow);
  const { pageIndex, pageSize, searchTerm, status } = vehicleTracking || {};

  const [detail, setDetail] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isApprove = useMemo(() => {
    return detail?.Status === 1;
  }, [detail]);

  const isBiggerThanApprove = useMemo(() => {
    return detail?.Status >= 1;
  }, [detail]);

  const progressComplete = useMemo(() => {
    return detail?.RequestDetails.reduce((r, o) => r + +(o.IMEI !== null), 0);
  }, [detail]);

  const getItem = async (Id, callback) => {
    getDetail(Id, callback);
  };

  const getDetailData = useCallback((id) => {
    setDetail();
    getItem(id, (data) => {
      setDetail(data);
      getDefaultCustomerEmail({
        tenantId: data.TenantId,
        requestId: id,
        onSuccess: (data) => {
          setDetail((prevState) => ({
            ...prevState,
            CustomerEmail: data,
          }));
        },
        onError: (error) => {
          showErrorMessage(error);
        },
      });
    });
  }, []);

  const onClickBackButton = () => {
    navigate('/vehicle-tracking/request');
  };

  const handleChange = (list) => {
    setDetail((prev) => ({ ...prev, RequestDetails: list }));
  };

  const handleChangeDetail = (data) => {
    setDetail((prev) => ({ ...prev, ...data }));
  };

  const callBackDeleteFnc = () => {
    dispatch(
      onGetListRequestWorkflow({
        url: 'api/DeviceRequest',
        field: 'vehicleTracking',
        config: {
          params: {
            status,
            pageIndex,
            pageSize,
            searchTerm,
          },
          paramsSerializer: { indexes: null },
        },
      }),
    );
    navigate('/vehicle-tracking/request');
  };

  const callBackSentDeviceFnc = (payload) => {
    const data = {
      ...detail,
      Status: 2,
      ...payload,
    };
    setDetail(data);
    dispatch(
      updateListRequestWorkflow({
        url: 'api/DeviceRequest',
        field: 'vehicleTracking',
        item: data,
      }),
    );
  };

  const handleApprove = (callBack) => {
    approveItem(detail, () => {
      callBack?.();
      const data = {
        ...detail,
        Status: 1,
        RequestCount: detail.RequestDetails.length,
      };
      setDetail(data);
      modifySimCards(id, detail.RequestDetails, detail.CompanyName);
      dispatch(
        updateListRequestWorkflow({
          url: 'api/DeviceRequest',
          field: 'vehicleTracking',
          item: data,
        }),
      );
      getDetailData(id);
    });
  };

  const modifySimCard = async (id, text) => {
    const res = await axios.patch(
      API_VEHICLE_TRACKING_REQUEST.MODIFY_SIM_CARD + id,
      {
        label: detail.CompanyName,
        description: detail.TenantId,
        //organization: text,
      },
      {
        baseURL: BASE_URL_SIM,
        headers: {
          Authorization: `Token ${process.env.REACT_APP_ACCESS_TOKEN_SIM}`,
        },
      },
    );
    return res;
  };

  const modifySimCards = async (id, request, detail) => {
    const list = request.map((item) => {
      modifySimCard(item.iccid, detail);
    });
    Promise.all(list)
      .then((results) => {
        // console.log(results);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const callBackSaveDraftFnc = (callBack) => {
    saveItemToDraft(detail, () => {
      const data = {
        ...detail,
        RequestCount: detail.RequestDetails.length,
      };
      setDetail(data);
      callBack?.();
    });
  }

  useEffect(() => {
    if (id) {
      getDetailData(id);
    }
  }, [id]);

  return (
    <>
      <AppsHeader>
        {isLoadingData && <AppLoader />}
        <Box
          sx={{
            cursor: 'pointer',
          }}
          component='span'
          mr={{ xs: 2, sm: 4 }}
          onClick={onClickBackButton}
        >
          <AppTooltip title={<IntlMessages id='common.back' />}>
            <ArrowBackIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          </AppTooltip>
        </Box>
        {!!detail && (
          <StatusToggleButton
            needConfirm
            selectedTask={detail}
            onClickApproveButton={(callBack) => handleApprove(callBack)}
            loadingBtn={isLoadingBtn}
            progress={progressComplete / detail.RequestDetails.length}
            allowApprove={true}
            title={'Confirm'}
            statusDataCustom={statusVehicleData}
          >
            Do you want to approve this request
          </StatusToggleButton>
        )}
        <Box
          component='span'
          sx={{
            marginLeft: 'auto',
            display: { sm: 'block' },
          }}
        >
          {!!detail && !isBiggerThanApprove && (
            <>
              <ResquestSaveDraft detail={detail} callBack={callBackSaveDraftFnc} loadingBtn={isLoadingBtn} />
              <ResquestCancelled item={detail} callBack={callBackDeleteFnc} />
            </>
          )}
        </Box>
        {isApprove && (
          <Box
            component='span'
            sx={{
              marginLeft: 'auto',
              display: { sm: 'block' },
            }}
          >
            {!!detail && (
              <ResquestSent detail={detail} callBack={callBackSentDeviceFnc} />
            )}
          </Box>
        )}
      </AppsHeader>
      <AppsContent isDetailView>
        {!isLoadingData && !!detail && (
          <ResquestDescription
            detail={detail}
            onChange={handleChange}
            handleChangeDetail={handleChangeDetail}
          />
        )}
      </AppsContent>
    </>
  );
};

export default RequestDetails;
