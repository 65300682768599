import React from 'react';
import Bullet from './Bullet';

const Legend = (props) => {
  const { payload, customClass = '' } = props;
  return (
    <ul className={`LegendList ${customClass}`}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>
          <div className='BulletLabel'>
            <Bullet backgroundColor={entry.payload.fill} size={10} />
            <div className='BulletLabelText'>{entry.payload.text}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Legend;
