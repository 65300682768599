import React, {useState} from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import IntlMessages from '@tenant/utility/IntlMessages';
import { useNavigate } from 'react-router-dom';

const RequestModal = ({callBack}) => {
  const navigate = useNavigate();
  const onOpenAddTask = () => {
    navigate('/vehicle-tracking/add');
  };


  return (
    <>
      <Button
        variant='text'
        color='primary'
        sx={{
          padding: '8px 28px',
          borderRadius: 30,
          '& .MuiSvgIcon-root': {
            fontSize: 26,
          },
        }}
        startIcon={<AddIcon />}
        onClick={onOpenAddTask}
      >
        <IntlMessages id='smsautomate.addsmsautomate' />
      </Button>
    </>
  );
};

export default React.memo(RequestModal);
