import React, { useCallback, useEffect, useMemo } from 'react';
import AppGridContainer from '@tenant/core/AppGridContainer';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CountrySelect from '@tenant/core/App/Select/CountrySelect';
import IndustrySelect from '@tenant/core/App/Select/IndustrySelect';
import TypeCustomerSelect from '@tenant/core/App/Select/TypeCustomerSelect';
import EmailIcon from '@mui/icons-material/Email';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import NumberOfUserSelect from '@tenant/core/App/Select/NumberOfUserSelect';
import ReferralCodeSelect from '@tenant/core/App/Select/ReferralCodeSelect';
import SeedTemplateSelect from '@tenant/core/App/Select/SeedTemplateSelect';
import { EnabledMemberShipByReferralCode } from './constants';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import useNextTick from '@tenant/utility/hooks/useNextTick';

const Form = ({
  industries,
  typeOfCustomers,
  numberOfUsers,
  templates,
  membershipSchemes,
  trackerClientGroups,
  setFieldValue = () => {},
  validateField = () => {},
  errors = {},
  values = {},
  setDisabledMemberShip,
}) => {
  const nextTick = useNextTick();
  const isDisabledMemberShip = useMemo(
    () =>
      values.MembershipSchemes?.ReferralCode
        ? !EnabledMemberShipByReferralCode.some(
            (e) => e === values?.MembershipSchemes?.ReferralCode,
          )
        : true,
    [values?.MembershipSchemes?.ReferralCode],
  );

  useEffect(() => {
    if (templates?.length > 0) {
      setFieldValue(
        'SeedTemplate',
        templates?.find((e) => e.IsDefault) ?? null,
        false,
      );
    }
  }, [templates]);

  useEffect(() => {
    setDisabledMemberShip?.(isDisabledMemberShip);
    if (isDisabledMemberShip) {
      setFieldValue('MembershipNumber', '', false);
    }
  }, [isDisabledMemberShip]);

  const onSetFieldValue = useCallback(async (field, value, trigger = true) => {
    setFieldValue(field, value, false);
    if (trigger) {
      await nextTick();
      validateField(field);
    }
  }, []);

  return (
    <AppGridContainer>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Company name
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TextField
          placeholder='Enter text'
          name='CompanyName'
          error={!!errors.CompanyName}
          helperText={errors.CompanyName}
          value={values.CompanyName ?? ''}
          sx={{ width: '100%' }}
          onChange={async (e) => {
            onSetFieldValue('CompanyName', e.target.value);
          }}
          onBlur={(e) => {
            onSetFieldValue('CompanyName', values.CompanyName?.trim());
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Country
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <CountrySelect
          placeholder='Select a country'
          error={!!errors.Country}
          helperText={errors.Country}
          value={values.Country ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('Country', value);
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Phone number
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TextField
          placeholder='Enter phone'
          error={!!errors.PhoneNumber}
          helperText={errors.PhoneNumber}
          value={values.PhoneNumber ?? ''}
          sx={{ width: '100%' }}
          onChange={(e) => {
            onSetFieldValue('PhoneNumber', e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Box
          component='p'
          color='text.primary'
          fontSize={14}
          mb={2}
          display={'flex'}
          alignItems={'center'}
        >
          Primary Email
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TextField
          error={!!errors.EmailAddress}
          helperText={errors.EmailAddress}
          value={values.EmailAddress ?? ''}
          sx={{ width: '100%' }}
          placeholder='example@joblogic.com'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            onSetFieldValue('EmailAddress', e.target.value?.trim());
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box
          component='p'
          color='text.primary'
          fontSize={14}
          mb={2}
          display={'flex'}
          alignItems={'center'}
        >
          Password
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
          <AppGlossary
            title={
              <>
                <Box>At least 12 characters</Box>
                <Box>Both lower (a-z) and upper case letters (A-Z)</Box>
                <Box>At least one number (0-9)</Box>
                <Box>At least one special character (e.g. , ! @ # ?)</Box>
              </>
            }
          />
        </Box>

        <TextField
          autoComplete='one-time-code'
          error={!!errors.Password}
          helperText={errors.Password}
          placeholder='Enter a password'
          sx={{ width: '100%' }}
          type={values.ShowPassword ? 'text' : 'password'}
          value={values.Password ?? ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    onSetFieldValue(
                      'ShowPassword',
                      !values.ShowPassword,
                      false,
                    );
                  }}
                  edge='end'
                >
                  {values.ShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onSetFieldValue('Password', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Industry
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <IndustrySelect
          initialData={industries}
          placeholder='Select a industry'
          error={!!errors.Industry}
          helperText={errors.Industry}
          value={values.Industry ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('Industry', value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Type of customers
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TypeCustomerSelect
          initialData={typeOfCustomers}
          error={!!errors.TypeOfCustomers}
          helperText={errors.TypeOfCustomers}
          value={values.TypeOfCustomers ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('TypeOfCustomers', value);
          }}
        />
      </Grid>
      {/*<Grid item xs={12} md={6} lg={4}>*/}
      {/*  <Box component='p' color='text.primary' fontSize={14} mb={2}>*/}
      {/*    Group*/}
      {/*  </Box>*/}
      {/*  <GroupTenantSelect*/}
      {/*    initialData={trackerClientGroups}*/}
      {/*    error={!!errors.Group}*/}
      {/*    helperText={errors.Group}*/}
      {/*    value={values.Group ?? null}*/}
      {/*    sx={{ width: '100%' }}*/}
      {/*    onChange={(e, value) => {*/}
      {/*      onSetFieldValue('Group', value);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Company Size
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <NumberOfUserSelect
          initialData={numberOfUsers}
          error={!!errors.NumberOfUsers}
          helperText={errors.NumberOfUsers}
          value={values.NumberOfUsers ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('NumberOfUsers', value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Referral Code
        </Box>
        <ReferralCodeSelect
          initialData={membershipSchemes?.filter(
            (e) => e.ReferralCode !== 'subcontractor',
          )}
          error={!!errors.MembershipSchemes}
          helperText={errors.MembershipSchemes}
          value={values.MembershipSchemes ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('MembershipSchemes', value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Seed Template
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <SeedTemplateSelect
          initialData={templates}
          error={!!errors.SeedTemplate}
          helperText={errors.SeedTemplate}
          value={values.SeedTemplate ?? null}
          sx={{ width: '100%' }}
          onChange={(e, value) => {
            onSetFieldValue('SeedTemplate', value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Membership Number
          {!isDisabledMemberShip && (
            <Box component='span' ml={1} sx={{ color: 'error.main' }}>
              *
            </Box>
          )}
        </Box>
        <TextField
          type='number'
          disabled={isDisabledMemberShip}
          placeholder='Enter number'
          error={!!errors.MembershipNumber}
          helperText={
            !isDisabledMemberShip
              ? !!errors.MembershipNumber && 'Please enter a valid number'
              : ''
          }
          value={values.MembershipNumber ?? ''}
          sx={{
            width: '100%',
            '& input.Mui-disabled': {
              backgroundColor: grey[50],
            },
          }}
          onChange={(e) => {
            onSetFieldValue('MembershipNumber', +e.target.value || '');
          }}
          onKeyDown={(e) => {
            if (e.key === '.' || e.key.toLowerCase() === 'e') {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <FormControlLabel
          control={<Checkbox checked={!!values.IsSubcontractorUseRefcom} />}
          label='Is Refcom'
          onChange={(e) => {
            onSetFieldValue('IsSubcontractorUseRefcom', e.target.checked);
          }}
        />
      </Grid>
    </AppGridContainer>
  );
};

export default React.memo(Form);

Form.propTypes = {
  industries: PropTypes.array,
  typeOfCustomers: PropTypes.array,
  numberOfUsers: PropTypes.array,
  templates: PropTypes.array,
  membershipSchemes: PropTypes.array,
  trackerClientGroups: PropTypes.array,
  setFieldValue: PropTypes.func,
  validateField: PropTypes.func,
  setDisabledMemberShip: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
};
