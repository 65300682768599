import { IconButton, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { InputField } from 'pages/apps/Company/formFields';
import { useTheme } from '@mui/styles';
import useInvoice from 'pages/apps/Company/hooks/useInvoice';
import { currencyFormat } from '@tenant/utility/Utils';

const InvoiceLine = ({
  isEdit,
  handleSubmit,
  index,
  //handleEditProduct,
  invoice,
  isView = false,
}) => {
  const theme = useTheme();
  const { calculatorLineByDiscount } = useInvoice();

  const styleText = (color) => ({
    fontWeight: 'bold',
    color,
  });

  const handleEditProduct = (invoice) => {
    handleSubmit({ ...invoice, IsEdit: true });
  };

  const onAddProduct = (invoice) => {
    if(invoice?.LineTotal === 0 && invoice?.DiscountValue > 0){
      const isPercent = !!invoice.Discount.includes('%');
      if (isPercent) {
        invoice.Discount = '100%';
      } else {
        invoice.Discount = invoice.DiscountValue;
      }
    }
    handleSubmit({ ...invoice, IsEdit: false });
  };

  const handleChangeDiscount = (invoice, index, event) => {
    let discount = event.target.value;
    invoice.UnitPrice = invoice.UnitAmount;
    const regex = /^[\d.%]*$/;
    if (regex.test(discount)) {
      const line = calculatorLineByDiscount(invoice, discount);
      const newInvoices = {
        ...line,
        Discount: line?.Discount,
        DiscountValue: line?.DiscountValue,
        Amount: line.Amount,
        TaxAmount: line.TaxAmount,
        LineTotal: line.AmountDue,
      };
      handleSubmit(newInvoices);
    }
  };

  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        }}
      >
        <TableCell>{invoice?.Description}</TableCell>
        <TableCell>{invoice?.Quantity}</TableCell>
        <TableCell>{invoice?.UnitAmount}</TableCell>
        <TableCell sx={styleText(theme.palette.success.main)}>
          {invoice.IsEdit ? (
            <InputField
              name={`Invoice[${index}]`}
              label={'Discount'}
              fullWidth
              onChange={(event) => handleChangeDiscount(invoice, index, event)}
              inputDiscount={{
                pattern: '/^[\\d.%]*$/',
                inputMode: 'numeric',
              }}
              value={invoice?.Discount ? invoice?.Discount : ''}
              inputRef={(ref) => {
                if (ref) {
                  ref.addEventListener('keydown', (event) => {
                    // Prevent non-numeric characters and special keys
                    if (
                      event.key === '.' ||
                      event.key === ',' ||
                      !event?.key?.match(/^[\d.%]*$/) ||
                      event.key === 'Backspace' ||
                      event.key === 'Delete' ||
                      event.key === 'ArrowLeft' ||
                      event.key === 'ArrowRight' ||
                      event.key === 'Tab' ||
                      event.key === 'Escape' ||
                      event.key === 'Enter'
                    ) {
                      return;
                    }

                    // Prevent multiple dots
                    if (event.key === '.' && value.includes('.')) {
                      event.preventDefault();
                    }
                  });
                }
              }}
            />
          ) :
            typeof invoice?.Discount === 'string' &&
            invoice?.Discount?.includes('%') ? (
            invoice.Discount
          ) : currencyFormat(invoice?.Discount) ? (
            currencyFormat(invoice?.Discount).toString()
          ) : null}
        </TableCell>
        <TableCell sx={styleText(theme.palette.error.main)}>
          {invoice?.TaxRate + '%'}
        </TableCell>
        <TableCell sx={styleText(theme.palette.error.main)}>
          {currencyFormat(invoice?.TaxAmount)}
        </TableCell>
        <TableCell>{currencyFormat(invoice?.Amount)}</TableCell>
        <TableCell sx={styleText(theme.palette.primary.main)}>
          {currencyFormat(invoice?.LineTotal)}
        </TableCell>
        {!isView && (
          <TableCell align='right' sx={{ p: 2 }}>
            {isEdit ? (
              <IconButton onClick={() => onAddProduct(invoice)}>
                <DoneIcon color='success' fontSize='small' />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleEditProduct(invoice)}>
                <EditIcon color='primary' fontSize='small' />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default InvoiceLine;
