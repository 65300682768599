import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ANALYTICS_DATA,
  SET_DATA_ANALYTICS,
  SET_FILTER_DATA_ANALYTICS,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@tenant/utility/IntlMessages';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { getPropertyByField } from '@tenant/utility/helper/Utils';

export const onGetAnalyticsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/dashboard/analytics')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_ANALYTICS_DATA, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDataDashboard = (payload) => {
  const {
    url,
    config,
    field,
    responseField = 'data',
    convertData,
    isCachedFilter = false,
  } = payload || {};
  return async (dispatch) => {
    await jwtAxios
      .get(url, config)
      .then((data) => {
        if (data.status === 200) {
          let dataset = getPropertyByField(data, responseField);
          if (convertData) {
            dataset = convertData(dataset);
          }
          dispatch({
            type: SET_DATA_ANALYTICS,
            payload: { field, data: dataset },
          });
          if (isCachedFilter) {
            dispatch({
              type: SET_FILTER_DATA_ANALYTICS,
              payload: { data: config?.params, field },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const setFilterDataDashboard = (payload) => {
  const { data, field } = payload || {};
  return (dispatch) => {
    dispatch({
      type: SET_FILTER_DATA_ANALYTICS,
      payload: { data, field },
    });
  };
};
