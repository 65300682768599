import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import AppGridContainer from '@tenant/core/AppGridContainer';
import {
  TextField,
  Autocomplete,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { debounce } from '@tenant/utility/helper/Utils';
import { DatePicker } from '@mui/x-date-pickers';
import ListVehicle from './ListVehicle';
import useVehicleTracking from '../../hooks/useVehicleTracking';
import useToast from '@tenant/utility/hooks/useToast';
import useTenant from 'pages/apps/hooks/useTenant';
import { useDispatch } from 'react-redux';
import { GET_LIST_DEVICE_CONTRACT_ITEM } from 'shared/constants/ActionTypes';
import { useLocation } from 'react-router-dom';
import AppLoader from '@tenant/core/AppLoader';

const RequestForm = (props) => {
  const {
    setFieldValue,
    errors,
    values,
    touched,
    setSubmitting,
    activeStep = true,
    setFieldError,
    setFieldTouched,
  } = props;
  const { showErrorMessage } = useToast();
  const { getDropdownTenants, loadingTenants } = useTenant();
  const [tenants, setTenants] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramTenantId = queryParams.get('tenantId');
  const [loading, setLoading] = useState(false);

  const [selectedTenant, setSelectedTenant] = useState(null);
  const { getListDraftInvoice, createDeviceRequestAllowed, getDefaultCustomerEmail } =
    useVehicleTracking();

  const onChangeTenant = (e, value) => {
    setSubmitting(true);
    setLoading(true);
    if (!value?.TenantId) return;
    setFieldValue('TenantId', value.TenantId);
    setSelectedTenant({
      ...value,
    });
    checkTenant(value.TenantId);
    getDefaultCustomerEmail({
      tenantId: value.TenantId,
      onSuccess: (data) => {
        setFieldValue('CustomerEmail', data);
      },
      onError: (error) => {
        showErrorMessage(error);
      },
    });
  };

  const checkTenant = (tenantId) => {
    createDeviceRequestAllowed({
      id: tenantId,
      onSuccess: () => {
        getListDraftInvoice({
          id: tenantId,
          onSuccess: (data) => {
            dispatch({ type: GET_LIST_DEVICE_CONTRACT_ITEM, payload: data });
            setSubmitting(false);
            setLoading(false);
          },
          onError: (err) => {
            showErrorMessage(err);
          },
        });
      },
      onError: (err) => {
        setSubmitting(true);
        setLoading(false);
        setFieldError('TenantId', err);
        setFieldTouched('TenantId', true, false);
      },
    });
  };

  const handleChangeRequestDate = (date) => {
    setFieldValue('RequestDate', date);
  };

  const onInputTenantChange = debounce((e, value, reason) => {
    if (e?.type === 'change') {
      getTenants({
        searchTerm: value,
      });
      return;
    }

    if (reason === 'clear') {
      setTenants([]);
      setFieldValue('TenantId', '');
      setSelectedTenant(null);
      getTenants();
      setLoading(false);
    }
  }, 700);

  const getTenants = (params) => {
    getDropdownTenants(params, (data) => {
      setTenants(data);
    });
  };

  useEffect(() => {
    if (tenants.length === 0 && !paramTenantId) return;
    if (paramTenantId) {
      const tenant = tenants?.find(
        (item) => item?.TenantId === paramTenantId,
      );
      if (!tenant) {
        getDropdownTenants({ tenantId: paramTenantId }, (data) => {
          const tenant = data?.find(
            (item) => item?.TenantId === paramTenantId,
          );
          setSelectedTenant(tenant);
          setFieldValue('TenantId', paramTenantId);
          checkTenant(paramTenantId);
        });
        return;
      }
      setSelectedTenant(tenant);
      setFieldValue('TenantId', paramTenantId);
    }
  }, [paramTenantId]);

  useEffect(() => {
    getTenants();
  }, []);

  if (!activeStep) {
    return null;
  }

  return (
    <Box>
      {loading && <AppLoader />}
      <AppGridContainer spacing={6}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant='outlined'>
            <Autocomplete
              name='TenantId'
              options={tenants ?? []}
              getOptionLabel={(opt) => opt.Name}
              isOptionEqualToValue={(option, value) =>
                option?.TenantId === value?.TenantId
              }
              renderOption={(props, option) => (
                <li {...props} key={option.TenantId}>
                  {option.Name}
                </li>
              )}
              loading={loadingTenants}
              value={selectedTenant}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label={'Tenant'}
                  required
                  error={Boolean(errors.TenantId) && touched.TenantId}
                  helperText={
                    Boolean(errors.TenantId) &&
                    touched.TenantId &&
                    errors.TenantId
                  }
                />
              )}
              onInputChange={onInputTenantChange}
              onChange={(e, value) => onChangeTenant(e, value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <DatePicker
            label='Request Date'
            value={values.RequestDate}
            error={Boolean(errors.RequestDate)}
            inputFormat='dd/MM/yyyy'
            onChange={handleChangeRequestDate}
            disablePast
            renderInput={(params) => <TextField fullWidth {...params} />}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant='outlined'
            required
            label={'Address'}
            value={values.Address}
            error={Boolean(errors.Address) && touched.Address}
            helperText={
              Boolean(errors.Address) && touched.Address && errors.Address
            }
            onChange={(e) => setFieldValue('Address', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControlLabel
            control={<Checkbox checked={values.ShouldSendEmail} />}
            label='Send email to customer'
            onChange={(e) => {
              setFieldValue('ShouldSendEmail', e.target.checked);
              if (!e.target.checked) {
                setFieldValue('CustomerEmail', '');
              }
            }}
          />
        </Grid>
        {values.ShouldSendEmail && (
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              variant='outlined'
              label={'Customer Email'}
              value={values.CustomerEmail}
              fullWidth
              placeholder='Please enter your email'
              error={Boolean(errors.CustomerEmail) && touched.CustomerEmail}
              helperText={
                Boolean(errors.CustomerEmail) &&
                touched.CustomerEmail &&
                errors.CustomerEmail
              }
              onChange={(e) => {
                setFieldValue('CustomerEmail', e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <ListVehicle {...props} />
        </Grid>
      </AppGridContainer>
    </Box>
  );
};

export default React.memo(RequestForm);

RequestForm.defaultProps = {
  isSubmitting: false,
};

RequestForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
};
