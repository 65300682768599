import React, { memo, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const CopyButton = ({ text }) => {
  const [isCopy, setIsCopy] = useState(false);

  const handleClick = () => {
    setIsCopy(true);
    navigator.clipboard.writeText(text?.toString());
  };

  const handleClose = () => {
    setIsCopy(false);
  };

  return (
    <>
      <Tooltip title='Copy'>
        <IconButton
          color='primary'
          edge='end'
          aria-label='copy'
          onClick={handleClick}
        >
          <ContentCopyIcon sx={{ fontSize: 14 }} />
        </IconButton>
      </Tooltip>

      {createPortal(
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={isCopy}
          message='Copy to clipboard'
          onClose={handleClose}
          // sx={{ position: "absolute" }}
          autoHideDuration={1500}
        />,
        document.body,
      )}
    </>
  );
};

CopyButton.propTypes = {
  text: PropTypes.string,
};

export default memo(CopyButton);
