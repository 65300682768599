import React from 'react';
import AppTooltip from '@tenant/core/AppTooltip';
import {useIntl} from 'react-intl';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const DescriptionTooltip = ({title, icon, placement = 'top'}) => {
  const {messages} = useIntl();
  return (
    <AppTooltip
      arrow
      placement={placement}
      title={
        <Box>
          <Box component='p'>{messages[`${title}`]}</Box>
        </Box>
      }
    >
      {icon}
    </AppTooltip>
  );
};

export default React.memo(DescriptionTooltip);

DescriptionTooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  placement: PropTypes.string,
  icon: PropTypes.node,
};
