import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import { inputFormat } from '@tenant/utility/constants/default';
import moment from 'moment';
import AppLoader from '@tenant/core/AppLoader';
import StepRemoveUserSelectionConfirm from './StepRemoveUserSelectionConfirm';

const DefaultSteps = [
  {
    name: 'Select User(s)',
    code: 'select-user',
  },
  {
    name: 'Confirm',
    code: 'confirm',
  },
];

const PRIMARY_TOTAL = 1;

const DetailRemoveMaxUserNumberConfirm = ({
  users,
  visible,
  onClose,
  onSubmit,
  subscriptionEnd,
  newMaxNumberOfUsers = 0,
  maxNumberOfUsers = 0,
  loadingBtn,
}) => {
  const [steps, setSteps] = useState(DefaultSteps);
  const [activeStep, setActiveStep] = useState(DefaultSteps[0].code);
  const [selected, setSelected] = useState([]);
  const totalUsers = useMemo(
    () => users.length + PRIMARY_TOTAL,
    [users.length],
  );
  const totalSelection = useMemo(
    () =>
      newMaxNumberOfUsers >= totalUsers ? 0 : totalUsers - newMaxNumberOfUsers,
    [newMaxNumberOfUsers, totalUsers],
  );
  const isSelectUserStep = useMemo(
    () => activeStep === 'select-user',
    [activeStep],
  );
  const isConfirmStep = useMemo(() => activeStep === 'confirm', [activeStep]);
  const isHasSelectUserStep = useMemo(
    () => steps.find((step) => step.code === 'select-user'),
    [steps],
  );

  const onChangeSelection = useCallback(
    (params) => {
      const find = selected.some((e) => e.UniqueId === params.value);

      if (find) {
        setSelected((s) => s.filter((e) => e.UniqueId !== params.value));
        return;
      }

      if (!find && selected.length >= totalSelection) {
        return;
      }

      setSelected([...selected, params.row]);
    },
    [selected, totalSelection],
  );

  const columns = useMemo(
    () => [
      {
        hide: isConfirmStep,
        field: 'UniqueId',
        headerName: 'Choose',
        sortable: false,
        width: 75,
        renderCell: (params) => (
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !selected.some((e) => e.UniqueId === params.value)
                    ? selected.length >= totalSelection
                    : false
                }
                checked={selected.some((e) => e.UniqueId === params.value)}
              />
            }
            onChange={() => onChangeSelection(params)}
          />
        ),
      },
      {
        field: 'EmailAddress',
        headerName: 'Email',
        flex: 1,
        sortable: false,
      },
      {
        field: 'Name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
      },
    ],
    [selected, totalSelection, isConfirmStep, onChangeSelection],
  );

  const onChangeStep = useCallback((step) => {
    setActiveStep(step);
  }, []);

  useEffect(() => {
    if (totalSelection === 0) {
      const newSteps = DefaultSteps.filter(
        (step) => step.code !== 'select-user',
      );
      setSteps(newSteps);
      setActiveStep(newSteps[0].code);
      return;
    }

    setSteps(deepClone(DefaultSteps));
    setActiveStep(DefaultSteps[0].code);
  }, [totalSelection]);

  return (
    <Dialog
      open={visible}
      aria-labelledby='remove-max-user-dialog-title'
      aria-describedby='remove-max-user-dialog-description'
      maxWidth='md'
      fullWidth
      height={500}
    >
      <DialogTitle id='remove-max-user-dialog-title' fontSize={16}>
        Selection User(s) to Deactivate
      </DialogTitle>

      {loadingBtn && <AppLoader />}

      <DialogContent sx={{ minHeight: 450 }}>
        {steps.length > 1 && (
          <Box mb={5}>
            <Stepper activeStep={steps.findIndex((e) => e.code === activeStep)}>
              {steps.map(({ name, code }) => {
                return (
                  <Step key={code}>
                    <StepLabel>{name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        )}

        {isSelectUserStep && (
          <Box sx={{ height: 350 }}>
            <Box mb={2}>
              Selected: {selected.length} / {totalSelection}
            </Box>
            <DataGrid
              hideFooter
              disableColumnSelector
              disableColumnMenu
              rows={users}
              columns={columns}
              getRowId={(row) => row.UniqueId}
            />
          </Box>
        )}

        {isConfirmStep && (
          <Box mt={4}>
            {isHasSelectUserStep && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <Box mb={2}>
                    All steps will be processed at{' '}
                    <strong>
                      {subscriptionEnd
                        ? moment(subscriptionEnd).format(inputFormat)
                        : formatDate(new Date())}
                    </strong>
                  </Box>

                  <StepRemoveUserSelectionConfirm />

                  <Box mt={4}>
                    <DialogContentText>
                      <Typography component={'span'} variant='button'>
                        Old Max Number of Users:
                      </Typography>{' '}
                      <Typography
                        component={'span'}
                        variant='button'
                        color={'secondary.main'}
                      >
                        {maxNumberOfUsers ?? 0}
                      </Typography>{' '}
                    </DialogContentText>
                    <DialogContentText mt={2} mb={2}>
                      <Typography component={'span'} variant='button'>
                        New Max Number of Users:
                      </Typography>{' '}
                      <Typography
                        component={'span'}
                        variant='button'
                        color={'primary.main'}
                      >
                        {newMaxNumberOfUsers ?? 0}
                      </Typography>{' '}
                    </DialogContentText>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7} sx={{ height: 350 }}>
                  <DataGrid
                    hideFooter
                    disableColumnSelector
                    disableColumnMenu
                    rows={selected}
                    columns={columns}
                    getRowId={(row) => row.UniqueId}
                  />
                </Grid>
              </Grid>
            )}

            {!isHasSelectUserStep && (
              <Box>
                We will change the number of users to{' '}
                <Typography
                  component={'span'}
                  color={'primary.main'}
                  fontWeight={'bold'}
                >
                  {newMaxNumberOfUsers}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {isConfirmStep && (
          <>
            {isHasSelectUserStep ? (
              <Button onClick={() => onChangeStep(DefaultSteps[0].code)}>
                Previous
              </Button>
            ) : (
              <Button onClick={onClose}>Cancel</Button>
            )}

            <LoadingButton
              loading={loadingBtn}
              color='primary'
              variant='contained'
              onClick={() => {
                onSubmit?.({
                  removeUserIds: selected?.map((e) => e.UniqueId),
                });
              }}
            >
              Submit
            </LoadingButton>
          </>
        )}
        {isSelectUserStep && (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              disabled={selected.length < totalSelection}
              onClick={() => onChangeStep(DefaultSteps[1].code)}
              variant='contained'
            >
              Next
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DetailRemoveMaxUserNumberConfirm);

DetailRemoveMaxUserNumberConfirm.propTypes = {
  users: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  subscriptionEnd: PropTypes.string,
  newMaxNumberOfUsers: PropTypes.number,
  maxNumberOfUsers: PropTypes.number,
  loadingBtn: PropTypes.bool,
};
