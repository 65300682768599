import { useEffect, useState } from 'react';
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useIntl } from 'react-intl';
import InputAdornment from '@mui/material/InputAdornment';
import PercentIcon from '@mui/icons-material/Percent';

import { BUNDLE } from 'pages/tenantDetail/constants';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import BundleSelect from '@tenant/core/App/Select/BundleSelect';
import { parseCredit } from 'pages/apps/ProductUsage/helpers';
import { calculateDiscountedPrices } from 'pages/tenantDetail/helpers/Bundles';
import { Fonts } from 'shared/constants/AppEnums';

const FormBundleContent = ({
  values,
  setFieldValue,
  errors,
  handleAddProduct,
  onRemoveProduct,
}) => {

  const { messages } = useIntl();
  const [discountType, setDiscountType] = useState(1);
  const onChangeBundle = (data) => {
    if (!data?.Id) return;

    setFieldValue('SelectedBundle', data);
    setFieldValue('Id', data?.Id);
    setFieldValue('BasePrice', data?.BasePrice);
    setFieldValue('Name', data?.Name);
    setFieldValue('Points', data?.Id === BUNDLE.Topup ? 1 : data?.Points);
    setFieldValue('Price', data?.Price);
    setFieldValue('Quantity', 1);
    setFieldValue('Note', '');
  };

  const handleRemoveProduct = () => {
    setFieldValue('SelectedBundle', null);
    onRemoveProduct();
  };

  useEffect(() => {
    if (values.Points > 1000) return;

    if (values.Id === BUNDLE.Topup) {
      setFieldValue('Price', values.Points > 0 ? (values.BasePrice * values.Points) : values.BasePrice);
    }
  }, [values.Id, values.Points, values.BasePrice]);

  useEffect(() => {
    if (values.DiscountPercent > 100) return;

    setFieldValue('DiscountedPrices', calculateDiscountedPrices(
      values.BundleTotalPrices,
      values.DiscountPercent
    ));
  }, [values.BundleTotalPrices, values.DiscountPercent]);

  useEffect(() => {
    setFieldValue('BundleTotalPrices', parseFloat(
      Number(values.Price) * Number(values.Quantity))
    );
    setFieldValue('BundleTotalPoints', parseFloat(
      Number(values.Points) * Number(values.Quantity))
    );
  }, [values.Points, values.Price, values.Quantity]);

  return (
    <>
      <TableRow
        sx={{
          'td, th': {
            border: 0,
          },
        }}
      >
        <TableCell sx={{ width: 150, maxWidth: 150 }}>
          <BundleSelect
            variant={'standard'}
            value={values.SelectedBundle || null}
            onChange={(e, data) => onChangeBundle(data)}
          />
        </TableCell>

        {values.Id === BUNDLE.Topup
          ? (
            <>
              <TableCell
                sx={{
                  width: 100,
                  maxWidth: 100
                }}
              >
                <TextField
                  fullWidth
                  required
                  variant='standard'
                  name='Points'
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 0.5, step: 0.5, max: 1000 }}
                  error={!!errors.Points}
                  value={values.Points}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (!value) {
                      setFieldValue('Points', '');
                      return;
                    }

                    if (value.match(/\./g)) {
                      const [, decimal] = value.split('.');

                      if (decimal?.length > 2) {
                        e.preventDefault();
                        return;
                      }

                      setFieldValue('Points', Number(value));
                      return;
                    }

                    setFieldValue('Points', Number(value));
                  }}
                />
              </TableCell>
              <TableCell align='right'
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: 14,
                  minWidth: 120,
                  maxWidth: 120
                }}
              >
                {numberToCurrency(values.Price ?? 0, 2, 'GBP')}
              </TableCell>
            </>
          )
          : (
            <>
              <TableCell
                align='right'
                sx={{
                  color: 'success.main',
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {parseCredit(values?.Points)}
              </TableCell>
              <TableCell
                align='right'
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {numberToCurrency(values.Price ?? 0, 2, 'GBP')}
              </TableCell>
            </>
          )
        }

        <TableCell
          align='right'
          sx={values.Id === BUNDLE.Topup
            ? { width: 110, maxWidth: 110 }
            : { width: 130, maxWidth: 130 }
          }
        >
          <TextField
            type='number'
            variant='standard'
            name='Quantity'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 1, step: 1 }}
            error={!!errors.Quantity}
            value={values.Quantity ?? 1}
            disabled={!values.Id || values.Id === BUNDLE.Topup}
            onChange={(e) => {
              const value = e.target.value;

              if (!value) {
                setFieldValue('Quantity', '');
                return;
              }

              if (value.match(/\./g)) {
                const [, decimal] = value.split('.');

                if (!!decimal?.length) {
                  e.preventDefault();
                  return;
                }

                setFieldValue('Quantity', Number(value));
                return;
              }
              setFieldValue('Quantity', Number(value));
            }}
          />
        </TableCell>

        <TableCell
          align='right'
          sx={{
            color: 'success.main',
            fontWeight: 600,
            fontSize: 14,
            minWidth: 120,
            maxWidth: 120
          }}
        >
          {parseCredit(values?.BundleTotalPoints)}
        </TableCell>

        <TableCell
          align='right'
          sx={{
            color: 'primary.main',
            fontWeight: 600,
            fontSize: 14,
            minWidth: 120,
            maxWidth: 120
          }}
        >
          {numberToCurrency(values.BundleTotalPrices ?? 0, 2, 'GBP')}
        </TableCell>

        <TableCell
          align='right'
          sx={{
            minWidth: 100,
            maxWidth: 100
          }}
        >
          <TextField
            type='number'
            variant='standard'
            name='DiscountPercent'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0, step: 1, max: 100 }}
            placeholder='0-100'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <PercentIcon fontSize='small' />
                </InputAdornment>
              ),
            }}
            error={!!errors.DiscountPercent}
            value={values.DiscountPercent ?? 0}
            disabled={!values.Id}
            onChange={(e) => {
              const value = e.target.value;

              if (!value) {
                setFieldValue('DiscountPercent', '');
                return;
              }

              if (value.match(/\./g)) {
                const [, decimal] = value.split('.');

                if (decimal?.length > 2) {
                  e.preventDefault();
                  return;
                }

                setFieldValue('DiscountPercent', Number(value));
                return;
              }
              setFieldValue('DiscountPercent', Number(value));
            }}
          />
        </TableCell>

        {/* Actions */}
        <TableCell align='right' sx={{ pr: 0, width: 120 }}>
          <IconButton onClick={handleAddProduct} disabled={!values?.SelectedBundle}>
            <CheckIcon
              color={!values?.SelectedBundle ? 'disabled' : 'success'}
              fontSize='small'
            />
          </IconButton>
          <IconButton onClick={handleRemoveProduct}>
            <DeleteIcon color='error' fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Error Message */}
      <>
        {!!errors.Points && values.Id === BUNDLE.Topup && (
          <TableRow
            sx={{
              'td, th': {
                border: 0,
              },
            }}
          >
            <TableCell colSpan={7} sx={{ p: '0 16px' }}>
              <FormHelperText error component='span'>
                {errors.Points}
              </FormHelperText>
            </TableCell>
          </TableRow>
        )}

        {!!errors.DiscountPercent && (
          <TableRow
            sx={{
              'td, th': {
                border: 0,
              },
            }}
          >
            <TableCell colSpan={7} sx={{ p: '0 16px' }}>
              <FormHelperText error component='span'>
                {errors.DiscountPercent}
              </FormHelperText>
            </TableCell>
          </TableRow>
        )}

        {!!errors.Quantity && (
          <TableRow
            sx={{
              'td, th': {
                border: 0,
              },
            }}
          >
            <TableCell colSpan={7} sx={{ p: '0 16px' }}>
              <FormHelperText error component='span'>
                {errors.Quantity}
              </FormHelperText>
            </TableCell>
          </TableRow>
        )}
      </>

      {values.Id === BUNDLE.Topup && (
        <TableRow
          sx={{
            'td, th': {
              border: 0,
            },
          }}
        >
          <TableCell colSpan={7} sx={{ p: '0 16px' }}>
            <TextField
              fullWidth
              multiline
              sx={{
                width: '100%',
                fontWeight: Fonts.LIGHT,
                marginTop: 2,
              }}
              inputProps={{ maxLength: 255 }}
              rows='2'
              variant='outlined'
              label='Note'
              name='Note'
              value={values.Note}
              onChange={(e) => {
                setFieldValue('Note', e.target.value);
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default FormBundleContent;
