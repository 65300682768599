import React, { useState, useMemo, useCallback, useEffect, useRef, useId } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StatusMultiSelect = ({
  options,
  checkedStatuses,
  handleSelectStatus,
  limitTags = 3,
}) => {
  const [checkedValues, setCheckedValues] = useState(checkedStatuses);
  const id = useId();

  return (
    <Box>
      <Box
        component={'label'}
        fontWeight='500'
      >
        Status
      </Box>
      <Autocomplete
        multiple
        size='small'
        id={`status-multi-select-${id}`}
        limitTags={limitTags}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) =>
          (option.Id === value.Id)
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.Id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
          </li>
        )}
        value={checkedValues}
        renderInput={(params) => (
          <TextField {...params} placeholder='Select Status' />
        )}
        onChange={(e, value) => {
          setCheckedValues(value);
          handleSelectStatus(value);
        }}
      />
    </Box>
  );
};

export default React.memo(StatusMultiSelect);