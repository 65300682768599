const formatDecimal = (number) => {
  return parseFloat(number).toFixed(2);
};

const calculatorUnitPrice = (month, price) => {
  return parseInt(month) * price;
};

const calculatorAmount = (quantity, price) => {
  return parseInt(quantity) * price;
};

const calculatorDiscountNumber = (value, amount) => {
  let result = 0;
  if (value) {
    const isPercent = !!value.toString().includes('%');
    if (isPercent) {
      const numberPercent = parseFloat(value);
      result = (numberPercent / 100) * amount;
    } else {
      result = parseFloat(value);
    }
  }
  return result;
};

const calculatorTaxAmount = (amount, discount, taxRatePercent) => {
  const discountNumber = calculatorDiscountNumber(discount, amount);

  const numberAfterDiscount = amount - discountNumber;
  return (taxRatePercent / 100) * numberAfterDiscount;
};

const calculatorTotalAmount = (amount, discount, taxValue) => {
  return amount - discount + taxValue;
};

export {
  formatDecimal,
  calculatorUnitPrice,
  calculatorAmount,
  calculatorDiscountNumber,
  calculatorTaxAmount,
  calculatorTotalAmount,
};
