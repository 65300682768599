import React from 'react';
import { Navigate } from 'react-router-dom';
import { initialUrl } from 'shared/constants/AppConst';
import { authRouteConfig } from './auth';
import Error403 from './errorPages/Error403';
import { errorPagesConfigs } from './errorPages';
import { tenantListConfig } from './tenantList';
import { tenantDetailConfig } from './tenantDetail';
import { userPagesConfig } from './userPages';
import { appsConfig } from './apps';
import { accountPagesConfigs } from './account';
import { vehicleTrackingConfig } from './vehicleTracking';
import pointsDashBoardConfigs from './pointsDashboards';
import { mappingManageRoutes } from './mappingManage';
import { permissionRoutes } from './permissions';
import { dashBoardConfigs } from './dashboards';
import { subcontractorListConfig } from './subcontractorList';
import { tenantDetailDashboardConfig } from './dashboards/tenantDetail';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...accountPagesConfigs,
    ...appsConfig,
    ...userPagesConfig,
    ...tenantListConfig,
    ...tenantDetailConfig,
    ...vehicleTrackingConfig,
    ...mappingManageRoutes,
    ...pointsDashBoardConfigs,
    ...permissionRoutes,
    ...dashBoardConfigs,
    ...subcontractorListConfig,
    ...tenantDetailDashboardConfig
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={initialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
