import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import Box from '@mui/material/Box';
import { onGetListRequestWorkflow } from 'redux/actions';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_INDEX,
} from '@tenant/utility/constants/default';
import { DataGrid } from '@mui/x-data-grid';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_ACTIVATION_TYPE_ENUM } from '@tenant/utility/constants/enum';
import { debounce, getKeyByValue } from '@tenant/utility/helper/Utils';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import {
  OrderBySubcontractorRequestEnum,
  OrderDirectionEnum,
} from '../constants/enum';
import SubcontractorMappingFilter from './SubcontractorMappingFilter';
import AppSearchInput from '@tenant/core/AppSearchBar/AppSearchInput';

const SubcontractorMappingList = ({ isHiddenUI }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { mappingList } = useSelector((state) => state.requestWorkflow);
  const {
    pageIndex,
    pageSize,
    totalCount,
    data,
    searchTerm,
    status,
    subStatuses,
    activationTypes,
    orderBy,
    orderDirection,
  } = mappingList || {};
  const [search, setSearch] = useState(searchTerm ?? '');
  const flexTable = useMediaQuery(theme.breakpoints.up('md'));

  const isStopGetInitial = useMemo(
    () =>
      !!searchTerm ||
      status?.length > 0 ||
      !!subStatuses?.length ||
      !!activationTypes?.length,
    [searchTerm, status, subStatuses, activationTypes],
  );

  const count = useMemo(() => {
    if (totalCount && pageSize) {
      if (totalCount % pageSize) return Math.floor(totalCount / pageSize) + 1;
      return Math.floor(totalCount / pageSize);
    }
    return 1;
  }, [totalCount, pageSize]);

  const getListData = useCallback(
    async (payload) => {
      const {
        pageIndex = DEFAULT_PAGE_INDEX,
        pageSize = DEFAULT_PAGE_SIZE,
        searchTerm,
        subStatuses,
        activationTypes,
        orderBy,
        orderDirection,
      } = payload || {};
      setIsLoading(true);
      await dispatch(
        onGetListRequestWorkflow({
          url: 'SubcontractorRequest/GetTenants',
          field: 'mappingList',
          config: {
            params: {
              pageIndex,
              pageSize,
              searchTerm,
              orderBy,
              orderDirection,
              subStatuses,
              activationTypes,
            },
            paramsSerializer: { indexes: null },
          },
        }),
      );
      setIsLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (isStopGetInitial) {
      return;
    }
    getListData();
  }, [getListData, isStopGetInitial]);

  const columns = useMemo(
    () => [
      {
        field: 'CompanyName',
        headerName: 'Main Contractor',
        width: 300,
        flex: flexTable,
        renderCell: (params) => (
          <Tooltip title={params.value}>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/apps/mapping-manage/${params.row.TenantId}`}
              style={{ textDecoration: 'none' }}
            >
              {params.value || 'Empty'}
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'EmailAddress',
        headerName: 'Email',
        width: 250,
        renderCell: (params) => (
          <Tooltip title={params.value}>
            <Box className='MuiDataGrid-cellContent'>
              {params.value || 'Empty'}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'ActivationType',
        headerName: 'Payment Type',
        width: 125,
        renderCell: (params) =>
          getKeyByValue(
            SUBSCRIPTION_ACTIVATION_TYPE_ENUM,
            params.row.ActivationType,
          ) || '',
      },
      {
        field: 'SubscriptionStart',
        headerName: 'Subscription Start',
        width: 150,
        sortable: false,
      },
      {
        field: 'SubscriptionEnd',
        headerName: 'Subscription End',
        width: 150,
        sortable: false,
      },
      {
        field: 'SubscriptionStatus',
        headerName: 'Subscription Status',
        width: 150,
        renderCell: (params) => (
          <Box component={'span'} sx={{ textTransform: 'uppercase' }}>
            {params.value}
          </Box>
        ),
      },
      {
        field: 'MappingCount',
        headerName: 'Mapping Count',
        width: 150,
        align: 'center',
        headerAlign: 'center',
      },
    ],
    [flexTable],
  );

  const onPageChange = (event, page) => {
    if (page < 0) return;

    getListData({
      pageIndex: page,
      pageSize,
      searchTerm,
      subStatuses,
      activationTypes,
      orderBy,
      orderDirection,
    });
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      getListData({
        pageIndex: DEFAULT_PAGE_INDEX,
        searchTerm: event.target.value,
        status,
        subStatuses,
        activationTypes,
        orderBy,
        orderDirection,
      });
    }
  };

  const clearSearchTerm = () => {
    setSearch('');
    getListData({
      pageIndex: DEFAULT_PAGE_INDEX,
      searchTerm: '',
      status,
      subStatuses,
      activationTypes,
      orderBy,
      orderDirection,
    });
  };

  const onSortModelChange = useCallback(
    debounce((model) => {
      const { field, sort } = model?.[0] ?? {};
      getListData({
        pageIndex,
        pageSize,
        totalCount,
        data,
        searchTerm,
        status,
        orderBy: OrderBySubcontractorRequestEnum[field],
        orderDirection: OrderDirectionEnum[sort],
        subStatuses,
        activationTypes,
      });
    }, 500),
    [getListData, pageIndex, pageSize, totalCount, data, searchTerm, status],
  );

  const onFilter = useCallback(
    ({ activationTypes, subStatuses }) => {
      getListData({
        pageIndex: 1,
        pageSize,
        totalCount,
        data,
        searchTerm,
        status,
        orderBy,
        orderDirection,
        activationTypes,
        subStatuses,
      });
    },
    [pageSize, totalCount, data, searchTerm, status, orderBy, orderDirection],
  );

  const onReset = useCallback(() => {
    onFilter({});
  }, [onFilter]);

  if (isHiddenUI) {
    return <></>;
  }

  return (
    <>
      <AppsHeader>
        <Box flex={1} display='flex' alignItems='center'>
          <AppSearchInput
            isClearable
            onClearSearch={clearSearchTerm}
            iconPosition='right'
            id='outlined-adornment-weight'
            size='small'
            searchValue={search ?? ''}
            overlap={false}
            placeholder={'Name/Email'}
            onKeyDown={onEnterSearch}
            onChange={onChangeSearch}
            sx={{
              '& .MuiInputBase-input': {
                width: 260,
              },
              '& .MuiInputBase-input:focus': {
                width: 300,
              },
              mr: 2,
            }}
          ></AppSearchInput>
          <SubcontractorMappingFilter
            onFilter={onFilter}
            onReset={onReset}
            defaultActivationTypes={activationTypes}
            defaultSubStatues={subStatuses}
          />
        </Box>
      </AppsHeader>
      <DataGrid
        hideFooter
        disableColumnSelector
        disableColumnMenu
        loading={isLoading}
        rows={data ?? []}
        columns={columns}
        getRowId={(row) => row.TenantId}
        sortingMode='server'
        onSortModelChange={onSortModelChange}
        sx={{
          borderTop: 0,
        }}
      />
      <FooterWrapper className='footer'>
        <div className='footerContainer justifyCenter'>
          <Pagination
            count={count ?? 0}
            page={pageIndex ?? 0}
            onChange={onPageChange}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount | 0}</Typography>
        </div>
      </FooterWrapper>
    </>
  );
};

export default React.memo(SubcontractorMappingList);

SubcontractorMappingList.propTypes = {
  isHiddenUI: PropTypes.bool,
};
