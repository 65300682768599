import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { API_ENDPOINT } from '../contants/endpoints';
import { PAYMENT_STATUS, PAYMENT_STATUS_ENUM } from '../contants/enums';
import useModal from '../hooks/useModal';
import LockoutContractConfirm from './ContractDetailAction/LockoutContractConfirm';
import ReactiveContractConfirm from './ContractDetailAction/ReactiveContractConfirm';
import ReCollectPayment from './ContractDetailAction/ReCollectPayment';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { inputFormat } from 'pages/tenantDetail/constants';

const CreditControls = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const dataBackup = useRef();
  const [total, setTotal] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const {
    visible: visibleLockout,
    onShow: onShowLockout,
    onClose: onCloseLockout,
  } = useModal();

  const {
    visible: visibleCollectPayment,
    onShow: onShowCollectPayment,
    onClose: onCloseCollectPayment,
  } = useModal();

  const {
    visible: visibleReactivate,
    onShow: onShowReactivate,
    onClose: onCloseReactivate,
  } = useModal();

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await jwtAxios.get(API_ENDPOINT.CREDIT_CONTROLS);

      const data = res.data.ObjectData;

      if (data) {
        const list =
          data
            ?.sort((a, b) => {
              return moment
                .utc(b.IssueDate, inputFormat)
                .diff(moment.utc(a.IssueDate, inputFormat));
            })
            ?.map((item, index) => ({ ...item, index })) || [];
        setData(list);
        dataBackup.current = list;
        setTotal(list.length);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleLockout = async () => {
    onShowLockout();
  };

  const handleCollectPayment = () => {
    onShowCollectPayment();
  };

  const handleOpenXeroInvoice = async (id) => {
    setIsLoading(true);
    const res = await jwtAxios.get(
      API_ENDPOINT.XERO_CONTRACT_SUBSCRIPTION +
        `?contractSubscriptionInvoiceId=${id}`,
    );
    setIsLoading(false);
    window.open(res.data, '_blank');
  };

  const handleChangeTextSearch = (e) => {
    const text = e.target.value;
    setTextSearch(text);
    const filteredData = dataBackup.current.filter((item) =>
      item.Name.toLowerCase().includes(text.toLowerCase()),
    );
    setData(filteredData);
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = [
    {
      field: 'Name',
      headerName: 'Contract Name',
      flex: 1,
      renderCell: (params) => (
        <NavLink
          className='MuiDataGrid-cellContent'
          style={{ textDecoration: 'none' }}
          to={`/company/${params.row.CompanyId}/contract/${params.row.ContractId}`}
          target='_blank'
        >
          <Tooltip title={params.value}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </NavLink>
      ),
    },
    {
      field: 'ContractLength',
      headerName: 'Contract Length',
      flex: 1,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'XeroInvoiceNumber',
      headerName: 'Invoice Number',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          variant='body2'
          onClick={() => handleOpenXeroInvoice(params.row.Id)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'IssueDate',
      headerName: 'Issue Date',
      align: 'center',
      renderCell: (params) => (
        <Typography variant='body2' align='center'>
          {params.value}
        </Typography>
      ),
      sortComparator: (a, b) =>
        moment.utc(b, inputFormat).diff(moment.utc(a, inputFormat)),
    },
    {
      field: 'ProcessCount',
      headerName: 'Failed Times',
      align: 'center',
      renderCell: (params) => (
        <Typography variant='body2' align='center'>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'ContractStatus',
      headerName: 'Contract Status',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            color: PAYMENT_STATUS[+params?.value]?.Color || '',
          }}
        >
          {PAYMENT_STATUS[+params?.value]?.Name}
        </Typography>
      ),
    },
    {
      field: 'FailedPaymentDetail',
      headerName: 'Payment detail',
      flex: 1,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.ContractStatus !== PAYMENT_STATUS_ENUM.Inactive ? (
              <Button
                id='action-credit-contract'
                aria-controls={open ? 'action-credit-contract' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => {
                  handleClick(e);
                  setItem(params.row);
                }}
                startIcon={<MoreVertIcon />}
              ></Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <AppCard
      title={
        <Stack direction='row' alignItems='center'>
          <Typography mr={2}>Stripe Payment Pending</Typography>
          <Typography variant='caption'>
            ( Total: {total} {total === 1 ? 'Contract' : 'Contracts'} )
          </Typography>
        </Stack>
      }
      action={
        <>
          <TextField
            label='Search'
            placeholder='Search'
            value={textSearch}
            variant='outlined'
            size='small'
            onChange={handleChangeTextSearch}
          />
        </>
      }
      sxStyle={{
        position: 'relative',
      }}
    >
      <DataGrid
        hideFooter
        disableColumnSelector
        disableColumnMenu
        loading={isLoading}
        rows={data ?? []}
        columns={columns}
        getRowId={(row) => row.index}
        sx={{
          height: 250,
        }}
      />
      {item?.ContractStatus === PAYMENT_STATUS_ENUM['Locked Out'] ||
      item?.ContractStatus === PAYMENT_STATUS_ENUM['Pending Cancel'] ? (
        <Menu
          id='action-credit-contract'
          aria-labelledby='action-credit-contract-button'
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={onShowReactivate}>Reactivate</MenuItem>
          <MenuItem onClick={handleCollectPayment}>Collect Payment</MenuItem>
        </Menu>
      ) : item?.ContractStatus === PAYMENT_STATUS_ENUM.Active ? (
        <Menu
          id='action-credit-contract'
          aria-labelledby='action-credit-contract-button'
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleLockout}>Lock out</MenuItem>
          <MenuItem onClick={handleCollectPayment}>Collect Payment</MenuItem>
        </Menu>
      ) : null}

      {visibleLockout && (
        <LockoutContractConfirm
          contractId={item.ContractId}
          visible={visibleLockout}
          onClose={() => {
            onCloseLockout();
            handleCloseMenu();
          }}
          tenants={[]}
          callback={() => {
            getList();
          }}
        />
      )}

      {visibleReactivate && (
        <ReactiveContractConfirm
          contractId={item.ContractId}
          visible={visibleReactivate}
          onClose={() => {
            onCloseReactivate();
            handleCloseMenu();
          }}
          tenants={[]}
          onCallBackSubmit={() => {
            getList();
          }}
        />
      )}

      {visibleCollectPayment && (
        <ReCollectPayment
          contractId={item.ContractId}
          visible={visibleCollectPayment}
          onClose={() => {
            onCloseCollectPayment();
            handleCloseMenu();
          }}
          item={item}
          onCallBackSubmit={() => {
            getList();
          }}
        />
      )}
    </AppCard>
  );
};

export default CreditControls;
