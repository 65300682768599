import React, {useMemo} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ENTITY_ENUM, ENTITY_ENUM_KEY} from '@tenant/utility/constants/enum';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MAX_ENTITY = 3;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EntitySelectDialog = ({
  open,
  onClose,
  onSave,
  entities,
  onSetEntities,
  entitiesEnum = ENTITY_ENUM,
  entitiesEnumKey = ENTITY_ENUM_KEY,
}) => {
  const theme = useTheme();
  const entityList = useMemo(
    () => Object.getOwnPropertyNames(entitiesEnum),
    [],
  );

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;
    if (value?.length > MAX_ENTITY) {
      value.pop();
    }

    onSetEntities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
      open={open}
      maxWidth='md'
      onClose={() => onClose()}
    >
      <DialogTitle sx={{fontSize: 15, backgroundColor: '#F4F7FE'}}>
        Select Entity
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl
          color='error'
          sx={{width: '100%', mb: 3, textAlign: 'right'}}
        >
          Max Number of Entities: {entities.length} / {MAX_ENTITY}
        </FormControl>

        <FormControl sx={{width: '100%'}}>
          <InputLabel id='multiple-entity-label'>Entity *</InputLabel>
          <Select
            error={!entities.length}
            labelId='multiple-entity-label'
            id='multiple-entity'
            multiple
            value={entities}
            onChange={handleChange}
            input={<OutlinedInput label='Entity' />}
            renderValue={(selected) =>
              selected.map((e) => entitiesEnumKey[e]).join(', ')
            }
            MenuProps={MenuProps}
          >
            {entityList.map((name) => (
              <MenuItem
                key={name}
                value={entitiesEnum[name]}
                style={getStyles(name, entities, theme)}
              >
                <Checkbox checked={entities.indexOf(entitiesEnum[name]) > -1} />
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button size='large' color='secondary' autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          size='large'
          variant='outlined'
          onClick={() => onSave(entities)}
          disabled={!entities.length}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EntitySelectDialog);

EntitySelectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  entities: PropTypes.array,
  onSetEntities: PropTypes.func,
  entitiesEnum: PropTypes.object,
  entitiesEnumKey: PropTypes.object,
};
