import { useState } from 'react';
import { useDispatch } from 'react-redux';

import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import {
  FETCH_SUCCESS,
  SET_TENANT_SUBSCRIPTION,
  SET_TENANT_DETAIL,
  SET_DROPDOWN_TENANTS,
  SET_SALE_PERSON_TRACKING,
  SET_TRANSACTIONS,
  SET_LOADING_TRANSACTIONS,
} from 'shared/constants/ActionTypes';
import { TENANT_API } from 'shared/constants/Api';

const useTenant = () => {
  const dispatch = useDispatch();
  const { showErrorMessage, showSuccessMessage, } = useToast();
  const [loadingTenants, setLoadingTenants] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [loadingBtnAdoption, setLoadingBtnAdoption] = useState(false);
  const [loadingTenantList, setLoadingTenantList] = useState(false);
  const [loadingClientGroup, setLoadingClientGroup] = useState(false);
  const [loadingIndustry, setLoadingIndustry] = useState(false);
  const [loadingCMSStaff, setLoadingCSMStaff] = useState(false);
  const [loadingBtnExport, setLoadingBtnExport] = useState(false);
  const [loadingCustomerJourneyStage, setLoadingCustomerJourneyStage] = useState(false);
  const [loadingSalePersonTracking, setLoadingSalePersonTracking] = useState(false);

  const getTenantSubscription = async (tenantId, cbSuccess, cbError) => {
    try {
      setLoadingSubscription(true);
      const response = await jwtAxios.get(`${TENANT_API.GET_SUPSCRIPTION}`, {
        params: {
          tenantId
        },
      });

      if (response.status === 200) {
        cbSuccess?.(response?.data || {});
        dispatch({
          type: SET_TENANT_SUBSCRIPTION,
          payload: {
            ...response?.data || {},
          }
        });
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
      dispatch({
        type: SET_TENANT_SUBSCRIPTION,
        payload: null
      });
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
      cbError?.();
    } finally {
      setLoadingSubscription(false);
    }
  };

  const getListTenants = async ({ params, cbSuccess, cbError }) => {
    try {
      setLoadingTenantList(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 10,
        orderBy = 0,
        filters = {},
      } = params || {};

      let payload = {
        SearchTerm: searchTerm,
        PageIndex: pageIndex,
        PageSize: pageSize,
        // GoCardLessOnly: gocardlessOnly,
        OrderBy: orderBy,
      };

      if (!!filters?.tenants?.length) {
        payload['TenantIds'] = (filters.tenants ?? []).map(t => t.TenantId).join(',');
      };

      if (!!filters?.clientGroups?.length) {
        payload['GroupIds'] = (filters.clientGroups ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.industries?.length) {
        payload['IndustryIds'] = (filters.industries ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.salePersons?.length) {
        payload['SalesPersonIds'] = (filters.salePersons ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.paymentTypes?.length) {
        payload['PaymentTypes'] = (filters.paymentTypes ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.accountManagers?.length) {
        payload['AccountManagerIds'] = (filters.accountManagers ?? []).map(t => t.Id).join(', ');
      };

      const response = await jwtAxios.post(TENANT_API.GET_TENANTS, payload);

      if (response?.status === 200) {
        cbSuccess?.(response?.data);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingTenantList(false);
    }
  };

  const getListTenantsWithEnv = async (params, callback) => {
    try {
      setLoadingTenants(true);
      const {
        env = 2,
        searchTerm = '',
        pageIndex = 1,
        pageSize = 10,
      } = params || {};

      const response = await jwtAxios.get(`${TENANT_API.GET_TENANTS_WITH_ENV}/${env}`, {
        params: {
          ...params,
          searchTerm,
          pageIndex,
          pageSize,
        },
      });

      if (response.status === 200) {
        setLoadingTenants(false);
        callback?.(response?.data?.Items);
        return;
      }

      setLoadingTenants(false);
      showErrorMessage();
    } catch (err) {
      setLoadingTenants(false);
      showErrorMessage(err?.message);
    }
  };

  const toggleSharePoints = async (params, callback) => {
    try {
      setLoadingPage(true);
      const { tenantId, unshare = false } = params;
      const response = await jwtAxios.put(`${TENANT_API.TOGGLE_SHARE_POINTS}/${tenantId}?unshare=${unshare}`);
      const message = `${unshare ? 'Disabled' : 'Enabled'} Contract Ownership.`;

      if (response.status === 200) {
        callback?.();
        showSuccessMessage(message);
        return;
      };

      showErrorMessage();
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingPage(false);
    }
  };

  const getTenantDetails = async (tenantId, callback) => {
    try {
      setLoadingPage(true);

      const response = await jwtAxios.get(`api/Tenant/${tenantId}`);

      if (response.status === 200) {
        callback?.(response?.data || {});
        dispatch({
          type: SET_TENANT_DETAIL,
          payload: {
            ...response?.data || {}
          }
        });
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
      dispatch({
        type: SET_TENANT_DETAIL,
        payload: null
      });
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingPage(false);
    }
  };

  const getDropdownTenants = async (params, callback) => {
    try {
      setLoadingTenants(true);
      const { searchTerm = '', pageIndex = 1, pageSize = 50, gocardlessOnly = false, hasDeviceOnly = false, tenantId } = params || {};
      const response = await jwtAxios.get(TENANT_API.GET_DROPDOWN_TENANT, {
        params: {
          tenantId,
          searchTerm,
          pageIndex,
          pageSize,
          // gocardlessOnly,
          hasDeviceOnly
        }
      });

      if (response.status === 200) {
        setLoadingTenants(false);
        callback?.(response?.data);
        dispatch({ type: SET_DROPDOWN_TENANTS, payload: response?.data });
        return;
      };

      setLoadingTenants(false);
      showErrorMessage();
    } catch (err) {
      setLoadingTenants(false);
      showErrorMessage(err?.message);
    }
  };

  const getTransactions = async (params, cb) => {
    try {
      setLoadingTransaction(true);
      dispatch({ type: SET_LOADING_TRANSACTIONS, payload: true });
      const {
        tenantId,
        contractId,
        start,
        end,
        pageIndex = 1,
        pageSize = 10,
        orderBy = 0,
        filterList = {},
      } = params || {};

      let payload = new URLSearchParams();
      payload.append('PageSize', pageSize);
      payload.append('PageIndex', pageIndex);
      payload.append('StartDate', start);
      payload.append('EndDate', end);

      if (contractId) payload.append('ContractId', contractId);

      if (tenantId) payload.append('TenantId', tenantId);

      if (!!orderBy) {
        for (const key in orderBy) {
          payload.append([key], orderBy[key]);
        }
      }

      if (!!filterList?.tenants?.length) {
        payload.append('TenantIds', (filterList.tenants ?? []).map(t => t.TenantId).join(','))
      }

      if (!!filterList?.transactionTypes?.length) {
        payload.append('Types', (filterList.transactionTypes ?? []).map(t => t.Id).join(','))
      }

      const response = await jwtAxios.get(TENANT_API.GET_TRANSACTIONS, {
        params: payload
      });

      if (response?.status === 200 && response?.data?.Success) {
        cb?.(response?.data?.ObjectData);
        dispatch({
          type: SET_TRANSACTIONS,
          payload: response?.data?.ObjectData,
        });
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingTransaction(false);
      dispatch({ type: SET_LOADING_TRANSACTIONS, payload: false });
    }
  };

  const checkEarlyAdoption = async (contractId, callback) => {
    try {
      setLoadingBtnAdoption(true);
      const response = await jwtAxios.get(TENANT_API.CHECK_EARLY_ADOPTION, {
        params: {
          contractId,
        }
      });

      if (response?.status === 200 && response?.data?.Success) {
        dispatch({ type: FETCH_SUCCESS });
        callback?.(response?.data?.ObjectData);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingBtnAdoption(false);
    }
  };

  const getListClientGroup = async (params, callback) => {
    try {
      setLoadingClientGroup(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 50,
      } = params || {};

      let query = new URLSearchParams();
      query.append('pageSize', pageSize);
      query.append('pageIndex', pageIndex);

      if (!!searchTerm) {
        query.append('searchTerm', searchTerm);
      }

      const response = await jwtAxios.get(
        TENANT_API.GET_CLIENT_GROUPS,
        {
          params: query,
        },
      );

      if (response?.status === 200) {
        callback?.(response?.data?.ObjectData?.Items ?? []);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingClientGroup(false);
    }
  };

  const getListIndustry = async (params, callback) => {
    try {
      setLoadingIndustry(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 50,
      } = params || {};

      let query = new URLSearchParams();
      query.append('pageSize', pageSize);
      query.append('pageIndex', pageIndex);

      if (!!searchTerm) {
        query.append('searchTerm', searchTerm);
      }

      const response = await jwtAxios.get(
        TENANT_API.GET_INDUSTRIES,
        {
          params: query,
        },
      );

      if (response?.status === 200) {
        callback?.(response?.data?.ObjectData?.Items ?? []);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingIndustry(false);
    }
  };

  const getListCSMStaff = async (params, callback) => {
    try {
      setLoadingCSMStaff(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 50,
        departmentId = 1,
      } = params || {};

      let query = new URLSearchParams();
      query.append('pageSize', pageSize);
      query.append('pageIndex', pageIndex);
      query.append('departmentId', departmentId);


      if (!!searchTerm) {
        query.append('searchTerm', searchTerm);
      }

      const response = await jwtAxios.get(
        TENANT_API.GET_CSM_STAFFS,
        {
          params: query,
        },
      );

      if (response?.status === 200) {
        callback?.(response?.data?.ObjectData?.Items ?? []);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingCSMStaff(false);
    }
  };

  const exportTenants = async ({ params, cbSuccess, cbError }) => {
    try {
      setLoadingBtnExport(true);
      const {
        searchTerm = '',
        orderBy = 0,
        filters = {},
      } = params || {};

      let payload = {
        SearchTerm: searchTerm,
        OrderBy: orderBy,
      };

      if (!!filters?.tenants?.length) {
        payload['TenantIds'] = (filters.tenants ?? []).map(t => t.TenantId).join(',');
      };

      if (!!filters?.clientGroups?.length) {
        payload['GroupIds'] = (filters.clientGroups ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.industries?.length) {
        payload['IndustryIds'] = (filters.industries ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.salePersons?.length) {
        payload['SalesPersonIds'] = (filters.salePersons ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.paymentTypes?.length) {
        payload['PaymentTypes'] = (filters.paymentTypes ?? []).map(t => t.Id).join(',');
      };

      if (!!filters?.accountManagers?.length) {
        payload['AccountManagerIds'] = (filters.accountManagers ?? []).map(t => t.Id).join(', ');
      };

      const response = await jwtAxios.post(TENANT_API.EXPORT_TENANTS, payload);

      if (response?.status === 200) {
        cbSuccess?.(response?.data);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingBtnExport(false);
    }
  };

  const getListTenantsFirstPage = async ({ params, cbSuccess, cbError }) => {
    try {
      setLoadingTenantList(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 10,
      } = params || {};

      const response = await jwtAxios.get(TENANT_API.GET_DETAIL, {
        params: {
          SearchTerm: searchTerm,
          PageIndex: pageIndex,
          PageSize: pageSize,
        },
      });

      if (response?.status === 200) {
        cbSuccess?.(response?.data);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
      cbError?.();
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingTenantList(false);
    }
  };

  const getSalePersonTracking = async (params, callback) => {
    try {
      setLoadingSalePersonTracking(true);
      const {
        searchTerm = '',
        pageIndex = 1,
        pageSize = 50,
      } = params || {};

      let query = new URLSearchParams();
      query.append('pageSize', pageSize);
      query.append('pageIndex', pageIndex);


      if (!!searchTerm) {
        query.append('searchTerm', searchTerm);
      }

      const response = await jwtAxios.get(
        TENANT_API.GET_SALE_PERSON_TRACKING,
        {
          params: query,
        },
      );

      if (response?.status === 200) {
        dispatch({ type: SET_SALE_PERSON_TRACKING, payload: response?.data?.Data ?? [] });
        callback?.(response?.data?.Data ?? []);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingSalePersonTracking(false);
    }
  };

  const getListCustomerJourneyStage = async (params, callback) => {
    try {
      setLoadingCustomerJourneyStage(true);
      const { searchTerm = '' } = params || {};

      let query = new URLSearchParams();
      if (searchTerm) {
        query.append('searchTerm', searchTerm);
      }

      const response = await jwtAxios.get(
        TENANT_API.GET_CUSTOMER_JOURNEY_STAGES,
        {
          params: query,
        },
      );

      if (response?.status === 200) {
        callback?.(response?.data ?? []);
        return;
      };

      showErrorMessage(response?.data?.ErrorMsg);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoadingCustomerJourneyStage(false);
    }
  };

  return {
    loadingTenants,
    loadingPage,
    loadingSubscription,
    loadingTransaction,
    loadingBtnAdoption,
    loadingTenantList,
    loadingClientGroup,
    loadingIndustry,
    loadingCMSStaff,
    loadingBtnExport,
    loadingSalePersonTracking,
    loadingCustomerJourneyStage,
    getTenantSubscription,
    getListTenants,
    getListTenantsWithEnv,
    toggleSharePoints,
    getTenantDetails,
    getDropdownTenants,
    getTransactions,
    checkEarlyAdoption,
    getListClientGroup,
    getListIndustry,
    getListCSMStaff,
    exportTenants,
    getListTenantsFirstPage,
    getSalePersonTracking,
    getListCustomerJourneyStage,
  };
};

export default useTenant;
