import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import React, { useCallback, useState } from 'react';

const usePermissionService = () => {
  const { showSuccessMessage, showErrorMessage } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingMultipleKey, setLoadingMultipleKey] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [associatedPermissionList, setAssociatedPermissionList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [role, setRole] = useState();

  const getListRole = useCallback(async ({ onSuccess }) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get('/api/Admin/Role/GetAll');
      setRoleList?.(data?.Items);
      onSuccess?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getListAssociatedPermissions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get(
        '/api/Admin/Role/AssociatedPermissions',
      );
      setAssociatedPermissionList?.(data?.Items);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getRoleDetail = useCallback(async (roleId, callback) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get(`/api/Admin/Role/${roleId}`);
      setRole?.(data);
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getUserRoles = useCallback(async ({ email, onSuccess, onError }) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get(
        `/api/Admin/Role/GetUserRoles?email=${email}`,
      );
      onSuccess?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
      onError?.(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllPermissions = useCallback(async ({ onSuccess, onError }) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.get(`api/Admin/Permission/GetAll`);
      onSuccess?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
      onError?.(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createRole = useCallback(async (payload, callback) => {
    try {
      setLoadingBtn(true);
      const { data } = await jwtAxios.post('/api/Admin/Role/Create', payload);
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const updateRole = useCallback(async (payload, callback) => {
    try {
      setLoadingBtn(true);
      const { data } = await jwtAxios.post('/api/Admin/Role/Update', payload);
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const updateUserRole = useCallback(async (payload, callback) => {
    try {
      setLoadingBtn(true);
      const { data } = await jwtAxios.put(
        '/api/Admin/Role/UpdateUserRole',
        payload,
      );
      callback?.(data);
      showSuccessMessage('User role updated');
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const addPermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      const { roleId, permissionId } = payload || {};
      if (!roleId || !permissionId) {
        return;
      }
      const key = `${roleId}_${permissionId}`;
      try {
        setLoadingMultipleKey((s) => [...s, key]);
        const { data } = await jwtAxios.post(
          `/api/Admin/Role/${roleId}/AddPermission`,
          { permissionId },
        );
        onSuccess(data);
        showSuccessMessage('Add Permission Success');
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingMultipleKey((s) => s.filter((e) => e !== key));
      }
    },
    [],
  );

  const removePermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      const { roleId, permissionId } = payload || {};
      if (!roleId || !permissionId) {
        return;
      }
      const key = `${roleId}_${permissionId}`;
      try {
        setLoadingMultipleKey((s) => [...s, key]);
        const { data } = await jwtAxios.post(
          `/api/Admin/Role/${roleId}/RemovePermission`,
          { permissionId },
        );
        onSuccess(data);
        showSuccessMessage('Remove Permission Success');
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingMultipleKey((s) => s.filter((e) => e !== key));
      }
    },
    [],
  );

  const getAllUserAndRoles = useCallback(async (payload, callback) => {
    try {
      setLoading(true);
      const { data } = await jwtAxios.post(
        `/api/Admin/Role/GetAllUserAndRoles`,
        payload,
      );
      callback?.(data);
      setUserRoleList(data?.Items);
      setTotalCount(data?.TotalCount ?? 0);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createGroup = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setLoadingBtn(true);
        const { data } = await jwtAxios.post(
          `/api/Admin/Permission/CreateGroup`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  const assisgnToUsers = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setLoadingBtn(true);
        const { data } = await jwtAxios.post(
          `/api/Admin/Role/${payload.RoleId}/AssisgnToUsers`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  const createPermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setLoadingBtn(true);
        const { data } = await jwtAxios.post(
          `/api/Admin/Permission/Create`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  const createGroupPermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setLoading(true);
        const { data } = await jwtAxios.post(
          `/api/Admin/Permission/CreateGroupPermission`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const addMultiplePermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        const { roleId } = payload || {};
        setLoadingBtn(true);
        const { data } = await jwtAxios.post(
          `/api/Admin/Role/${roleId}/AddMultiplePermissions`,
          payload,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  const removeUserRole = useCallback(async (payload, callback) => {
    try {
      const { userId, roleIds } = payload || {};
      if (!userId || !roleIds?.length) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.delete(
        `/api/Admin/Role/RemoveUserRoles?userId=${userId}&RoleIds=${roleIds}`,
      );
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const removeAllUserRoles = useCallback(async (payload, callback) => {
    try {
      const { userId } = payload || {};
      setLoadingBtn(true);
      const { data } = await jwtAxios.delete(
        `/api/Admin/Role/RemoveAllUserRoles?userId=${userId}`,
      );
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const removeRole = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        const { roleId } = payload || {};
        if (!roleId) {
          return;
        }
        setLoadingBtn(true);
        const { data } = await jwtAxios.delete(
          `/api/Admin/Role/Remove?roleId=${roleId}`,
        );
        onSuccess(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  const deletePermission = useCallback(
    async ({ payload, onSuccess = () => {}, onError = () => {} }) => {
      try {
        const { permissionId } = payload || {};
        if (!permissionId) {
          return;
        }
        setLoadingBtn(true);
        const { data } = await jwtAxios.delete(
          `/api/Admin/Permission/Remove?permissionId=${permissionId}`,
        );
        onSuccess(data);
      } catch (err) {
        showErrorMessage();
        console.error(err);
        onError(err);
      } finally {
        setLoadingBtn(false);
      }
    },
    [],
  );

  return {
    // variables:
    loading,
    roleList,
    associatedPermissionList,
    loadingBtn,
    loadingMultipleKey,
    userRoleList,
    totalCount,
    role,
    // services:
    //get
    getListRole,
    getListAssociatedPermissions,
    getRoleDetail,
    getUserRoles,
    getAllPermissions,
    //post, put
    createRole,
    updateRole,
    updateUserRole,
    addPermission,
    removePermission,
    getAllUserAndRoles,
    createGroupPermission,
    assisgnToUsers,
    createPermission,
    createGroup,
    addMultiplePermission,
    //delete
    removeUserRole,
    removeAllUserRoles,
    removeRole,
    deletePermission,
  };
};

export default usePermissionService;
