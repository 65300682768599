const {
  GET_LIST_REQUEST_WORKFLOW,
  UPDATE_LIST_REQUEST_WORKFLOW,
} = require('shared/constants/ActionTypes');

const initialState = {
  sms: {},
  vehicleTracking: {},
  vehicleTrackingInfo: {}
};

const requestWorkflowReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_LIST_REQUEST_WORKFLOW:
      return {
        ...state,
        [payload.field]: {
          ...state[payload.field],
          ...payload,
        },
      };
    case UPDATE_LIST_REQUEST_WORKFLOW:
      const index = state[payload.field]?.data?.findIndex(
        (e) => e.Id === payload.item?.Id,
      );
      const list = [...state[payload.field]?.data];
      list[index] = payload.item;
      return {
        ...state,
        [payload.field]: {
          ...state[payload.field],
          data: list,
        },
      };
    default:
      return state;
  }
};

export default requestWorkflowReducer;
