import { useIntl } from 'react-intl';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@mui/material';
import { envList } from 'shared/constants/AppsContants';
import {
  inputFormat,
  dateFormatByKey,
} from '@tenant/utility/constants/default';
import { STATISTIC_DATE_GROUP_TYPE } from '../constants/enum';
import { formatDate } from './DateHelper';

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      console.log('key', key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

// 'intl' service singleton reference
let intl;

export function IntlGlobalProvider({ children }) {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
}

export const appIntl = () => {
  return intl;
};

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const generateRandomHexColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return '#' + randomColor;
};

export const getPropertyByField = (object = {}, field = '') => {
  if (typeof object !== 'object' || typeof field !== 'string') {
    return null;
  }

  let result = object;
  const keys = field.split('.'); // field can nested by '.', ex: Additionaldata.Results

  keys.forEach((k) => {
    if (typeof result === 'object') {
      result = result[k];
    }
  });

  return result ?? null;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const numberToPercent = (number) => {
  return (number || 0).toFixed(0) + '%';
};

export const numberToCurrency = (value, toDecimal = 0, currency = 'USD') => {
  return value !== undefined || value !== null
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      })
        .format(value.toFixed(toDecimal))
        .replace(/(\.|,)00$/g, '')
    : '';
};

export const getFontSizeLabelChart = (dataLength) => {
  return dataLength < 12 ? 14 : dataLength < 24 ? 12 : 9;
};

export const getGroupDateTypeByDate = (start, end, isHasYear = false) => {
  const keyName = `${moment(start, inputFormat).format('MMM')} ${moment(
    start,
    inputFormat,
  ).format('DD')} ${moment(start, inputFormat).format('YYYY')}-${moment(
    end,
    inputFormat,
  ).format('MMM')} ${moment(end, inputFormat).format('DD')} ${moment(
    end,
    inputFormat,
  ).format('YYYY')}`;
  const TOTAL_MONTHS_FOR_YEAR = 36;
  const TOTAL_MONTHS_FOR_DAY = 1;

  const totalMonths = moment(end, inputFormat).diff(
    moment(start, inputFormat),
    'months',
    true,
  );

  let dateGroupType = totalMonths <= TOTAL_MONTHS_FOR_DAY ? 0 : 1;

  if (isHasYear && totalMonths > TOTAL_MONTHS_FOR_YEAR) {
    dateGroupType = 2;
  }

  return {
    keyName,
    dateGroupType,
  };
};

export const getRangeDateByKeyName = (
  keyName,
  options = dateFormatByKey,
  isHasYear = false,
) => {
  let start = moment().subtract(1, 'month').format(inputFormat);
  let end = moment().format(inputFormat);
  if (options[keyName]) {
    start = options[keyName].value[0];
    end = options[keyName].value[1];
  } else {
    const value = typeof (keyName === 'string') ? keyName.split('-') : null;
    if (value) {
      start = moment(value[0]).format(inputFormat);
      end = moment(value[1]).format(inputFormat);
    }
  }
  const { dateGroupType } = getGroupDateTypeByDate(start, end, isHasYear);
  return {
    start,
    end,
    dateGroupType,
  };
};

export const checkIsEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

function mappCharacters(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export const generatePassword = (passwordLength) => {
  const numberChars = '0123456789';
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerChars = 'abcdefghiklmnopqrstuvwxyz';
  const specialChars = '@$!%*?&';
  const allChars = upperChars + numberChars + lowerChars + specialChars;
  let randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = upperChars;
  randPasswordArray[1] = numberChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray[3] = specialChars;
  randPasswordArray = randPasswordArray.fill(allChars, 4);
  return mappCharacters(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }),
  ).join('');
};

export const checkIsEmptyObject = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

export const getUserAvatar = (displayName) => {
  return displayName ? displayName.charAt(0).toUpperCase() : '';
};

export const getFullAddressTenant = (tenant) => {
  const { Address1, Address2, Address3, Address4 } = tenant || {};
  if (!Address1 && !Address2 && !Address3 && !Address4) {
    return '';
  }
  return [Address1, Address2, Address3, Address4].filter((e) => !!e).join(', ');
};

export const deepClone = (val) => JSON.parse(JSON.stringify(val));

export const checkIsPercentage = (value) =>
  /^-\d+(\.\d+)?%$/.test(value) || /^\d+(\.\d+)?%$/.test(value);

export const percentageToNumber = (percent) =>
  parseFloat(percent.replace('%', ''));

export const roundNumber = (value, decimal = 2) => {
  return parseFloat(value || 0).toFixed(decimal);
};

export const validateIPaddress = (ipAddress) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAddress,
    )
  ) {
    return true;
  }
  return false;
};

export const chartDateFormatter = (
  date,
  dateGroupType,
  format = inputFormat,
) => {
  if (dateGroupType === STATISTIC_DATE_GROUP_TYPE.Day) {
    return formatDate(new moment(date, format), 'DD MMM');
  } else if (dateGroupType === STATISTIC_DATE_GROUP_TYPE.Month) {
    return formatDate(new moment(date, format), 'MMM YYYY');
  }

  return formatDate(new moment(date, format), 'YYYY');
};

export const formatNumber = (value, config = {}) => {
  return typeof +value === 'number'
    ? new Intl.NumberFormat('en-US', config).format(value)
    : '';
};

export const formatNumberWithCompact = (value) => {
  return typeof +value === 'number'
    ? new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
    : '';
};

/**
 *
 * @param {*} data is a list data of object structured
 * @param {*} field is a field inside object structured
 * @param {*} direction is a enum direction: asc or desc
 * @returns
 */
export const sortByField = (data = [], field = '', direction = 'desc') => {
  if (!data || !field) return data;

  if (direction === 'asc') {
    return data.sort((a, b) => a?.[field] - b?.[field]);
  }

  return data.sort((a, b) => b?.[field] - a?.[field]);
};

/**
 *
 * @param {*} date is a string date
 * @param {*} dateGroupType is a enum type of date include: 0, 1, 2
 * @param {*} format is a string format of the date
 * @returns object is {startDate, endDate, keyName}
 */
export const getRangeDateFromDate = (
  date = new moment().format(inputFormat),
  dateGroupType = STATISTIC_DATE_GROUP_TYPE.Day,
  format = inputFormat,
) => {
  const dateIsoString = new moment(date, format).toISOString();
  let startDate = '';
  let endDate = '';
  let keyName = '';
  let newDateGroupType = dateGroupType;
  if (dateGroupType === STATISTIC_DATE_GROUP_TYPE.Day) {
    startDate = formatDate(new moment(dateIsoString).startOf('day'), format);
    endDate = formatDate(new moment(dateIsoString).endOf('day'), format);
    keyName = `${formatDate(
      new moment(dateIsoString).startOf('day'),
      'MMM DD YYYY',
    )}-${formatDate(new moment(dateIsoString).endOf('day'), 'MMM DD YYYY')}`;
    newDateGroupType = STATISTIC_DATE_GROUP_TYPE.Day;
  } else if (dateGroupType === STATISTIC_DATE_GROUP_TYPE.Month) {
    startDate = formatDate(new moment(dateIsoString).startOf('month'), format);
    endDate = formatDate(new moment(dateIsoString).endOf('month'), format);
    keyName = `${formatDate(
      new moment(dateIsoString).startOf('month'),
      'MMM DD YYYY',
    )}-${formatDate(new moment(dateIsoString).endOf('month'), 'MMM DD YYYY')}`;
    newDateGroupType = STATISTIC_DATE_GROUP_TYPE.Day;
  } else if (dateGroupType === STATISTIC_DATE_GROUP_TYPE.Year) {
    startDate = formatDate(new moment(dateIsoString).startOf('year'), format);
    endDate = formatDate(new moment(dateIsoString).endOf('year'), format);
    keyName = `${formatDate(
      new moment(dateIsoString).startOf('year'),
      'MMM DD YYYY',
    )}-${formatDate(new moment(dateIsoString).endOf('year'), 'MMM DD YYYY')}`;
    newDateGroupType = STATISTIC_DATE_GROUP_TYPE.Day;
  }

  return {
    startDate,
    endDate,
    keyName,
    dateGroupType: newDateGroupType,
  };
};
