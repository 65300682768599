import React, {useState, useEffect, useCallback, useMemo} from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppCard from '@tenant/core/AppCard';
import AppTooltip from '@tenant/core/AppTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Code} from 'react-content-loader';
import {useIntl} from 'react-intl';
import {DateMenu} from '@tenant/core/App/Date/DateMenu';
import {inputFormat} from '@tenant/utility/constants/default';
import {useDispatch, useSelector} from 'react-redux';
import StatGraphs from 'pages/dashboards/Analytics/Entity/StatGraphs';
import EntitySelectDialog from 'pages/dashboards/Analytics/Entity/EntitySelectDialog';
import {
  onGetSubcontractorByMonth,
  setFilterSubcontractorByMonth,
} from 'redux/actions';
import {
  ENTITY_SUBCONTRACTOR_ENUM,
  ENTITY_SUBCONTRACTOR_ENUM_KEY,
  SUBCONTRACTOR_KEY_NAME,
} from '@tenant/utility/constants/enum';

const Usage = () => {
  const {type, data, endDate, startDate, dataRange, dateGroupType} =
    useSelector(({subcontractorByMonth}) => subcontractorByMonth);
  const dispatch = useDispatch();
  const {messages} = useIntl();
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({
    type,
    startDate,
    endDate,
    dataRange,
    dateGroupType,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onSave = (data) => {
    const medium = {
      ...config,
      type: data,
    };
    setOpen(false);
    setConfig(medium);
    getStatistic(medium);
  };

  const setFilter = (data) => {
    const medium = {
      ...config,
      type: data,
    };
    setConfig(medium);
    dispatch(setFilterSubcontractorByMonth(medium));
  };

  const onSelectionType = (data) => {
    const medium = {
      ...config,
      dateGroupType: data.dateGroupType,
      dataRange: data.keyName,
      startDate: moment(data.startDate, inputFormat).toISOString(),
      endDate: moment(data.endDate, inputFormat).toISOString(),
    };
    setConfig(medium);
    getStatistic(medium);
  };

  const getStatistic = useCallback(
    async ({type, startDate, endDate, dataRange, dateGroupType} = config) => {
      try {
        setIsLoading(true);
        await dispatch(
          onGetSubcontractorByMonth({
            type,
            startDate,
            endDate,
            dataRange,
            dateGroupType,
          }),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (data?.length <= 0) getStatistic();
  }, []);

  return (
    <AppCard
      sxStyle={{height: 1}}
      title={
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <span style={{paddingRight: 5}}>
            {messages['subcontractor.usage']}
          </span>
          <AppTooltip
            arrow
            title={
              <div style={{padding: 10}}>
                <p>{messages['subcontractor.usage.tooltip.caption']}:</p>
                <div style={{fontSize: 13, paddingTop: 5}}>
                  -{' '}
                  <strong>
                    {messages['subcontractor.tooltip.subcontractor.title']}:
                  </strong>{' '}
                  {messages['subcontractor.tooltip.subcontractor.content']}
                </div>
                <div style={{fontSize: 13, paddingTop: 5}}>
                  -{' '}
                  <strong>
                    {messages['subcontractor.tooltip.allocation.title']}:
                  </strong>{' '}
                  {messages['subcontractor.tooltip.allocation.content']}
                </div>
                <div style={{fontSize: 13, paddingTop: 5}}>
                  -{' '}
                  <strong>
                    {messages['subcontractor.tooltip.subpo.title']}:
                  </strong>{' '}
                  {messages['subcontractor.tooltip.subpo.content']}
                </div>
                <div style={{fontSize: 13, paddingTop: 5}}>
                  -{' '}
                  <strong>
                    {messages['subcontractor.tooltip.contract.title']}:
                  </strong>{' '}
                  {messages['subcontractor.tooltip.contract.content']}
                </div>
              </div>
            }
          >
            <InfoOutlinedIcon sx={{fontSize: 20}} />
          </AppTooltip>
        </Box>
      }
      action={
        <>
          <Link href='#' onClick={onOpen} underline='none'>
            {config.type
              ?.map((e) => ENTITY_SUBCONTRACTOR_ENUM_KEY[e])
              ?.join(', ')}
          </Link>
          <DateMenu
            defaultValue={dataRange}
            handleChangeValue={onSelectionType}
          />
        </>
      }
    >
      {isLoading && <Code />}
      {!isLoading && (
        <>
          <EntitySelectDialog
            open={open}
            entities={config.type}
            onSave={onSave}
            onClose={onClose}
            onSetEntities={setFilter}
            entitiesEnum={ENTITY_SUBCONTRACTOR_ENUM}
            entitiesEnumKey={ENTITY_SUBCONTRACTOR_ENUM_KEY}
          />
          <StatGraphs
            data={data}
            dateGroupType={dateGroupType}
            nameMapping={SUBCONTRACTOR_KEY_NAME}
          />
        </>
      )}
    </AppCard>
  );
};

export default React.memo(Usage);
