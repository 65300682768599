import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import ReportingAccessInfo from '../ReportingAccess/ReportingAccessInfo';

const ReportingAccessTab = ({tenantId}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <ReportingAccessInfo tenantId={tenantId} />
      </Grid>
    </Grid>
  );
};

export default React.memo(ReportingAccessTab);

ReportingAccessTab.propTypes = {};
