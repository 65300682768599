import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import AppTooltip from '@tenant/core/AppTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppCard from '@tenant/core/AppCard';
import { Code } from 'react-content-loader';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { onGetSubcontractorTenantTrend } from 'redux/actions';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { inputFormat } from '@tenant/utility/constants/default';
import SubcontractorTenantTrendChart from '@tenant/core/App/Chart/SubcontractorTenantTrendChart';

const TenantTrend = () => {
  const { data, endDate, startDate, dataRange, dateGroupType } =
    useSelector(({ subcontractorTenantTrend }) => subcontractorTenantTrend);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState({ startDate, endDate, dataRange, dateGroupType });

  const onSelectionType = (data) => {
    const medium = {
      ...config,
      dateGroupType: data.dateGroupType,
      dataRange: data.keyName,
      startDate: moment(data.startDate, inputFormat).toISOString(),
      endDate: moment(data.endDate, inputFormat).toISOString()
    };
    setConfig(medium);
    getStatistic(medium);
  };

  const getStatistic = useCallback(async ({ startDate, endDate, dataRange, dateGroupType } = config) => {
    try {
      setIsLoading(true);
      await dispatch(onGetSubcontractorTenantTrend({ startDate, endDate, dataRange, dateGroupType }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data?.length <= 0) getStatistic();
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{paddingRight: 5 }}>{messages['subcontractor.tenanttrend']}</span>
          <AppTooltip 
            arrow
            title={
              <div style={{ padding: 10 }}>
                <p>{messages['subcontractor.tenanttrend.tooltip.caption']}:</p>
                <div style={{ fontSize: 13, paddingTop: 5 }}>
                  - <strong>{messages['subcontractor.tenanttrend.tooltip.prefix1']}:</strong> {messages['subcontractor.tenanttrend.tooltip.item1']}
                </div>
                <div style={{ fontSize: 13, paddingTop: 5 }}>
                  - <strong>{messages['subcontractor.tenanttrend.tooltip.prefix1']}:</strong> {messages['subcontractor.tenanttrend.tooltip.item2']}
                </div>
              </div>
            } 
          >
            <InfoOutlinedIcon sx={{ fontSize: 20 }}/>
          </AppTooltip>
        </Box>
      }
      action={
        <DateMenu
            defaultValue={dataRange}
            handleChangeValue={onSelectionType}
          />
      }
    >
      {isLoading && <Code />}
      {!isLoading && <SubcontractorTenantTrendChart data={data} dateGroupType={dateGroupType} />}
    </AppCard>
  );
};

export default React.memo(TenantTrend);
