import React, { useCallback, useState } from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';
import { percentageToNumber } from '@tenant/utility/helper/Utils';

const useMappingService = () => {
  const { showSuccessMessage, showErrorMessage } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [mappingList, setMappingList] = useState(null);

  const getExistingMappings = useCallback(async (params, callback) => {
    try {
      if (!params.mainTenantId) {
        return;
      }
      setLoading(true);
      const { data } = await jwtAxios.get(
        'api/Subcontractor/GetExistingMappings',
        { params },
      );
      setMappingList(data);
      callback?.(data);
    } catch (err) {
      showErrorMessage();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getInvoiceInfo = useCallback(async (params, callback) => {
    try {
      if (!params.requestId) {
        return;
      }
      setLoading(true);
      const { data } = await jwtAxios.get(
        'SubcontractorRequest/GetInvoiceInfo/' + params.requestId,
      );
      callback?.(data);
    } catch (err) {
      showErrorMessage(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getInvoiceInfoByMappingId = useCallback(async (params, callback) => {
    try {
      const { mappingId, mainTenantId } = params || {};
      if (!mappingId || !mainTenantId) {
        return;
      }
      setLoading(true);
      const { data } = await jwtAxios.get(
        'SubcontractorRequest/GetInvoiceInfoByMappingId/' +
          mappingId +
          `?mainTenantId=${mainTenantId}`,
      );
      callback?.(data);
    } catch (err) {
      showErrorMessage(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const getContractByTenant = useCallback(
    async ({ tenantId, onSuccess, onError }) => {
      try {
        setLoading(true);
        const { data } = await jwtAxios.get(
          `/api/Contract/GetContractByTenant?tenantId=${tenantId}`,
        );
        onSuccess?.(data);
      } catch (err) {
        showErrorMessage(err);
        onError?.(err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const generateInvoice = useCallback(async (payload, callback) => {
    try {
      const { isPercent, RequestId, Discount, ContractId } = payload || {};
      if (!RequestId) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.put(
        '/SubcontractorRequest/GenerateInvoice',
        {
          ContractGuid: ContractId,
          RequestId,
          [isPercent ? 'DiscountPercent' : 'Discount']: isPercent
            ? percentageToNumber(Discount)
            : Discount,
        },
      );
      callback?.(data);
      showSuccessMessage('Add Access License is successfully');
    } catch (err) {
      showErrorMessage('Add Access License is failed');
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const generateInvoiceByMappingId = useCallback(async (payload, callback) => {
    try {
      const { isPercent, MappingId, MainTenantId, Discount, ContractId } =
        payload || {};
      if (!MappingId || !MainTenantId) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.put(
        '/SubcontractorRequest/GenerateInvoiceByMappingId',
        {
          MappingId,
          MainTenantId,
          ContractGuid: ContractId,
          [isPercent ? 'DiscountPercent' : 'Discount']: isPercent
            ? percentageToNumber(Discount)
            : Discount,
        },
      );
      callback?.(data);
      showSuccessMessage('Add Access License is successfully');
    } catch (err) {
      showErrorMessage('Add Access License is failed');
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const disconnectTenant = useCallback(async (payload, callback) => {
    try {
      if (!payload.requestId) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.put(
        'SubcontractorRequest/DisconnectTenant/' +
          payload.requestId +
          `?isSubbyTenant=${
            payload?.isSubbyTenant ?? false
          }&deleteSubContractorLibrary=${
            payload.deleteSubContractorLibrary ?? false
          }`,
      );
      callback?.(data);
      showSuccessMessage('Disconnect mapping is successfully');
    } catch (err) {
      showErrorMessage('Disconnect mapping is failed');
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const disconnectTenantByMappingId = useCallback(async (payload, callback) => {
    try {
      const {
        mappingId,
        mainTenantId,
        isSubbyTenant,
        deleteSubContractorLibrary,
      } = payload || {};
      if (!mappingId || !mainTenantId) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.put(
        'SubcontractorRequest/DisconnectTenantByMappingId/',
        {
          mappingId,
          mainTenantId,
          isSubbyTenant,
          deleteSubContractorLibrary,
        },
      );
      callback?.(data);
      showSuccessMessage('Disconnect mapping is successfully');
    } catch (err) {
      showErrorMessage('Disconnect mapping is failed');
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const transferLicense = useCallback(async (payload, callback) => {
    try {
      const { mappingId, invoiceId, deactivateSubby } = payload || {};
      if (!mappingId || !invoiceId) {
        return;
      }
      setLoadingBtn(true);
      const { data } = await jwtAxios.post(
        `/SubcontractorRequest/TransferLicense/${invoiceId}/${mappingId}?deactivateSubby=${
          deactivateSubby ?? false
        }`,
      );
      callback?.(data);
      showSuccessMessage('Swap mapping is successfully');
    } catch (err) {
      showErrorMessage('Swap mapping is failed');
      console.error(err);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  return {
    loading,
    loadingBtn,
    mappingList,
    // get
    getExistingMappings,
    getInvoiceInfo,
    getInvoiceInfoByMappingId,
    getContractByTenant,
    //put,post
    generateInvoice,
    generateInvoiceByMappingId,
    disconnectTenant,
    disconnectTenantByMappingId,
    transferLicense,
  };
};

export default useMappingService;
