const {SET_FIELD_TENANT_DETAIL} = require('shared/constants/ActionTypes');

const initialState = {};

const tenantDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELD_TENANT_DETAIL: {
      const {tenantId, field, data, filter} = action.payload ?? {};
      return {
        ...state,
        [tenantId]: {
          ...state[tenantId],
          [field]: {
            data,
            filter,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default tenantDetailReducer;
