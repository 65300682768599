import React, {useState, useEffect, useMemo} from 'react';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, IconButton, Tooltip} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppCard from '@tenant/core/AppCard';
import {Fonts} from 'shared/constants/AppEnums';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import SimpleBar from 'simplebar-react';
import {List} from 'react-content-loader';
import LazyLoad from 'react-lazy-load';
import ReplyIcon from '@mui/icons-material/Reply';

const publicAPIs = [
  {
    id: 2,
    name: 'Public API',
  },
];

const ClientInfo = ({tenantId}) => {
  const [expandedList, setExpandedList] = useState([1, 2, 3]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const publicApiRequest = useMemo(() => data?.publicApiRequest ?? {}, [data]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedList((s) =>
      s.some((e) => e === panel) ? s.filter((e) => e !== panel) : [...s, panel],
    );
  };

  useEffect(() => {
    const getSqlData = async () => {
      try {
        if (!tenantId) {
          return;
        }
        setIsLoading(true);
        const data = await jwtAxios.get(
          `AccessRequest/${tenantId}/RequestData`,
        );

        if (!data.data) {
          return;
        }
        setData(data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    getSqlData();
  }, [tenantId]);

  return (
    <LazyLoad offset={100}>
      <AppCard
        contentStyle={{padding: 0}}
        sxStyle={{
          height: expandedList.some((e) => e === publicAPIs[0].id)
            ? '100%'
            : 'inherit',
        }}
      >
        {isLoading && <List style={{padding: 20}} />}
        {!isLoading &&
          publicAPIs.map((data) => (
            <Accordion
              sx={{
                position: 'relative',
                boxShadow: 'none',
                mb: '-1px',
                '&:last-of-type': {
                  borderRadius: 0,
                },
              }}
              key={data.id}
              expanded={expandedList.some((e) => e === data.id)}
              onChange={handleChange(data.id)}
            >
              <AccordionSummary
                sx={{
                  padding: '0 24px',
                  '& .MuiSvgIcon-root': {
                    color: (theme) => theme.palette.grey[500],
                  },
                  '&.Mui-expanded': {
                    borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                    borderBottom: (theme) =>
                      `solid 1px ${theme.palette.divider}`,
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <Box
                    component='h3'
                    sx={{
                      color: 'text.primary',
                      fontWeight: Fonts.BOLD,
                      fontSize: 16,
                    }}
                  >
                    {data.name}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: '5px 24px',
                }}
              >
                <SimpleBar
                  style={{
                    maxHeight: 300,
                    overflow: 'auto',
                  }}
                >
                  <React.Fragment key={publicApiRequest.Id}>
                    <Grid container>
                      <Grid item xs={6} md={3}>
                        <ListItemText
                          primary='Client Id'
                          secondary={publicApiRequest.ClientId || '-'}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <ListItemText
                          primary='Client Secret'
                          secondary={
                            <Box component={'span'}>
                              {publicApiRequest.ClientSecret ? (
                                <>
                                  **********
                                  {/* <Tooltip title='Send it to my email'>
                                    <IconButton size='small' sx={{ml: 2}}>
                                      <ReplyIcon fontSize='inherit' />
                                    </IconButton>
                                  </Tooltip> */}
                                </>
                              ) : (
                                '-'
                              )}
                            </Box>
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={6} md={3}>
                        <ListItemText
                          primary='Requested At'
                          secondary={publicApiRequest.RequestedAt || '-'}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <ListItemText
                          primary='Allow Tenant'
                          secondary={publicApiRequest.AllowTenant || '-'}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <SimpleBar
                          style={{
                            maxHeight: 300,
                            overflow: 'auto',
                          }}
                        >
                          <ListItemText
                            primary='White list Ips'
                            secondary={publicApiRequest.WhitelistIps || '-'}
                            secondaryTypographyProps={{
                              whiteSpace: 'pre',
                              height: 100,
                              overflow: 'auto',
                            }}
                          />
                        </SimpleBar>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </SimpleBar>
              </AccordionDetails>
            </Accordion>
          ))}
      </AppCard>
    </LazyLoad>
  );
};

export default React.memo(ClientInfo);

ClientInfo.propTypes = {
  tenantId: PropTypes.string.isRequired,
};
