import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TENANT_LIST,
  GET_TENANT_ALLOW_SMS_LIST,
  SET_FILTER_TENANT_LIST,
  SET_TENANT_ALLOW_SMS_LIST_EXTRA,
  TENANT_LIST_LOADING,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { loadingKeys } from 'shared/constants/AppsContants';

const defaultPagination = {
  pageIndex: 1,
  pageSize: 10,
  searchTerm: '',
};

export const onGetTenantList = (payload) => {
  const { pageSize = 10, pageIndex = 1, searchTerm = '', havingAccessToTypes } =
    payload || defaultPagination;
  return (dispatch) => {
    const { messages } = appIntl();
    // dispatch({type: FETCH_START});
    dispatch({
      type: TENANT_LIST_LOADING,
      payload: {
        key: loadingKeys.getList,
        value: true,
      },
    });

    jwtAxios
      .get('/api/Tenant', {
        SearchTerm: searchTerm,
        PageIndex: pageIndex,
        PageSize: pageSize,
        HavingAccessToTypes: havingAccessToTypes ?? [],
      })
      .then((data) => {
        if (data.status === 200) {
          // dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: TENANT_LIST_LOADING,
            payload: {
              key: loadingKeys.getList,
              value: false,
            },
          });
          dispatch({
            type: SET_FILTER_TENANT_LIST,
            payload: {
              pageIndex,
              pageSize,
              searchTerm,
              havingAccessToTypes,
              totalCount: data.data.TotalCount,
            },
          });
          dispatch({ type: GET_TENANT_LIST, payload: data.data.Items });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          dispatch({
            type: TENANT_LIST_LOADING,
            payload: {
              key: loadingKeys.getList,
              value: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({
          type: TENANT_LIST_LOADING,
          payload: {
            key: loadingKeys.getList,
            value: false,
          },
        });
      });
  };
};

export const onGetContractorList = (payload) => {
  const { pageSize = 10, pageIndex = 1, searchTerm = '', havingAccessToTypes, orderBy = 0, } =
    payload || defaultPagination;
  return (dispatch) => {
    const { messages } = appIntl();
    // dispatch({type: FETCH_START});
    dispatch({
      type: TENANT_LIST_LOADING,
      payload: {
        key: loadingKeys.getList,
        value: true,
      },
    });

    jwtAxios
      .post('/api/Tenant/GetAll', {
        SearchTerm: searchTerm,
        PageIndex: pageIndex,
        PageSize: pageSize,
        HavingAccessToTypes: havingAccessToTypes ?? [],

        OrderBy: orderBy,
      })
      .then((data) => {
        if (data.status === 200) {
          // dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: TENANT_LIST_LOADING,
            payload: {
              key: loadingKeys.getList,
              value: false,
            },
          });
          dispatch({
            type: SET_FILTER_TENANT_LIST,
            payload: {
              pageIndex,
              pageSize,
              searchTerm,
              havingAccessToTypes,
              totalCount: data.data.TotalCount,
            },
          });
          dispatch({ type: GET_TENANT_LIST, payload: data.data.Items });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          dispatch({
            type: TENANT_LIST_LOADING,
            payload: {
              key: loadingKeys.getList,
              value: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({
          type: TENANT_LIST_LOADING,
          payload: {
            key: loadingKeys.getList,
            value: false,
          },
        });
      });
  };
};

export const onGetTenantAllowSmsList = (payload) => {
  const { searchTerm, pageIndex, pageSize } = payload || defaultPagination;
  return (dispatch) => {
    const { messages } = appIntl();
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/api/Tenant/GetTenantsAllowUseSms', {
        SearchTerm: searchTerm,
        PageIndex: pageIndex,
        PageSize: pageSize,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_TENANT_ALLOW_SMS_LIST_EXTRA,
            payload: {
              pageIndex,
              pageSize,
              searchTerm,
              totalCount: data.data.TotalCount,
            },
          });
          dispatch({ type: GET_TENANT_ALLOW_SMS_LIST, payload: data.data.Items });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
