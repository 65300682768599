import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  Link,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import AppGridContainer from '@tenant/core/AppGridContainer';
import Status from '@tenant/core/App/RequestFlow/Status';
import { debounce } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import moment from 'moment';
import FlagIcon from '@mui/icons-material/Flag';
import DoneIcon from '@mui/icons-material/Done';
import { statusVehicleData } from 'shared/constants/AppsContants';
import { API_VEHICLE_TRACKING_REQUEST, BASE_URL_SIM } from '../../constants';
import axios from 'axios';

const ResquestDescription = ({ detail, onChange, handleChangeDetail }) => {
  const [listIMEI, setListIMEI] = useState([]);
  const [listICCID, setListICCID] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingICCID, setLoadingICCID] = useState(false);

  const isBiggerThanApprove = useMemo(() => {
    return detail?.Status >= 1;
  }, [detail]);

  const isPending = useMemo(() => {
    return detail?.Status === 0;
  }, [detail]);

  const [listRequestDetails, setListRequestDetails] = useState(
    detail.RequestDetails ?? [],
  );

  const listIMEISelected = useMemo(() => {
    return listRequestDetails.reduce((r, o) => {
      if (o.IMEI) {
        return [...r, o.IMEI];
      } else return [...r];
    }, []);
  }, [listRequestDetails, listIMEI]);

  const listIMEIAvailable = useMemo(() => {
    return listIMEI.filter(
      (obj1) => !listIMEISelected.some((obj2) => obj1 === obj2),
    );
  }, [listIMEISelected]);

  const listICCIDSelected = useMemo(() => {
    return listRequestDetails.reduce((r, o) => {
      if (o?.iccid) {
        return [...r, o?.iccid];
      } else return [...r];
    }, []);
  }, [listICCID, listRequestDetails]);

  const listICCIDAvailable = useMemo(() => {
    return listICCID.filter(
      (obj1) => !listICCIDSelected.some((obj2) => obj1 === obj2),
    );
  }, [listICCIDSelected]);

  const onChangeIMEI = (event, value, params) => {
    const list = [...listRequestDetails];
    const index = list.findIndex((e) => e.Id === params.id);
    list[index].IMEI = value;
    setListRequestDetails(list);
    onChange(list);
  };

  const onSearchIMEI = debounce((event, value) => {
    if (event?.type === 'change') {
      getAvailableIMEIs(value);
    }
  });

  const getAvailableIMEIs = async (text) => {
    setLoading(true);
    try {
      const { data } = await jwtAxios.get(
        API_VEHICLE_TRACKING_REQUEST.AVAILABLE_IMEIS,
        {
          params: { searchTerm: text },
        },
      );
      setListIMEI(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangeICCID = (event, value, params) => {
    const list = [...listRequestDetails];
    const index = list.findIndex((e) => e.Id === params.id);
    list[index].iccid = value;
    setListRequestDetails(list);
    onChange(list);
  };

  const onSearchICCID = debounce((event, value) => {
    if (event?.type === 'change') {
      getAvailableICCID(value);
    }
  });

  const getAvailableICCID = async (text) => {
    try {
      setLoadingICCID(true);
      const { data } = await axios.get(
        API_VEHICLE_TRACKING_REQUEST.SIMS_SUBSCRIPTION,
        {
          baseURL: BASE_URL_SIM,
          params: {
            iccid: text,
            per_page: 15,
          },
          headers: {
            Authorization: `Token ${process.env.REACT_APP_ACCESS_TOKEN_SIM}`,
          },
        },
      );
      setListICCID(data.map((o) => o?.iccid));
      setLoadingICCID(false);
    } catch (error) {
      setLoadingICCID(false);
      console.error(error);
    }
  };

  const columns = [
    {
      field: 'RegistrationNumber',
      headerName: 'Registration Number',
      width: 300,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={<Typography>{params.row.Model}</Typography>}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'LinkedEngineerEmail',
      headerName: 'Engineer Email',
      width: 300,
      flex: 1,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: 'IMEI',
      headerName: 'IMEI',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return isBiggerThanApprove ? (
          <Typography>{params.value}</Typography>
        ) : (
          <FormControl fullWidth>
            <Autocomplete
              name='IMEI'
              options={listIMEIAvailable}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              value={params.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='IMEI'
                  required
                  variant='standard'
                />
              )}
              onInputChange={onSearchIMEI}
              onChange={(e, value) => onChangeIMEI(e, value, params)}
              loading={loading}
              onOpen={() => getAvailableIMEIs()}
            />
          </FormControl>
        );
      },
    },
    {
      field: 'iccid',
      headerName: 'iccid',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return isBiggerThanApprove ? (
          <Typography>{params.value}</Typography>
        ) : (
          <FormControl fullWidth>
            <Autocomplete
              name='iccid'
              options={listICCIDAvailable}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              value={params.value}
              renderInput={(params) => (
                <TextField {...params} label='iccid' variant='standard' />
              )}
              loading={loadingICCID}
              onInputChange={onSearchICCID}
              onChange={(e, value) => onChangeICCID(e, value, params)}
              onOpen={() => getAvailableICCID()}
            />
          </FormControl>
        );
      },
    },
  ];

  const progressComplete = useMemo(() => {
    return detail?.RequestDetails.reduce((r, o) => r + +(o.IMEI !== null), 0);
  }, [detail]);

  const openLink = () => {
    window.open(detail?.InvoicePublicLink, '_blank');
  };

  useEffect(() => {
    getAvailableIMEIs();
    getAvailableICCID();
  }, []);

  return (
    <>
      <AppGridContainer sx={{ p: 5 }} spacing={5} columns={12}>
        <Grid item xs={12} md={4}>
          <ListItemText primary='Tenant Name' secondary={detail?.CompanyName} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText primary='Tenant Id' secondary={detail?.TenantId} />
        </Grid>

        <Grid item xs={12} md={4}>
          <ListItemText
            primary='Request By'
            secondary={`${detail?.RequesterName} (${detail?.RequesterEmail})`}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText
            primary='Request Date'
            secondary={moment(detail?.RequestDate).format(inputFormatDateTime)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText
            primary='Status'
            secondary={
              <Status
                status={detail?.Status}
                statusDataCustom={statusVehicleData}
              />
            }
          />
        </Grid>
        {detail?.InvoiceNumber && (
          <>
            <Grid item xs={12} md={4}>
              <ListItemText
                primary='Invoice Number'
                secondary={`${detail?.InvoiceNumber}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ListItemText
                primary='Invoice Status'
                secondary={`${detail?.InvoiceStatus}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ListItemText
                primary='Invoice PublicLink'
                secondary={
                  <Link className='pointer' onClick={openLink}>
                    Link
                  </Link>
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4}>
          <ListItemText
            primary='Delivery Tracking Number'
            secondary={
              detail?.DeliveryTrackingNumber
                ? `${detail?.DeliveryTrackingNumber}`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText
            primary='Sent Date'
            secondary={
              detail?.SendDate
                ? moment(detail?.SendDate).format(inputFormatDateTime)
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText primary='Address' secondary={detail?.Address || '-'} />
        </Grid>
        {isPending && (
          <Grid item xs={12} md={4}>
            <Tooltip
              title='You need to fill all IMEIs to approve this request'
              arrow
            >
              <Stack
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='center'
                sx={{
                  width: 'fit-content',
                }}
              >
                {progressComplete === detail.RequestDetails?.length ? (
                  <DoneIcon />
                ) : (
                  <FlagIcon />
                )}

                <ListItemText
                  primary=''
                  secondary={`${progressComplete} / ${detail.RequestDetails?.length}`}
                />
              </Stack>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <DataGrid
            hideFooter
            disableColumnSelector
            rows={listRequestDetails}
            columns={columns}
            getRowId={(row) => row.Id}
            autoHeight
            disableColumnFilter
            disableColumnMenu
          />
        </Grid>
      </AppGridContainer>
    </>
  );
};

export default React.memo(ResquestDescription);
