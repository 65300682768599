export const AuthorizePermission = {
  Administrator: 1,
  'Admin Management': 2,
  Tenant: 3,
  'Tenant - View': 4,
  'Tenant - Create': 5,
  'Tenant - Update': 6,
  'Tenant - Export': 7,
  'Vehicle Tracking': 8,
  'Vehicle Tracking - View': 9,
  'Vehicle Tracking - Create': 10,
  'Vehicle Tracking - Update': 11,
  'Vehicle Tracking - Export': 12,
  Point: 13,
  'Point - View': 14,
  'Point - Create': 15,
  'Point - Update': 16,
  'Point - Export': 17,
  Bundle: 18,
  Analytic: 19,
  'Analytic - View': 20,
  'Revenue - View': 21,
};
