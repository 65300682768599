import React, { useEffect, useState, useMemo } from 'react';
import InvoiceProfessionalChart from '@tenant/core/App/Chart/InvoiceProfessionalChart';
import AppCard from '@tenant/core/AppCard';
import AppSelect from '@tenant/core/AppSelect';
import { useIntl } from 'react-intl';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import { Code } from 'react-content-loader';
import PropTypes from 'prop-types';
import { dateGroupTypes, dateRanges } from '@tenant/utility/constants/default';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import { getKeyByValue } from '@tenant/utility/helper/Utils';
import { Box } from '@mui/material';
import DescriptionTooltip from 'pages/dashboards/Xero/InvoiceProfessional/DescriptionTooltip';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { setFieldTenantDetail } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const DEFAULT_RANGE_DATE = 'This year';
const FIELD_NAME = 'invoiceProfessional';
const defaultDateRange = dateRanges[DEFAULT_RANGE_DATE];

const InvoiceProfessional = ({ tenantId }) => {
  const dispatch = useDispatch();
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD_NAME] ?? null,
    [tenant],
  );
  const { data, filter } = storeTenant ?? {};
  const {
    startDate = defaultDateRange[0],
    endDate = defaultDateRange[1],
    dateGroupType = dateGroupTypes[DEFAULT_RANGE_DATE],
    selectedType = DEFAULT_RANGE_DATE,
    dataLoaded,
  } = filter ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const isDay = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day',
    [dateGroupType],
  );
  const isYear = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Year',
    [dateGroupType],
  );

  const handleSelectionType = (data) => {
    const { keyName, startDate, endDate, dateGroupType } = data || {};
    getResponseStatistics({
      startDate,
      endDate,
      dateGroupType,
      selectedType: keyName,
    });
  };

  const getResponseStatistics = async (filter) => {
    try {
      const { startDate, endDate, dateGroupType, selectedType } = filter ?? {};
      if (!startDate || !endDate) {
        return;
      }
      setIsLoading(true);
      const { data } = await jwtAxios.get(
        `/api/Xero/Tenants/${tenantId}/ServiceInvoiceStatistic`,
        {
          params: {
            startDate,
            endDate,
            dateGroupType,
          },
        },
      );
      if (!data) {
        return;
      }
      dispatch(
        setFieldTenantDetail({
          tenantId,
          field: FIELD_NAME,
          data: data.Items,
          filter: {
            startDate,
            endDate,
            dateGroupType,
            selectedType,
            dataLoaded: true,
          },
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataLoaded) {
      return;
    }
    getResponseStatistics({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      dateGroupType,
      selectedType,
    });
  }, []);

  const { messages } = useIntl();

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>{messages['dashboard.analytics.invoice']}</Box>
          <Box>
            <DescriptionTooltip />
          </Box>
        </Box>
      }
      action={
        <DateMenu
          isHasYear
          defaultValue={selectedType}
          handleChangeValue={handleSelectionType}
        />
      }
    >
      {isLoading && <Code />}

      {!isLoading && (
        <InvoiceProfessionalChart
          data={data?.map((e) => ({
            ...e,
            Key: formatDate(
              e.Key,
              isDay ? 'DD MMM' : isYear ? 'YYYY' : 'MMM YYYY',
            ),
          }))}
        />
      )}
    </AppCard>
  );
};

export default React.memo(InvoiceProfessional);

InvoiceProfessional.propTypes = {
  tenantId: PropTypes.string.isRequired,
};
