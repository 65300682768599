import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Legend,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  formatNumber,
  formatNumberWithCompact,
  getFontSizeLabelChart,
} from '@tenant/utility/helper/Utils';

const KEY_LIST = ['SmsUsage', 'SegmentUsage'];
const KEY_NAME = {
  SmsUsage: 'Sms',
  SegmentUsage: 'Segment',
};
const COLOR = {
  SmsUsage: '#ed7d31',
  SegmentUsage: '#8884d8',
};

const SMSUsageChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height={275}>
      <LineChart data={data} syncId='smsId'>
        <XAxis
          dataKey='Key'
          tickLine={false}
          axisLine={false}
          fontSize={getFontSizeLabelChart(data?.length)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          fontSize={12}
          tickFormatter={(value) => formatNumberWithCompact(value)}
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          formatter={(value) => formatNumber(value)}
        />
        <Legend verticalAlign='top' height={35} />
        <CartesianGrid strokeDasharray='3 3' stroke='#eee' />
        {KEY_LIST?.length > 0
          ? KEY_LIST.map((e) => (
              <Line
                key={e}
                type='monotone'
                dataKey={e}
                name={KEY_NAME[e]}
                stroke={COLOR[e]}
                dot={false}
                strokeWidth={2}
                activeDot={{ r: 4 }}
              />
            ))
          : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(SMSUsageChart);

SMSUsageChart.defaultProps = {
  data: [],
};

SMSUsageChart.propTypes = {
  data: PropTypes.array,
};
