import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  checkIsPercentage,
  numberToCurrency,
  numberToPercent,
  percentageToNumber,
  roundNumber,
} from '@tenant/utility/helper/Utils';
import useScreenSize from '@tenant/utility/hooks/useScreenSize';
import { TextField } from '@mui/material';
import { getAmountWithDiscount } from '../helper';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';

const min = 0;
const max = 100;

const XeroInvoiceTable = ({ invoice, onChangeDiscount }) => {
  const { flexTable } = useScreenSize();
  const { Total, Quantity, Tax, Discount } = invoice || {};

  const isPercentage = useMemo(() => checkIsPercentage(Discount), [Discount]);
  const amountAfterDiscount = useMemo(
    () =>
      getAmountWithDiscount(
        Total || 0,
        isPercentage ? percentageToNumber(Discount || 0) : Discount || 0,
        isPercentage,
      ) || 0,
    [Total, Discount],
  );

  const columns = useMemo(
    () => [
      {
        field: 'Description',
        headerName: 'Description',
        width: 250,
        flex: flexTable,
        renderCell: ({ value }) => (
          <span style={{ whiteSpace: 'pre-line' }}>{value}</span>
        ),
      },
      {
        field: 'Quantity',
        headerName: 'Quantity',
        width: 75,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'Discount',
        headerName: 'Discount',
        width: 150,
        align: 'right',
        headerAlign: 'right',
        renderHeader: () => (
          <>
            Discount{' '}
            <AppGlossary
              title={
                'Discounts can be entered as a numeric value or a percentage. Include the % symbol for a percentage discount.'
              }
            />
          </>
        ),
        renderCell: ({ value, row }) => (
          <TextField
            disabled={!invoice?.Quantity}
            type='text'
            autoComplete='off'
            value={value || ''}
            size='small'
            sx={{
              '& fieldset': { padding: 0 },
              '& input': { textAlign: 'right' },
            }}
            onChange={(e) => {
              let newValue = e.target.value;
              const isPercent = checkIsPercentage(e.target.value);

              if (isPercent) {
                let newValueNumber = percentageToNumber(newValue);
                if (newValueNumber > max) newValueNumber = max;
                if (newValueNumber < min) newValueNumber = min;

                onChangeDiscount?.(newValueNumber + '%');
                return;
              }

              if (!+newValue) {
                onChangeDiscount?.('');
                return;
              }

              if (newValue > Total) {
                newValue = Total;
              }

              if (newValue < min) newValue = min;

              onChangeDiscount?.(newValue);
            }}
            onBlur={() => {
              const isPercent = checkIsPercentage(value);
              const convertValue = isPercent
                ? percentageToNumber(value)
                : value;
              const newValue = +convertValue || 0;
              const prefix = isPercent ? '%' : '';
              const parseValue =
                newValue && !Number.isInteger(+newValue)
                  ? parseFloat(newValue).toFixed(2) + prefix
                  : parseInt(newValue, 10) + prefix;
              onChangeDiscount?.(parseValue);
            }}
          />
        ),
      },
      {
        field: 'TaxRate',
        headerName: 'Tax Rate',
        width: 75,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'Amount',
        headerName: 'Amount',
        width: 125,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'AmountDue',
        headerName: 'Amount Due',
        width: 125,
        align: 'right',
        headerAlign: 'right',
      },
    ],
    [flexTable, onChangeDiscount, Total],
  );

  const data = useMemo(
    () => [
      {
        id: 'SC',
        ItemCode: 'SC',
        Description: `Subcontractor Portal License to run in line with your existing main contract £59 x ${Quantity} months`,
        Quantity: 1,
        TaxRate: numberToPercent(Tax || 0),
        Amount: numberToCurrency(Total || 0, 2, 'GBP'),
        AmountDue: numberToCurrency(
          amountAfterDiscount + (amountAfterDiscount * (Tax || 0)) / 100,
          2,
          'GBP',
        ),
        Discount: Discount || 0,
      },
    ],
    [Total, Quantity, Discount],
  );

  return (
    <DataGrid
      hideFooter
      disableColumnFilter
      disableSelectionOnClick
      disableColumnSelector
      disableColumnMenu
      onRowClick={(params, event) => event.stopPropagation()}
      rows={data}
      columns={columns}
      sx={{
        height: 125,
        '[class^=MuiDataGrid]': {
          '[class^=MuiDataGrid-cell]': { borderBottom: 'none' },
        },
      }}
    />
  );
};

export default React.memo(XeroInvoiceTable);

XeroInvoiceTable.propTypes = {
  invoice: PropTypes.object,
  onChangeDiscount: PropTypes.func,
};
