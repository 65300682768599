import { formatNumber } from '@tenant/utility/helper/Utils';
import React from 'react';

const Tooltip = ({ active, payload, unit = 'Tenant' }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className='custom-tooltip'
        style={{
          borderWidth: 1,
          borderColor: payload[0].payload.payload.color,
          borderStyle: 'dashed',
          borderRadius: 4,
        }}
      >
        <p className='label'>{payload[0].payload.payload.text}</p>
        <p
          className='intro'
          style={{
            color: payload[0].payload.payload.color || payload[0].payload.fill,
          }}
        >
          {unit}: {formatNumber(payload[0].value)}
        </p>
      </div>
    );
  }

  return null;
};

export default Tooltip;
