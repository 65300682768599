import React, { isValidElement, } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.6em',
    height: '0.6em',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const TableChart = ({
  uniqueProp,
  rows,
  columns,
  wrapperStyle,
  sxStyle,
  isLoading = false,
  ...rest
}) => {

  return (
    <Box sx={{ width: '100%', height: 325, ...wrapperStyle }}>
      <StyledDataGrid
        hideFooter
        disableColumnSelector
        disableColumnMenu
        loading={isLoading}
        getRowId={(row) => row[uniqueProp]}
        rows={rows}
        columns={columns}
        localeText={{
          noRowsLabel: 'No Data',
        }}
        sx={{ ...sxStyle }}
        {...rest}
      />
    </Box>
  );
};

TableChart.propTypes = {
  sxStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  uniqueProp: PropTypes.string.isRequired,
};

export default React.memo(TableChart);

