export default {
  formId: 'companyAddForm',
  formDetail: 'ContractDetail',
  formConfig: 'ContractConfig',
  formTenant: 'Tenants',
  formField: {
    CompanyName: {
      name: 'CompanyName',
      label: 'Company Name',
      requiredErrorMsg: 'Company Name is required',
    },
    ContractType: {
      name: 'ContractType',
      label: 'Contract Type',
      requiredErrorMsg: 'Contract Type is required',
    },
    Tenant: {
      name: 'Tenant',
      label: 'Tenant',
    },
    TenantId: {
      name: 'TenantId',
      label: 'Tenant',
      requiredErrorMsg: 'Tenant is required',
    },
    ContractName: {
      name: 'ContractName',
      label: 'Contract Name',
      requiredErrorMsg: 'Contract Name is required',
    },
    ContactName: {
      name: 'ContactName',
      label: 'Contact Name',
      requiredErrorMsg: 'Contact Name is required',
    },
    ContactEmail: {
      name: 'ContactEmail',
      label: 'Contact Email',
      requiredErrorMsg: 'Contact Email is required',
    },
    PaymentMethod: {
      name: 'PaymentMethod',
      label: 'Payment Method',
      requiredErrorMsg: 'Payment Method is required',
    },
    ContractLength: {
      name: 'ContractLength',
      label: 'Contract Length',
      requiredErrorMsg: 'Contract Length is required',
    },
    PackageType: {
      name: 'PackageType',
      label: 'Package Type',
      requiredErrorMsg: 'Package Type is required',
    },
    MaximumNumberUser: {
      name: 'MaximumNumberUser',
      label: 'Maximum Number of Users',
      requiredErrorMsg: 'Maximum Number of Users is required',
      minNumberErrorMsg: 'Maximum Users must be greater than 0',
      inValidNumberErrorMsg: 'Please enter a valid number.',
      lessMaxUserErrorMsg: 'Maximum Users must be greater than Free Users',
    },
    NumberOfFreeUsers: {
      name: 'NumberOfFreeUsers',
      label: 'Free User',
      requiredErrorMsg: 'Free User is required',
      inValidNumberErrorMsg: 'Please enter a valid number.',
      minNumberErrorMsg: 'Min value number is 0',
      lessMaxUserErrorMsg: 'Free User must be less than Maximum Users',
    },
    PaidUser: {
      name: 'PaidUser',
      label: 'Paid User',
      requiredErrorMsg: 'Paid User is required',
    },
    XeroContactId: {
      name: 'XeroContactId',
      label: 'Xero Contact',
      requiredErrorMsg: 'Xero Contact is required',
    },
    XeroContactEmail: {
      name: 'XeroContactEmail',
      label: 'Xero Contact Email',
      requiredErrorMsg: 'Xero Contact Email is required',
    },
    TrackerId: {
      name: 'TrackerId',
      label: 'Tracker Id',
      requiredErrorMsg: 'Tracker Id is required',
    },
    ContractSignDate: {
      name: 'ContractSignDate',
      label: 'Contract Sign Date',
      requiredErrorMsg: 'Contract Sign Date is required',
      inValidErrorMsg: 'Please enter a valid date',
    },
    VAT: {
      name: 'VAT',
      label: 'VAT',
      requiredErrorMsg: 'VAT is required',
    },
    CustomLength: {
      name: 'CustomLength',
      label: 'Custom Value',
      requiredErrorMsg: 'Custom Value is required',
      minNumberErrorMsg: 'Please enter a number greater than or equal to 1',
    },
    StartDate: {
      name: 'StartDate',
      label: 'Start Date',
      requiredErrorMsg: 'Start Date is required',
      inValidErrorMsg: 'Please enter a valid date',
    },
    EndDate: {
      name: 'EndDate',
      label: 'End Date',
      requiredErrorMsg: 'End Date is required',
      inValidErrorMsg: 'Please enter a valid date',
      afterStartDateErrorMsg: 'End Date must be greater than Start Date',
    },
    PaymentTerms: {
      name: 'PaymentTerms',
      label: 'Payment Terms',
      requiredErrorMsg: 'Payment Terms is required',
    },
    Membership: {
      name: 'Membership',
      label: 'Membership',
      requiredErrorMsg: 'Membership is required',
    },
    AccountingManualPlan: {
      name: 'AccountingManualPlan',
      label: 'AccountingManualPlan',
    },
    ProductName: {
      name: 'ProductName',
      label: 'ProductName',
    },
    Country: {
      name: 'Country',
      label: 'Country',
      requiredErrorMsg: 'Country is required',
    },
    LineId: {
      name: 'LineId',
      label: 'LineId',
      requiredErrorMsg: 'LineId is required',
    },
    LineType: {
      name: 'LineType',
      label: 'Item',
      requiredErrorMsg: 'Item is required',
    },
    LineDescription: {
      name: 'LineDescription',
      label: 'Description',
      requiredErrorMsg: 'Description is required',
    },
    Quantity: {
      name: 'Quantity',
      label: 'Quantity',
      requiredErrorMsg: 'Quantity is required',
      minNumberErrorMsg: 'Please enter a number greater than or equal to 1',
    },
    UnitPrice: {
      name: 'UnitPrice',
      label: 'Unit Price',
      requiredErrorMsg: 'Unit Price is required',
      minNumberErrorMsg: 'Please enter a number greater than or equal to 1',
    },
    DiscountPercent: {
      name: 'DiscountPercent',
      label: 'Discount Percent',
    },
    DiscountValue: {
      name: 'DiscountValue',
      label: 'Discount Value',
    },
    Discount: {
      name: 'Discount',
      label: 'Discount',
    },
    TaxRate: {
      name: 'TaxRate',
      label: 'Tax Rate',
      requiredErrorMsg: 'Tax Rate is required',
    },
    TaxAmount: {
      name: 'TaxAmount',
      label: 'Tax Amount',
      requiredErrorMsg: 'Tax Amount is required',
    },
    SubAmount: {
      name: 'SubAmount',
      label: 'Sub Amount',
      requiredErrorMsg: 'Sub Amount is required',
    },
    Amount: {
      name: 'Amount',
      label: 'Amount',
      requiredErrorMsg: 'Amount is required',
    },
    LineTotal: {
      name: 'LineTotal',
      label: 'LineTotal',
    },
    IsCreateInvoice: {
      name: 'IsCreateInvoice',
      label: 'Generate Xero Invoice',
    },
    PromoCode: {
      name: 'PromoCode',
      label: 'Promo Code',
    },
  },
};
