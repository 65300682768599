import React, { useCallback, } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import IntlMessages from '@tenant/utility/IntlMessages';

const BuyPoints = ({
  tenantId,
  sxStyle,
  contractId,
  companyId,
  showInMenu = false,
  handleClickItem,
}) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    if (!!companyId) return handleClickItem?.();
    if (!!tenantId) return navigate(`/tenant/detail/${tenantId}/contract/${contractId}/buy-bundle`);

    handleClickItem?.();
  }, [companyId, contractId, tenantId]);

  return (
    showInMenu ? (
      <MenuItem
        onClick={handleOnClick}
      >
        <IntlMessages id='bundleContract.upgrade' />
      </MenuItem>
    ) : (
      <Button
        color='primary'
        sx={{
          ...sxStyle,
        }}
        onClick={handleOnClick}
        variant='contained'
      >
        <IntlMessages id='bundleContract.upgrade' />
      </Button>
    )
  );
};

export default React.memo(BuyPoints);

BuyPoints.propTypes = {
  tenantId: PropTypes.string,
  sxStyle: PropTypes.object,
  contractId: PropTypes.string,
  companyId: PropTypes.string,
  showInMenu: PropTypes.bool,
  handleClickItem: PropTypes.func,
};
