import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { DataGrid } from '@mui/x-data-grid';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import DetailUpdateMaxNumberUsers from '../ContractDetailAction/DetailUpdateMaxNumberUsers';
import useModal from '@tenant/utility/hooks/useModal';
import AppsFooter from '@tenant/core/AppsContainer/AppsFooter';
import { grey } from '@mui/material/colors';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { getNewValueFromAudit } from '../../helpers/helper';
import { compareObjects, isJSONString } from '@tenant/utility/Utils';

const RemoveAuditDetail = ({
  tenantId,
  contractId,
  onGetContractAndInvoice,
  subscriptionEnd,
  audit,
  maxNumberOfUsers,
  isEndSubscription,
}) => {
  const { visible, onShow, onClose } = useModal();
  const columns = useMemo(
    () => [
      {
        field: 'EmailAddress',
        headerName: 'Email',
        flex: 1,
        sortable: false,
      },
    ],
    [],
  );
  const isNewest = useMemo(() => !!audit?.IsNewest, [audit?.IsNewest]);
  const isRemoveUser = useMemo(
    () => audit?.Type === 'Remove User',
    [audit?.Type],
  );
  const isAddUser = useMemo(() => audit?.Type === 'Add User', [audit?.Type]);
  const changeNumber = useMemo(
    () => (audit?.NewValue ?? 0) - (audit?.OldValue ?? 0),
    [audit?.NewValue, audit?.OldValue],
  );

  let compare = null;

  try {
    compare = compareObjects(
      JSON.parse(audit.OldValue),
      JSON.parse(audit.NewValue),
    ).map(JSON.parse(audit.OldValue), JSON.parse(audit.NewValue));
  } catch (error) {}

  const isObjectData = useMemo(() => {
    return isJSONString(audit.OldValue);
  }, [audit]);

  return (
    <>
      <Box height={'100%'}>
        {isRemoveUser && (
          <DetailUpdateMaxNumberUsers
            tenantId={tenantId}
            isAdditionalUser={false}
            contractId={contractId}
            visible={visible}
            onClose={onClose}
            onShow={onShow}
            defaultValue={Math.abs(changeNumber)}
            maxNumberOfUsers={maxNumberOfUsers}
            onGetContractAndInvoice={onGetContractAndInvoice}
            subscriptionEnd={subscriptionEnd}
          />
        )}

        <Box
          sx={{
            '& .MuiListItemIcon-root': {
              minWidth: 125,
            },
          }}
        >
          <ListItem
            sx={{
              alignItems: 'baseline',
            }}
          >
            <ListItemIcon sx={{ mr: 2 }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                Type
              </Typography>
            </ListItemIcon>
            <ListItemText primary={audit?.Type} />
          </ListItem>
          {isObjectData ? (
            <>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Data change
                  </Typography>
                </ListItemIcon>
              </ListItem>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Old Value</TableCell>
                      <TableCell>New Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(compare).map((key) => {
                      return (
                        <TableRow>
                          <TableCell>{key}</TableCell>
                          <TableCell>
                            {JSON.stringify(compare[key].oldValue)}
                          </TableCell>
                          <TableCell>
                            {JSON.stringify(compare[key].newValue)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Value
                  </Typography>
                </ListItemIcon>
                <ListItemText primary={audit?.OldValue} />
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    New Value
                  </Typography>
                </ListItemIcon>
                <ListItemText primary={audit?.NewValue} />
              </ListItem>
            </>
          )}

          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon sx={{ mr: 2 }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                User
              </Typography>
            </ListItemIcon>
            <ListItemText primary={audit?.ActionBy} />
          </ListItem>
          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon sx={{ mr: 2 }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                Date
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={formatDateFromISO(audit?.CreatedAt, inputFormatDateTime)}
            />
          </ListItem>
          {isAddUser && (
            <>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Contract Price
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={numberToCurrency(
                    audit?.ContractPrice ?? 0,
                    2,
                    'GBP',
                  )}
                />
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Total
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={numberToCurrency(
                    (audit?.PerUserTotal ?? 0) * changeNumber,
                    2,
                    'GBP',
                  )}
                />
              </ListItem>

              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Subscription
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={`${audit?.SubStartDate ?? ''} - ${
                    audit?.SubEndDate ?? ''
                  }`}
                />
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Duration
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={`${audit?.SubscriptionDurationRemaining?.Months} months ${audit?.SubscriptionDurationRemaining?.Days} days`}
                />
              </ListItem>

              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Discount
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={
                    audit?.Discount?.indexOf('%') >= 0
                      ? audit?.Discount
                      : numberToCurrency(+audit?.Discount || 0, 2, 'GBP')
                  }
                />
              </ListItem>
              <ListItem sx={{ alignItems: 'baseline' }}>
                <ListItemIcon sx={{ mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Tax Amount
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  primary={numberToCurrency(+audit?.VATAmount || 0, 2, 'GBP')}
                />
              </ListItem>
            </>
          )}
        </Box>

        <Divider />

        {isRemoveUser && audit?.Users?.length > 0 && (
          <Box p={4} height={350}>
            <Typography variant='h5' component={'div'} mb={3}>
              Deactivate Users at {formatDateFromISO(subscriptionEnd)}
            </Typography>
            <DataGrid
              hideFooter
              disableColumnSelector
              disableColumnMenu
              rows={audit?.Users ?? []}
              columns={columns}
              getRowId={(row) => row.UniqueId}
            />
          </Box>
        )}
      </Box>

      {isRemoveUser && isNewest && !isEndSubscription && (
        <AppsFooter>
          <Box
            sx={{ backgroundColor: grey[50], mx: -5, my: -2, fontSize: 12 }}
            height={50}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <a
              href='#modify-audit'
              onClick={(e) => {
                e.preventDefault();
                onShow();
              }}
            >
              Click to modify it!
            </a>
          </Box>
        </AppsFooter>
      )}
    </>
  );
};

export default React.memo(RemoveAuditDetail);

RemoveAuditDetail.propTypes = {
  tenantId: PropTypes.string,
  contractId: PropTypes.string,
  onGetContractAndInvoice: PropTypes.func,
  subscriptionEnd: PropTypes.string,
  audit: PropTypes.object,
  maxNumberOfUsers: PropTypes.number,
  isEndSubscription: PropTypes.bool,
};
