import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TENANT_ID_LIST,
  SET_CACHED_TENANT_ID_LIST,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';

export const onGetTenantIDList = (params) => {
  const {
    env = 1,
    searchTerm = '',
    pageIndex = 1,
    pageSize = 10,
    ...rest
  } = params || {};
  const { messages } = appIntl();
  return (dispatch, getState) => {
    const { publicAPI } = getState();
    const { tenantIdListCached } = publicAPI || {};
    if (tenantIdListCached && !searchTerm) {
      dispatch({ type: GET_TENANT_ID_LIST, payload: tenantIdListCached });
      return;
    }
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/api/Tenant/GetAll', {
        searchTerm,
        pageIndex,
        pageSize,
        ...rest,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_TENANT_ID_LIST, payload: response.data.Items });
          if (!searchTerm) {
            dispatch({
              type: SET_CACHED_TENANT_ID_LIST,
              payload: response.data.Items,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
