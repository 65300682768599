import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import CreateVehicle from './CreateVehicle';
import { useDispatch, useSelector } from 'react-redux';
import useVehicleTracking from '../../hooks/useVehicleTracking';
import { GET_LIST_VEHICLE_TENANT } from 'shared/constants/ActionTypes';
import FlagIcon from '@mui/icons-material/Flag';
import useToast from '@tenant/utility/hooks/useToast';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import moment from 'moment';

function ListVehicle(props) {
  const dispatch = useDispatch();
  const { values, setFieldValue } = props;
  const [open, setOpen] = useState(false);
  const [listVehicle, setListVehicle] = useState([]);
  const { getListVehicleTenant, addVehicle } = useVehicleTracking();
  const [loading, setLoading] = useState(false);
  const listVehiclesTenant = useSelector(
    ({ vehicleTracking }) => vehicleTracking.listVehiclesTenant,
  );
  const { showErrorMessage } = useToast();

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const apiRef = useGridApiRef();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: 'RegistrationNumber',
      headerName: 'Reg. Number',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
    {
      field: 'Make',
      headerName: 'Make',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
    {
      field: 'Model',
      headerName: 'Model',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
    {
      field: 'VIN',
      headerName: 'VIN',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
    {
      field: 'ServiceDueDate',
      headerName: 'Service Due Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'MOTDueDate',
      headerName: 'MOT Due Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'RoadFundLicenceDueDate',
      headerName: 'Road Fund Licence Due Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{formatDate(moment(params.value))}</Typography>
      ),
    },
    {
      field: 'LeaseRenewalDueDate',
      headerName: 'Lease Renewal Due Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'LinkedEngineerName',
      headerName: 'Engineer',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
  ];

  const handleCheckList = (newSelection) => {
    setRowSelectionModel(newSelection);
    setFieldValue('ListVehicle', newSelection);
  };

  const createVehicle = (data) => {
    setLoading(true);
    addVehicle({
      id: values.TenantId,
      payload: data,
      onSuccess: () => {
        getList(values.TenantId);
      },
      onError: (err) => {
        setLoading(false);
        showErrorMessage(err);
      },
    });
  };

  const getList = (id) => {
    getListVehicleTenant({
      id,
      onSuccess: (data) => {
        setListVehicle(data);
        dispatch({
          type: GET_LIST_VEHICLE_TENANT,
          payload: { TenantId: values.TenantId, Items: data },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!values.TenantId) return;
    if (listVehiclesTenant.TenantId === values.TenantId) {
      setListVehicle(listVehiclesTenant.Items);
      return;
    }
    setLoading(true);
    getList(values.TenantId);
  }, [values.TenantId]);

  useEffect(() => {
    if (values.ListVehicle) {
      setRowSelectionModel(values.ListVehicle);
    }
  }, [values.ListVehicle]);

  return (
    <>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        spacing={4}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
        >
          <Stack direction='row' alignItems='center' justifyContent='end'>
            <FlagIcon />
            <Typography variant='p'>
              Selected: {values?.ListVehicle?.length}
            </Typography>
          </Stack>
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpen}
            disabled={!values.TenantId}
          >
            Add Vehicle
          </Button>
        </Stack>
        <Box height={'calc(100vh - 544px)'} width={'100%'}>
          <DataGrid
            apiRef={apiRef}
            hideFooter
            checkboxSelection
            rows={listVehicle ?? []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row.Id}
            selectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            onSelectionModelChange={(newSelection) => {
              handleCheckList(newSelection);
            }}
          />
        </Box>
      </Stack>
      {open && (
        <CreateVehicle
          open={open}
          handleClose={handleClose}
          createVehicle={createVehicle}
          tenantId={values.TenantId}
        />
      )}
    </>
  );
}

export default ListVehicle;
