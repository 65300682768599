export const envList = [
  {
    Id: 1,
    Name: 'Uat',
  },
  {
    Id: 2,
    Name: 'Live',
  },
];

export const COMMON_STATUS_ENUM = {
  PENDING: 0,
  APPROVED: 1,
  CANCELLED: 2,
  REJECTED: 3,
};

export const statusData = {
  0: {
    Name: 'Pending',
    Color: '#FF9900',
    Id: COMMON_STATUS_ENUM.PENDING,
  },
  1: {
    Name: 'Approved',
    Color: '#339900',
    Id: COMMON_STATUS_ENUM.APPROVED,
  },
  2: {
    Name: 'Cancelled',
    Color: '#FF3300',
    Id: COMMON_STATUS_ENUM.CANCELLED,
  },
  3: {
    Name: 'Rejected',
    Color: '#FF3300',
    Id: COMMON_STATUS_ENUM.REJECTED,
  },
};

export const statusVehicleData = {
  0: {
    Name: 'Pending',
    Color: '#FF9900',
    Id: 0,
  },
  1: {
    Name: 'Approved',
    Color: '#339900',
    Id: 1,
  },
  2: {
    Name: 'Sent',
    Color: '#4dabf5',
    Id: 2,
  },
  3: {
    Name: 'Cancelled',
    Color: '#FF3300',
    Id: 3,
  },
};

export const extraLoadingKeys = {
  refcom: 'REFCOM',
  transactionType: 'TRANSACTION_TYPE',
  transactionUsage: 'TRANSACTION_USAGE',
  topTenTransaction: 'TOP_TEN_TRANSACTION',
  publicApi: 'PUBLIC_API',
  productUsage: 'PRODUCT_USAGE'
};

export const loadingKeys = {
  getList: 'GET_LIST',
  create: 'CREATE',
  delete: 'DELETE',
  update: 'UPDATE',
  getDetail: 'GET_DETAIL',
  getRefcomList: 'GET_REFCOM_LIST',
};
