import {
  GET_TENANT_LIST,
  GET_TENANT_ALLOW_SMS_LIST,
  SET_FILTER_TENANT_LIST,
  SET_TENANT_ALLOW_SMS_LIST_EXTRA,
  TENANT_LIST_LOADING,
  SET_TENANT_LIST_FILTER,
  SET_TENANT_LIST_PARAMS,
} from 'shared/constants/ActionTypes';
import { loadingKeys } from 'shared/constants/AppsContants';

const initialState = {
  tenantList: [],
  tenantAllowSmsList: [],
  pageIndex: 1,
  pageSize: 10,
  searchTerm: "",
  showLoadMore: false,
  totalCount: 0,
  tenantSmsListExtra: {
    pageIndex: 0,
    pageSize: 0,
    searchTerm: 0,
    totalCount: 0
  },
  loading: {
    [loadingKeys.getList]: false,
  },
  filterForm: {
    tenants: [],
    clientGroups: [],
    paymentTypes: [],
    industries: [],
    salePersons: [],
    accountManagers: [],
  },
  listParams: {
    pageIndex: 1,
    pageSize: 10,
    searchTerm: '',
  },
};

const tenantListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TENANT_LIST: {
      return {
        ...state,
        tenantList: action.payload,
        showLoadMore: (action.payload?.length ?? 0) === state.pageSize
      };
    }

    case GET_TENANT_ALLOW_SMS_LIST: {
      return {
        ...state,
        tenantAllowSmsList: action.payload,
      };
    }

    case SET_FILTER_TENANT_LIST: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SET_TENANT_ALLOW_SMS_LIST_EXTRA: {
      return {
        ...state,
        tenantSmsListExtra: { ...action.payload }
      };
    }

    case TENANT_LIST_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.key]: action.payload.value,
        },
      };

    case SET_TENANT_LIST_FILTER:
      return {
        ...state,
        filterForm: action.payload,
      };

    case SET_TENANT_LIST_PARAMS:
      return {
        ...state,
        listParams: { ...action.payload },
      };
    default:
      return state;
  }
};

export default tenantListReducer;
