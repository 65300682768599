import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import {
  Box,
  ListItem,
  Typography,
  IconButton,
  ListItemText,
  Divider,
} from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate, isoDateToDayAgo } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { useDispatch, useSelector } from 'react-redux';
import { dismissNotification } from 'redux/actions/Notification';
import { LoadingButton } from '@mui/lab';
import AppTooltip from '../AppTooltip';
import { Link } from 'react-router-dom';
import { getUserAvatar } from '@tenant/utility/helper/Utils';

const NotificationItem = (props) => {
  const { item, onClose } = props;
  const { loadingIdBtn } = useSelector((s) => s.notification);
  const dispatch = useDispatch();

  const onDismiss = (notificationId) => {
    dispatch(dismissNotification({ notificationId }));
  };

  return (
    <ListItem
      sx={{
        padding: '8px 20px',
        borderBottom: '1px solid #ddd',
        borderLeft: '2.5px solid transparent',
        transition: '0.5s',
        '&:hover': {
          backgroundColor: '#fafafa',
        },
      }}
      className='item-hover'
      secondaryAction={
        <AppTooltip title='Dismiss this notification'>
          <LoadingButton
            sx={{ padding: 0, minWidth: 'auto' }}
            edge='end'
            loading={loadingIdBtn === item.Id}
            aria-label='delete'
            color='secondary'
            onClick={() => onDismiss(item.Id)}
          >
            <ClearIcon />
          </LoadingButton>
        </AppTooltip>
      }
    >
      <ListItemAvatar>
        <AppTooltip title={item.SenderId}>
          <Avatar sx={{ backgroundColor: 'warning.main' }}>
            {getUserAvatar(item.SenderId)}
          </Avatar>
        </AppTooltip>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            component={'p'}
            sx={{
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              '&:hover a': {
                textDecoration: 'underline',
              },
            }}
          >
            <Link
              to={item.Url}
              style={{
                textDecoration: 'none',
                color: '#072d3d',
                transition: 'color .3s ease-in-out',
                fontWeight: Fonts.MEDIUM,
              }}
              onClick={() => {
                onDismiss(item.Id);
                onClose?.();
              }}
            >
              {item.EntityType}
            </Link>
          </Box>
        }
        secondary={
          <>
            <Box
              component='div'
              dangerouslySetInnerHTML={{ __html: item.Content }}
            ></Box>
            <Box
              component='div'
              sx={{
                fontSize: 10,
                fontWeight: Fonts.MEDIUM,
                mb: 0.5,
                color: '#b5b6b6',
                mr: 1,
                mt: 2,
              }}
            >
              {isoDateToDayAgo(item.ModelCreatedAt)}
            </Box>
          </>
        }
        primaryTypographyProps={{
          component: 'div',
        }}
        secondaryTypographyProps={{
          component: 'div',
          sx: { paddingRight: 4 },
        }}
      />
    </ListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
