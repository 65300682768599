import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import StatGraphs from './StatGraphs';
import AppCard from '@tenant/core/AppCard';
import Link from '@mui/material/Link';
import EntitySelectDialog from './EntitySelectDialog';
import {TenantEntityEnumKey} from './constants/enum';
import {formatDate} from '@tenant/utility/helper/DateHelper';
import {useDispatch, useSelector} from 'react-redux';
import LineChartSkeleton from '@tenant/core/AppSkeleton/LineChartSkeleton';
import {DateMenu} from '@tenant/core/App/Date/DateMenu';
import {getRangeDateByKeyName} from '@tenant/utility/helper/Utils';
import DescriptionTooltip from '@tenant/core/App/DescriptionTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from '@mui/material';
import {setFieldTenantDetail} from 'redux/actions';
import jwtAxios from '@tenant/services/auth/jwt-auth';

const FIELD_NAME = 'ENTITY_CREATED';
const DATE_RANGE_FORMAT = 'Last 30 days';

const Entity = ({tenantId}) => {
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD_NAME] ?? null,
    [tenant],
  );
  const {data, filter} = storeTenant ?? {};
  const {
    entities = [1, 2, 3, 4],
    rangeDate = DATE_RANGE_FORMAT,
    dateGroupType = 0,
    dataLoaded,
  } = filter ?? {};
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [defaultEntities, setDefaultEntities] = useState(entities);

  const setFilter = (payload) => {
    dispatch(
      setFieldTenantDetail({
        tenantId,
        field: FIELD_NAME,
        data: data,
        filter: {
          entities,
          rangeDate,
          dateGroupType,
          ...payload,
        },
      }),
    );
  };

  const getEntityStatistics = async (filter) => {
    try {
      const {StartDate, EndDate, EntitityLabelIds, dateGroupType} =
        filter ?? {};
      if (!StartDate || !EndDate || !EntitityLabelIds) {
        return;
      }
      setIsLoading(true);
      setDefaultEntities(EntitityLabelIds);

      const data = await jwtAxios.get('api/Tenant/GetTenantEntityUsage', {
        params: {
          StartDate: formatDate(StartDate),
          EndDate: formatDate(EndDate),
          EntitityLabelIds,
          DateGroupType: dateGroupType,
          tenantId,
        },
        paramsSerializer: {indexes: null},
      });

      dispatch(
        setFieldTenantDetail({
          tenantId,
          field: FIELD_NAME,
          data: data?.data,
          filter: {
            rangeDate,
            entities: EntitityLabelIds,
            dateGroupType,
            dataLoaded: true,
          },
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectionType = (data) => {
    const {startDate, endDate, dateGroupType, keyName} = data || {};
    setFilter({rangeDate: keyName, dateGroupType});
    getEntityStatistics({
      EntitityLabelIds: entities,
      dateGroupType,
      StartDate: startDate,
      EndDate: endDate,
      rangeDate: keyName,
    });
  };

  const getReport = () => {
    const {start, end, dateGroupType} = getRangeDateByKeyName(rangeDate);
    getEntityStatistics({
      StartDate: start,
      EndDate: end,
      EntitityLabelIds: entities,
      dateGroupType,
      rangeDate,
    });
  };

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setFilter({entities: defaultEntities});
  };

  const onSave = (value) => {
    if (!value?.length) {
      return;
    }
    setFilter({entities: value});
    setOpen(false);
    getReport();
  };

  useEffect(() => {
    if (dataLoaded) {
      return;
    }
    getReport();
  }, []);

  return (
    <AppCard
      sxStyle={{height: 1}}
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>Entity Usage</Box>
          <Box>
            <DescriptionTooltip
              icon={<InfoOutlinedIcon sx={{fontSize: 20}} />}
              title={'analytics.entity.tooltip.caption'}
            />
          </Box>
        </Box>
      }
      action={
        <>
          <Link href='#' onClick={onOpen} underline='none'>
            {entities?.map((e) => TenantEntityEnumKey[e])?.join(', ')}
          </Link>
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleSelectionType}
          />
        </>
      }
    >
      {isLoading && <LineChartSkeleton />}

      {!isLoading && (
        <>
          <EntitySelectDialog
            open={open}
            onClose={onClose}
            onSave={onSave}
            entities={entities}
            onSetEntities={(data) => setFilter({entities: data})}
          />

          <StatGraphs data={data} dateGroupType={dateGroupType} />
        </>
      )}
    </AppCard>
  );
};
export default Entity;

Entity.defaultProps = {
  data: [],
};

Entity.propTypes = {
  data: PropTypes.array,
  tenantId: PropTypes.string,
};
