import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import IntlMessages from '@tenant/utility/IntlMessages';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {Fonts} from 'shared/constants/AppEnums';
import {statusData} from 'shared/constants/AppsContants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useAuthUser} from '@tenant/utility/AuthHooks';
import {formatDate} from '@tenant/utility/helper/DateHelper';
import Divider from '@mui/material/Divider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const StatusButton = styled(Button)(({theme}) => ({
  fontSize: 12,
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}));

const StyledDoneIcon = styled(DoneIcon)(({theme}) => ({
  marginRight: 4,
  fontSize: 18,
  verticalAlign: 'middle',
  [theme.breakpoints.up('sm')]: {
    marginRight: 8,
  },
}));

const StatusToggleButton = ({
  selectedTask,
  onClickApproveButton,
  loadingBtn,
  children,
  needConfirm,
  progress = 1,
  allowApprove = true,
  title = 'Approve Item'
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeTaskStatus = () => {
    onClickApproveButton(() => {
      handleClose();
    });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: 700, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{fontSize: 20}}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            loading={loadingBtn}
            color='success'
            onClick={onChangeTaskStatus}
          >
            Approve
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {selectedTask.Status ? (
        <Typography
          component='h2'
          variant='h2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 3.5,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
            mb: {xs: 3, sm: 0},
            color: statusData[selectedTask.Status]
              ? statusData[selectedTask.Status]?.Color
              : '#0A8FDC',
          }}
        >
          {statusData[selectedTask.Status]
            ? statusData[selectedTask.Status]?.Name
            : ''}
        </Typography>
      ) : null}
      {allowApprove && !selectedTask.Status && (
        <StatusButton
          variant='outlined'
          sx={{
            color: statusData[selectedTask.Status]?.Color,
            borderColor: statusData[selectedTask.Status]?.Color,
            ':hover': {
              bgcolor: 'rgba(255, 204, 153, 0.08)',
              borderColor: 'rgba(255, 204, 153, 0.7)',
            },
          }}
          startIcon={<StyledDoneIcon />}
          onClick={needConfirm ? handleClickOpen : onChangeTaskStatus}
          disabled={progress < 1}
        >
          <IntlMessages id='publicapi.markAsApprove' />
        </StatusButton>
      )}
      
    </>
  );
};

export default StatusToggleButton;

StatusToggleButton.propTypes = {
  selectedTask: PropTypes.object.isRequired,
};
