import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import useModal from '@tenant/utility/hooks/useModal';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import CloseIcon from '@mui/icons-material/Close';
import AppTooltip from '@tenant/core/AppTooltip';
import RemoveAuditDetail from './RemoveAuditDetail';
import { deepClone } from '@tenant/utility/helper/Utils';
import { DEFAULT_VALUE_JSON } from '../../contants/enums';

const AuditTab = ({
  data,
  loading,
  contract,
  contractId,
  tenantId,
  isEndSubscription,
  onGetContractAndInvoice,
}) => {
  const {
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    onShow: onShowDrawer,
  } = useModal();
  const [auditItem, setAuditItem] = useState();

  const handleShowDetail = useCallback(
    (item) => {
      setAuditItem(deepClone(item));
      onShowDrawer();
    },
    [onShowDrawer],
  );

  const columns = useMemo(
    () => [
      {
        field: 'Type',
        headerName: 'Type',
        flex: 1,
        sortable: false,
        renderCell: (params) => params.value,
      },
      // {
      //   field: 'Status',
      //   headerName: 'Status',
      //   sortable: false,
      //   width: 125,
      //   renderCell: (params) => (
      //     <Chip
      //       color={
      //         params.value === AUDIT_STATUS_ENUM['pending']
      //           ? 'primary'
      //           : 'success'
      //       }
      //       label={params.value}
      //       size='small'
      //       sx={{
      //         '& .MuiChip-label': { textTransform: 'capitalize', fontSize: 12 },
      //       }}
      //     />
      //   ),
      // },
      {
        field: 'OldValue',
        headerName: 'Old Value',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => {
          let formattedJson;
          try {
            if (params.value) {
              const json = JSON.parse(params.value);
              formattedJson =
                typeof json === DEFAULT_VALUE_JSON
                  ? DEFAULT_VALUE_JSON
                  : params.value;
            }
          } catch (error) {
            formattedJson = params.value;
          }
          return (
            <Tooltip
              title={
                formattedJson === DEFAULT_VALUE_JSON
                  ? 'Data change is big, click more to watch detail'
                  : ''
              }
            >
              <Box
                component='pre'
                sx={{
                  textAlign: 'right',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  color: 'secondary.main',
                }}
              >
                {formattedJson}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'NewValue',
        headerName: 'New Value',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => {
          let formattedJson;
          try {
            if (params.value) {
              const json = JSON.parse(params.value);
              formattedJson =
                typeof json === DEFAULT_VALUE_JSON
                  ? DEFAULT_VALUE_JSON
                  : params.value;
            }
          } catch (error) {
            formattedJson = params.value;
          }
          return (
            <Tooltip
              title={
                formattedJson === DEFAULT_VALUE_JSON
                  ? 'Data change is big, click more to watch detail'
                  : ''
              }
            >
              <Box
                component='pre'
                sx={{
                  textAlign: 'right',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  color: 'secondary.main',
                }}
              >
                {formattedJson}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'ActionBy',
        headerName: 'Requested By',
        sortable: false,
        width: 175,
      },
      {
        field: 'CreatedAt',
        headerName: 'Requested At',
        sortable: false,
        width: 175,
        renderCell: (params) =>
          formatDateFromISO(params.value, inputFormatDateTime),
      },
      {
        field: 'Action',
        headerName: '',
        sortable: false,
        width: 100,
        renderCell: (params) => (
          <Button variant='text' onClick={() => handleShowDetail(params.row)}>
            More
          </Button>
        ),
      },
    ],
    [handleShowDetail],
  );

  return (
    <Box sx={{ height: 450 }}>
      <Drawer
        anchor={'right'}
        PaperProps={{
          sx: { width: 500 },
        }}
        open={visibleDrawer}
        onClose={onCloseDrawer}
      >
        <AppsHeader>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
          >
            <Typography variant='h3' component='div'>
              Audit Detail
            </Typography>

            <AppTooltip title='Close'>
              <IconButton onClick={onCloseDrawer}>
                <CloseIcon />
              </IconButton>
            </AppTooltip>
          </Box>
        </AppsHeader>
        <RemoveAuditDetail
          audit={auditItem}
          contractId={contractId}
          tenantId={tenantId}
          subscriptionEnd={contract?.EndDate}
          maxNumberOfUsers={contract?.MaximumNumberOfUsers}
          isEndSubscription={isEndSubscription}
          onGetContractAndInvoice={async () => {
            await onGetContractAndInvoice?.(contractId);
            onCloseDrawer();
          }}
        />
      </Drawer>

      <DataGrid
        loading={loading}
        hideFooter
        disableColumnSelector
        disableColumnMenu
        rows={data}
        columns={columns}
        getRowId={(row) => row.CreatedAt}
      />
    </Box>
  );
};

export default React.memo(AuditTab);

AuditTab.propTypes = {};
