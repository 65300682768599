export const ORDER_MODEL = {
  Email: {
    asc: 1,
    desc: 2
  },
  TenantName: {
    asc: 3,
    desc: 4,
  },
  Industry: {
    asc: 5,
    desc: 6,
  },
  PointsUsage: {
    asc: 7,
    desc: 8,
  },
  PointsBalance: {
    asc: 9,
    desc: 10,
  },
};