import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, InputAdornment, TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useModal from '@tenant/utility/hooks/useModal';
import {DatePicker} from '@mui/x-date-pickers';
import {formatDate} from '@tenant/utility/helper/DateHelper';
import {inputFormat, inputFormatMUI} from '../../constants';
import moment from 'moment';

const EntityStatisticChooseFilter = ({value, onChange}) => {
  const dateRef = useRef();

  return (
    <>
      <Box
        fontWeight={'bold'}
        component='span'
        onClick={() => dateRef.current.querySelector('button').click()}
      >
        Up to
      </Box>
      <DatePicker
        value={value ?? null}
        onChange={(value) => {
          onChange(value);
        }}
        ref={dateRef}
        inputFormat={inputFormatMUI}
        maxDate={new moment()}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              maxWidth: 150,
              '& fieldset': {
                border: 0,
              },
            }}
            onClick={() => dateRef.current.querySelector('button').click()}
          />
        )}
      />
    </>
  );
};

export default React.memo(EntityStatisticChooseFilter);

EntityStatisticChooseFilter.propTypes = {};
