import React from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import FormCreateVehicle from './FormCreateVehicle';
import { LoadingButton } from '@mui/lab';
import { validationSchema } from '../../helpers';
import IntlMessages from '@tenant/utility/IntlMessages';

function CreateVehicle({ open, handleClose, createVehicle, tenantId }) {

  const initialValues = {
    RegistrationNumber: '',
    Make: '',
    Model: '',
    VIN: '',
    EngineerId: '',
    TrackingDeviceIMEI: '',
    AdditionalInfo: '',
    MOTDueDate: null,
    ServiceDueDate: null,
    LastKnownLocation: '',
    MaximumMileage: 0,
    RoadFundLicenceDueDate: null,
    LeaseRenewalDueDate: null,
  };

  const onSubmit = (values) => {
    if(values.data.EngineerId === ''){
      delete values.data.EngineerId
    }
    createVehicle(values.data);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle><Typography fontSize={20} fontWeight={500}>Create Vehicle</Typography></DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            onSubmit({ data, setSubmitting, resetForm });
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
          }) => (
            <>
              <Form style={{ height: '100%' }} noValidate autoComplete='off'>
                <Box
                  sx={{
                    minHeight: '100%',
                    p: 4,
                  }}
                >
                  <FormCreateVehicle
                    isSubmitting={isSubmitting}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    tenantId={tenantId}
                  />
                </Box>
                <div
                  style={{
                    textAlign: 'right',
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#ffffff',
                    zIndex: 1,
                  }}
                > 
                  <Button variant='outlined' color='error' onClick={handleClose}>Cancel</Button>
                  <LoadingButton
                    sx={{
                      m: 4,
                      minWidth: 100,
                    }}
                    color='primary'
                    variant='outlined'
                    disabled={isSubmitting}
                    type='submit'
                  >
                    <IntlMessages id='common.submit' />
                  </LoadingButton>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default CreateVehicle;
