import React from 'react';
import AppTooltip from '@tenant/core/AppTooltip';
import {useIntl} from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from '@mui/material';

const TOOLTIP_KEY_INT = 'analytics.serviceInvoiceComposition.tooltip';

const DescriptionTooltip = () => {
  const {messages} = useIntl();
  return (
    <AppTooltip
      arrow
      title={
        <Box>
          <Box component='p'>{messages[`${TOOLTIP_KEY_INT}.caption`]}</Box>
        </Box>
      }
    >
      <InfoOutlinedIcon sx={{fontSize: 20}} />
    </AppTooltip>
  );
};

export default React.memo(DescriptionTooltip);

DescriptionTooltip.propTypes = {};
