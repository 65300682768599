import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import AppTooltip from '@tenant/core/AppTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppCard from '@tenant/core/AppCard';
import AppSelect from '@tenant/core/AppSelect';
import AppEmptyResult from '@tenant/core/AppEmptyResult';
import CustomActiveShapePieChart from '@tenant/core/App/Chart/PieChart/CustomActiveShapePieChart';
import { Code } from 'react-content-loader';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { onGetSubcontractorIndustry } from 'redux/actions';
import {
  ENTITY_SUBCONTRACTOR_ENUM,
  ENTITY_SUBCONTRACTOR_ENUM_KEY
} from '@tenant/utility/constants/enum';

const Industry = () => {
  const { type, data } = useSelector(({ subcontractorIndustry }) => subcontractorIndustry);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState({ type });

  const onSelectionType = useCallback(
    (data) => {
      const medium = { 
        ...config, 
        type: ENTITY_SUBCONTRACTOR_ENUM[data]
      };
      setConfig(medium);
      getStatistic(medium);
      return medium;
    },
    [config],
  );

  const getStatistic = useCallback(async ({ type } = config) => {
    try {
      setIsLoading(true);
      await dispatch(onGetSubcontractorIndustry({ type }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data?.length <= 0) getStatistic();
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      action={
        <AppSelect
          menus={Object.keys(ENTITY_SUBCONTRACTOR_ENUM)}
          onChange={onSelectionType}
          defaultValue={ENTITY_SUBCONTRACTOR_ENUM_KEY[type]}
        />
      }
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{paddingRight: 5 }}>{messages['subcontractor.industry']}</span>
          <AppTooltip title={<p style={{ padding: 10 }}>{messages['subcontractor.industry.tooltip']}</p>} arrow>
            <InfoOutlinedIcon sx={{ fontSize: 20 }}/>
          </AppTooltip>
        </Box>
      }
    >
      {
        isLoading 
        ? <Code /> 
        : <>
            {
              data?.length > 0 
              ? <div style={{ width: '100%' }}>
                  <CustomActiveShapePieChart data={data} height={490} legendClass={'two-columns'} unit={'Total'} />
                </div>
              : <AppEmptyResult title='Empty' />
            }
          </>
      }
    </AppCard>
  );
};

export default React.memo(Industry);
