import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useAuthUser } from '../AuthHooks';

const useConnectionNotification = ({ accessToken, url }) => {
  const [connection, setConnection] = useState();
  const { isAuthenticated } = useAuthUser();

  useEffect(() => {
    const initialConnection = () => {
      const result = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => accessToken,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(result);
    };
    if (isAuthenticated) {
      initialConnection();
    }
  }, [isAuthenticated]);

  return connection;
};

export default useConnectionNotification;
