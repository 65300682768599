import React, { useState, useMemo, useCallback, useEffect, useRef, useId } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

import { TRANSACTION_TYPES } from '@tenant/utility/constants/enum';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TransactionTypeMultiSelect = ({
  checkedTypes,
  handleSelectType,
  limitTags = 3,
}) => {
  const [checkedValues, setCheckedValues] = useState(checkedTypes);
  const id = useId();

  return (
    <Box>
      <Box fontWeight='500' component='p' mb={2}>
        Transaction Type
      </Box>
      <Autocomplete
        multiple
        size='small'
        id={`transaction-type-multi-select-${id}`}
        limitTags={limitTags}
        options={TRANSACTION_TYPES}
        disableCloseOnSelect
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) =>
          (option.Id === value.Id)
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.Id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
          </li>
        )}
        value={checkedValues}
        renderInput={(params) => (
          <TextField {...params} placeholder='Select Transaction Type(s)' />
        )}
        onChange={(e, value) => {
          setCheckedValues(value);
          handleSelectType(value);
        }}
      />
    </Box>
  );
};

export default React.memo(TransactionTypeMultiSelect);