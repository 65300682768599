import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  PRODUCT_REQUEST_LOADING,
  GET_PRODUCT_REQUESTS,
  GET_REQUEST_DETAIL,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { API } from 'pages/apps/ProductUsage/constants';
import { loadingKeys } from 'shared/constants/AppsContants';
import { LoadingKeyDetail } from '../../pages/apps/ProductUsage/constants';

export const getProductRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: true,
        },
      });

      const response = await jwtAxios.get(API.ADD_PRODUCT_REQUEST);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PRODUCT_REQUESTS, payload: response.data });
        dispatch({
          type: PRODUCT_REQUEST_LOADING,
          payload: {
            key: loadingKeys.getList,
            value: false,
          },
        });
        return;
      }

      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: false,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: false,
        },
      });
    }
  };
};

export const onGetProductRequests = ({
  status = [],
  tenants = [],
  pageIndex = 1,
  pageSize = 10,
  searchTerm = '',
  filter = [],
  orderBy = 0,
}) => {
  const { messages } = appIntl();
  let payload = {
    PageSize: pageSize,
    PageIndex: pageIndex,
    SearchTerm: searchTerm,
  };

  if (status?.length) {
    payload['Status'] = [];
    status.forEach((item) => {
      payload['Status'].push(Number(item));
    });
  }

  if (tenants?.length) {
    payload['TenantIds'] = tenants.map((t) => t.TenantId).join(', ');
  }

  if (filter?.length) {
    payload['DynamicFilters'] = filter.map((t) => ({
      Field: t.field,
      Operator: t.operator,
      Value: t.value,
    }));
  }

  if (!!orderBy) {
    payload['OrderBy'] = orderBy;
  }

  return async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: true,
        },
      });

      const response = await jwtAxios.post(API.GET_ALL_REQUESTS, payload);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PRODUCT_REQUESTS, payload: response.data });
        dispatch({
          type: PRODUCT_REQUEST_LOADING,
          payload: {
            key: loadingKeys.getList,
            value: false,
          },
        });
        return;
      }

      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: false,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: loadingKeys.getList,
          value: false,
        },
      });
    }
  };
};

export const onGetRequestDetail = (id) => {
  const { messages } = appIntl();

  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: LoadingKeyDetail,
          value: true,
        },
      });

      const response = await jwtAxios.get(`${API.ADD_PRODUCT_REQUEST}/${id}`);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_REQUEST_DETAIL, payload: response.data });
        dispatch({
          type: PRODUCT_REQUEST_LOADING,
          payload: {
            key: LoadingKeyDetail,
            value: false,
          },
        });
        return;
      }

      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });

      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: LoadingKeyDetail,
          value: false,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({
        type: PRODUCT_REQUEST_LOADING,
        payload: {
          key: LoadingKeyDetail,
          value: false,
        },
      });
    }
  };
};
