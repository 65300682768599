import React, { useCallback, useId, useState } from 'react';
import { debounce } from '@tenant/utility/helper/Utils';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import useTenant from 'pages/apps/hooks/useTenant';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { CircularProgress } from '@mui/material';

const TenantSelect = ({
  error = false,
  helperText = '',
  value,
  variant = 'standard',
  placeholder = 'Tenants',
  gocardlessOnly = false,
  isHideLabel = false,
  isShowAdditionalInfo = false,
  infoProp = '',
  required = false,
  disableClearable = true,
  ...rest
}) => {
  const { dropdownTenants } = useSelector(({ tenant }) => tenant);
  const { loadingTenants, getDropdownTenants } = useTenant();
  const id = useId();
  const [search, setSearch] = useState('');
  const [tenants, setTenants] = useState(deepClone(dropdownTenants));
  const [initialData, setInitialData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getTenants = (params = {}) => {
    getDropdownTenants(params, (data) => {
      setTenants(data);
      setIsLoaded(true);

      if (!params?.searchTerm) setInitialData(data);
    });
  };

  const onOpen = useCallback(() => {
    if (loadingTenants || isLoaded) return;

    getTenants({
      gocardlessOnly: gocardlessOnly,
    });
  }, [tenants, loadingTenants]);

  const onInputTenantChange = debounce((e, value) => {
    if (e?.type === 'change') {
      setTenants([]);
      getTenants({
        searchTerm: value,
        gocardlessOnly: gocardlessOnly,
      });
      setSearch(value);
    }
  }, 700);

  const handleBlur = useCallback(() => {
    if (!!search) {
      setSearch('');
      setTenants(initialData);
    }
  }, [search, initialData]);

  return (
    <Autocomplete
      id={`tenant-select-${id}`}
      name='tenant'
      disableClearable={disableClearable}
      options={tenants || []}
      getOptionLabel={(opt) => {
        if (isShowAdditionalInfo) {
          return !!opt[infoProp]
            ? `${opt?.Name} (${opt?.[infoProp]})`
            : opt?.Name;
        }
        return opt?.Name;
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.TenantId === value?.TenantId;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.TenantId}>
          {isShowAdditionalInfo
            ? !!option[infoProp]
              ? `${option.Name} (${option[infoProp]})`
              : option.Name
            : option.Name}
        </li>
      )}
      loading={loadingTenants}
      renderInput={(params) => (
        <TextField
          {...params}
          label={isHideLabel ? null : placeholder}
          variant={variant}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingTenants ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onOpen={onOpen}
      onInputChange={onInputTenantChange}
      onBlur={handleBlur}
      value={value}
      {...rest}
    />
  );
};

export default React.memo(TenantSelect);
