import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import LazyLoad from 'react-lazy-load';
import AppCard from '@tenant/core/AppCard';
import AppSelect from '@tenant/core/AppSelect';
import XeroInvoices from '../Invoice/XeroInvoices';
import InvoiceProfessional from '../Invoice/InvoiceProfessional';
import {dateRanges} from '@tenant/utility/constants/default';
import {formatDate} from '@tenant/utility/helper/DateHelper';

const InvoiceTab = ({tenantId}) => {
  return (
    <Grid container spacing={8}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          '& .LazyLoad': {
            height: '100%',
          },
        }}
      >
        <XeroInvoices tenantId={tenantId} />
      </Grid>
      <Grid item xs={12} md={12}>
        <LazyLoad offset={100}>
          <InvoiceProfessional tenantId={tenantId} />
        </LazyLoad>
      </Grid>
    </Grid>
  );
};

export default React.memo(InvoiceTab);

InvoiceTab.propTypes = {
  tenantId: PropTypes.string,
};
