import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  getFontSizeLabelChart,
  numberToCurrency,
} from '@tenant/utility/helper/Utils';
import {Box} from '@mui/system';

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload?.length > 0) {
    return (
      <Box
        className='custom-tooltip'
        backgroundColor='white'
        p={2}
        sx={{border: '1px solid #ddd'}}
      >
        <Box p={1} fontWeight={600}>
          {label}
        </Box>
        {payload.map((e) => (
          <Box key={e.dataKey} p={1} color={e.fill}>
            {`${e.name} : ${numberToCurrency(e.payload[e.dataKey])}`}
          </Box>
        ))}
      </Box>
    );
  }
};

const InvoiceProfessionalChart = ({data}) => {
  return (
    <ResponsiveContainer width='100%' height={350}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 25,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='Key'
          tick={{fontSize: getFontSizeLabelChart(data?.length ?? 0)}}
        />
        <YAxis tickFormatter={(value) => numberToCurrency(value, 0)} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          dataKey='ProServiceSubTotal'
          name='Professional'
          stackId='a'
          fill='#28B463'
        />
        <Bar
          dataKey='NonProServiceSubTotal'
          name='Non Professional'
          stackId='a'
          fill='#F39C12'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(InvoiceProfessionalChart);

InvoiceProfessionalChart.propTypes = {
  data: PropTypes.array,
};
