import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Code } from 'react-content-loader';
import { Box } from '@mui/material';

import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { RANGE_DATE } from '../constants';
import { dateRanges, dateGroupTypes } from '@tenant/utility/constants/default';
import PointUsageChart from './PointUsageChart';
import DescriptionTooltip from './PointUsageChartTooltip';
import { API, INDEX_COLOR } from 'pages/apps/ProductUsage/constants';

const PointUsageStatistic = ({ tenantId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [chartDateGroupType, setChartDateGroupType] = useState(
    dateGroupTypes[RANGE_DATE.THIS_YEAR],
  );

  useEffect(() => {
    getData();
  }, []);

  const handleChangeDateRange = (data) => {
    const { keyName, dateGroupType } = data || {};
    setRangeDate(keyName);
    setChartDateGroupType(dateGroupType);
    getData(keyName);
  };

  const getData = useCallback(
    async (selectedKey) => {
      try {
        setIsLoading(true);

        const { start, end, dateGroupType } = getRangeDateByKeyName(selectedKey || rangeDate);
        const response = await jwtAxios.get(API.GET_TENANT_POINTS_USAGE_OVER_PERIOD, {
          params: {
            StartDate: start,
            EndDate: end,
            TenantId: tenantId,
            StatisticDateGroupType: dateGroupType,
          },
        });

        setData(response?.data?.ObjectData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate]
  );

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>Points Usage</Box>
          <Box>
            <DescriptionTooltip />
          </Box>
        </Box>
      }
      sxStyle={{
        height: '100%',
      }}
      action={
        <DateMenu
          defaultValue={rangeDate}
          handleChangeValue={handleChangeDateRange}
        />
      }
    >
      {isLoading && <Code />}

      {!isLoading && (
        <>
          {/* <Box flex={1} display='flex' justifyContent='center'>
            <Box sx={{ m: 2, ml: 0, color: INDEX_COLOR[1] }}>
              <Box component='strong' mr={1}>
                Invoice:
              </Box>
              {data?.TotalXeroPoint}
            </Box>
            <Box sx={{ m: 2, color: INDEX_COLOR[0] }}>
              <Box component='strong' mr={1}>
                Technical Team:
              </Box>
              {data?.TotalJLContractorPoint}
            </Box>
          </Box> */}
          <PointUsageChart data={data} dateGroupType={chartDateGroupType} />
        </>
      )}
    </AppCard>
  );
};

PointUsageStatistic.propTypes = {
  tenantId: PropTypes.string.isRequired,
};

export default React.memo(PointUsageStatistic);
