import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import useModal from '@tenant/utility/hooks/useModal';
import { Divider, IconButton, Tooltip } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import useMappingService from '../hooks/useMappingService';
import { LoadingButton } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DisconnectButton = ({ mapping, onGetExistingMappings }) => {
  const [isDeactivate, setIsDeactivate] = useState(mapping?.IsSubby);
  const [isDeleteLibrary, setIsDeleteLibrary] = useState(true);
  const { visible, onShow, onClose } = useModal();
  const { loadingBtn, disconnectTenant, disconnectTenantByMappingId } =
    useMappingService();

  const onSuccess = useCallback((mapping) => {
    onClose();
    onGetExistingMappings(mapping?.MainTenantId);
  }, []);

  return (
    <>
      {mapping?.RequestId || (mapping?.MappingId && mapping?.MainTenantId) ? (
        <Tooltip title='Disconnect mapping'>
          <IconButton color='error' onClick={onShow}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled color='error' onClick={onShow}>
          <LinkOffIcon />
        </IconButton>
      )}

      {visible && (
        <Dialog
          fullWidth
          maxWidth='sm'
          open={visible}
          TransitionComponent={Transition}
          keepMounted
          onClose={onClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle fontSize={16}>{'Disconnect Mapping?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Are you sure want to disconnect this mapping between Main
              Contractor{' '}
              <Box
                component={'span'}
                sx={{ color: 'primary.main', fontWeight: 550 }}
              >
                {mapping?.MainTenantName}
              </Box>{' '}
              and Subcontractor{' '}
              <Box
                component={'span'}
                sx={{ color: 'secondary.main', fontWeight: 550 }}
              >
                {mapping?.SubTenantName}
              </Box>
              ?
            </DialogContentText>

            <Divider sx={{ mt: 3 }} />

            {mapping?.IsSubby && (
              <FormControlLabel
                disabled={!mapping?.IsSubby}
                control={<Checkbox checked={isDeactivate} />}
                label={
                  <>
                    Deactivate this Subcontractor{' '}
                    <Box
                      component={'span'}
                      fontWeight={'bold'}
                      sx={{ color: 'secondary.main' }}
                    >
                      {mapping?.SubTenantName}
                    </Box>{' '}
                  </>
                }
                onChange={(e) => setIsDeactivate(e.target.checked)}
              />
            )}
            <FormControlLabel
              control={<Checkbox checked={isDeleteLibrary} />}
              label={<>Remove the subcontractor Library after disconnect.</>}
              onChange={(e) => setIsDeleteLibrary(e.target.checked)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={onClose}>
              Close
            </Button>
            <LoadingButton
              loading={loadingBtn}
              variant='contained'
              color='error'
              onClick={() => {
                if (mapping?.RequestId) {
                  disconnectTenant(
                    {
                      requestId: mapping?.RequestId,
                      isSubbyTenant: isDeactivate,
                      deleteSubContractorLibrary: isDeleteLibrary,
                    },
                    () => {
                      onSuccess(mapping);
                    },
                  );
                  return;
                }

                disconnectTenantByMappingId(
                  {
                    mappingId: mapping?.MappingId,
                    mainTenantId: mapping?.MainTenantId,
                    isSubbyTenant: isDeactivate,
                    deleteSubContractorLibrary: isDeleteLibrary,
                  },
                  () => {
                    onSuccess(mapping);
                  },
                );
              }}
            >
              Yes, Disconnect it!
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(DisconnectButton);

DisconnectButton.propTypes = {
  mapping: PropTypes.object,
  onGetExistingMappings: PropTypes.func,
};
