import React, { isValidElement, useState, } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import EditButton from './EditButton';
import { CiEdit } from "react-icons/ci";
import StatusToggleButton from './StatusToggleButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

import IntlMessages from '@tenant/utility/IntlMessages';
import AppConfirmDialog from '@tenant/core/AppConfirmDialog';
import AppTooltip from '@tenant/core/AppTooltip';

const FormHeader = ({
  selectedTask,
  leftSideContent,
  rightSideContent,
  onOpenApproveTask,
  handleGoBack,
  editFormLink,
  goBackLink = '',
  allowApprove = false,
  allowEdit = false,
}) => {
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const onClickEditButton = () => {
    navigate(editFormLink);
  };

  const onGoBack = () => {
    navigate(goBackLink || -1);
    handleGoBack?.();
  };

  return (
    <>
      {/* Go Back button */}
      <Box
        sx={{
          cursor: 'pointer',
        }}
        component='span'
        mr={{ xs: 2, sm: 4 }}
        onClick={() => onGoBack()}
      >
        <AppTooltip title={<IntlMessages id='common.back' />}>
          <ArrowBackIcon
            sx={{
              color: 'text.secondary',
            }}
          />
        </AppTooltip>
      </Box>

      {isValidElement(leftSideContent) ? (
        leftSideContent
      ) : (selectedTask
        ? <StatusToggleButton
          allowApprove={allowApprove}
          selectedTask={selectedTask}
          onClickApproveButton={
            () => onOpenApproveTask()
          }
        />
        : null
      )}

      {isValidElement(rightSideContent) ? (
        <Box
          component='span'
          sx={{
            marginLeft: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {rightSideContent}
        </Box>
      ) : (
        <>
          <Box
            component='span'
            sx={{
              marginLeft: 'auto',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            {allowEdit ? (
              <EditButton
                action={onClickEditButton}
                title={
                  <>
                    <CiEdit />
                    <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                      Edit
                    </span>
                  </>
                }
              />
            ) : null}
          </Box>
        </>
      )}
    </>
  );
};

FormHeader.propTypes = {
  selectedTask: PropTypes.object,
  leftSideContent: PropTypes.node,
  rightSideContent: PropTypes.node,
  onOpenApproveTask: PropTypes.func,
  editFormLink: PropTypes.string,
  allowApprove: PropTypes.bool,
  allowEdit: PropTypes.bool,
  goBackLink: PropTypes.string,
  handleGoBack: PropTypes.func,
};

export default React.memo(FormHeader);

