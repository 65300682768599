import React, { isValidElement, } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import IntlMessages from '@tenant/utility/IntlMessages';

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(4),
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
  borderRadius: 8,
  fontSize: '0.875rem',
  fontWeight: '700',
}));

const RequestContentBlock = ({
  sxStyle,
  title,
  titleStyle,
  headerStyle,
  children,
  headerContent,
  ...rest
}) => {

  return (
    <StyledSpan sx={{ ...sxStyle }} {...rest}>
      <Box
        sx={{
          marginBottom: 5,
          ...headerStyle,
        }}
      >
        {isValidElement(headerContent) ? (
          headerContent
        ) : (
          <Typography
            variant="subtitle1"
            component="p"
            sx={{ fontWeight: 500, ...titleStyle }}
          >
            <IntlMessages id={title} />
          </Typography >
        )}

      </Box>

      <Grid container>
        {children}
      </Grid>
    </StyledSpan>
  );
};

RequestContentBlock.propTypes = {
  title: PropTypes.string,
  sxStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  children: PropTypes.node,
  headerContent: PropTypes.node,
};

export default React.memo(RequestContentBlock);

