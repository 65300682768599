import {Grid, Skeleton, Stack} from '@mui/material';

const LineChartSkeleton = () => {
  return (
    <Grid container spacing={6} direction='row' sx={{mt: 3}}>
      <Grid item xs={12}>
        <Stack spacing={4} direction='row' justifyContent='center'>
          {Array.from(Array(2).keys()).map((item) => {
            return (
              <Stack xs={1} spacing={2} direction='row' key={item}>
                <Skeleton variant='circular' width={15} height={15} />
                <Skeleton variant='text' width={40} />
              </Stack>
            );
          })}
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack xs={1} spacing={6} direction='column'>
          {Array.from(Array(6).keys()).map((item) => {
            return <Skeleton variant='text' key={item} />;
          })}
        </Stack>
      </Grid>
      <Grid item xs={11}>
        <Skeleton variant='rectangular' height={250} />
        <Stack spacing={2} direction='row'>
          {Array.from(Array(5).keys()).map((item) => {
            return <Skeleton variant='text' width={'20%'} key={item} />;
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LineChartSkeleton;
