import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useScreenSize = () => {
  const theme = useTheme();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const isTooltipClick = useMediaQuery(theme.breakpoints.down('lg'));
  const isFlexTable = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowDimensions,
    isTooltipClick,
    flexTable: isFlexTable ? 1 : undefined,
  };
};

export default useScreenSize;
