export const API = {
  GET_REACH_CAP_STATISTIC: '/api/JLPointDataAnalyzer/Dashboard/GetTenantsReachingCap',
  GET_REACH_EXPIRY_STATISTIC: '/api/JLPointDataAnalyzer/Dashboard/GetTenantsReachingExpiry',
  GET_COMPANY_PURCHASE_DISTRIBUTION: '/api/JLPointDataAnalyzer/Dashboard/GetCompanyPurchaseDistribution',
  GET_NEW_ACTIVATION: '/api/JLPointDataAnalyzer/Dashboard/TenantsActivation',
  GET_RENEWAL_EVENTS: '/api/Contract/SearchRenewalEvents',
};

export const NEW_ACTIVATION_COLUMNS = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 250,
    headerName: 'Tenant',
    sortable: false,
  },
  {
    field: 'ContractSignedDate',
    minWidth: 180,
    headerName: 'Contract Signed Date',
    sortable: false,
  },
  {
    field: 'SubscriptionEnd',
    minWidth: 130,
    headerName: 'Subscription End',
    sortable: false,
  },
];

export const RENEWAL_DASHBOARD_COLUMNS = [
  {
    field: 'CompanyName',
    headerName: 'Company Name',
    style: {
      width: 200,
      maxWidth: 250,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'ContractName',
    headerName: 'Contract Name',
    style: {
      width: 150,
      maxWidth: 200,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'ContractLength',
    headerName: 'Contract Length',
    style: {
      minWidth: 100,
      backgroundColor: '#FFFFFF',
    },
    align: 'center',
  },
  {
    field: 'SubscriptionEnd',
    headerName: 'Subscription End',
    style: {
      minWidth: 100,
      backgroundColor: '#FFFFFF',
    },
    align: 'center',
  },
  {
    field: 'EventType',
    headerName: 'Event Type',
    style: {
      backgroundColor: '#FFFFFF',
    },
    align: 'center',
  },
  {
    field: 'EventDate',
    headerName: 'Event Date',
    style: {
      backgroundColor: '#FFFFFF',
    },
    align: 'center',
  },
];

export const ROW_PER_PAGE = 10;

export const EVENT_TYPE_ENUMS = {
  GENERATE_DRAFT: 'Generate Draft',
  SEND_ESTIMATED_QUOTE: 'Send Estimated Quote',
  SEND_PAYMENT_COLLECTION: 'Send Payment Collection',
  ACTIVE_DRAFT_CONTRACT: 'Active Draft Contract',
};

export const CONTRACT_LENGTH_ENUMS = {
  '1 Year': 2,
  '2 Years': 3,
  '3 Years': 4,
  '5 Years': 5,
};
