import React, { useMemo } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Typography,
  Tooltip,
} from '@mui/material';
import { currencyFormat } from '@tenant/utility/Utils';
import { DataGrid } from '@mui/x-data-grid';
import { Fonts } from 'shared/constants/AppEnums';
import { calculatorDiscountNumber } from '../../helpers/calculator';

const DetailInvoiceContent = ({ listInvoices, loading }) => {
  const showValSubtotal = useMemo(() => {
    return listInvoices.reduce((accumulator, currentItem) => {
      return parseFloat(accumulator) + parseFloat(currentItem.Amount);
    }, 0);
  }, [listInvoices]);

  const showValVAT = useMemo(() => {
    return listInvoices.reduce((accumulator, currentItem) => {
      return parseFloat(accumulator) + parseFloat(currentItem.TaxAmount);
    }, 0);
  }, [listInvoices]);

  const showValDiscount = useMemo(() => {
    return listInvoices.reduce((accumulator, currentItem) => {
      let discountNumber = 0;
      if (currentItem.DiscountValue > 0) {
        discountNumber = calculatorDiscountNumber(
          currentItem.DiscountValue,
          currentItem.Amount,
        );
      } else if (!!currentItem.DiscountPercent) {
        discountNumber = calculatorDiscountNumber(
          currentItem.DiscountPercent + '%',
          currentItem.Amount,
        );
      } 
      return parseFloat(accumulator) + discountNumber;
    }, 0);
  }, [listInvoices]);

  const showValTotal = useMemo(() => {
    return showValSubtotal - showValDiscount + showValVAT;
  }, [listInvoices]);

  const showDiscountInTable = (params) => {
    if (!params?.value && !params.row.DiscountPercent) {
      return 0;
    } else if (params?.value && !params.row.DiscountPercent) {
      return params.value;
    } else if (!params?.value && params.row.DiscountPercent) {
      return params.row.DiscountPercent + '%';
    }
  };

  const columns = [
    {
      field: 'Item',
      headerName: 'Item',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography noWrap variant='body2'>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography noWrap variant='body2'>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'UnitAmount',
      headerName: 'Unit Amount',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='body2'>{currencyFormat(params.value)}</Typography>
      ),
    },
    {
      field: 'Quantity',
      headerName: 'Quantity',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'DiscountValue',
      headerName: 'Discount Value',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='body2' color='success.main'>
          {showDiscountInTable(params)}
        </Typography>
      ),
    },
    {
      field: 'TaxRateValue',
      headerName: 'Tax Rate Value',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='body2' color='error.main'>
          {params.value + '%'}
        </Typography>
      ),
    },
    {
      field: 'TaxAmount',
      headerName: 'Tax Amount',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant='body2' color='error.main'>
          {currencyFormat(params.value)}
        </Typography>
      ),
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography
          variant='body2'
          color='info.main'
          fontWeight={Fonts.SEMI_BOLD}
        >
          {currencyFormat(params.value)}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          p: 2,
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: 2,
        }}
      >
        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          loading={loading}
          rows={listInvoices ?? []}
          columns={columns}
          getRowId={(row) => row.Id}
          sx={{ height: 250, border: 'none' }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {!loading && (
          <TableContainer
            sx={{
              marginTop: '30px',
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              maxWidth: 350,
            }}
          >
            <Table sx={{ backgroundColor: '#f6f8fa' }}>
              <TableBody>
                <TableRow>
                  <TableCell>Subtotal</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='info.main' align='right'>
                      {currencyFormat(showValSubtotal)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discount</TableCell>
                  <TableCell>
                    <Typography
                      variant='body2'
                      color='success.main'
                      align='right'
                    >
                      {currencyFormat(showValDiscount)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>VAT</TableCell>
                  <TableCell>
                    <Typography
                      variant='body2'
                      color='error.main'
                      align='right'
                    >
                      {currencyFormat(showValVAT)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      color='info.main'
                      fontWeight={Fonts.SEMI_BOLD}
                      align='right'
                    >
                      {currencyFormat(showValTotal)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default DetailInvoiceContent;
