import {SET_FIELD_TENANT_DETAIL} from 'shared/constants/ActionTypes';

export const setFieldTenantDetail = (payload) => {
  const {data, field, tenantId, filter} = payload;
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_TENANT_DETAIL,
      payload: {
        field,
        data,
        tenantId,
        filter,
      },
    });
  };
};
