import { SET_FIELD_SELECT } from 'shared/constants/ActionTypes';

const initialState = {};

const selectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FIELD_SELECT:
      return { ...state, [payload.field]: structuredClone(payload.data) };
    default:
      return state;
  }
};

export default selectReducer;
