import React, { useMemo, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useLocation, useParams, } from 'react-router-dom';

import {
  SET_TENANT_LIST_FILTER,
  SET_TENANT_LIST_PARAMS,
} from 'shared/constants/ActionTypes';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import { getTableColumns, getTableRows } from './helpers';
import { handleDownload } from '@tenant/utility/Utils';
import { ORDER_MODEL } from './constants';
import TenantFilterForm from './TenantFilterForm';
import DataListContainer from 'pages/apps/components/DataListContainer';
import useTenant from 'pages/apps/hooks/useTenant';
import TenantAdd from './TenantAdd';
import AppsContainer from '@tenant/core/AppsContainer';

const initialFilterForm = {
  tenants: [],
  clientGroups: [],
  paymentTypes: [],
  industries: [],
  salePersons: [],
  accountManagers: [],
};

const Tenant = () => {
  const dispatch = useDispatch();
  const { orderBy } = useSelector(({ common }) => common);
  const { filterForm, listParams, } = useSelector(({ tenantList }) => tenantList);
  const {
    loadingBtnExport,
    loadingTenantList,
    getListTenants,
    exportTenants,
    getListTenantsFirstPage,
  } = useTenant();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const location = useLocation();

  const getListDataFirstPage = useCallback(() => {
    resetStorageData();
    getListTenantsFirstPage({
      params: {
        searchTerm: '',
        pageIndex: 1,
        pageSize: 10,
      },
      cbSuccess: (data) => {
        setData(data?.Items ?? []);
        setTotal(data?.TotalCount ?? 0);
      },
    });
  }, []);

  const getListData = useCallback(
    (params) => {
      const {
        pageIndex = 1,
        searchTerm = '',
        pageSize = 10,
        filterList,
      } = params || {};

      getListTenants({
        params: {
          ...params,
          filters: filterList,
          searchTerm,
        },
        cbSuccess: (data) => {
          setData(data?.Items ?? []);
          setTotal(data?.TotalCount ?? 0);
        },
      });

      if (!!params) {
        dispatch({
          type: SET_TENANT_LIST_PARAMS,
          payload: {
            pageIndex,
            pageSize,
            searchTerm,
          }
        });

        dispatch({
          type: SET_TENANT_LIST_FILTER,
          payload: filterList,
        });
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => getTableColumns(),
    []
  );

  const rows = useMemo(
    () => getTableRows(data),
    [data]
  );

  const handleSortData = useCallback((payload) => {
    getListData({
      ...payload,
    });
  }, []);

  const isAddNew = useMemo(
    () => location.pathname === '/tenant/add',
    [location.pathname],
  );

  const handleExport = useCallback(() => {
    exportTenants({
      params: {
        filters: filterForm,
        orderBy: orderBy.tenantList,
        ...listParams,
      },
      cbSuccess: (data) => {
        handleDownload(
          data,
          `Tenant_List_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.csv`,
        );
      },
    })
  }, [filterForm, listParams, orderBy]);

  const resetStorageData = useCallback(() => {
    dispatch({
      type: SET_TENANT_LIST_PARAMS,
      payload: {
        pageIndex: 1,
        pageSize: 10,
        searchTerm: ''
      }
    });

    dispatch({
      type: SET_TENANT_LIST_FILTER,
      payload: initialFilterForm,
    });
  }, []);

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Breadcrumbs sx={{ py: 1 }} aria-label='breadcrumb'>
          <Typography color='text.primary'>Company</Typography>
          <Typography color='text.primary'>
            {isAddNew ? 'Add' : 'List'}
          </Typography>
        </Breadcrumbs>
        {!isAddNew && (
          <LoadingButton
            variant='outlined'
            onClick={handleExport}
            loading={loadingBtnExport}
            startIcon={<FileDownloadIcon />}
          >
            Export
          </LoadingButton>
        )}
      </Stack>

      <DataListContainer
        hasSearchBar
        hasFilterForm
        disableColumnMenu
        isHiddenUI={isAddNew}
        columns={columns}
        rows={rows}
        isLoadingList={loadingTenantList}
        total={total}
        data={data}
        rowsPerPage={10}
        searchBarWidth={620}
        keyOrderBy='tenantList'
        orderModel={ORDER_MODEL}
        filterList={filterForm}
        initialFilterList={initialFilterForm}
        listParams={listParams}
        searchPlaceholder='Tenant / Tenant Id / Tracker Id / Client Group / Account Manager / Sale Person'
        handleGetData={getListData}
        handleSortData={handleSortData}
        handleGetDataFirstPage={getListDataFirstPage}
        renderFilterFormContent={
          (props) => <TenantFilterForm {...props} />
        }
      />

      {isAddNew && (
        <AppsContainer fullView>
          <Box
            sx={{
              transition: 'all 0.5s ease',
              transform: 'translateX(100%)',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              opacity: 0,
              visibility: 'hidden',
              backgroundColor: 'background.paper',
              '&.show': {
                transform: 'translateX(0)',
                opacity: 1,
                visibility: 'visible',
              },
            }}
            className={clsx({
              show: isAddNew,
            })}
          >
            <TenantAdd />
          </Box>
        </AppsContainer>
      )}
    </>
  );
};

export const tenantListConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ['/tenant', '/tenant/add'],
    element: <Tenant />,
  },
];

export default Tenant;
