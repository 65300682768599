const orderDetails = {
  TenantId: "2e343ea6-3b4e-41a2-9656-005ba50cdbbe",
  CompanyName: "Lai's Company",
  Status: 1,
  OrderDate: "11/01/2024 04:57:48",
  Id: 1,
  InvoiceNo: "INVOICE01",
  TotalPoints: 50,
  PaidAmount: 1000.5,
  BundlePurchases: [
    {
      BundleId: 1,
      BundleName: "Micro",
      PurchaseId: 1,
      Points: 15,
      Price: 1875,
    },
    {
      BundleId: 1,
      BundleName: "Micro",
      PurchaseId: 1,
      Points: 15,
      Price: 1875,
    },
  ],
};

export default orderDetails;
