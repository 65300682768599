import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  IconButton,
  Popover,
  Checkbox,
  Badge,
  List,
  FormGroup,
  FormControlLabel,
  Pagination,
  Typography,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { onGetListRequestWorkflow } from 'redux/actions';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_INDEX,
} from '@tenant/utility/constants/default';
import { debounce } from '@tenant/utility/helper/Utils';
import { statusData } from '../constants';
import TenantFilter from '@tenant/core/AdvancedFilter/TenantFilter';
import { getTableInfoColumns } from 'pages/vehicleTracking/request/helpers';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton } from '@mui/lab';
import { handleDownload } from '@tenant/utility/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import useToast from '@tenant/utility/hooks/useToast';

const sortList = {
  ActivityStatus_asc: 0,
  ActivityStatus_desc: 1,
};
const RequestList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const inStockParams = searchParams.get('inStock');

  let location = useLocation();
  const { showErrorMessage } = useToast();

  const { vehicleTrackingInfo } = useSelector((state) => state.requestWorkflow);
  const {
    pageIndex,
    pageSize,
    totalCount,
    data,
    searchTerm,
    activityStatus,
    tenantIds,
  } = vehicleTrackingInfo || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadingBtnDownLoad, setIsLoadingBtnDownLoad] = useState(false);
  const [search, setSearch] = useState(searchTerm ?? '');

  const checked = useRef([]);
  const tenants = useRef([]);
  const deviceInfoOrderBy = useRef(1);

  const getListData = useCallback(
    async (payload) => {
      const {
        pageIndex = DEFAULT_PAGE_INDEX,
        pageSize = DEFAULT_PAGE_SIZE,
        status,
        tenantIds,
        searchTerm,
        deviceInfoOrderBy,
      } = payload || {};
      setIsLoading(true);
      await dispatch(
        onGetListRequestWorkflow({
          url: 'api/DeviceInfo',
          field: 'vehicleTrackingInfo',
          config: {
            params: {
              activityStatus: status,
              pageIndex,
              pageSize,
              searchTerm,
              tenantIds: tenantIds?.map((t) => t.TenantId).join(', '),
              withGroup: inStockParams !== 'true' ? true : false,
              deviceInfoOrderBy,
            },
            paramsSerializer: { indexes: null },
          },
        }),
      );
      setIsLoading(false);
    },
    [dispatch, inStockParams],
  );

  const clearSearchTerm = () => {
    setSearch('');
    getListData({
      pageSize,
      pageIndex: 1,
      searchTerm: '',
      status: checked.current,
      tenantIds: tenants.current,
      deviceInfoOrderBy: deviceInfoOrderBy.current,
    });
  };

  const onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      getListData({
        pageSize,
        pageIndex: 1,
        searchTerm: event.target.value,
        status: checked.current,
        tenantIds: tenants.current,
        deviceInfoOrderBy: deviceInfoOrderBy.current,
      });
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = useMemo(
    () => getTableInfoColumns(inStockParams !== 'true' ? true : false),
    [inStockParams],
  );

  const statusList = useMemo(() => {
    const result = [];
    for (let i in statusData) {
      result.push({
        ...statusData[i],
        Key: i,
      });
    }
    return result;
  }, [statusData]);

  const onChangeStatus = useCallback(
    debounce((statusArray) => {
      getListData({
        pageSize,
        status: statusArray,
        pageIndex: 1,
        searchTerm: search,
        tenantIds: tenants.current,
        deviceInfoOrderBy: deviceInfoOrderBy.current,
      });
    }, 200),
    [search, inStockParams],
  );

  const handleChangeCheckbox = (event, Name) => {
    if (event.target.checked) {
      const list = [...checked.current, Name];
      checked.current = list;
    } else {
      const list = checked.current.filter((e) => e !== Name);
      checked.current = list;
    }
    onChangeStatus(checked.current);
  };

  const onPageChange = useCallback(
    (event, newPage) => {
      if (newPage < 0) return;
      setPage(newPage);

      getListData({
        pageIndex: newPage,
        pageSize,
        searchTerm: search,
        status: checked.current,
        tenantIds: tenants.current,
        deviceInfoOrderBy: deviceInfoOrderBy.current,
      });
    },
    [search, inStockParams],
  );

  const handleClickRow = (params) => {
    if (inStockParams) {
      navigate('/vehicle-info/' + params.row.Imei + '?inStock=true');
    } else {
      navigate('/vehicle-info/' + params.row.Imei);
    }
  };

  const handleChangeTenants = useCallback(
    (value) => {
      tenants.current = value;
      getListData({
        pageSize,
        status: checked.current,
        pageIndex: 1,
        searchTerm: search,
        tenantIds: value,
        deviceInfoOrderBy: deviceInfoOrderBy.current,
      });
    },
    [search, inStockParams],
  );

  const count = useMemo(() => {
    if (totalCount && pageSize) {
      if (totalCount % pageSize) return Math.floor(totalCount / pageSize) + 1;
      return Math.floor(totalCount / pageSize);
    }
    return 1;
  }, [totalCount, pageSize]);

  const isPageList = useMemo(() => {
    return !id && !location.pathname.includes('add');
  }, [id, location]);

  const downloadFile = async () => {
    try {
      setIsLoadingBtnDownLoad(true);
      const { data } = await jwtAxios.get(`api/DeviceInfo/Export`, {
        params: {
          activityStatus: checked.current,
          searchTerm: search,
          tenantIds: tenants.current?.map((t) => t.TenantId).join(', '),
          withGroup: inStockParams !== 'true' ? true : false,
        },
        paramsSerializer: {
          indexes: null,
        },
      });
      handleDownload(
        data,
        `DeviceInfo${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.csv`,
      );
    } catch (err) {
      showErrorMessage(err?.response?.data?.Message);
      console.error(err);
    } finally {
      setIsLoadingBtnDownLoad(false);
    }
  };

  const handleSortList = (sortArray) => {
    if (!Array.isArray(sortArray) || sortArray.length === 0) return;
    const firstSortObject = sortArray[0];
    const sortKey = `${firstSortObject.field}_${firstSortObject.sort}`;
    deviceInfoOrderBy.current = sortList[sortKey];

    getListData({
      pageSize,
      pageIndex: 1,
      searchTerm: search.current,
      status: checked.current,
      tenantIds: tenants.current,
      deviceInfoOrderBy: deviceInfoOrderBy.current,
    });
  };
  useEffect(() => {
    setSearch('');
    checked.current = [];
    tenants.current = [];
    deviceInfoOrderBy.current = 1;
    setPage(1);
    getListData({ deviceInfoOrderBy: deviceInfoOrderBy.current });
  }, [getListData, inStockParams]);

  return (
    <>
      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
        >
          <AppSearch
            isClearable
            searchValue={search}
            onClearSearch={clearSearchTerm}
            iconPosition='right'
            id='outlined-adornment-weight'
            size='small'
            overlap={false}
            onKeyDown={onEnterSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Search IMEI'
          ></AppSearch>
          {!inStockParams && (
            <IconButton
              aria-describedby='filter-popover'
              aria-label='filter'
              onClick={handleClick}
            >
              <Badge
                badgeContent={checked.current.length + tenants.current.length}
                color='primary'
                sx={(theme) => ({
                  '& .MuiBadge-badge': {
                    right: -3,
                    top: 13,
                    border: `2px solid ${theme.palette.background.paper}`,
                    padding: '0 4px',
                  },
                })}
              >
                <FilterAltOutlinedIcon />
              </Badge>
            </IconButton>
          )}
          <Popover
            id='filter-popover'
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement='bottom-end'
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                minWidth: 250,
                bgcolor: 'background.paper',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                p: 3,
              }}
            >
              <FormGroup sx={{ mb: 3 }}>
                {statusList.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.current.includes(option.Name)}
                      />
                    }
                    label={option.Name}
                    key={option.Id}
                    onChange={(e) => handleChangeCheckbox(e, option.Name)}
                  />
                ))}
              </FormGroup>

              {!inStockParams && (
                <>
                  <Divider />
                  <TenantFilter
                    checkedTenants={tenants.current || []}
                    handleToggle={(e, value) => handleChangeTenants(value)}
                    additionalParams={{ hasDeviceOnly: true }}
                  />
                </>
              )}
            </List>
          </Popover>
        </Box>
        {isPageList && (
          <LoadingButton
            variant='outlined'
            onClick={downloadFile}
            loading={isLoadingBtnDownLoad}
            startIcon={<FileDownloadIcon />}
          >
            Export
          </LoadingButton>
        )}
      </AppsHeader>
      <DataGrid
        hideFooter
        disableColumnSelector
        loading={isLoading}
        rows={data ?? []}
        columns={columns}
        getRowId={(row) => row.Imei}
        onRowClick={handleClickRow}
        sortingMode='server'
        onSortModelChange={handleSortList}
        disableColumnFilter
        disableColumnMenu
        sx={{
          mt: -2,
          borderTop: 0,
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.6em',
            height: '0.6em',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      />
      <FooterWrapper className='footer'>
        <div className='footerContainer justifyCenter'>
          <Pagination
            count={count}
            page={page}
            onChange={onPageChange}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount | 0}</Typography>
        </div>
      </FooterWrapper>
    </>
  );
};

export default RequestList;
