import React, {useCallback, useState} from 'react';

const useModal = () => {
  const [visible, setVisible] = useState(false);

  const onShow = useCallback((callback) => {
    setVisible(true);
    typeof callback === 'function' && callback?.();
  }, []);

  const onClose = useCallback((callback) => {
    setVisible(false);
    typeof callback === 'function' && callback?.();
  }, []);

  return {visible, onClose, onShow};
};

export default useModal;
