export const COLORS = [
  '#fa6a64',
  '#5a2476',
  '#755dff',
  '#373715',
  '#3d4381',
  '#91b776',
  '#356f61',
  '#a19b66',
  '#ff5a36',
  '#f1c232',
  '#2b2b2b',
  '#b5b3f4',
  '#bd33a4',
  '#e4717a',
];
