import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import {
  Slide,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ResquestSaveDraft = ({ callBack, loadingBtn }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    callBack?.(handleClose);
  };

  return (
    <>
      <LoadingButton
        variant='contained'
        color='primary'
        onClick={handleClickOpen}
      >
        Save draft
      </LoadingButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle sx={{ fontSize: 20 }}>{'Save draft'}</DialogTitle>
        <DialogContent>
          <Typography>Do you want to save draft?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose}>
            No
          </Button>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleSave}
            loading={loadingBtn}
            disabled={loadingBtn}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResquestSaveDraft.propTypes = {
  item: PropTypes.object.isRequired,
  callBack: PropTypes.func,
};

export default React.memo(ResquestSaveDraft);
