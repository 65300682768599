import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import AppTooltip from '@tenant/core/AppTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import AppCard from '@tenant/core/AppCard';
import Typography from '@mui/material/Typography';
import AppEmptyResult from '@tenant/core/AppEmptyResult';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Code } from 'react-content-loader';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onGetSubcontractorMapping } from 'redux/actions';
import { formatNumber } from '@tenant/utility/helper/Utils';

const Mapping = ({ tenantId = null }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { messages } = useIntl();
  const { data } = useSelector(
    ({ subcontractorMapping }) => subcontractorMapping,
  );

  const detectionId = tenantId
    ? 'SubContractorTenantId'
    : 'MainContractorTenantId';

  const detectionName = tenantId
    ? 'SubContractorTenantName'
    : 'MainContractorTenantName';

  const goToTenant = useCallback((e, id = null) => {
    e.preventDefault();
    navigate('/tenant/detail/' + id);
  }, []);

  const onCellClick = useCallback((params, event) => {
    if (params.field === detectionName) {
      goToTenant(event, params.row[detectionId]);
    }
  }, []);

  const getStatistic = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(onGetSubcontractorMapping(tenantId));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const columns = useMemo(() => {
    const list = [
      {
        field: detectionName,
        flex: 1,
        headerName: 'Company',
        minWidth: 300,
        resizable: false,
        renderCell: (params) => (
          <Link href='#' underline='none'>
            {params.value}
          </Link>
        ),
      },
      {
        field: 'NoOfJobMapping',
        headerName: 'No. Deployed Job',
        minWidth: 190,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
      {
        field: 'NoOfAccept',
        headerName: 'No. Accepted Job',
        minWidth: 190,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
      {
        field: 'NoOfJobTransfer',
        headerName: 'No. Transferred Job',
        minWidth: 190,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
      {
        field: 'NoOfReject',
        headerName: 'No. Rejected Job',
        minWidth: 190,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
      {
        field: 'NoOfJobTransferPaperWork',
        headerName: 'No. Transferred Job with Paper Work',
        minWidth: 210,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
      {
        field: 'NoOfDoNothing',
        headerName: 'No. Do Nothing Job',
        minWidth: 190,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
    ];

    if (!tenantId) {
      list.push(
        {
          field: 'NoOfTotalSubcontractor',
          headerName: 'No. Total Subcontractor',
          minWidth: 190,
          headerAlign: 'right',
          align: 'right',
          renderCell: (params) => (
            <Typography sx={{ color: 'success.main' }}>
              {formatNumber(params.value)}
            </Typography>
          ),
        },
        {
          field: 'NoOfSubcontractorMapping',
          headerName: 'No. Mapping Subcontractor',
          minWidth: 190,
          headerAlign: 'right',
          align: 'right',
          renderCell: (params) => (
            <Typography sx={{ color: 'success.main' }}>
              {formatNumber(params.value)}
            </Typography>
          ),
        },
      );
    }

    return list;
  }, []);

  useEffect(() => {
    getStatistic();
  }, [tenantId]);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ paddingRight: 5 }}>
            {messages['subcontractor.countmapping']}
          </span>
          <AppTooltip
            title={
              <p style={{ padding: 10 }}>
                {messages['subcontractor.countmapping.tooltip']}
              </p>
            }
            arrow
          >
            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
          </AppTooltip>
        </Box>
      }
    >
      {isLoading && <Code />}
      {!isLoading && (
        <>
          {data?.length > 0 ? (
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                columns={columns}
                loading={isLoading}
                onCellClick={onCellClick}
                rows={data.filter((e) => e[detectionId])}
                getRowId={(row) => row[detectionId] ?? 'not_used'}
              />
            </div>
          ) : (
            <AppEmptyResult title='Empty' />
          )}
        </>
      )}
    </AppCard>
  );
};

Mapping.propTypes = { tenantId: PropTypes.string };

export default React.memo(Mapping);
