import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useToast from '@tenant/utility/hooks/useToast';
import useContractService from '../../hooks/useContractService';
import DetailRemoveMaxUserNumberConfirm from './DetailRemoveMaxUserNumberConfirm';
import useModal from '@tenant/utility/hooks/useModal';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import AppLoader from '@tenant/core/AppLoader';
import DetailAddMaxUserNumberConfirm from './DetailAddMaxUserNumberConfirm';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import useCompany from '../../hooks/useCompany';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setMaxUserNumber } from 'redux/actions';

const PRIMARY_TOTAL = 1;
const INVALID_REDUCE_TOOLTIP =
  'The Maximum Number of Users must be greater than 1.';

const DetailUpdateMaxNumberUsers = ({
  visible,
  onClose,
  onShow,
  contractId,
  onGetContractAndInvoice,
  isAdditionalUser,
  tenantId,
  subscriptionStart,
  subscriptionEnd,
  defaultValue,
  tenantName,
  accountingManualPlan,
  paymentMethodType,
  tenants,
  currentTenant,
  setCurrentTenant,
  setTenantGroup,
  isDisabledReduce,
}) => {
  const dispatch = useDispatch();

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useToast();
  const { editStripeMaxUsers } = useContractService();
  const [value, setValue] = useState(
    defaultValue ?? (isAdditionalUser ? 1 : undefined),
  );
  const [valueReduce, setValueReduce] = useState(defaultValue);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [users, setUsers] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [loadingTenantsByContract, setLoadingTenantsByContract] =
    useState(false);
  const [selectedTenant, setSelectedTenant] = useState(currentTenant);
  const [selectedReduceTenant, setSelectedReduceTenant] =
    useState(currentTenant);

  const { getUsersByTenantId, markRemovalLicense } = useContractService();
  const { getTenantByContract } = useCompany();

  const maxNumberOfUsers = useSelector(
    ({ company }) => company.maximumNumberOfUsers,
  );
  const isMultipleTenants = useSelector(
    ({ company }) => company.isMultipleTenants,
  );

  const {
    visible: visibleRemove,
    onShow: onShowRemove,
    onClose: onCloseRemove,
  } = useModal();

  const newMaxNumberOfUsers = useMemo(
    () =>
      isAdditionalUser
        ? (maxNumberOfUsers ?? 0) + +(value ?? 0)
        : (maxNumberOfUsers ?? 0) - (valueReduce ?? 0),
    [isAdditionalUser, maxNumberOfUsers, value, valueReduce],
  );

  const totalUsers = useMemo(
    () => (users?.length ?? 0) + PRIMARY_TOTAL,
    [users?.length],
  );

  const handleCloseModal = useCallback(() => {
    onClose();
    setValue(defaultValue);
    setIsFirstRender(true);
  }, [onClose]);

  const getUsersByTenant = useCallback(
    (payload) => {
      const {
        isCallBack = false,
        id = tenantId,
        isReduceMaxUser = true,
      } = payload ?? {};
      setLoading(true);
      getUsersByTenantId({
        payload: { TenantId: id },
        onSuccess: (data) => {
          setUsers(deepClone(data));
          setIsUserLoaded(true);
          if (!isCallBack) {
            return;
          }
          const totalUser = data?.length ?? 0;
          const totalRemoveUser = totalUser - newMaxNumberOfUsers;
          if (isReduceMaxUser && totalRemoveUser > 0) {
            onClose();
            onShowRemove();
          }
        },
        onFinally: () => {
          setLoading(false);
        },
      });
    },
    [getUsersByTenantId, newMaxNumberOfUsers, tenantId],
  );

  const checkInvalid = useCallback(() => {
    return (
      (isAdditionalUser ? !value : !valueReduce) || newMaxNumberOfUsers <= 0
    );
  }, [value, valueReduce, newMaxNumberOfUsers]);

  const handleSuccess = useCallback(() => {
    handleCloseModal();
    showSuccessMessage('Edit Max Number of Users successfully');
    onGetContractAndInvoice?.(contractId);
    setLoadingBtn(false);
    setValue();
    setCurrentTenant(null);
    setTenantGroup(null);
    setIsFirstRender(true);
  }, [
    handleCloseModal,
    showSuccessMessage,
    onGetContractAndInvoice,
    contractId,
  ]);

  const handleError = useCallback(
    (message) => {
      const showMessage = typeof message === 'string' ? message : undefined;
      showErrorMessage(showMessage ?? 'Edit Max Number of Users failed');
      setLoadingBtn(false);
    },
    [showErrorMessage],
  );

  const handleSubmitRemoveUsers = useCallback(
    ({ payload, onSuccess, onError, onFinally }) => {
      markRemovalLicense({
        payload,
        onSuccess: () => {
          handleSuccess();
          onSuccess?.();
        },
        onError: (err) => {
          handleError(err?.response?.data);
          onError?.();
        },
        onFinally,
      });
    },
    [handleSuccess, handleError],
  );

  const handleGetTenantByContract = useCallback(() => {
    setLoadingTenantsByContract(true);
    getTenantByContract(contractId, (tenants) => {
      const { Tenants = [] } = tenants || {};
      setTenantList([...Tenants]);
      setLoadingTenantsByContract(false);
    });
  }, [contractId]);

  const handleSelectReduceTenant = (value) => {
    dispatch(setMaxUserNumber(value.MaximumNumberUser));
    setSelectedReduceTenant(value);
    getUsersByTenant({ isCallBack: true, id: value.TenantId });
  };

  const handleOpenSelectTenant = useCallback(() => {
    if (tenantList.length === 0) {
      handleGetTenantByContract();
    }
  }, [tenantList]);

  useEffect(() => {
    if (visible && isAdditionalUser && !value) {
      setValue(1);
    }
    // Only for reduce max user
    if (visible && !isUserLoaded) {
      getUsersByTenant();
    }
  }, [visible, isUserLoaded]);

  const onChangeAddMaxUsers = useCallback((newValue) => {
    setIsFirstRender(false);
    setValue(newValue ? parseInt(newValue).toFixed(0) : undefined);
  }, []);

  const onBlurAddMaxUsers = useCallback(() => {
    if (isAdditionalUser || checkInvalid()) {
      return;
    }

    if (!isUserLoaded) {
      getUsersByTenant({ isCallBack: true });
      return;
    }

    const totalRemoveUser = totalUsers - newMaxNumberOfUsers;

    if (totalRemoveUser <= 0) {
      return;
    }

    onClose();
    onShowRemove();
  }, [
    onClose,
    onShowRemove,
    getUsersByTenant,
    checkInvalid,
    isAdditionalUser,
    isUserLoaded,
  ]);

  const onSubmitAddUser = useCallback(
    (selectedTenant = {}) => {
      setLoadingBtn(true);
      editStripeMaxUsers({
        payload: {
          ContractId: contractId,
          MaximumNumberOfUsers: newMaxNumberOfUsers,
          TenantId: selectedTenant.TenantId,
          DiscountAmount: selectedTenant.DiscountAmount,
          DiscountPercent:
            selectedTenant.DiscountPercent ||
            (!selectedTenant.DiscountAmount ? 0 : undefined),
          AmountDue: selectedTenant.AmountDue,
        },
        onSuccess: () => {
          onClose();
          handleSuccess();
        },
        onError: (err) => {
          handleError(err?.response?.data);
        },
      });
    },
    [
      editStripeMaxUsers,
      handleSuccess,
      handleError,
      onClose,
      newMaxNumberOfUsers,
      contractId,
    ],
  );

  const onCloseReduceMaxUserConfirm = () => {
    onCloseRemove();
    onShow();
  };

  const onSubmitReduceMaxUserConfirm = ({ removeUserIds }) => {
    setLoadingBtn(true);
    handleSubmitRemoveUsers({
      payload: {
        RemoveUserIds: removeUserIds,
        ContractId: contractId,
        DecreaseNumber: valueReduce,
        TenantId: selectedReduceTenant.TenantId,
      },
      onSuccess: () => {
        setValueReduce();
        onCloseRemove();
        handleCloseModal();
      },
      onFinally: () => {
        setLoadingBtn(false);
      },
    });
  };

  const onChangeDiffNumber = (e) => {
    const value = +e.target.value > 0 ? +e.target.value : undefined;
    setIsFirstRender(false);

    const newValue =
      value && value > maxNumberOfUsers - 1 ? maxNumberOfUsers - 1 : value;

    setValueReduce(newValue ? parseInt(newValue) : undefined);
  };

  const onBlurDiffNumber = () => {
    if (isAdditionalUser || checkInvalid()) {
      return;
    }

    if (!isUserLoaded) {
      getUsersByTenant({ isCallBack: true });
      return;
    }

    const totalRemoveUser = totalUsers - newMaxNumberOfUsers;

    if (totalRemoveUser <= 0) {
      return;
    }

    onClose();
    onShowRemove();
  };

  const onSubmitReduceMaxUsers = () => {
    setIsFirstRender(false);

    if (checkInvalid()) {
      return;
    }

    const totalUser = users?.length ?? 0;
    const totalRemoveUser = totalUser - newMaxNumberOfUsers;

    if (!isAdditionalUser && totalRemoveUser >= 0) {
      onClose();
      onShowRemove();
      return;
    }

    setLoadingBtn(true);
    markRemovalLicense({
      payload: {
        ContractId: contractId,
        DecreaseNumber: valueReduce,
        TenantId: selectedReduceTenant.TenantId,
      },
      onSuccess: () => {
        setValueReduce();
        handleSuccess();
      },
      onError: (err) => {
        handleError(err?.response?.data?.Message);
      },
      onFinally: () => {
        setLoadingBtn(false);
      },
    });
  };

  return (
    <>
      {isAdditionalUser && (
        <DetailAddMaxUserNumberConfirm
          visible={visible}
          loadingBtn={loadingBtn}
          accountingManualPlan={accountingManualPlan}
          paymentMethodType={paymentMethodType}
          contractId={contractId}
          tenantId={selectedTenant.TenantId}
          subscriptionStart={subscriptionStart}
          subscriptionEnd={subscriptionEnd}
          tenantName={tenantName}
          totalUsers={totalUsers}
          value={value}
          checkInvalid={checkInvalid}
          isFirstRender={isFirstRender}
          handleChange={onChangeAddMaxUsers}
          handleBlur={onBlurAddMaxUsers}
          onClose={onClose}
          onSubmit={onSubmitAddUser}
          tenants={tenants}
          currentTenant={currentTenant}
          currentMaxNumberOfUsers={maxNumberOfUsers}
          newMaxNumberOfUsers={newMaxNumberOfUsers}
          getUsersByTenant={getUsersByTenant}
        />
      )}

      {visibleRemove && (
        <DetailRemoveMaxUserNumberConfirm
          users={users}
          tenantId={tenantId}
          subscriptionEnd={subscriptionEnd}
          visible={visibleRemove}
          newMaxNumberOfUsers={newMaxNumberOfUsers}
          maxNumberOfUsers={maxNumberOfUsers}
          loadingBtn={loadingBtn}
          onClose={onCloseReduceMaxUserConfirm}
          onSubmit={onSubmitReduceMaxUserConfirm}
        />
      )}

      {!isAdditionalUser && (
        <Dialog
          open={visible}
          onClose={handleCloseModal}
          aria-labelledby='remove-client-group-dialog-title'
          aria-describedby='remove-client-group-dialog-description'
          maxWidth='sm'
          fullWidth
        >
          <DialogTitle id='remove-client-group-dialog-title' fontSize={16}>
            Reduce Max User
          </DialogTitle>

          {(loadingBtn || loading) && <AppLoader />}

          <DialogContent>
            <Box mt={4} display={'flex'} alignItems={'center'}>
              <Typography component={'span'} variant='button'>
                Tenant:
              </Typography>
              {isMultipleTenants ? (
                <Autocomplete
                  size='small'
                  disableClearable
                  loading={loadingTenantsByContract}
                  options={tenantList}
                  getOptionLabel={(option) => option?.ContactName}
                  isOptionEqualToValue={(option, value) => {
                    return option?.TenantId === value?.TenantId;
                  }}
                  renderOption={(props, option) => (
                    <Box key={option.TenantId} component='li' {...props}>
                      {option?.ContactName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select a tenant'
                      sx={{ width: 200, marginLeft: '10px' }}
                    />
                  )}
                  value={selectedReduceTenant}
                  onOpen={handleOpenSelectTenant}
                  onChange={(e, value) => {
                    handleSelectReduceTenant(value);
                  }}
                />
              ) : (
                <Typography variant='body3' sx={{ ml: 2 }}>
                  {selectedReduceTenant?.ContactName}
                </Typography>
              )}
            </Box>
            <Box mt={4}>
              <Typography component={'span'} variant='button'>
                Number of Users:
              </Typography>{' '}
              {totalUsers} / {maxNumberOfUsers ?? 0}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Typography
                component={'span'}
                variant='button'
                id='increase-label'
              >
                Decrease
              </Typography>
              <TextField
                disabled={isDisabledReduce}
                size='small'
                autoComplete='off'
                htmlFor='increase-label'
                sx={{
                  m: 4,
                  maxWidth: 125,
                  '& .Mui-disabled': {
                    backgroundColor: grey[50],
                  },
                }}
                value={valueReduce ?? ''}
                type='number'
                required
                placeholder='Enter a number'
                error={!isFirstRender && checkInvalid()}
                onChange={onChangeDiffNumber}
                onBlur={onBlurDiffNumber}
              />
            </Box>
            <DialogContentText>
              <Typography component={'span'} variant='button'>
                New Max Number of Users:
              </Typography>{' '}
              <Typography
                component={'span'}
                variant='button'
                color={'primary.main'}
              >
                {newMaxNumberOfUsers ?? 0}
              </Typography>{' '}
            </DialogContentText>

            {!isDisabledReduce && (
              <DialogContentText mt={5} fontSize={12}>
                <i>
                  * The new Max Number of Users will be processed at{' '}
                  {formatDateFromISO(subscriptionEnd)}
                </i>
              </DialogContentText>
            )}

            {isDisabledReduce && (
              <DialogContentText mt={5} fontSize={12} color={'error.main'}>
                * {INVALID_REDUCE_TOOLTIP}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button color='secondary' onClick={handleCloseModal}>
              Close
            </Button>
            <LoadingButton
              disabled={isDisabledReduce}
              variant='contained'
              color='primary'
              loading={loadingBtn}
              onClick={onSubmitReduceMaxUsers}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(DetailUpdateMaxNumberUsers);

DetailUpdateMaxNumberUsers.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  maxNumberOfUsers: PropTypes.number,
  contractId: PropTypes.string,
  onGetContractAndInvoice: PropTypes.func,
  isAdditionalUser: PropTypes.bool,
  tenantId: PropTypes.string,
  subscriptionStart: PropTypes.string,
  subscriptionEnd: PropTypes.string,
  defaultValue: PropTypes.number,
  tenantName: PropTypes.string,
  accountingManualPlan: PropTypes.bool,
  isDisabledReduce: PropTypes.bool,
  paymentMethodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tenants: PropTypes.array,
  currentTenant: PropTypes.object,
  setCurrentTenant: PropTypes.func,
  setTenantGroup: PropTypes.func,
};
