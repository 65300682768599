import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import Grid from '@mui/material/Grid';
import {
  ListItemText,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

import AppGridContainer from '@tenant/core/AppGridContainer';
import IntlMessages from '@tenant/utility/IntlMessages';
import useTenant from 'pages/apps/hooks/useTenant';
import {
  DISABLED_BUNDLE_NEXT_STEP,
} from 'shared/constants/ActionTypes';
import TenantSelect from '@tenant/core/App/Select/TenantSelect';
import RequestContentBlock from 'pages/apps/components/RequestContentBlock';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import useCompany from 'pages/apps/Company/hooks/useCompany';
import { setContractSummary } from 'redux/actions';

const StepSelectTenant = ({
  values,
  setFieldValue,
  errors,
}) => {
  const {
    loadingSubscription,
    getTenantSubscription,
  } = useTenant();
  const { subscriptionInfo, } = useSelector(({ tenant }) => tenant);
  const { contractSummary, } = useSelector(({ company }) => company);
  const dispatch = useDispatch();
  const { getContractSummary, loadingContractSummary, } = useCompany();
  const [message, setMessage] = useState('');

  const getSubscriptionInfo = useCallback((tenantId) => {
    getTenantSubscription(tenantId, (data) => {
      if (!data.ContractId) {
        setMessage('tenant.noContractMessage');
        return;
      }
      getContractInfo(data.ContractId);
    });
  }, []);

  const getContractInfo = useCallback((contractId) => {
    getContractSummary(contractId);
  }, []);

  useEffect(() => {
    dispatch({
      type: DISABLED_BUNDLE_NEXT_STEP,
      payload: loadingContractSummary || !contractSummary || loadingSubscription,
    });
  }, [contractSummary, loadingSubscription, loadingContractSummary]);

  return (
    <AppGridContainer spacing={5}>
      <Grid item xs={12} lg={12}>
        <Stack direction='column' spacing={3} justifyContent='space-between' mt={2}>
          <TenantSelect
            required
            isShowAdditionalInfo
            infoProp={'ClientGroupName'}
            variant={'outlined'}
            placeholder={'Tenant'}
            value={values?.selectedTenant || null}
            error={!!errors.tenantId}
            helperText={errors.tenantId}
            onChange={(e, data) => {
              if (!data?.TenantId) return;

              setFieldValue('selectedTenant', data);
              setFieldValue('tenantId', data?.TenantId);
              setMessage('');
              dispatch(setContractSummary(null));

              getSubscriptionInfo(data.TenantId);
            }}
          />

          {message && !loadingContractSummary && !loadingSubscription && (
            <Box
              sx={{
                color: 'warning.main',
                fontStyle: 'italic',
                textAlign: 'left',
              }}
            >
              <Typography component={'span'} sx={{ fontSize: '15px' }}>
                <IntlMessages id={message} />
              </Typography>
            </Box>
          )}

          {!!values.tenantId && (
            <>
              <RequestContentBlock title='tenant.info'>
                {loadingSubscription ? (
                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={6}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='common.company' />
                      </Box>
                      <ListItemText secondary={subscriptionInfo?.CompanyName || 'N/A'} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='common.country' />
                      </Box>
                      <ListItemText secondary={subscriptionInfo?.Country || 'N/A'} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='tenant.subscriptionType' />
                      </Box>
                      <ListItemText secondary={subscriptionInfo?.ActivationType || 'N/A'} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='common.email' />
                      </Box>
                      <ListItemText secondary={subscriptionInfo?.EmailAddress || 'N/A'} />
                    </Grid>
                  </Grid>
                )}
              </RequestContentBlock>


              <RequestContentBlock title='bundleContract.contractInfo'>
                {loadingContractSummary || loadingSubscription ? (
                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={6}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='bundleContract.contract' />
                      </Box>
                      <ListItemText secondary={contractSummary?.ContractName || 'N/A'} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='productForm.balance' />
                      </Box>
                      <ListItemText
                        secondary={contractSummary?.PointsBalance ?? 0}
                        secondaryTypographyProps={{
                          color: 'primary.main',
                          fontWeight: 600
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='bundleContract.startDate' />
                      </Box>
                      <ListItemText
                        secondary={
                          contractSummary?.StartDate
                            ? formatDate(contractSummary.StartDate)
                            : '-'
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} mb={5}>
                      <Box component='p' color='text.primary'>
                        <IntlMessages id='bundleContract.endDate' />
                      </Box>
                      <ListItemText
                        secondary={
                          contractSummary?.EndDate
                            ? formatDate(contractSummary.EndDate)
                            : '-'
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </RequestContentBlock>
            </>
          )}

        </Stack>
      </Grid>
    </AppGridContainer>
  );
};

export default React.memo(StepSelectTenant);

StepSelectTenant.propTypes = {};