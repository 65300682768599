import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {
  inputFormat,
  dateGroupTypes,
  dateFormatByKey,
} from '@tenant/utility/constants/default';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { getGroupDateTypeByDate } from '@tenant/utility/helper/Utils';
import { IoMdArrowDropright } from 'react-icons/io';
import Button from '@mui/material/Button';

const generateData = (data, filterBy) => {
  const result = {};
  data.forEach((item) => {
    if (filterBy.includes(item.key)) {
      if (result[item.title]) {
        result[item.title] = [...result[item.title], item];
      } else {
        result[item.title] = [item];
      }
    }
  });
  return result;
};

const DateOptions = ({
  defaultKey = 'This month',
  onDateChange,
  filterBy = ['days', 'month', 'year'],
  onClose,
  dataOptions = null,
  isHasYear = false,
}) => {
  const options = dataOptions || dateFormatByKey;
  const values = options[defaultKey];
  const [open, setOpen] = useState(!values);
  const [selected, setSelected] = useState(defaultKey);
  const groupData = generateData(Object.values(options), filterBy);
  const [start, setStart] = useState(moment().subtract(1, 'month'));
  const [end, setEnd] = useState(moment());

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectDate = (keyName, data) => {
    const dateGroupType = dateGroupTypes[keyName || defaultKey];
    const result = {
      startDate: data.value[0],
      endDate: data.value[1],
      dateGroupType: dateGroupType,
      keyName,
    };
    onDateChange(result);
    setSelected(keyName);
    onClose();
  };

  useEffect(() => {
    if (!values) {
      setOpen(true);
      const defaultKeyStr = defaultKey.split('-');
      const start = defaultKeyStr[0];
      const end = defaultKeyStr[1];
      setStart(moment(start, 'MMM DD YYYY'));
      setEnd(moment(end, 'MMM DD YYYY'));
    }
  }, []);

  const onGetData = (start, end) => {
    const { keyName, dateGroupType } = getGroupDateTypeByDate(
      start,
      end,
      isHasYear,
    );
    const result = {
      startDate: moment(start).format(inputFormat),
      endDate: moment(end).format(inputFormat),
      dateGroupType,
      keyName,
    };
    onDateChange(result);
  };

  return (
    <>
      {Object.entries(groupData).map(([key, value]) => {
        return (
          <List
            key={key}
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
              <ListSubheader
                component='div'
                id='nested-list-subheader'
                sx={{ backgroundColor: '#fafafa' }}
              >
                {key}
              </ListSubheader>
            }
          >
            {value.map((childItem, childIndex) => {
              return (
                <ListItemButton
                  key={childIndex}
                  onClick={() =>
                    handleSelectDate(childItem.text, options[childItem.text])
                  }
                  selected={selected !== null && selected === childItem.text}
                >
                  <ListItemText secondary={childItem.text} />
                  <ListItemText
                    secondary={options[childItem.text].description}
                    style={{ textAlign: 'end' }}
                  />
                </ListItemButton>
              );
            })}
          </List>
        );
      })}
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          paddingBottom: '2px',
        }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <ListSubheader
            component='div'
            id='nested-list-subheader'
            sx={{
              backgroundColor: '#fafafa',
            }}
            onClick={handleClick}
          >
            <Box
              component={'div'}
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              Custom date range
              {open ? (
                <Button
                  sx={{
                    ml: 3,
                    position: 'relative',
                    padding: 0,
                  }}
                  variant='standard'
                  size='small'
                  onClick={() => {
                    onGetData(start, end);
                    onClose();
                  }}
                  type='submit'
                >
                  Save
                </Button>
              ) : (
                <IoMdArrowDropright />
              )}
            </Box>
          </ListSubheader>
        }
      >
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton sx={{ pl: 5 }}>
              <ListItemText secondary='Start' sx={{ mr: 4 }} />
              <DatePicker
                fullWidth
                autoOk
                format={inputFormat}
                variant='standard'
                name='RequestDate'
                value={start}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant='standard' />
                )}
                onChange={(value) => setStart(value)}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 5 }}>
              <ListItemText secondary='End' sx={{ mr: 5 }} />
              <DatePicker
                fullWidth
                autoOk
                format={inputFormat}
                variant='standard'
                name='RequestDate'
                value={end}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant='standard' />
                )}
                onChange={(value) => setEnd(value)}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </>
  );
};

export default DateOptions;
