import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useMemo } from 'react';
import IntlMessages from '@tenant/utility/IntlMessages';
import InvoiceLine from './InvoiceLine';
import { calculatorDiscountNumber } from 'pages/apps/Company/helpers/calculator';
import { currencyFormat } from '@tenant/utility/Utils';

function DraftInvoice(props) {
  const { setFieldValue, values, isView = false, activeStep = true } = props;

  const handleEditInvoice = (invoice, index) => {
    const newInvoices = [...values.Invoices];
    newInvoices[index] = { ...invoice };
    setFieldValue('Invoices', newInvoices);
  };

  const showValSubtotal = useMemo(() => {
    const listLine = values.Invoices?.filter((item) => !item.IsEdit);

    const value = listLine.reduce((accumulator, currentItem) => {
      return parseFloat(accumulator) + parseFloat(currentItem.Amount);
    }, 0);
    setFieldValue(`SubTotal`, value);
    return value;
  }, [values.Invoices]);

  const showValVAT = useMemo(() => {
    const listLine = values.Invoices?.filter((item) => !item.IsEdit);

    const value = listLine.reduce((accumulator, currentItem) => {
      return parseFloat(accumulator) + parseFloat(currentItem.TaxAmount);
    }, 0);
    setFieldValue(`TotalVat`, value);
    return value;
  }, [values.Invoices]);

  const showValDiscount = useMemo(() => {
    const listLine = values.Invoices?.filter((item) => !item.IsEdit);

    const value = listLine.reduce((accumulator, currentItem) => {
      const discountNumber = calculatorDiscountNumber(
        currentItem.Discount,
        currentItem.Amount,
      );
      if (
        isNaN(discountNumber) ||
        discountNumber === undefined ||
        discountNumber === null
      )
        return parseFloat(accumulator);
      return parseFloat(accumulator) + discountNumber;
    }, 0);

    setFieldValue(`DiscountTotal`, value);
    return value;
  }, [values.Invoices]);

  const showValTotal = useMemo(() => {
    const value = showValSubtotal - showValDiscount + showValVAT;
    setFieldValue(`Total`, value);
    return value;
  }, [showValSubtotal, showValDiscount, showValVAT]);

  if (!activeStep) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'right' }}>
        <FieldArray
          name='Invoices'
          render={(arrayHelpers) => (
            <>
              {values.Invoices && values.Invoices.length > 0 && (
                <>
                  <TableContainer sx={{ height: 'calc(100vh - 500px)' }}>
                    <Table
                      sx={{ minWidth: 500 }}
                      aria-label='simple table'
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit Amount</TableCell>
                          <TableCell>Discount</TableCell>
                          <TableCell>Tax Rate</TableCell>
                          <TableCell>Tax Amount</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Amount Due</TableCell>
                          {!isView && <TableCell>Actions</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.Invoices.map((invoice, index) => (
                          <InvoiceLine
                            invoice={invoice}
                            key={index}
                            isEdit={invoice.IsEdit}
                            index={index}
                            isView={isView}
                            handleSubmit={(data) =>
                              handleEditInvoice(data, index)
                            }
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={0} sm={9} md={9} sx={{'align-self': 'end'}}>
        <FormControlLabel
          control={<Checkbox checked={values.IsGenerateInvoice} color='warning' />}
          label={
            <Typography variant='h4'>
              <IntlMessages id='bundle.generateXeroInvoice' />
            </Typography>
          }
          onChange={(e) => setFieldValue('IsGenerateInvoice', e.target.checked)}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <List dense>
          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                <IntlMessages id='vehicleTrackingRequest.draftInvoice.subtotal' />
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={currencyFormat(showValSubtotal) ?? 0}
              primaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  textAlign: 'right',
                  fontWeight: 700,
                  color: 'primary.main',
                },
              }}
            />
          </ListItem>
          <Divider component='li' />
          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                <IntlMessages id='vehicleTrackingRequest.draftInvoice.discount' />
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={currencyFormat(showValDiscount) ?? 0}
              primaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  textAlign: 'right',
                  fontWeight: 700,
                  color: 'success.main',
                },
              }}
            />
          </ListItem>
          <Divider component='li' />
          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                <IntlMessages id='vehicleTrackingRequest.draftInvoice.vat' />
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={currencyFormat(showValVAT) ?? 0}
              primaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  textAlign: 'right',
                  fontWeight: 700,
                  color: 'error.main',
                },
              }}
            />
          </ListItem>
          <Divider component='li' />
          <ListItem sx={{ alignItems: 'baseline' }}>
            <ListItemIcon>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                <IntlMessages id='vehicleTrackingRequest.draftInvoice.total' />
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={currencyFormat(showValTotal) ?? 0}
              primaryTypographyProps={{
                sx: {
                  fontSize: '15px',
                  textAlign: 'right',
                  fontWeight: 700,
                  color: 'primary.main',
                },
              }}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}

export default DraftInvoice;
