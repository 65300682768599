import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { debounce } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';

function DynamicInput({
  label,
  name,
  type,
  setFieldValue,
  values,
  errors,
  touched,
  disabled,
  optionLabel,
  optionEqualToValue,
  optionKey,
  url,
}) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [List, setList] = useState([]);

  const onSearch = debounce((event, value) => {
    if (event?.type === 'change') {
      const params = {
        searchTerm: value,
      };
      getList(params);
    }
    if (event?.type === 'blur') {
      const params = {
        searchTerm: selected?.Name,
      };
      getList(params);
    }
  });

  const onChange = (e, value) => {
    if (!value) return;

    setFieldValue(name, value[optionKey]);
    setSelected(value);
  };

  const getList = async (params) => {
    setLoading(true);
    jwtAxios
      .get(url, { params })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setList(response.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (url) {
      getList();
    }
  }, []);

  switch (type) {
    case 'DatePicker':
      return (
        <Grid item xs={12} sm={6} md={6}>
          <DatePicker
            label={label}
            value={values[name]}
            inputFormat='dd/MM/yyyy'
            onChange={(date) => setFieldValue(name, date)}
            renderInput={(params) => (
              <TextField
                fullWidth
                error={Boolean(errors[name])}
                helperText={errors[name]}
                sx={{
                  '& .MuiInputBase-root.MuiOutlinedInput-root': {
                    border: Boolean(errors[name]) ? '1px solid #d32f2f' : '',
                  },
                  '& .MuiFormHelperText-root': {
                    color: Boolean(errors[name]) ? '#d32f2f' : '',
                  },
                  '& .MuiInputBase-input': {
                    color: Boolean(errors[name]) ? '#d32f2f' : '',
                  },
                  '& .MuiFormLabel-root': {
                    color: Boolean(errors[name]) ? '#d32f2f' : '',
                  },
                }}
                {...params}
              />
            )}
            disabled={disabled}
          />
        </Grid>
      );
    case 'number':
      return (
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label={label}
            value={values[name]}
            type='number'
            helperText={errors[name]}
            error={Boolean(errors[name])}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
            }}
            disabled={disabled}
          />
        </Grid>
      );
    case 'textarea':
      return (
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            label={label}
            value={values[name]}
            helperText={errors[name]}
            error={Boolean(errors[name])}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
            }}
            disabled={disabled}
            multiline
            rows={3}
          />
        </Grid>
      );
    case 'select':
      return (
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant='outlined'>
            <Autocomplete
              name={name}
              disableClearable
              options={List ?? []}
              getOptionLabel={(option) => option[optionLabel]}
              isOptionEqualToValue={(option, value) =>
                option[optionEqualToValue] === value[optionEqualToValue]
              }
              renderOption={(props, option) => (
                <li {...props} key={option[optionKey]}>
                  {option[optionLabel]}
                </li>
              )}
              loading={loading}
              value={selected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label={label}
                  error={Boolean(errors[name]) && touched[name]}
                  helperText={
                    Boolean(errors[name]) &&
                    touched[name] &&
                    errors[name] &&
                    `Please select ${label}`
                  }
                />
              )}
              onInputChange={onSearch}
              onChange={(e, value) => onChange(e, value)}
              onSearch={onSearch}
            />
          </FormControl>
        </Grid>
      );
    default:
      return (
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label={label}
            value={values[name]}
            helperText={errors[name]}
            error={Boolean(errors[name])}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
            }}
            disabled={disabled}
          />
        </Grid>
      );
  }
}

export default DynamicInput;
