import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  isValidElement,
} from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination, Typography, Hidden, Zoom, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import DataList from 'pages/apps/components/DataList';
import { UPDATE_ORDER_BY } from 'shared/constants/ActionTypes';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import AppAdvancedFilters from '@tenant/core/AppAdvancedFilters';
import AppSearch from '@tenant/core/AppSearchBar';
import IntlMessages from '@tenant/utility/IntlMessages';
import {
  ORDER_DIRECTION,
  ORDER_DIRECTION_VALUE,
} from '@tenant/utility/constants/enum';
import AppsContainer from '@tenant/core/AppsContainer';
import FilterFormContainer from './FilterFormContainer';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { DEFAULT_PAGE_INDEX } from '@tenant/utility/constants/default';

const DataListContainer = ({
  columns,
  rows,
  total,
  data,
  linkToNavigate,
  rowsPerPage,
  rangeDate = '',
  orderModel,
  keyOrderBy,
  handleGetData,
  handleSortData,
  handleAddNew,
  handleGetDataFirstPage,
  headerContent,
  renderFilterFormContent,
  filterTransformOrigin,
  filterAnchorOrigin,
  datagridSortModel,
  filterList = {},
  initialFilterList = {},
  statusFilterOptions = [],
  hasSearchBar = false,
  isLoadingList = false,
  searchPlaceholder = 'Search',
  hasTenantFilter = false,
  hasStatusFilter = false,
  hasFilterForm = false,
  hasTransactionTypeFilter = false,
  addNewBtnText = 'common.addNew',
  hasButtonAddNew = false,
  isHiddenUI = false,
  tenantFilterKey = 'tenants',
  statusFilterKey = 'statuses',
  clientGroupFilterKey = 'clientGroups',
  transTypeFilterKey = 'transactionTypes',
  salePersonFilterKey = 'salePersons',
  accountManagerFilterKey = 'accountManagers',
  searchBarWidth = 200,
  justifyFilter = 'start',
  title = '',
  listParams = {},
  isResetList = false,
  hasDateMenu = false,
  isInitData = true,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(listParams?.pageIndex || 1);
  const { orderBy } = useSelector(({ common }) => common);
  const [searchTerm, setSearchTerm] = useState(listParams?.searchTerm || '');

  const hasFilters = useMemo(
    () => hasTenantFilter || hasStatusFilter || hasTransactionTypeFilter,
    [],
  );
  const count = useMemo(() => {
    if (total && rowsPerPage) {
      if (total % rowsPerPage) return Math.floor(total / rowsPerPage) + 1;
      return Math.floor(total / rowsPerPage);
    }
    return 1;
  }, [total]);

  const handleChangePage = useCallback(
    (newPage) => {
      setPage(newPage);
      handleGetData({
        pageIndex: newPage,
        orderBy: orderBy[keyOrderBy],
        filterList: filterList,
        searchTerm: searchTerm,
        keyName: rangeDate,
      });
    },
    [filterList, orderBy, searchTerm, rangeDate],
  );

  const handleFilter = useCallback(
    (params) => {
      const searchValue = hasConflictedFilter(params) ? '' : searchTerm;

      handleGetData({
        orderBy: orderBy[keyOrderBy],
        keyName: rangeDate,
        filterList: params,
        searchTerm: searchValue,
      });
      setPage(DEFAULT_PAGE_INDEX);
      setSearchTerm(searchValue);
    },
    [orderBy, searchTerm, rangeDate],
  );

  const handleSort = useCallback(
    (sortModel) => {
      const payload = {
        keyName: rangeDate,
        orderBy: !!sortModel.length
          ? (() => {
              const [{ field, sort }] = sortModel;
              return orderModel
                ? orderModel[field][sort]
                : { Field: field, Direction: ORDER_DIRECTION[sort] };
            })()
          : 0,
        filterList: filterList,
        searchTerm: searchTerm,
        pageIndex: page,
      };

      handleSortData(payload);
      dispatch({
        type: UPDATE_ORDER_BY,
        payload: {
          key: keyOrderBy,
          value: payload.orderBy,
        },
      });
    },
    [filterList, page, searchTerm, rangeDate],
  );

  const clearSearchTerm = useCallback(() => {
    handleGetData({
      orderBy: orderBy[keyOrderBy],
      keyName: rangeDate,
      filterList: filterList,
    });

    setPage(DEFAULT_PAGE_INDEX);
    setSearchTerm('');
  }, [filterList, orderBy, rangeDate]);

  const onEnterSearch = useCallback(
    (e) => {
      if (e.key !== 'Enter') return;

      const filters = hasConflictedFilter(filterList)
        ? {
            ...filterList,
            [tenantFilterKey]: [],
            [clientGroupFilterKey]: [],
            [salePersonFilterKey]: [],
            [accountManagerFilterKey]: [],
          }
        : filterList;

      handleGetData({
        searchTerm: e.target.value,
        keyName: rangeDate,
        orderBy: orderBy[keyOrderBy],
        filterList: filters,
      });
      setPage(DEFAULT_PAGE_INDEX);
    },
    [filterList, orderBy, rangeDate],
  );

  const handleResetFilter = useCallback(() => {
    setPage(DEFAULT_PAGE_INDEX);
    handleGetData({
      keyName: rangeDate,
      orderBy: orderBy[keyOrderBy],
      filterList: initialFilterList,
      searchTerm: searchTerm,
    });
  }, [initialFilterList, orderBy, searchTerm, rangeDate]);

  const hasConflictedFilter = useCallback((filters) => {
    return (
      !!filters?.[tenantFilterKey]?.length ||
      !!filters?.[clientGroupFilterKey]?.length ||
      !!filters?.[salePersonFilterKey]?.length ||
      !!filters?.[accountManagerFilterKey]?.length
    );
  }, []);

  const handleSaveFilterForm = useCallback(
    (data) => {
      const searchValue = hasConflictedFilter(data) ? '' : searchTerm;

      handleGetData({
        orderBy: orderBy[keyOrderBy],
        keyName: rangeDate,
        filterList: data,
        searchTerm: searchValue,
      });
      setPage(DEFAULT_PAGE_INDEX);
      setSearchTerm(searchValue);
    },
    [orderBy, searchTerm, rangeDate],
  );

  const handleResetFilterForm = useCallback(() => {
    setPage(DEFAULT_PAGE_INDEX);
    handleGetData({
      keyName: rangeDate,
      orderBy: orderBy[keyOrderBy],
      filterList: initialFilterList,
      searchTerm: searchTerm,
    });
  }, [initialFilterList, orderBy, searchTerm, rangeDate]);

  const handleChangeDate = useCallback(
    (data) => {
      setPage(DEFAULT_PAGE_INDEX);
      const { keyName } = data || {};
      handleGetData({
        pageIndex: DEFAULT_PAGE_INDEX,
        orderBy: orderBy[keyOrderBy],
        filterList: filterList,
        searchTerm: searchTerm,
        keyName: keyName,
      });
    },
    [filterList, orderBy, searchTerm],
  );

  const sortModel = useMemo(() => {
    if (orderBy[keyOrderBy]?.hasOwnProperty('Direction')) {
      const { Field, Direction } = orderBy[keyOrderBy];

      return [
        {
          field: Field,
          sort: ORDER_DIRECTION_VALUE[Direction],
        },
      ];
    }

    return datagridSortModel?.[orderBy[keyOrderBy]] ?? [];
  }, [datagridSortModel, orderBy]);

  useEffect(() => {
    setPage(DEFAULT_PAGE_INDEX);
    setSearchTerm('');
    dispatch({
      type: UPDATE_ORDER_BY,
      payload: {
        key: keyOrderBy,
        value: 0,
      },
    });

    handleGetDataFirstPage ? handleGetDataFirstPage() : handleGetData();
  }, []);

  return isHiddenUI ? (
    <></>
  ) : (
    <>
      {hasFilterForm && (
        <FilterFormContainer
          handleSave={handleSaveFilterForm}
          handleReset={handleResetFilterForm}
          renderContent={renderFilterFormContent}
        />
      )}

      <AppsContainer
        fullView
        title={!!title ? <IntlMessages id={title} /> : null}
      >
        <AppsHeader>
          <Box
            flex={1}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            {headerContent && isValidElement(headerContent) && headerContent}

            {hasSearchBar && (
              <AppSearch
                isClearable
                onClearSearch={clearSearchTerm}
                iconPosition='right'
                id='outlined-adornment-weight'
                size='small'
                overlap={false}
                searchValue={searchTerm}
                onKeyDown={onEnterSearch}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={searchPlaceholder}
                sx={{
                  width: searchBarWidth,
                }}
              />
            )}

            {hasFilters && (
              <Box
                flex={1}
                gap={4}
                display='flex'
                justifyContent={justifyFilter}
                ml={2}
              >
                <AppAdvancedFilters
                  filterList={filterList}
                  transformOrigin={filterTransformOrigin}
                  anchorOrigin={filterAnchorOrigin}
                  hasTenantFilter={hasTenantFilter}
                  hasStatusFilter={hasStatusFilter}
                  hasTransactionTypeFilter={hasTransactionTypeFilter}
                  tenantFilterKey={tenantFilterKey}
                  statusFilterKey={statusFilterKey}
                  transTypeFilterKey={transTypeFilterKey}
                  statusFilterOptions={statusFilterOptions}
                  handleSave={handleFilter}
                  handleReset={handleResetFilter}
                />
              </Box>
            )}

            {hasButtonAddNew && (
              <Box flex={1} gap={4} display='flex' justifyContent='end'>
                <Hidden smDown>
                  <Zoom in style={{ transitionDelay: '300ms' }}>
                    <Button
                      variant='text'
                      color='primary'
                      sx={{
                        padding: '8px 28px',
                        borderRadius: 30,
                        '& .MuiSvgIcon-root': {
                          fontSize: 26,
                        },
                      }}
                      startIcon={<AddIcon />}
                      onClick={handleAddNew}
                    >
                      <IntlMessages id={addNewBtnText} />
                    </Button>
                  </Zoom>
                </Hidden>
              </Box>
            )}

            {hasDateMenu && (
              <DateMenu
                defaultValue={rangeDate}
                handleChangeValue={(data) => handleChangeDate(data)}
              />
            )}
          </Box>
        </AppsHeader>
        <DataList
          data={data}
          navigateLink={linkToNavigate}
          loading={isLoadingList}
          columns={columns}
          rows={rows}
          sortModel={sortModel}
          disableColumnMenu
          handleSort={handleSort}
        />
        <FooterWrapper className='footer'>
          <div className='footerContainer justifyCenter'>
            <Pagination
              count={count}
              page={page}
              onChange={(e, data) => handleChangePage(data)}
              showFirstButton
              showLastButton
            />
            <Typography>Total: {total | 0}</Typography>
          </div>
        </FooterWrapper>
      </AppsContainer>
    </>
  );
};

DataListContainer.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  rows: PropTypes.array,
  total: PropTypes.number,
  linkToNavigate: PropTypes.string,
  rowsPerPage: PropTypes.number,
  rangeDate: PropTypes.string,
  orderModel: PropTypes.object,
  keyOrderBy: PropTypes.string,
  handleGetData: PropTypes.func.isRequired,
  handleSortData: PropTypes.func,
  isLoadingList: PropTypes.bool,
  headerContent: PropTypes.node,
  filterAnchorOrigin: PropTypes.object,
  filterTransformOrigin: PropTypes.object,
  hasSearchBar: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  statusFilterOptions: PropTypes.array,
  hasTenantFilter: PropTypes.bool,
  hasStatusFilter: PropTypes.bool,
  hasButtonAddNew: PropTypes.bool,
  handleAddNew: PropTypes.func,
  addNewBtnText: PropTypes.string,
  filterList: PropTypes.object,
  initialFilterList: PropTypes.object,
  clientGroupFilterKey: PropTypes.string,
  accountManagerFilterKey: PropTypes.string,
  salePersonFilterKey: PropTypes.string,
  justifyFilter: PropTypes.string,
  renderFilterFormContent: PropTypes.func,
  title: PropTypes.string,
  datagridSortModel: PropTypes.object,
  listParams: PropTypes.object,
  isResetList: PropTypes.bool,
  isInitData: PropTypes.bool,
};

DataListContainer.defaultProps = {};

export default React.memo(DataListContainer);
