import {
  GET_CONTRACT_ITEMS,
  GET_LIST_COMPANIES,
  GET_LIST_COMPANIES_LOADING,
  GET_PAYMENT_TERMS,
  IS_INIT,
  LIST_INVOICE_LINE_EDIT,
  SET_CUR_TENANT,
  SET_CUR_TENANT_ID,
  SET_FILTER_COMPANIES_LIST,
  SET_PACKAGE_INFO,
  SET_PRECONDITION,
  UPDATE_LIST_COMPANIES,
  SET_CUR_CONTRACT,
  SET_COMPANY_CONTRACTS,
  SET_CURR_COMPANY,
  SET_PURCHASE_INVOICES,
  SET_INCREASE_MAX_USER_NUMBER,
  SET_IS_MULTIPLE_TENANTS,
  SET_CONTRACT_SUMMARY,
} from 'shared/constants/ActionTypes';

const initialState = {
  loading: false,
  contractItems: [],
  paymentTerms: [],
  companies: [],
  packageInfo: {},
  curTenantId: null,
  currTenant: null,
  currContract: null,
  filterCached: {
    pageIndex: 1,
    pageSize: 10,
    searchTerm: '',
    totalCount: 0,
  },
  preConditionData: {},
  companyContracts: [],
  currCompany: null,
  purchaseInvoices: [],
  isInit: false,
  maximumNumberOfUsers: 0,
  isMultipleTenants: false,
  contractSummary: null,
};

const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONTRACT_ITEMS:
      state.contractItems = payload;
      return { ...state };

    case GET_PAYMENT_TERMS:
      state.paymentTerms = payload;
      return { ...state };

    case GET_LIST_COMPANIES:
      return {
        ...state,
        companies: payload,
      };
    case SET_FILTER_COMPANIES_LIST:
      return {
        ...state,
        filterCached: structuredClone(payload),
      };
    case GET_LIST_COMPANIES_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case UPDATE_LIST_COMPANIES:
      const index = state.companies?.data?.findIndex(
        (e) => e.Id === payload.item?.Id,
      );
      const list = [...state.companies?.data];
      list[index] = payload.item;
      return {
        ...state,
        companies: {
          ...state.companies,
          data: list,
        },
      };

    case SET_PACKAGE_INFO:
      state.packageInfo = payload;
      return { ...state };

    case SET_CUR_TENANT_ID:
      state.curTenantId = payload;
      return { ...state };

    case SET_CUR_TENANT:
      state.currTenant = payload;
      return { ...state };

    case SET_PRECONDITION:
      state.preConditionData = payload;
      return { ...state };
    case LIST_INVOICE_LINE_EDIT:
      state.invoiceLineEdit = payload;
      return { ...state };
    case SET_CUR_CONTRACT:
      return {
        ...state,
        currContract: payload
      };
    case SET_COMPANY_CONTRACTS:
      return {
        ...state,
        companyContracts: payload
      };
    case SET_CURR_COMPANY:
      return {
        ...state,
        currCompany: payload
      };
    case SET_PURCHASE_INVOICES:
      return {
        ...state,
        purchaseInvoices: payload,
      };

    case IS_INIT:
      state.isInit = payload;
      return { ...state };

    case SET_INCREASE_MAX_USER_NUMBER:
      state.maximumNumberOfUsers = payload;
      return { ...state };

    case SET_IS_MULTIPLE_TENANTS:
      state.isMultipleTenants = payload;
      return { ...state };

    case SET_CONTRACT_SUMMARY:
      return {
        ...state,
        contractSummary: payload,
      };

    default:
      return state;
  }
};
export default companyReducer;
