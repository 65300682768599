import {
  FETCH_ERROR,
  FETCH_SUCCESS,
  GET_REFCOM_REFERAL_LOADING,
  GET_REFCOM_REFERAL_AND_USAGE,
  GET_TRANSACTION_TYPE,
  GET_TOP_TEN_REFCOM_TRANSACTION,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import {loadingKeys, extraLoadingKeys} from 'shared/constants/AppsContants';

const refcomKey = `${extraLoadingKeys.refcom}-${loadingKeys.getList}`;
const transactionKey = `${extraLoadingKeys.transactionType}-${loadingKeys.getList}`;
const topTenTransaction = `${extraLoadingKeys.topTenTransaction}-${loadingKeys.getList}`;
export const onGetRefcomReferalAndUsage = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({
      type: GET_REFCOM_REFERAL_LOADING,
      payload: {
        key: refcomKey,
        value: true,
      },
    });
    jwtAxios
      .get('api/Tenant/GetRefcomUsageStatistic')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_REFCOM_REFERAL_AND_USAGE, payload: data.data});
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: refcomKey,
              value: false,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: refcomKey,
              value: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch({
          type: GET_REFCOM_REFERAL_LOADING,
          payload: {
            key: refcomKey,
            value: false,
          },
        });
      });
  };
};

export const onGetTransactionTypes = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({
      type: GET_REFCOM_REFERAL_LOADING,
      payload: {
        key: transactionKey,
        value: true,
      },
    });
    jwtAxios
      .get('/api/Tenant/GetGasTransactionTypeStatistic', {
        params,
        paramsSerializer: {indexes: null},
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_TRANSACTION_TYPE, payload: data.data});
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: transactionKey,
              value: false,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: transactionKey,
              value: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch({
          type: GET_REFCOM_REFERAL_LOADING,
          payload: {
            key: transactionKey,
            value: false,
          },
        });
      });
  };
};

export const onGetRefcomTopTen = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({
      type: GET_REFCOM_REFERAL_LOADING,
      payload: {
        key: topTenTransaction,
        value: true,
      },
    });
    jwtAxios
      .get('api/Tenant/GetRefrigerantTypeUsageStatistic')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_TOP_TEN_REFCOM_TRANSACTION, payload: data.data});
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: topTenTransaction,
              value: false,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          dispatch({
            type: GET_REFCOM_REFERAL_LOADING,
            payload: {
              key: topTenTransaction,
              value: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch({
          type: GET_REFCOM_REFERAL_LOADING,
          payload: {
            key: topTenTransaction,
            value: false,
          },
        });
      });
  };
};
