import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import RoleModal from './RoleModal';
import useModal from '@tenant/utility/hooks/useModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RoleDeleteConfirm from './RoleDeleteConfirm';
import { Link } from 'react-router-dom';
import usePermissionService from '../hooks/usePermissionService';
import SearchPermission from './SearchPermission';
import { deepClone } from '@mui/x-data-grid/utils/utils';

const Role = ({ roleList, callbackSubmit }) => {
  const [data, setData] = useState();
  const [role, setRole] = useState();
  const [search, setSearch] = useState('');
  const { visible, onShow, onClose } = useModal();
  const {
    visible: visibleConfirm,
    onClose: onCloseConfirm,
    onShow: onShowConfirm,
  } = useModal();
  const { removeRole, loadingBtn } = usePermissionService();

  const handleChangeSearch = useCallback(
    (value) => {
      setSearch(value);
      if (value) {
        setData(
          roleList.filter(
            (e) => e.Name.toLowerCase().indexOf(value.toLowerCase()) >= 0,
          ),
        );
        return;
      }
      setData(deepClone(roleList));
    },
    [roleList],
  );

  useEffect(() => {
    setData(deepClone(roleList));
  }, [roleList]);

  const handleShowForm = useCallback(
    (role) => {
      setRole(role);
      onShow();
    },
    [onShow],
  );

  const handleCloseForm = useCallback(
    (role) => {
      setRole();
      onClose();
    },
    [onClose],
  );

  const handleShowConfirmModal = useCallback(
    (userRole) => {
      if (userRole) {
        setRole(userRole);
        onShowConfirm();
      }
    },
    [onShowConfirm],
  );

  const handleCloseConfirmModal = useCallback(() => {
    setRole();
    onCloseConfirm();
  }, [onCloseConfirm]);

  const columns = useMemo(
    () => [
      {
        field: 'Name',
        headerName: 'Name',
        width: 300,
        flex: 1,
        renderCell: (params) => (
          <Link
            to={`/admin/permissions/${params.row.RoleId}`}
            style={{ textDecoration: 'none' }}
          >
            {params.value}
          </Link>
        ),
      },
      { field: 'Description', headerName: 'Description', width: 350 },
      {
        field: 'Action',
        headerName: 'Action',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <>
            {/* <Tooltip title='Edit'>
              <IconButton
                color='primary'
                onClick={() => handleShowForm(params.row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip> */}
            {params.row.CanDelete && (
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => handleShowConfirmModal(params.row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    [],
  );

  const onDelete = useCallback(
    (payload) => {
      removeRole({
        payload,
        onSuccess: () => {
          callbackSubmit?.();
          onCloseConfirm();
        },
      });
    },
    [removeRole],
  );

  return (
    <Box sx={{ height: 'calc(100vh - 300px)' }}>
      <RoleModal
        visible={visible}
        onClose={handleCloseForm}
        role={role}
        callbackSubmit={callbackSubmit}
      />
      <RoleDeleteConfirm
        visible={visibleConfirm}
        onClose={handleCloseConfirmModal}
        role={role}
        onDelete={onDelete}
        loading={loadingBtn}
      />

      <Box display={'flex'} justifyContent={'space-between'} mb={3}>
        <Box>
          <SearchPermission
            searchValue={search}
            onChange={(e) => handleChangeSearch(e.target.value)}
          />
        </Box>
        <Button variant='contained' onClick={() => handleShowForm()}>
          Add Role
        </Button>
      </Box>

      <DataGrid
        disableColumnFilter
        disableSelectionOnClick
        disableColumnSelector
        hideFooter
        getRowId={(row) => row.RoleId}
        columns={columns}
        rows={data ?? []}
      />
    </Box>
  );
};

export default React.memo(Role);

Role.propTypes = {};
