import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import { INDEX_COLOR } from './constants/color';
import { inputFormat } from '@tenant/utility/constants/default';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import {
  formatNumber,
  getFontSizeLabelChart,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';
import moment from 'moment';

const StatGraphs = ({ data, dateGroupType, nameMapping }) => {
  const [chartData, setChartData] = useState([]);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    if (!data) {
      setChartData([]);
      setEntities([]);
      return;
    }

    setChartData(
      data.map((e) => ({
        ...e.Statistic,
        Date:
          getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day'
            ? moment(e.Date, inputFormat).format('DD MMM')
            : moment(e.Date, inputFormat).format('MMM YYYY'),
      })),
    );
    setEntities(Object.keys(data[0]?.Statistic || {}));
  }, [data]);

  return (
    <ResponsiveContainer width='100%' height={350}>
      <LineChart
        data={chartData}
        dataset={data}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey='Date'
          tickLine={false}
          axisLine={false}
          padding={{ left: 20, right: 20 }}
          fontSize={getFontSizeLabelChart(data)}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          formatter={(value) => formatNumber(value)}
        />
        <Legend verticalAlign='top' height={35} />
        <CartesianGrid stroke='#eee' horizontal={true} vertical={false} />
        {entities?.length > 0
          ? entities.map((e, i) => (
              <Line
                key={e}
                type='monotone'
                dataKey={e}
                name={nameMapping && nameMapping[e] ? nameMapping[e] : e}
                stroke={INDEX_COLOR[i]}
                dot={false}
                strokeWidth={2}
                activeDot={{ r: 4 }}
              />
            ))
          : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(StatGraphs);

StatGraphs.defaultProps = {
  data: [],
};

StatGraphs.propTypes = {
  data: PropTypes.array,
  nameMapping: PropTypes.object,
  dateGroupType: PropTypes.number.isRequired,
};
