import * as yup from 'yup';

export const validationSchema = yup.object({
  CompanyName: yup
    .string()
    .required('Company Name is required')
    .max(255)
    .label('Company Name'),
  Country: yup
    .object()
    .nullable()
    .required('Country is required')
    .label('Country'),
  PhoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'Phone Number is invalid')
    .required('Phone Number is required')
    .label('Phone Number'),
  Industry: yup
    .object()
    .nullable()
    .required('Industry is required')
    .label('Industry'),
  TypeOfCustomers: yup
    .object()
    .nullable()
    .required('Type of Customer is required')
    .label('Type of Customer'),
  NumberOfUsers: yup.object().nullable().required().label('Company Size'),
  SeedTemplate: yup
    .object()
    .nullable()
    .required('Seed Template is required')
    .label('Seed Template'),
  Group: yup.object().nullable(),
  MembershipSchemes: yup.object().nullable(),
  MembershipNumber: yup.number(),
  IsSubcontractorUseRefcom: yup.bool(),
  Region: yup.string().required('Region is required').label('Region'),
  EmailAddress: yup
    .string()
    .email('Primary Email is invalid')
    .required('Primary Email is required')
    .label('Primary Email'),
  Password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password at least 12 characters')
    .matches(/^(?=.*?[A-Z])/, 'Password at least 1 upper case letters (A-Z)')
    .matches(/^(?=.*?[a-z])/, 'Password at least 1 lower case letters (a-z)')
    .matches(/^(?=.*?[0-9])/, 'Password at least 1 number (0-9)')
    .matches(
      /^(?=.*?[!"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~])/,
      'Password  at least one special character (e.g. , ! @ # ?)',
    ),
});

export const validationSchemaMembershipNumber = yup.object({
  MembershipNumber: yup
    .number()
    .integer()
    .required()
    .typeError('Membership Number must be a number')
    .nullable()
    .test(
      'length',
      'Max 300 numbers',
      (val) =>
        val?.toLocaleString('fullwide', { useGrouping: false })?.toString()
          ?.length <= 300,
    )
    .label('Membership Number'),
});

export const EnabledMemberShipByReferralCode = ['fin_1', 'fin_2', 'axa_1'];
