import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';

const CustomsBreadcrumbs = ({ paths }) => {
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {paths.map((path, index) =>
        path?.url ? (
          <Link key={index} to={path.url}>
            {path.label}
          </Link>
        ) : (
          <Typography color='text.primary' key={index}>{path.label}</Typography>
        ),
      )}
    </Breadcrumbs>
  );
};

export default CustomsBreadcrumbs;
