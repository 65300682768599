import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MIN_ENTITY = 1;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TransactionTypeDialog = ({
  open,
  onClose,
  onSave,
  entities,
  onSetEntities,
  initEntities = [0, 1, 2],
}) => {
  const handleChange = (event) => {
    const {
      target: {value},
    } = event;
    if (value?.length < MIN_ENTITY) {
      value.pop();
    }
    const result = typeof value === 'string' ? value.split(',') : value;
    onSetEntities(result);
  };

  const renderValueSelected = (value) => {
    const names = value.map((item) => {
      return entities.find((it) => it.Id == item)?.Description || '';
    });
    return names.join(', ');
  };

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
      open={open}
      maxWidth='md'
      onClose={onClose}
    >
      <DialogTitle sx={{fontSize: 15, backgroundColor: '#F4F7FE'}}>
        Select Transaction Type
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl
          color='error'
          sx={{width: '100%', mb: 3, textAlign: 'right'}}
        >
          Min Number of Entities: {MIN_ENTITY} / {entities?.length}
        </FormControl>

        <FormControl sx={{width: '100%'}}>
          <InputLabel id='multiple-entity-label'>Entity *</InputLabel>
          <Select
            error={!initEntities?.length}
            labelId='multiple-entity-label'
            id='multiple-entity'
            multiple
            value={initEntities}
            onChange={handleChange}
            input={<OutlinedInput label='Transaction Type' />}
            MenuProps={MenuProps}
            renderValue={(selected) => renderValueSelected(selected)}
          >
            {entities.map((item) => (
              <MenuItem key={item.Id} value={item.Id}>
                <Checkbox checked={initEntities.indexOf(item.Id) > -1} />
                {item.Description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button size='large' color='secondary' autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          size='large'
          variant='outlined'
          onClick={() => onSave(initEntities)}
          disabled={!initEntities?.length}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(TransactionTypeDialog);

TransactionTypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  entities: PropTypes.array,
  onSetEntities: PropTypes.func,
};
