import React from 'react';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import AppCard from '@tenant/core/AppCard';
import { formatNumber } from '@tenant/utility/helper/Utils';

const CommonFields = [
  'CustomerCount',
  'SiteCount',
  'QuoteCount',
  'JobCount',
  'VisitCount',
  'InvoiceCount',
  'InvoiceCreditCount',
  'PurchaseOrderCount',
];

const PPMFields = [
  'PPMContractCount',
  'PPMQuoteCount',
  'PPMInvoiceCount',
  'PPMContractPurchaseOrderCount',
];

const LibFields = [
  'NonLoginUserCount',
  'UserWithBackOfficeCount',
  'EngineerCount',
  'BothLoginsCount',
];

const EntityStatisticList = ({ statistics = [] }) => {
  const findStatisticByField = (fieldName) => {
    return (
      statistics.find((statistic) => statistic.field === fieldName) ?? {
        id: fieldName,
        count: '0',
        details: fieldName,
        field: fieldName,
      }
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AppCard mb={4}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
          >
            {CommonFields.map((e) => (
              <Grid key={e} item xs={4} sm={3} md={3} lg={3}>
                <ListItemText
                  primary={formatNumber(findStatisticByField(e).count) || 0}
                  secondary={findStatisticByField(e).details}
                  primaryTypographyProps={{ textAlign: ' center' }}
                  secondaryTypographyProps={{
                    textAlign: ' center',
                    color: 'primary.main',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </AppCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppCard sx={{ height: '100%' }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{
              width: '100%',
              height: '100%',
              bgcolor: 'background.paper',
            }}
          >
            {PPMFields.map((e) => (
              <Grid key={e} item xs={12} sm={6} md={6} lg={6}>
                <ListItemText
                  primary={formatNumber(findStatisticByField(e).count) || 0}
                  secondary={findStatisticByField(e).details}
                  primaryTypographyProps={{ textAlign: ' center' }}
                  secondaryTypographyProps={{
                    textAlign: ' center',
                    color: 'primary.main',
                  }}
                />
              </Grid>
            ))}
            <Divider orientation='vertical' />
          </Grid>
        </AppCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppCard mt={4} sx={{ height: '100%' }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
          >
            {LibFields.map((e) => (
              <Grid key={e} item xs={12} sm={6} md={6} lg={6}>
                <ListItemText
                  primary={formatNumber(findStatisticByField(e).count) || 0}
                  secondary={findStatisticByField(e).details}
                  primaryTypographyProps={{ textAlign: ' center' }}
                  secondaryTypographyProps={{
                    textAlign: ' center',
                    color: 'primary.main',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </AppCard>
      </Grid>
    </Grid>
  );
};

EntityStatisticList.propTypes = {
  statistics: PropTypes.array.isRequired,
};

export default React.memo(EntityStatisticList);
