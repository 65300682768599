import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {Box, Grid, ListItemText, Stack, Typography} from '@mui/material';

import AppTooltip from '@tenant/core/AppTooltip';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import IntlMessages from '@tenant/utility/IntlMessages';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useVehicleTrackingInfo from '../hooks/useVehicleTrackingInfo';
import AppLoader from '@tenant/core/AppLoader';
import {useNavigate, useParams} from 'react-router-dom';
import AppGridContainer from '@tenant/core/AppGridContainer';
import {inputFormat} from '@tenant/utility/constants/default';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import {statusData} from '../constants';

const RequestDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {isLoadingData, getDetail} = useVehicleTrackingInfo();
  const [detail, setDetail] = useState();

  const onClickBackButton = () => {
    if(detail?.GroupId) {
      navigate('/vehicle-tracking/info');
      return;
    }
    navigate('/vehicle-tracking/device?inStock=true');
  };

  const getItem = async (Id, callback) => {
    getDetail(Id, callback);
  };

  const getDetailData = useCallback((id) => {
    setDetail();
    getItem(id, (data) => {
      setDetail(data);
    });
  }, []);

  useEffect(() => {
    if (id) {
      getDetailData(id);
    }
  }, [id]);

  return (
    <>
      <AppsHeader>
        {isLoadingData && <AppLoader />}
        <Box
          sx={{
            cursor: 'pointer',
          }}
          component='span'
          mr={{xs: 2, sm: 4}}
          onClick={onClickBackButton}
        >
          <AppTooltip title={<IntlMessages id='common.back' />}>
            <ArrowBackIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          </AppTooltip>
        </Box>
        <Box
          component='span'
          sx={{
            marginLeft: 'auto',
            display: {sm: 'block'},
          }}
        ></Box>
      </AppsHeader>
      {!!detail && (
        <AppGridContainer sx={{p: 5}} spacing={5} columns={12}>
          <Grid item xs={12} md={4}>
            <ListItemText primary='IMEI' secondary={detail?.Imei || '-'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Company Name'
              secondary={detail?.CompanyName || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText primary='Serial' secondary={detail?.Serial || '-'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText primary='Model' secondary={detail?.Model || '-'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction='column' marginY={2} >
              <Typography>Activity Status</Typography>
              <Stack direction='row' alignItems='center'>
                <Brightness1Icon
                  sx={{
                    color:
                      statusData[detail?.ActivityStatus] !== 'undefined'
                        ? statusData[detail?.ActivityStatus]?.Color
                        : '',
                    fontSize: 14,
                    marginRight: 1,
                  }}
                />
                <Typography>{detail?.ActivityStatus}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Shipment'
              secondary={detail?.Shipment || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Product Code'
              secondary={detail?.ProductCode || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Task Queue'
              secondary={detail?.TaskQueue || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Sold At'
              secondary={detail?.SoldAt ? moment(detail?.SoldAt).format(inputFormat) : '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Current Configuration'
              secondary={detail?.CurrentConfiguration || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Current Firmware'
              secondary={detail?.CurrentFirmware || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Description'
              secondary={detail?.Description || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='First Login'
              secondary={detail?.FirstLogin || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Group Name'
              secondary={detail?.GroupName || '-'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary='Send Date'
              secondary={
                detail?.SendDate
                  ? moment(detail?.SendDate).format(inputFormat)
                  : '-'
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText primary='Imsi' secondary={detail?.Imsi || '-'} />
          </Grid>
        </AppGridContainer>
      )}
    </>
  );
};

export default RequestDetails;
