export const OrderBySubcontractorRequestEnum = {
  MappingCount: 1,
  EmailAddress: 2,
  CompanyName: 3,
  ActivationType: 4,
  SubscriptionStatus: 5,
};

export const OrderDirectionEnum = {
  asc: 1,
  desc: 2,
};
