import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import VehicleTrackingRequest from './request';
import VehicleTrackingInfo from './info';
import VehicleTrackingInfoDetail from './info/detail';
import RequestAdd from './request/components/AddRequest/RequestAdd';
import RequestList from './request/components/RequestList';
import RequestDetails from './request/components/DetailRequest/RequestDetails';

export const vehicleTrackingConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/vehicle-tracking',
    element: <VehicleTrackingRequest />,
    children: [
      {
        path: '/vehicle-tracking/request',
        element: <RequestList />,
      },
      {
        path: '/vehicle-tracking/add',
        element: <RequestAdd />,
      },
      {
        path: '/vehicle-tracking/:id',
        element: <RequestDetails />,
        type: 'Detail',
      },
    ],
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: ['/vehicle-tracking/info'],
    element: <VehicleTrackingInfo />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: ['/vehicle-info/:id'],
    element: <VehicleTrackingInfoDetail />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: ['/vehicle-tracking/device'],
    element: <VehicleTrackingInfo/>,
  },
];
