import React, { useEffect, useState, useMemo, useCallback } from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { formatNumber, numberToCurrency } from '@tenant/utility/helper/Utils';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldTenantDetail } from 'redux/actions';
import { dateRanges } from '@tenant/utility/constants/default';
import AppCard from '@tenant/core/AppCard';
import AppSelect from '@tenant/core/AppSelect';
import {
  formatDate,
  formatDateFromISO,
} from '@tenant/utility/helper/DateHelper';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { Card, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const DEFAULT_XERO_DATE = 'This year';
const FIELD_NAME = 'xeroInvoices';
const defaultRangeDate = dateRanges[DEFAULT_XERO_DATE];

const XeroInvoices = ({ tenantId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD_NAME] ?? null,
    [tenant],
  );
  const { data, filter } = storeTenant ?? {};
  const {
    startDate = defaultRangeDate[0],
    endDate = defaultRangeDate[1],
    selectedXeroDate = DEFAULT_XERO_DATE,
    dataLoaded,
  } = filter ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const total = useMemo(
    () => (data ? data.reduce((a, b) => a + +b.Total, 0) : 0),
    [data],
  );
  const isFlexTable = useMediaQuery(theme.breakpoints.up('xl'));

  const getRemainPaid = useCallback((row) => {
    return (row.Total ?? 0) - (row.AmountPaid ?? 0);
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'InvoiceNumber',
        headerName: 'Invoice Number',
        width: 200,
        resizable: false,
        flex: isFlexTable ? 1 : undefined,
      },
      {
        field: 'InvoiceDate',
        headerName: 'Invoice Date',
        width: 150,
        resizable: false,
        renderCell: (params) => formatDateFromISO(params.value),
      },
      {
        field: 'DueDate',
        headerName: 'DueDate',
        width: 150,
        resizable: false,
        renderCell: (params) => formatDateFromISO(params.value),
      },
      {
        field: 'TotalDiscount',
        headerName: 'Total Discount',
        width: 125,
        resizable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography>
            {numberToCurrency(params.value ?? 0, 2, 'GBP')}
          </Typography>
        ),
      },
      {
        field: 'TotalTax',
        headerName: 'Total Tax',
        width: 125,
        resizable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography>{numberToCurrency(params.value, 2, 'GBP')}</Typography>
        ),
      },
      {
        field: 'Subtotal',
        headerName: 'Subtotal',
        headerAlign: 'right',
        align: 'right',
        width: 125,
        renderCell: (params) => (
          <Typography>{numberToCurrency(params.value, 2, 'GBP')}</Typography>
        ),
      },
      {
        field: 'Total',
        headerName: 'Total',
        headerAlign: 'right',
        align: 'right',
        width: 125,
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {numberToCurrency(params.value, 2, 'GBP')}{' '}
          </Typography>
        ),
      },
      {
        field: 'AmountPaid',
        headerName: 'Amount Paid',
        headerAlign: 'right',
        align: 'right',
        width: 125,
        renderCell: (params) => (
          <Typography
            color={params.value < params.row.Total ? 'error.main' : ''}
          >
            {numberToCurrency(params.value, 2, 'GBP')}
          </Typography>
        ),
      },
      {
        field: 'Remain',
        headerName: 'Remain',
        headerAlign: 'right',
        align: 'right',
        width: 125,
        renderCell: (params) => {
          const remain = getRemainPaid(params.row);
          return (
            <Typography color={remain > 0 ? 'error.main' : ''}>
              {numberToCurrency(remain, 2, 'GBP')}
            </Typography>
          );
        },
      },
    ],
    [getRemainPaid],
  );

  const getList = useCallback(
    async ({ startDate, endDate, selectedXeroDate }) => {
      try {
        if (!tenantId || !startDate || !endDate) {
          return;
        }
        setIsLoading(true);
        const data = await jwtAxios.get(
          `/api/Xero/Tenants/${tenantId}/Invoices`,
          {
            params: {
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
            },
          },
        );

        if (!data.data) {
          return;
        }

        dispatch(
          setFieldTenantDetail({
            tenantId,
            field: FIELD_NAME,
            data: data.data?.Items ?? [],
            filter: {
              startDate,
              endDate,
              selectedXeroDate,
              dataLoaded: true,
            },
          }),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [tenantId, startDate, endDate],
  );

  const handleSelectionXeroDate = (data) => {
    const { keyName, startDate, endDate } = data || {};
    getList({
      startDate,
      endDate,
      selectedXeroDate: keyName,
    });
  };

  useEffect(() => {
    if (dataLoaded) {
      return;
    }
    getList({ startDate, endDate, selectedXeroDate });
  }, []);

  return (
    <AppCard
      title='Invoice'
      sxStyle={{
        height: '100%',
        minHeight: 450,
      }}
      action={
        <DateMenu
          defaultValue={selectedXeroDate}
          handleChangeValue={handleSelectionXeroDate}
        />
      }
    >
      <Grid container spacing={3} justifyContent='space-between' sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={6} sm={3}>
              <AppCard sxStyle={{ textAlign: 'center' }}>
                <ListItemText
                  primary={'Invoice'}
                  secondary={formatNumber(data?.length ?? 0)}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}
                />
              </AppCard>
            </Grid>
            <Grid item xs={6} sm={3}>
              <AppCard sxStyle={{ textAlign: 'center' }}>
                <ListItemText
                  primary={'Total'}
                  secondary={numberToCurrency(total, 2, 'GBP') ?? 0}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    color: 'success.main',
                  }}
                />
              </AppCard>
            </Grid>
            <Grid item xs={6} sm={3}>
              <AppCard sxStyle={{ textAlign: 'center' }}>
                <ListItemText
                  primary={'Amount Paid'}
                  secondary={numberToCurrency(
                    data?.reduce((a, b) => a + (b.AmountPaid ?? 0), 0) ?? 0,
                    2,
                    'GBP',
                  )}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    color: 'primary.main',
                  }}
                />
              </AppCard>
            </Grid>
            <Grid item xs={6} sm={3}>
              <AppCard sxStyle={{ textAlign: 'center' }}>
                <ListItemText
                  primary={'Remain'}
                  secondary={numberToCurrency(
                    ((total ?? 0) -
                      data?.reduce((a, b) => a + (b.AmountPaid ?? 0), 0) ??
                      0) ||
                      0,
                    2,
                    'GBP',
                  )}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    color: 'secondary.main',
                  }}
                />
              </AppCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={4} height={350}>
        <DataGrid
          disableColumnSelector
          hideFooter
          loading={isLoading}
          getRowId={(row) => row.InvoiceNumber}
          rows={data ?? []}
          columns={columns}
        />
      </Box>
    </AppCard>
  );
};

export default React.memo(XeroInvoices);

XeroInvoices.propTypes = {
  tenantId: PropTypes.string.isRequired,
};
