import React from 'react';

const Label = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy - 15}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='16'
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 15}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fill='#82ca9d'
        fontSize='26'
        fontWeight='600'
      >
        {value}
      </text>
    </g>
  );
};

export default Label;
