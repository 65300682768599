import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import {
  checkIsPercentage,
  percentageToNumber,
} from '@tenant/utility/helper/Utils';

const DiscountTextField = (props) => {
  const {
    disabled,
    value,
    minPercent = 0,
    maxPercent = 100,
    minValue = 0,
    maxValue,
    handleChange,
    handleBlur,
    handleFocus,
    ...textFieldProps
  } = props ?? {};

  const onChange = useCallback(
    (e) => {
      let newValue = e.target.value;
      const isPercent = checkIsPercentage(newValue);

      if (isPercent) {
        let newValuePercent = percentageToNumber(newValue);
        if (newValuePercent > maxPercent) newValuePercent = maxPercent;
        if (newValuePercent < minPercent) newValuePercent = minPercent;

        handleChange?.(newValuePercent, isPercent);
        return;
      }

      if (!+newValue) {
        handleChange?.('', isPercent);
        return;
      }

      if (newValue > maxValue) {
        newValue = maxValue;
      }

      if (newValue < minValue) newValue = minValue;

      handleChange?.(newValue, isPercent);
    },
    [handleChange, maxValue, minValue, maxPercent, minPercent],
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value;
      const isPercent = checkIsPercentage(value);
      const convertValue = isPercent ? percentageToNumber(value) : value;
      const newValue = +convertValue || 0;
      const parseValue =
        newValue && !Number.isInteger(+newValue)
          ? parseFloat(newValue).toFixed(2)
          : parseInt(newValue, 10);

      handleBlur?.(parseValue, isPercent);
    },
    [handleBlur],
  );

  return (
    <TextField
      disabled={disabled}
      type='text'
      autoComplete='off'
      value={value || ''}
      sx={{
        '& fieldset': { padding: 0 },
        '& input': { textAlign: 'right' },
      }}
      onFocus={(e) => {
        handleFocus?.();
        e.target.select();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.target.blur();
        }
      }}
      onChange={onChange}
      onBlur={onBlur}
      {...textFieldProps}
    />
  );
};

export default React.memo(DiscountTextField);

DiscountTextField.propTypes = {};
