import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Grid, Tooltip, useMediaQuery } from '@mui/material';
import { RootURL } from '../constants/default';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DisconnectButton from './DisconnectButton';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AddLicenseButton from './AddLicenseButton';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import { useTheme } from '@emotion/react';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppsContent from '@tenant/core/AppsContainer/AppsContent';
import IntlMessages from '@tenant/utility/IntlMessages';
import AppTooltip from '@tenant/core/AppTooltip';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InformationTenantDescription from '@tenant/core/App/Tenant/InformationTenantDescription';
import useTenantService from '@tenant/utility/hooks/useTenantService';
import { Code } from 'react-content-loader';
import { SubscriptionStatusEnum } from '@tenant/utility/constants/enum';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import moment from 'moment';
import { inputFormatDateTime } from '@tenant/utility/constants/default';

const StepMappingList = ({
  data,
  loading,
  isTransfer,
  selection,
  onSelection,
  onGetExistingMappings,
}) => {
  const { getSubscriptionInfo, loading: loadingDetail } = useTenantService();
  const [tenantInfo, setTenantInfo] = useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const { mainTenantId } = useParams();
  const isFlex = useMediaQuery(theme.breakpoints.up('xl'));
  const isHasSubscription = useMemo(() =>
    tenantInfo
      ? moment().isBefore(moment(tenantInfo.EndDate, inputFormatDateTime)) &&
        tenantInfo.SubscriptionStatus === SubscriptionStatusEnum['Active']
      : false,
  );

  const goToAddInvoice = useCallback(
    (id) => {
      navigate(`${RootURL}/${mainTenantId}/${id}/add-invoice`);
    },
    [mainTenantId],
  );

  const columns = useMemo(
    () => [
      {
        hide: !isTransfer,
        field: 'selection',
        headerName: '',
        width: 50,
        renderCell: (params) => (
          <FormControlLabel
            checked={selection === params.row.MappingId}
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            onChange={() => onSelection(params.row.MappingId)}
          />
        ),
      },
      {
        hide: isTransfer,
        field: 'MainTenantName',
        headerName: 'Main Contractor',
        width: 300,
        flex: isFlex ? 1 : undefined,
        renderCell: (params) =>
          params.row.RequestId ? (
            <Box sx={{ width: '100%' }}>
              <Box className='MuiDataGrid-cellContent'>
                <Link
                  to={`/apps/subcontractor-automate/all/${params.row.RequestId}`}
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                >
                  {params.row.MainTenantName}
                </Link>
              </Box>
              <Box className='MuiDataGrid-cellContent'>
                ID: {params.row.MainTenantId}
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              <Box className='MuiDataGrid-cellContent'>
                {params.row.MainTenantName}
              </Box>
              <Box className='MuiDataGrid-cellContent'>
                ID:{' '}
                <Tooltip title={params.row.MainTenantId}>
                  <Box component={'span'}>{params.row.MainTenantId}</Box>
                </Tooltip>
              </Box>
            </Box>
          ),
      },
      {
        field: 'SubTenantName',
        headerName: 'Subcontractor Tenant',
        width: 300,
        flex: isFlex ? 1 : undefined,
        renderCell: (params) => (
          <Box sx={{ width: '100%' }}>
            <Box className='MuiDataGrid-cellContent'>{params.value}</Box>
            <Box className='MuiDataGrid-cellContent'>
              ID:{' '}
              <Tooltip title={params.row.SubTenantId}>
                <Box component={'span'}>{params.row.SubTenantId}</Box>
              </Tooltip>
            </Box>
          </Box>
        ),
      },
      {
        field: 'SubcontractorLibraryEmail',
        headerName: 'Subcontractor Library Email',
        width: 200,
      },
      {
        hide: isTransfer,
        field: 'InvoiceId',
        headerName: 'Access License',
        width: 125,
        align: 'center',
        renderCell: (params) => params.row.InvoiceId && <DoneAllIcon />,
      },
      {
        field: 'MappingDate',
        headerName: 'Mapping Date',
        width: 150,
        renderCell: (params) =>
          params.row.MappingDate ? formatDate(params.row.MappingDate) : '-',
      },
      {
        hide: isTransfer,
        field: 'Action',
        headerName: 'Action',
        width: 125,
        disableColumnSelector: true,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: (params) => (
          <>
            Action{' '}
            {!loadingDetail && !isHasSubscription && (
              <AppGlossary title='The Add access license only applies to the main tenant who has an active subscription' />
            )}
          </>
        ),
        renderCell: (params) => (
          <ButtonGroup variant='outlined' aria-label='outlined button group'>
            {!params.row.InvoiceId && isHasSubscription && (
              <AddLicenseButton
                mapping={params.row}
                onGetExistingMappings={onGetExistingMappings}
              />
            )}

            {!params.row.InvoiceId && (
              <DisconnectButton
                mapping={params.row}
                onGetExistingMappings={onGetExistingMappings}
              />
            )}
          </ButtonGroup>
        ),
      },
    ],
    [
      goToAddInvoice,
      isTransfer,
      data,
      selection,
      onSelection,
      onGetExistingMappings,
      isFlex,
      isHasSubscription,
      loadingDetail,
    ],
  );

  useEffect(() => {
    if (mainTenantId) {
      getSubscriptionInfo({
        params: { tenantId: mainTenantId },
        onSuccess: (data) => {
          setTenantInfo(data);
        },
      });
    }
  }, [mainTenantId]);

  const onClickBackButton = () => {
    navigate('/apps/mapping-manage/all');
  };

  return (
    <>
      <AppsHeader>
        <Box
          sx={{
            cursor: 'pointer',
          }}
          component='span'
          mr={{ xs: 2, sm: 4 }}
          onClick={onClickBackButton}
        >
          <AppTooltip title={<IntlMessages id='common.back' />}>
            <ArrowBackIcon
              sx={{
                color: 'text.secondary',
              }}
            />
          </AppTooltip>
        </Box>
      </AppsHeader>
      <AppsContent isDetailView>
        <Grid container>
          {loadingDetail && (
            <>
              <Grid item xs={6} lg={6} sx={{ svg: { height: 75 }, p: 4 }}>
                <Code />
              </Grid>
              <Grid item xs={6} lg={6} sx={{ svg: { height: 75 }, p: 4 }}>
                <Code />
              </Grid>
            </>
          )}
          {!!tenantInfo && !loadingDetail && (
            <Grid item xs>
              <InformationTenantDescription
                tenant={tenantInfo}
                header='Main Tenant Information'
                sxStyle={{ border: 'none' }}
              />
            </Grid>
          )}
        </Grid>
        <Box sx={{ height: 'calc(100vh - 340px)', p: 4 }}>
          <DataGrid
            loading={loading}
            hideFooter
            disableColumnSelector
            rows={data ?? []}
            columns={columns}
            getRowId={(row) => row.MappingId}
            pageSizeOptions={[5, 10]}
          />
        </Box>
      </AppsContent>
    </>
  );
};

export default React.memo(StepMappingList);

StepMappingList.propTypes = {
  data: PropTypes.array,
  isTransfer: PropTypes.bool,
  loading: PropTypes.bool,
  onGetExistingMappings: PropTypes.func,
  selection: PropTypes.string,
  onSelection: PropTypes.func,
};
