import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useToast from '@tenant/utility/hooks/useToast';
import { currencyFormat } from '@tenant/utility/Utils';
import { API_ENDPOINT } from '../../contants/endpoints';
import jwtAxios from '@tenant/services/auth/jwt-auth';

const ReCollectPayment = ({
  visible,
  onClose,
  contractId,
  onCallBackSubmit,
  item,
}) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useToast();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      const res = await jwtAxios.post(
        API_ENDPOINT.COLLECT_PAYMENT + `?contractId=${contractId}`,
      );

      if (res?.status === 200) {
        showSuccessMessage('Recollect contract successfully');
        onClose?.();
        onCallBackSubmit?.();
      }
    } catch (err) {
      const message =
          typeof err?.response?.data?.Message === 'string'
            ? err.response.data.Message
            : 'Recollect failed!';
        showErrorMessage(message);
        onClose?.();
    } finally {
      setLoadingBtn(false);
    }
  };

  return (
    <Dialog
      open={visible}
      maxWidth='sm'
      fullWidth
      height={500}
      onClose={onClose}
    >
      <DialogTitle fontSize={16}>Collect Payment?</DialogTitle>

      <DialogContent mt={4}>
        <DialogContentText id='alert-dialog-description'>
            We will collect{' '}
            <Typography component='span' color='primary'>
              {currencyFormat(item?.TotalAmount)} {item?.Currency}
            </Typography>{' '}
            for this{' '}
            <Typography component='span' color='primary'>
              {item?.Name}
            </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loadingBtn}
          variant='contained'
          onClick={onSubmit}
        >
          Recollect
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ReCollectPayment);

ReCollectPayment.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCallBackSubmit: PropTypes.func,
  contractId: PropTypes.string,
  tenants: PropTypes.array,
};
