import IntlMessages from '@tenant/utility/IntlMessages';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { getPropertyByField } from '@tenant/utility/helper/Utils';
import {
  GET_LIST_REQUEST_WORKFLOW,
  FETCH_ERROR,
  UPDATE_LIST_REQUEST_WORKFLOW,
} from 'shared/constants/ActionTypes';

export const onGetListRequestWorkflow = (payload) => {
  const {
    url,
    config,
    field,
    responseField = 'data.Items',
    method = 'get',
  } = payload || {};

  const onSuccess = ({ dispatch, data, responseField, params }) => {
    const {
      pageIndex,
      pageSize,
      status,
      searchTerm,
      tenantIds,
      orderBy,
      orderDirection,
      subStatuses,
      activationTypes,
      ...rest
    } = params || {};

    if (data.status === 200) {
      dispatch({
        type: GET_LIST_REQUEST_WORKFLOW,
        payload: {
          pageIndex,
          pageSize,
          status,
          field,
          searchTerm,
          tenantIds,
          orderBy,
          orderDirection,
          subStatuses,
          activationTypes,
          ...rest,
          data: getPropertyByField(data, responseField),
          totalCount: getPropertyByField(data, 'data.TotalCount'),
        },
      });
      return;
    }

    dispatch({
      type: FETCH_ERROR,
      payload: <IntlMessages id='message.somethingWentWrong' />,
    });
  };

  const onError = ({ dispatch, error }) => {
    dispatch({ type: FETCH_ERROR, payload: error.message });
  };

  if (method === 'get') {
    return async (dispatch) => {
      await jwtAxios
        .get(url, config)
        .then((data) => {
          onSuccess({ data, dispatch, responseField, params: config.params });
        })
        .catch((error) => {
          onError({ dispatch, error });
        });
    };
  }

  return async (dispatch) => {
    await jwtAxios
      .post(url, config)
      .then((data) => {
        onSuccess({ data, dispatch, responseField, params: config });
      })
      .catch((error) => {
        onError({ dispatch, error });
      });
  };
};

export const setListRequestWorkflow = (payload) => {
  const { data, field } = payload;
  return (dispatch) =>
    dispatch({
      type: GET_LIST_REQUEST_WORKFLOW,
      payload: {
        field,
        data,
      },
    });
};

export const updateListRequestWorkflow = (payload) => {
  const { item, field } = payload;
  return (dispatch) =>
    dispatch({
      type: UPDATE_LIST_REQUEST_WORKFLOW,
      payload: {
        field,
        item,
      },
    });
};
