import clsx from 'clsx';
import React from 'react';
import { Box } from '@mui/material';

import AppsContainer from '@tenant/core/AppsContainer';
import { useParams, useSearchParams } from 'react-router-dom';
import RequestDetails from './components/RequestDetails';
import RequestList from './components/RequestList';
import IntlMessages from '@tenant/utility/IntlMessages';

const VehicleTrackingInfoDetail = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const inStockParams = searchParams.get('inStock');
  return (
    <AppsContainer
      fullView
      title={
        <IntlMessages
          id={
            inStockParams
              ? 'vehicleTrackingInfo.header2'
              : 'vehicleTrackingInfo.header'
          }
        />
      }
    >
      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: id,
        })}
      >
        <RequestDetails />
      </Box>
    </AppsContainer>
  );
};

export default React.memo(VehicleTrackingInfoDetail);
