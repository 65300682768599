//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';
export const SET_DATA_ANALYTICS = 'set_data_analytics';
export const SET_FILTER_DATA_ANALYTICS = 'set_filter_data_analytics';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTRACTOR_LIST
export const GET_TENANT_LIST = 'GET_TENANT_LIST';
export const GET_TENANT_ALLOW_SMS_LIST = 'GET_TENANT_ALLOW_SMS_LIST';
export const SET_FILTER_TENANT_LIST = 'SET_FILTER_TENANT_LIST';
export const SET_TENANT_ALLOW_SMS_LIST_EXTRA =
  'SET_TENANT_ALLOW_SMS_LIST_EXTRA';
export const TENANT_LIST_LOADING = 'TENANT_LIST_LOADING';

//SUBCONTRACTOR_LIST
export const GET_SUBCONTRACTOR_BY_TOP = 'GET_SUBCONTRACTOR_BY_TOP';
export const SET_FILTER_SUBCONTRACTOR_BY_TOP =
  'SET_FILTER_SUBCONTRACTOR_BY_TOP';
export const GET_SUBCONTRACTOR_BY_MONTH = 'GET_SUBCONTRACTOR_BY_MONTH';
export const SET_FILTER_SUBCONTRACTOR_BY_MONTH =
  'SET_FILTER_SUBCONTRACTOR_BY_MONTH';
export const GET_SUBCONTRACTOR_MAPPING = 'GET_SUBCONTRACTOR_MAPPING';
export const SET_FILTER_SUBCONTRACTOR_MAPPING =
  'SET_FILTER_SUBCONTRACTOR_MAPPING';
export const GET_SUBCONTRACTOR_INDUSTRY = 'GET_SUBCONTRACTOR_INDUSTRY';
export const SET_FILTER_SUBCONTRACTOR_INDUSTRY =
  'SET_FILTER_SUBCONTRACTOR_INDUSTRY';
export const GET_SUBCONTRACTOR_TRENDING = 'GET_SUBCONTRACTOR_TRENDING';
export const SET_FILTER_SUBCONTRACTOR_TRENDING =
  'SET_FILTER_SUBCONTRACTOR_TRENDING';
export const GET_SUBCONTRACTOR_MAPPING_TREND =
  'GET_SUBCONTRACTOR_MAPPING_TREND';
export const SET_FILTER_SUBCONTRACTOR_MAPPING_TREND =
  'SET_FILTER_SUBCONTRACTOR_MAPPING_TREND';
export const GET_SUBCONTRACTOR_TENANT_TREND = 'GET_SUBCONTRACTOR_TENANT_TREND';
export const SET_FILTER_SUBCONTRACTOR_TENANT_TREND =
  'SET_FILTER_SUBCONTRACTOR_TENANT_TREND';

//PUBLIC API LIST
export const API_LIST_LOADING = 'API_LIST_LOADING';
export const GET_PUBLIC_API_LIST = 'GET_PUBLIC_API_LIST';
export const CREATE_NEW_PUBLIC_API = 'CREATE_NEW_PUBLIC_API';
export const GET_TENANT_ID_LIST = 'GET_TENANT_ID_LIST';
export const SET_CACHED_TENANT_ID_LIST = 'SET_CACHED_TENANT_ID_LIST';
export const UPDATE_PUBLIC_API_DETAIL = 'UPDATE_PUBLIC_API_DETAIL';
export const GET_TASK_PUBLIC_DETAIL = 'GET_TASK_PUBLIC_DETAIL';
export const APPROVE_TASK_PUBLIC = 'APPROVE_TASK_PUBLIC';
export const EDIT_PUBLIC_API = 'EDIT_PUBLIC_API';
export const EDIT_PUBLIC_API_DONE = 'EDIT_PUBLIC_API_DONE';
export const DELETE_PUBLIC_API = 'DELETE_PUBLIC_API';
export const CANCEL_TASK_PUBLIC = 'CANCEL_TASK_PUBLIC';

//SQL_MANAGEMENT
export const API_LIST_SQL_LOADING = 'API_LIST_SQL_LOADING';
export const GET_SQL_MANAGEMENT_LIST = 'GET_SQL_MANAGEMENT_LIST';
export const CREATE_NEW_SQL_MANAGEMENT = 'CREATE_NEW_SQL_MANAGEMENT';
export const GET_SQL_MANAGEMENT_DETAIL = 'GET_SQL_MANAGEMENT_DETAIL';
export const APPROVE_SQL = 'APPROVE_SQL';
export const CANCEL_SQL = 'CANCEL_SQL';
export const EDIT_SQL = 'EDIT_SQL';
export const EDIT_SQL_DONE = 'EDIT_SQL_DONE';

// REQUEST WORKFLOW
export const GET_LIST_REQUEST_WORKFLOW = 'GET_LIST_REQUEST_WORKFLOW';
export const UPDATE_LIST_REQUEST_WORKFLOW = 'UPDATE_LIST_REQUEST_WORKFLOW';

// REQUEST REFCOM
export const GET_REFCOM_REFERAL_LOADING = 'GET_REFCOM_REFERAL_LOADING';
export const GET_REFCOM_REFERAL_AND_USAGE = 'GET_REFCOM_REFERAL_AND_USAGE';

export const GET_TRANSACTION_TYPE = 'GET_TRANSACTION_TYPE';
export const GET_REFCOM_TRANSACTION_LOADING = 'GET_REFCOM_TRANSACTION_LOADING';
export const GET_REFCOM_TRANSACTION = 'GET_REFCOM_TRANSACTION';
export const GET_TOP_TEN_REFCOM_TRANSACTION = 'GET_TOP_TEN_REFCOM_TRANSACTION';

// PRODUCT REQUEST
export const PRODUCT_REQUEST_LOADING = 'product-request-loading';
export const GET_PRODUCT_REQUESTS = 'get-product-requests';
export const GET_REQUEST_DETAIL = 'get-request-detail';
export const UPDATE_REQUEST = 'update-request';
export const UPDATE_FILTER_PS = 'update-filter-status';
export const UPDATE_ORDER_BY = 'update-order-by';
export const CACHE_PRODUCTS = 'cache-products';

//AUTHORIZATION
export const SET_AUTHORIZATION_FIELD = 'SET_AUTHORIZATION_FIELD';

// BUNDLE
export const SET_TENANT_SUBSCRIPTION = 'update-tenant-subscription';
export const DISABLED_BUNDLE_NEXT_STEP = 'disabled-bundle-next-step';
export const SET_RECOMMENDED_BUNDLES = 'update-recommended-bundles';
export const SET_BUNDLES = 'update-bundles';
export const UPDATE_POINT_BALANCE = 'update-point-balance';
export const SET_REQUEST_ELIGIBILITY = 'update-request-eligibility';
export const SET_BUNDLE_ORDERS = 'set-bundle-orders';
export const SET_BUNDLE_ORDERS_FILTERS = 'set-bundle-orders-filters';
export const SET_BUNDLE_ORDERS_LIST_PARAMS = 'set-bundle-orders-list-params';
export const SET_IS_BACK_FROM_ORDER_DETAILS = 'set-is-go-back';
export const SET_IS_ADDED_NEW_ORDER = 'set-is-added-new-order';
export const SET_IS_CANCELLED_ORDER = 'set-is-cancelled-order';
export const SET_TRANSACTIONS = 'set-transactions';
export const SET_TRANSACTIONS_PARAMS = 'set-transactions-params';
export const SET_IS_INIT_TRANSACTIONS = 'set-is-init-transactions';
export const SET_LOADING_TRANSACTIONS = 'set-loading-transactions';

// TENANT
export const SET_TENANT_DETAIL = 'set-tenant-detail';
export const SET_DROPDOWN_TENANTS = 'set-dropdown-tenants';
export const SET_TENANT_LIST_FILTER = 'set-tenant-list-filter';
export const SET_TENANT_LIST_PARAMS = 'set-tenant-list-params';
export const SET_TRANSACTION_FILTERS = 'set-transaction-filters';
export const SET_SALE_PERSON_TRACKING = 'set-sale-person-tracking';

//NOTIFICATION
export const GET_TOTAL_COUNT_NOTIFICATION = 'GET_TOTAL_COUNT_NOTIFICATION';
export const GET_LIST_NOTIFICATION = 'GET_LIST_NOTIFICATION';
export const SET_FIELD_NOTIFICATION = 'SET_FIELD_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const DISMISS_ALL_NOTIFICATION = 'DISMISS_ALL_NOTIFICATION';

// COMPANY
export const GET_CONTRACT_ITEMS = 'GET_CONTRACT_ITEMS';
export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const GET_LIST_COMPANIES = 'GET_LIST_COMPANIES';
export const SET_FILTER_COMPANIES_LIST = 'SET_FILTER_COMPANIES_LIST';
export const UPDATE_LIST_COMPANIES = 'UPDATE_LIST_COMPANIES';
export const SET_PACKAGE_INFO = 'SET_PACKAGE_INFO';
export const SET_CUR_TENANT_ID = 'SET_CUR_TENANT_ID';
export const SET_CUR_TENANT = 'SET_CUR_TENANT';
export const SET_CUR_CONTRACT = 'SET_CUR_CONTRACT';
export const SET_PRECONDITION = 'SET_PRECONDITION';
export const GET_LIST_COMPANIES_LOADING = 'GET_LIST_COMPANIES_LOADING';
export const LIST_INVOICE_LINE_EDIT = 'LIST_INVOICE_LINE_EDIT';
export const SET_COMPANY_CONTRACTS = 'SET_COMPANY_CONTRACTS';
export const SET_CURR_COMPANY = 'SET_CURR_COMPANY';
export const SET_PURCHASE_INVOICES = 'SET_PURCHASE_INVOICES';
export const IS_INIT = 'IS_INIT';
export const SET_INCREASE_MAX_USER_NUMBER = 'SET_INCREASE_MAX_USER_NUMBER';
export const SET_IS_MULTIPLE_TENANTS = 'SET_IS_MULTIPLE_TENANTS';
export const SET_CONTRACT_SUMMARY = 'SET_CONTRACT_SUMMARY';
export const SET_COMPANY_MARKETPLACE = 'SET_COMPANY_MARKETPLACE';

// SELECT
export const SET_FIELD_SELECT = 'SET_FIELD_SELECT';

//VEHIHLE TRACKING
export const GET_LIST_VEHICLE_TENANT = 'GET_LIST_VEHICLE_TENANT';
export const GET_LIST_DEVICE_CONTRACT_ITEM = 'GET_LIST_DEVICE_CONTRACT_ITEM';

// TENANT DETAIL
export const SET_FIELD_TENANT_DETAIL = 'SET_FIELD_TENANT_DETAIL';

// MARKETPLACE
export const GET_APP_REQUESTS = 'GET_APP_REQUESTS';
