import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid,
  Box,
  ListItemText,
  TextField,
  Autocomplete,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  INCREASE_MAX_USERS_STEPS,
  PAYMENT_METHODS_ENUM_OBJ,
} from '../../contants/enums';
import AppLoader from '@tenant/core/AppLoader';
import XeroInvoiceInformation from './XeroInvoiceInformation';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import useContractService from '../../hooks/useContractService';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { grey } from '@mui/material/colors';
import { setMaxUserNumber } from 'redux/actions';

const activeStep = 2;

const convertDataXeroForm = (data) => {
  return data
    ? {
        ...data,
        AmountDue: data.GrandTotal,
        IsPercent: !!data.DiscountPercent,
        Discount: data.DiscountAmount || data.DiscountPercent,
        TaxRate: data.Tax,
        Quantity: data.IncreaseCount,
      }
    : undefined;
};

const DetailAddMaxUserNumberConfirm = ({
  visible,
  onClose,
  loadingBtn,
  onSubmit,
  accountingManualPlan,
  paymentMethodType,
  contractId,
  subscriptionStart,
  subscriptionEnd,
  totalUsers,
  checkInvalid,
  value,
  isFirstRender,
  handleChange,
  handleBlur,
  tenants,
  currentTenant,
  currentMaxNumberOfUsers,
  newMaxNumberOfUsers,
  getUsersByTenant,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [xeroForm, setXeroForm] = useState();
  const [selectedTenant, setSelectedTenant] = useState(currentTenant);

  const isMultipleTenants = useSelector(
    ({ company }) => company.isMultipleTenants,
  );

  const { getEditUserPreview, getEditUserPreviewWithDiscount } =
    useContractService();

  const isGoCardless = useMemo(
    () =>
      paymentMethodType?.toString() === PAYMENT_METHODS_ENUM_OBJ['GoCardless'],
    [paymentMethodType],
  );
  const isStripe = useMemo(
    () => paymentMethodType?.toString() === PAYMENT_METHODS_ENUM_OBJ['Stripe'],
    [paymentMethodType],
  );
  const currentSteps = useMemo(() => {
    if (isStripe && !accountingManualPlan) {
      return INCREASE_MAX_USERS_STEPS.filter((e) => e.step === 2);
    }

    if (isGoCardless) {
      return INCREASE_MAX_USERS_STEPS.filter((e) => e.step !== 1);
    }

    return INCREASE_MAX_USERS_STEPS;
  }, [isGoCardless, isStripe, accountingManualPlan]);

  const isIssueInvoice = useMemo(
    () => currentSteps.some((e) => e.step === 0),
    [currentSteps],
  );

  const resetIsConfirm = () => {
    setIsConfirm(false);
  };

  const onChangeXeroForm = useCallback((value) => {
    resetIsConfirm();

    setXeroForm((s) => {
      const newValue = {
        ...s,
        Quantity: s?.Quantity,
        TaxRate: s?.Tax,
        Amount: s?.PerUserPrice || value?.PerUserPrice,
        Discount: s?.DiscountAmount || s?.DiscountPercent || 0,
        IsPercent: !!s?.DiscountPercent,
        AmountDue: s?.GrandTotal,
        ...value,
      };

      return newValue;
    });
  }, []);

  const changeNumber = useMemo(
    () => newMaxNumberOfUsers - currentMaxNumberOfUsers,
    [newMaxNumberOfUsers, currentMaxNumberOfUsers],
  );

  const getEditUserPreviewFnc = useCallback(
    (newQuantity = 1) => {
      setLoading(true);
      getEditUserPreview({
        payload: {
          contractId,
          tenantId: selectedTenant.TenantId,
          increaseCount: newQuantity,
        },
        onSuccess: (data) => {
          onChangeXeroForm(convertDataXeroForm(data?.ObjectData));
        },
        onFinally: () => setLoading(false),
      });
    },
    [contractId, changeNumber, onChangeXeroForm],
  );

  const getNewXeroDetail = (newQuantity) => {
    getEditUserPreviewFnc(newQuantity);
  };

  const onChangeIncrease = (e) => {
    const newChangeNumber = +e.target.value || 1;
    const result = newChangeNumber < 0 ? 1 : newChangeNumber;
    handleChange?.(result);
  };

  const handleSelectTenant = (value) => {
    dispatch(setMaxUserNumber(value.MaximumNumberUser));
    getEditUserPreviewFnc(xeroForm?.Quantity);
    getUsersByTenant({
      isCallBack: true,
      id: value.TenantId,
      isReduceMaxUser: false,
    });
    setSelectedTenant(value);
  };

  useEffect(() => {
    if (!xeroForm && isIssueInvoice) {
      getEditUserPreviewFnc(value);
    }
  }, [isIssueInvoice, xeroForm, getEditUserPreview, value]);

  const onChangeDiscount = (newXeroForm) => {
    setLoading(true);
    getEditUserPreviewWithDiscount({
      payload: {
        contractId,
        tenantId: selectedTenant.TenantId,
        increaseCount: xeroForm?.Quantity,
        discountAmount: newXeroForm?.DiscountAmount,
        discountPercent: newXeroForm?.DiscountPercent,
      },
      onSuccess: (data) => {
        onChangeXeroForm(convertDataXeroForm(data?.ObjectData));
      },
      onFinally: () => setLoading(false),
    });
  };

  return (
    <Dialog
      open={visible}
      aria-labelledby='add-max-user-dialog-title'
      aria-describedby='add-max-user-dialog-description'
      maxWidth='md'
      fullWidth
      height={500}
    >
      {loading && <AppLoader />}

      <DialogTitle id='add-max-user-dialog-title' fontSize={16}>
        All steps will be process
      </DialogTitle>

      {loadingBtn && <AppLoader />}

      <DialogContent sx={{ p: '15px 24px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Stepper activeStep={activeStep} orientation='vertical'>
              {currentSteps.map((step) => (
                <Step completed={false} active key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} columnSpacing={12}>
              {isMultipleTenants && (
                <Grid item xs={12} sm={12}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography component={'span'} variant='button'>
                      Tenant
                    </Typography>
                    <Autocomplete
                      size='small'
                      disableClearable
                      options={tenants}
                      getOptionLabel={(option) => option?.ContactName}
                      isOptionEqualToValue={(option, value) => {
                        return option?.TenantId === value?.TenantId;
                      }}
                      renderOption={(props, option) => (
                        <Box key={option.TenantId} component='li' {...props}>
                          {option?.ContactName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select a tenant'
                          sx={{ width: 200, marginLeft: '10px' }}
                        />
                      )}
                      value={selectedTenant}
                      onChange={(e, value) => {
                        handleSelectTenant(value);
                      }}
                    />
                  </Box>
                  <Divider sx={{ mt: 5 }} />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography
                    component={'span'}
                    variant='button'
                    id='increase-label'
                  >
                    Increase
                  </Typography>{' '}
                  <TextField
                    size='small'
                    autoComplete='off'
                    htmlFor='increase-label'
                    sx={{
                      m: 2,
                      maxWidth: 125,
                      '& .Mui-disabled': {
                        backgroundColor: grey[50],
                      },
                    }}
                    value={value || ''}
                    type='number'
                    required
                    placeholder='Enter a number'
                    error={!isFirstRender && checkInvalid?.()}
                    onChange={onChangeIncrease}
                    onBlur={(e) => {
                      handleBlur?.(e);
                      getNewXeroDetail(+e.target.value || 1);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItemText
                  primary='Number of Users'
                  secondary={totalUsers + '/' + (currentMaxNumberOfUsers ?? 0)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItemText
                  primary='Current Max Number of Users'
                  secondary={currentMaxNumberOfUsers}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItemText
                  primary='New Max Number of Users'
                  secondary={newMaxNumberOfUsers}
                />
              </Grid>
              {isIssueInvoice && (
                <>
                  <Grid item xs={12} sm={6}>
                    <ListItemText
                      primary='Total'
                      secondary={numberToCurrency(
                        +xeroForm?.AmountDue || 0,
                        2,
                        'GBP',
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItemText
                      primary='Contract Price'
                      secondary={numberToCurrency(
                        +xeroForm?.ContractPrice || 0,
                        2,
                        'GBP',
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItemText
                      primary='Subscription'
                      secondary={`${formatDateFromISO(subscriptionStart)} - 
                  ${formatDateFromISO(subscriptionEnd)}`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItemText
                      primary='Duration'
                      secondary={`${xeroForm?.MonthsRemaining ?? '-'} months 
                  ${xeroForm?.DaysRemaining ?? '-'} days`}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {isIssueInvoice && (
              <Box mt={2} mb={2}>
                <XeroInvoiceInformation
                  canEditDiscount
                  xeroForm={xeroForm}
                  onChangeXeroForm={onChangeXeroForm}
                  onChangeDiscount={onChangeDiscount}
                />
              </Box>
            )}
            <DialogContentText mt={4} id='alert-dialog-description'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isConfirm ?? false}
                    onChange={(e) => setIsConfirm(e.target.checked)}
                  />
                }
                label="I've read and understand the content. I'm familiar with the steps involved, and I fully acknowledge that any mistakes could have an impact on invoices, requiring data corrections."
                sx={{
                  alignItems: 'start',
                  '& .MuiFormControlLabel-label': { fontSize: 12 },
                  '& .MuiCheckbox-root': { paddingTop: 0 },
                }}
              />
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {isConfirm && (
          <Typography mr={4} color={'warning.main'} fontSize={12}>
            * Please check the discount before submitting.
          </Typography>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          disabled={!isConfirm || checkInvalid()}
          loading={loadingBtn}
          color='primary'
          variant='contained'
          onClick={() => {
            const payload = selectedTenant
              ? Object.assign(selectedTenant, xeroForm || {})
              : xeroForm;
            onSubmit?.(payload, newMaxNumberOfUsers);
          }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DetailAddMaxUserNumberConfirm);

DetailAddMaxUserNumberConfirm.propTypes = {
  visible: PropTypes.bool,
  loadingBtn: PropTypes.bool,
  accountingManualPlan: PropTypes.bool,
  isFirstRender: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  checkInvalid: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  totalUsers: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contractId: PropTypes.string,
  subscriptionStart: PropTypes.string,
  subscriptionEnd: PropTypes.string,
  paymentMethodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tenantId: PropTypes.string,
  tenants: PropTypes.array,
  currentTenant: PropTypes.object,
  currentMaxNumberOfUsers: PropTypes.number,
  newMaxNumberOfUsers: PropTypes.number,
  getUsersByTenant: PropTypes.func,
};
