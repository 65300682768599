import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { ColorlibStepIconRoot } from 'pages/apps/Company/styles/stepperStyle';

export function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <AirportShuttleIcon />,
      2: <AdfScannerIcon />,
    };
  
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }