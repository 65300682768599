import React, { useState, useCallback } from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';

const useTenantService = () => {
  const [loading, setLoading] = useState(false);

  const getSubscriptionInfo = useCallback(
    async ({ params, onSuccess, onError }) => {
      try {
        const { tenantId } = params || {};
        if (!tenantId) {
          return;
        }
        setLoading(true);
        const { data } = await jwtAxios.get('api/Tenant/SubscriptionInfo', {
          params: { tenantId },
        });
        onSuccess?.(data);
      } catch (err) {
        onError?.(err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    // variables
    loading,
    // services
    getSubscriptionInfo,
  };
};

export default useTenantService;
