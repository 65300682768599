import React, { useState, useCallback, useEffect } from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import AppsContainer from '@tenant/core/AppsContainer';
import SubcontractorMappingList from './components/SubcontractorMappingList';
import { useParams } from 'react-router-dom';
import StepMappingList from './components/StepMappingList';
import { Box } from '@mui/material';
import clsx from 'clsx';
import useMappingService from './hooks/useMappingService';
import SubcontractorMappingFilter from './components/SubcontractorMappingFilter';

const MappingManage = () => {
  const { getExistingMappings, loading } = useMappingService();
  const { mainTenantId } = useParams();
  const [mappingList, setMappingList] = useState();

  const onGetExistingMappings = useCallback(
    (mainTenantId, callback) => {
      getExistingMappings({ mainTenantId }, (data) => {
        setMappingList(data ? [...data] : undefined);
        callback?.(data);
      });
    },
    [getExistingMappings],
  );

  useEffect(() => {
    setMappingList();
    onGetExistingMappings(mainTenantId);
  }, [onGetExistingMappings, mainTenantId]);

  return (
    <AppsContainer fullView title='Management' offset={45}>
      <SubcontractorMappingList isHiddenUI={!!mainTenantId} />
      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: mainTenantId,
        })}
      >
        {mainTenantId ? (
          <StepMappingList
            data={mappingList}
            loading={loading}
            onGetExistingMappings={onGetExistingMappings}
          />
        ) : null}
      </Box>
    </AppsContainer>
  );
};

export default React.memo(MappingManage);

MappingManage.propTypes = {};

export const mappingManageRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ['/apps/mapping-manage/all', '/apps/mapping-manage/:mainTenantId'],
    element: <MappingManage />,
  },
];
