import {
  FETCH_ERROR,
  GET_APP_REQUESTS,
  SET_COMPANY_MARKETPLACE,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@tenant/services/auth/jwt-auth';
import { getPropertyByField } from '../../@tenant/utility/helper/Utils';
import { MARKETPLACE_API } from '../../pages/apps/marketplace/constants';

export const apiPost = (url, data) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const apiGet = (url, params) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .get(url, { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const setMarketplaceUsage = (payload) => {
  return (dispatch) => {
    dispatchMarketplaceUsage(dispatch, payload);
  };
};

export const dispatchMarketplaceUsage = (dispatch, payload) => {
  dispatch({
    type: SET_COMPANY_MARKETPLACE,
    payload,
  });
};

export const getAppRequests = (payload) => {
  const { params } = payload || {};

  const onSuccess = ({ dispatch, data, params }) => {
    const { pageIndex, pageSize, ...filter } = params;

    if (data.status === 200) {
      dispatch({
        type: GET_APP_REQUESTS,
        payload: {
          pageIndex,
          pageSize,
          ...filter,
          data: getPropertyByField(data, 'data.Items'),
          totalCount: getPropertyByField(data, 'data.TotalCount'),
        },
      });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Error on Getting App Requests Occurred',
      });
    }
  };

  const onError = ({ dispatch, error }) => {
    const errorMessage = error?.message ? ': ' + error.message : '';
    dispatch({
      type: FETCH_ERROR,
      payload: 'Error on Getting App Requests' + errorMessage,
    });
  };

  return async (dispatch) => {
    const { Tenants, ...payload } = params ?? {};
    await jwtAxios
      .post(MARKETPLACE_API.GET_APP_REQUEST, payload)
      .then((data) => {
        onSuccess({ data, dispatch, params });
      })
      .catch((error) => {
        onError({ dispatch, error });
      });
  };
};
