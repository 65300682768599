import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Popover,
  Hidden,
  Zoom,
  Checkbox,
  Badge,
  List,
  Tooltip,
  Typography,
  FormGroup,
  FormControlLabel,
  Pagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RequestModal from './RequestModal';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_INDEX,
  inputFormat,
} from '@tenant/utility/constants/default';
import { onGetListRequestWorkflow, setListRequestWorkflow } from 'redux/actions';
import Status from '@tenant/core/App/RequestFlow/Status';
import { addItemToList } from '@tenant/utility/helper/DataHelper';
import { debounce } from '@tenant/utility/helper/Utils';
import TenantFilter from '@tenant/core/AdvancedFilter/TenantFilter';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { statusVehicleData } from 'shared/constants/AppsContants';

const sortList = {
  RequestDate_asc: 1,
  RequestDate_desc: 0,
  CompanyName_asc: 2,
  CompanyName_desc: 3,
}

const RequestList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { vehicleTracking } = useSelector((state) => state.requestWorkflow);
  const { pageIndex, pageSize, totalCount, data, searchTerm, status } =
    vehicleTracking || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState(searchTerm ?? '');

  const checked = useRef([]);
  const tenants = useRef([]);
  const deviceRequestOrderBy = useRef(0);
  const [page, setPage] = useState(pageIndex);
  
  const getListData = useCallback(
    async (payload) => {
      const {
        pageIndex = DEFAULT_PAGE_INDEX,
        pageSize = DEFAULT_PAGE_SIZE,
        status,
        searchTerm,
        tenantIds,
        deviceRequestOrderBy,
      } = payload || {};
      setIsLoading(true);
      await dispatch(
        onGetListRequestWorkflow({
          url: 'api/DeviceRequest',
          field: 'vehicleTracking',
          config: {
            params: {
              status,
              pageIndex,
              pageSize,
              searchTerm,
              tenantIds: tenantIds?.map((t) => t.TenantId).join(', '),
              deviceRequestOrderBy
            },
            paramsSerializer: { indexes: null },
          },
        }),
      );
      setIsLoading(false);
    },
    [dispatch],
  );

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const callBack = useCallback(
    (result) => {
      if (result?.Id) {
        dispatch(
          setListRequestWorkflow({
            field: 'vehicleTracking',
            data: addItemToList({ list: data, item: result }),
          }),
        );
        goToDetail(result.Id);
      }
    },
    [data],
  );

  const columns = useMemo(
    () => [
      {
        field: 'Id',
        headerName: 'Request',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        renderCell: (params) => (
          <Tooltip title='View Details' placement='left'>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={{
                pathname: `/vehicle-tracking/${params.row.Id}`,
              }}
              style={{ textDecoration: 'none', color: 'grey'}}
            >
              <VisibilityIcon fontSize='small' />
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'CompanyName',
        headerName: 'Tenant Name',
        width: 300,
        flex: 1,
        sortable: true,
        renderCell: (params) => (
          <Tooltip title={params.value}>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/tenant/detail/${params.row.TenantId}`}
              style={{ textDecoration: 'none' }}
            >
              {params.value || '-'}
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'RequestCount',
        headerName: 'Device Count',
        width: 120,
        sortable: false,
        type: 'number',
        cellClassName: 'MuiDataGrid-cell--textCenter',
        renderCell: (params) => (
          <Typography>{params.value || '0'}</Typography>
        )
      },
      {
        field: 'RequesterName',
        headerName: 'Request By',
        width: 180,
        sortable: false,
      },
      {
        field: 'RequestDate',
        headerName: 'Request Date',
        width: 150,
        sortable: true,
        renderCell: (params) => (
          <Typography variant='body2'>
            {moment(params.value).format(inputFormat)}
          </Typography>
        ),
      },
      {
        field: 'Status',
        headerName: 'Status',
        width: 150,
        sortable: false,
        renderCell: (params) => <Status status={params.row.Status} statusDataCustom={statusVehicleData}/>,
      },
    ],
    [],
  );

  const onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      getListData({
        pageSize,
        pageIndex: 1,
        searchTerm: event.target.value,
        status: checked.current,
        tenantIds: tenants.current,
        deviceRequestOrderBy: deviceRequestOrderBy.current,
      });
    }
  };

  const statusList = useMemo(() => {
    const result = [];
    for (let i in statusVehicleData) {
      result.push({
        ...statusVehicleData[i],
        Key: i,
      });
    }

    return result;
  }, [statusVehicleData]);

  const clearSearchTerm = () => {
    setSearch('');
    getListData({
      pageSize,
      pageIndex: 1,
      searchTerm: '',
      status: checked.current,
      tenantIds: tenants.current,
    });
  };

  const onChangeStatus = useCallback(
    debounce((statusArray) => {
      getListData({
        pageSize,
        status: statusArray,
        pageIndex: 1,
        searchTerm: search,
        tenantIds: tenants.current,
      });
    }, 200),
    [search],
  );

  const handleChangeCheckbox = (event, Id) => {
    if (event.target.checked) {
      const list = [...checked.current, Id];
      checked.current = list;
    } else {
      const list = checked.current.filter((e) => e !== Id);
      checked.current = list;
    }
    onChangeStatus(checked.current);
  };

  const onPageChange = useCallback((event, newPage) => {
    if (newPage < 0) return;
    setPage(newPage);
    getListData({
      pageIndex: newPage,
      pageSize,
      searchTerm: search,
      status: checked.current,
      tenantIds: tenants.current,
      deviceRequestOrderBy: deviceRequestOrderBy.current,
    });
  }, [search]);

  const handleChangeTenants = useCallback((value) => {
    tenants.current = value;
    getListData({
      pageSize,
      status: checked.current,
      pageIndex: 1,
      searchTerm: search,
      tenantIds: value,
    });
  }, [search]);

  const isStopGetInitial = useMemo(
    () => data?.length > 0 || searchTerm || status,
    [data?.length, searchTerm, status],
  );

  const count = useMemo(() => {
    if (totalCount && pageSize) {
      if (totalCount % pageSize) return Math.floor(totalCount / pageSize) + 1;
      return Math.floor(totalCount / pageSize);
    }
    return 1;
  }, [totalCount, pageSize]);

  useEffect(() => {
    if (isStopGetInitial) {
      return;
    }
    getListData();
  }, [getListData]);


  const handleSortList = (sortArray) => {
    if (!Array.isArray(sortArray) || sortArray.length === 0) return;
    const firstSortObject = sortArray[0];
    const sortKey = `${firstSortObject.field}_${firstSortObject.sort}`;
    deviceRequestOrderBy.current = sortList[sortKey];

    getListData({
      pageSize,
      pageIndex,
      searchTerm,
      status,
      deviceRequestOrderBy: deviceRequestOrderBy.current
    });
  };
  return (
    <>
      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <AppSearch
            isClearable
            searchValue={search ?? ''}
            onClearSearch={clearSearchTerm}
            iconPosition='right'
            id='outlined-adornment-weight'
            size='small'
            overlap={false}
            onKeyDown={onEnterSearch}
            onChange={onChangeSearch}
          ></AppSearch>

          <IconButton
            aria-describedby='filter-popover'
            aria-label='filter'
            onClick={handleClick}
          >
            <Badge
              badgeContent={checked.current.length + tenants.current.length}
              color='primary'
              sx={(theme) => ({
                '& .MuiBadge-badge': {
                  right: -3,
                  top: 13,
                  border: `2px solid ${theme.palette.background.paper}`,
                  padding: '0 4px',
                },
              })}
            >
              <FilterAltOutlinedIcon />
            </Badge>
          </IconButton>

          <Popover
            id='filter-popover'
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement='bottom-end'
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                minWidth: 250,
                bgcolor: 'background.paper',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                p: 3,
              }}
            >
              <FormGroup sx={{ mb: 3 }}>
                {statusList.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checked.current.includes(option.Id)}
                      />
                    }
                    label={option.Name}
                    key={option.Id}
                    onChange={(e) => handleChangeCheckbox(e, option.Id)}
                  />
                ))}
              </FormGroup>
              <TenantFilter
                checkedTenants={tenants.current || []}
                handleToggle={(e, value) => handleChangeTenants(value)}
              />
            </List>
          </Popover>

          <Box
            flex={1}
            gap={4}
            display='flex'
            justifyContent='end'
            alignItems='center'
          >
            <Hidden smDown>
              <Zoom in style={{ transitionDelay: '300ms' }}>
                <Box>
                  <RequestModal callBack={callBack} />
                </Box>
              </Zoom>
            </Hidden>
          </Box>
        </Box>
      </AppsHeader>
      <Hidden smUp>
        <Zoom in style={{ transitionDelay: '300ms' }}>
          <Box>
            <RequestModal callBack={callBack} />
          </Box>
        </Zoom>
      </Hidden>
      <DataGrid
        hideFooter
        disableColumnSelector
        rows={data ?? []}
        columns={columns}
        getRowId={(row) => row.Id}
        disableColumnFilter
        disableColumnMenu
        loading={isLoading}
        sortingMode="server"
        onSortModelChange={handleSortList}
        sx={{
          mt: -2,
          borderTop: 0,
        }}
      />
      <FooterWrapper className='footer'>
        <div className='footerContainer justifyCenter'>
          <Pagination
            count={count}
            page={page}
            onChange={onPageChange}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount | 0}</Typography>
        </div>
      </FooterWrapper>
    </>
  );
};

export default RequestList;
