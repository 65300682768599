import {
  GET_TOTAL_COUNT_NOTIFICATION,
  GET_LIST_NOTIFICATION,
  FETCH_ERROR,
  SET_FIELD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_ALL_NOTIFICATION,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import IntlMessages from '@tenant/utility/IntlMessages';

export const getTotalCountNotification = () => {
  const url = '/api/Notification/CountNotifications';
  return (dispatch) => {
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_TOTAL_COUNT_NOTIFICATION,
            payload: data.data.Total,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getListNotification = () => {
  const url = '/api/Notification/GetAll';
  return (dispatch) => {
    dispatch({ type: SET_FIELD_NOTIFICATION, payload: { loading: true } });
    jwtAxios
      .get(url)
      .then((data) => {
        dispatch({ type: SET_FIELD_NOTIFICATION, payload: { loading: false } });
        if (data.status === 200) {
          dispatch({
            type: GET_LIST_NOTIFICATION,
            payload: data.data.Results,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SET_FIELD_NOTIFICATION, payload: { loading: false } });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const dismissNotification = (payload) => {
  const url = '/api/Notification/Dismiss';
  return (dispatch) => {
    const { notificationId } = payload || {};
    if (!notificationId) {
      return;
    }
    dispatch({
      type: SET_FIELD_NOTIFICATION,
      payload: { loadingIdBtn: notificationId },
    });
    dispatch({
      type: DISMISS_NOTIFICATION,
      payload: { notificationId },
    });
    jwtAxios
      .post(url, payload)
      .then((data) => {
        dispatch({
          type: SET_FIELD_NOTIFICATION,
          payload: { loadingIdBtn: '' },
        });
        if (data.status !== 200) {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_FIELD_NOTIFICATION,
          payload: { loadingIdBtn: '' },
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const dismissAllNotification = () => {
  const url = '/api/Notification/DismissAll';
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_NOTIFICATION,
      payload: { loadingBtn: true },
    });
    jwtAxios
      .post(url)
      .then((data) => {
        dispatch({
          type: SET_FIELD_NOTIFICATION,
          payload: { loadingBtn: false },
        });
        if (data.status === 200) {
          dispatch({
            type: DISMISS_ALL_NOTIFICATION,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_FIELD_NOTIFICATION,
          payload: { loadingBtn: false },
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
