import React, {useState, useEffect, useCallback, useMemo} from 'react';
import SMSUsageChart from './SMSUsageChart';
import AppCard from '@tenant/core/AppCard';
import {useIntl} from 'react-intl';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import moment from 'moment';
import {inputFormat} from 'pages/tenantDetail/constants';
import {Code} from 'react-content-loader';
import PropTypes from 'prop-types';
import AppSelect from '@tenant/core/AppSelect';
import {dateGroupTypes, dateRanges} from '@tenant/utility/constants/default';
import {STATISTIC_DATE_GROUP_TYPE} from '@tenant/utility/constants/enum';
import {getKeyByValue, numberToCurrency} from '@tenant/utility/helper/Utils';
import SMSCostChart from './SMSCostChart';
import {Box, Typography} from '@mui/material';

const DEFAULT_RANGE_DATE = 'This year';

const getRandomArbitrary = (min = 100, max = 1000) => {
  return parseFloat(Math.random() * (max - min) + min).toFixed(0);
};

const data = [
  {
    name: 'Jan 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Feb 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Jan 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Mar 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Apr 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'May 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Jun 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Jul 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Aug 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Sep 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Oct 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Nov 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
  {
    name: 'Dec 2024',
    SmsUsage: getRandomArbitrary(),
    Cost: getRandomArbitrary(1000, 20000),
    SegmentUsage: getRandomArbitrary(),
  },
];

const SMSUsage = ({data, isLoading}) => {
  const {messages} = useIntl();

  return (
    <AppCard title={messages['dashboard.analytics.sms_usage']}>
      {isLoading && <Code />}

      {!isLoading && <SMSUsageChart data={data} />}

      <Box sx={{mt: 4}}>{!isLoading && <SMSCostChart data={data} />}</Box>
    </AppCard>
  );
};

SMSUsage.PropTypes = {};

export default React.memo(SMSUsage);
