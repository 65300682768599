import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import List from '@mui/material/List';
import AppScrollbar from '@tenant/core/AppScrollbar';
import IntlMessages from '@tenant/utility/IntlMessages';
import NotificationItem from './NotificationItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  dismissAllNotification,
  getListNotification,
} from 'redux/actions/Notification';
import TodoListSkeleton from '../AppSkeleton/TodoListSkeleton';
import { LoadingButton } from '@mui/lab';

const AppNotificationContent = ({ onClose, sxStyle }) => {
  const dispatch = useDispatch();
  const { totalCount, dataLoaded, notifications, loading, loadingBtn } =
    useSelector((s) => s.notification);

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getListNotification());
    }
  }, [dispatch, dataLoaded]);

  const onDismissAll = () => {
    dispatch(dismissAllNotification());
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: '5px 20px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: { xs: 56, sm: 70 },
        }}
      >
        <Typography component='h3' variant='h3'>
          <IntlMessages id='common.notifications' />({totalCount ?? 0})
        </Typography>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: 'auto',
            color: 'text.secondary',
          }}
          onClick={onClose}
          size='large'
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: { xs: 'calc(100% - 110px)', sm: 'calc(100% - 110px)' },
        }}
      >
        {loading ? (
          <TodoListSkeleton />
        ) : (
          <List sx={{ py: 0 }}>
            {notifications.map((item) => (
              <NotificationItem key={item.Id} item={item} onClose={onClose} />
            ))}
          </List>
        )}
      </AppScrollbar>
      {notifications?.length > 0 && (
        <LoadingButton
          sx={{
            borderRadius: 0,
            width: '100%',
            textTransform: 'capitalize',
            marginTop: 'auto',
            height: 40,
          }}
          disabled={!!loading}
          variant='contained'
          color='primary'
          loading={loadingBtn}
          onClick={onDismissAll}
        >
          Dismiss All
        </LoadingButton>
      )}
    </Box>
  );
};

export default AppNotificationContent;

AppNotificationContent.propTypes = {
  onClose: PropTypes.func,
  sxStyle: PropTypes.object,
};
