import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Box, Typography } from '@mui/material';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import moment from 'moment';
import { statusData as statusDataInfo } from '../../info/constants';
import { envList, statusData } from 'shared/constants/AppsContants';
import * as yup from 'yup';

export const getTableInfoColumns = (inStock) => {
  if (!inStock) {
    return [
      { field: 'Imei', headerName: 'IMEI', flex: 1, sortable: false },
      {
        field: 'Model',
        headerName: 'Model',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Typography variant='body2'>{params.value || '-'}</Typography>
        ),
      },
      {
        field: 'ActivityStatus',
        headerName: 'Status',
        width: 200,
        sortable: true,
        renderCell: (params) => {
          return (
            <Box component='span' display='flex' alignItems='center'>
              <Brightness1Icon
                sx={{
                  color:
                    statusDataInfo[params.value] !== 'undefined'
                      ? statusDataInfo[params.value]?.Color
                      : '',
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              {params.value}
            </Box>
          );
        },
      },
      {
        field: 'SoldAt',
        headerName: 'Purchase Date',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Typography variant='body2'>
            {formatDate(moment(params.value))}
          </Typography>
        ),
      },
    ];
  }
  return [
    { field: 'Imei', headerName: 'IMEI', flex: 1, sortable: false },
    {
      field: 'CompanyName',
      headerName: 'Company Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>{params.value || '-'}</Typography>
      ),
    },
    { field: 'GroupName', headerName: 'Group Name', flex: 1, sortable: false },
    {
      field: 'ActivityStatus',
      headerName: 'Status',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box component='span' display='flex' alignItems='center'>
            <Brightness1Icon
              sx={{
                color:
                  statusDataInfo[params.value] !== 'undefined'
                    ? statusDataInfo[params.value]?.Color
                    : '',
                fontSize: 14,
                marginRight: 1,
              }}
            />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: 'SendDate',
      headerName: 'Send Date',
      width: '150',
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'SoldAt',
      headerName: 'Sold Date',
      width: '150',
      sortable: false,
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
  ];
};

export const formatData = ({
  Id,
  TenantId,
  CompanyName,
  Status,
  ApprovedBy,
  CreatedBy,
}) => {
  return {
    id: Id,
    tenantId: TenantId,
    tenant: CompanyName,
    status: Status,
    approvedBy: ApprovedBy,
    createdBy: CreatedBy,
  };
};

export const getEnvironmentName = (environment) => {
  return envList.find((e) => e.Id === environment)?.Name ?? '';
};

export const checkIsStatus = (status, name) => {
  return status !== undefined || status !== null
    ? statusData[status]?.Name === name
    : false;
};

export const checkIsCancelledStatus = (status) => {
  return checkIsStatus(status, 'Delete');
};

export const checkIsApprovedStatus = (status) => {
  return checkIsStatus(status, 'Approved');
};

export const validationSchema = yup.object({
  RegistrationNumber: yup.string().required(),
  Make: yup.string(),
  Model: yup.string(),
  VIN: yup.string(),
  LinkedEngineerId: yup.string(),
  TrackingDeviceIMEI: yup.string(),
  AdditionalInfo: yup.string(),
  MOTDueDate: yup.string().nullable(),
  ServiceDueDate: yup.string().nullable(),
  LastKnownLocation: yup.number(),
  MaximumMileage: yup.number(),
  RoadFundLicenceDueDate: yup.string().nullable(),
  LeaseRenewalDueDate: yup.string().nullable(),
});