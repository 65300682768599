export const MESSAGE = {
  DELETE: 'Are you sure, you want to delete the selected request ?',
  ADD_NEW_REQUEST: 'Add new request',
};

export const API_VEHICLE_TRACKING_REQUEST = {
  CREATE: 'api/DeviceRequest',
  CANCEL: (id) => `api/DeviceRequest/${id}/Cancel`,
  GET_DETAIL: (id) => `api/DeviceRequest/${id}`,
  APPROVE: (id) => `api/DeviceRequest/${id}/Approve`,
  GET_VEHICLE_TENANT: '/TenantVehicle',
  UPDATE_LIST_VEHICLE: '/DeviceRequestPublicLink/Save',
  ADD_VEHICLE: (id) => `/TenantVehicle?tenantId=${id}`,
  LIST_ENGINEER: '/TenantEngineer/WithoutStockVehicle',
  GET_DETAIL_DEVICE: 'DeviceRequestPublicLink/DeviceRequest',
  AVAILABLE_IMEIS: 'api/DeviceRequest/AvailableIMEIs',
  SIMS_SUBSCRIPTION: 'api/v2.2/sims',
  DEVICE_CONTRACT_ITEM: 'api/DeviceContractItem',
  CREATE_DEVICE_REQUEST_ALLOWED: 'api/Tenant/CreateDeviceRequestAllowed',
  MODIFY_SIM_CARD: 'api/v2.2/sims/',
  SENT_DEVICE: (id) => `api/DeviceRequest/${id}/Send`,
  UPDATE_DETAIL: (id) => `api/DeviceRequest/UpdateDetail/${id}`,
  GET_CUSTOMER_EMAIL: 'api/DeviceRequest/GetCustomerEmail',
};

export const BASE_URL_SIM = 'https://iot.truphone.com';

export const LABEL_SIM = 'Teltonika Truphone';
