import moment from 'moment';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { appIntl } from '@tenant/utility/helper/Utils';
import { inputFormat } from '@tenant/utility/constants/default';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_BY_MONTH,
  SET_FILTER_SUBCONTRACTOR_BY_MONTH,
} from 'shared/constants/ActionTypes';
import { 
  SUBCONTRACTOR_NAME_KEY,
  ENTITY_SUBCONTRACTOR_ENUM_KEY,
} from '@tenant/utility/constants/enum';

const transformData = (input, excepted = []) => {
  return (!Array.isArray(input) || !input.length) ? [] : input.map((item) => {
    const statistic = {
      Subcontractor: item.Statistic.SubcontractorItemCount,
      Allocation: item.Statistic.AllocationItemCount,
      SubPO: item.Statistic.SubPOItemCount,
      ContractPO: item.Statistic.ContractPOItemCount,
    };

    excepted.forEach(key => delete statistic[key]);

    return {
      Date: moment(item.Date).format(inputFormat),
      Statistic: statistic
    };
  });
};

export const onGetSubcontractorByMonth = (payload) => {
  const { messages } = appIntl();
  const { type, endDate, startDate, dateGroupType } = payload;
  const excepted = Object.keys(ENTITY_SUBCONTRACTOR_ENUM_KEY)
    .filter(key => !type.includes(1 * key)).map(key => SUBCONTRACTOR_NAME_KEY[ENTITY_SUBCONTRACTOR_ENUM_KEY[key]]);

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await jwtAxios
      .post('/api/Subcontractor/CountByMonth', {
        Types: type,
        EndDate: endDate,
        StartDate: startDate,
        DateGroupType: dateGroupType
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ 
            type: FETCH_SUCCESS 
          });

          dispatch({
            type: SET_FILTER_SUBCONTRACTOR_BY_MONTH,
            payload
          });

          dispatch({
            type: GET_SUBCONTRACTOR_BY_MONTH,
            payload: transformData(data.data || [], excepted)
          });


        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
      });
  };
};

export const setFilterSubcontractorByMonth = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILTER_SUBCONTRACTOR_BY_MONTH,
      payload
    });
  };
};
