import React, {useEffect, useState} from 'react';
import PublicAPIChart from '@tenant/core/App/Chart/PublicAPIChart';
import AppCard from '@tenant/core/AppCard';
import {useIntl} from 'react-intl';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import {formatDate} from '@tenant/utility/helper/DateHelper';
import {Code} from 'react-content-loader';
import PropTypes from 'prop-types';
import {dateGroupTypes, dateRanges} from '@tenant/utility/constants/default';
import {Box} from '@mui/material';
import DescriptionTooltip from 'pages/dashboards/Analytics/PublicAPI/DescriptionTooltip';
import {DateMenu} from '@tenant/core/App/Date/DateMenu';
import {getRangeDateByKeyName} from '@tenant/utility/helper/Utils';

const DEFAULT_RANGE_DATE = 'Last 30 days';

const PublicAPI = ({tenantId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(DEFAULT_RANGE_DATE);
  const [data, setData] = useState();
  const [dateGroupType, setDateGroupType] = useState(
    dateGroupTypes[DEFAULT_RANGE_DATE],
  );

  useEffect(() => {
    const {start, end, dateGroupType} =
      getRangeDateByKeyName(DEFAULT_RANGE_DATE);

    getResponseStatistics({
      startDate: start,
      endDate: end,
      dateGroupType,
    });
  }, [tenantId]);

  const handleSelectionType = (data) => {
    const {keyName} = data || {};
    const {start, end, dateGroupType} = getRangeDateByKeyName(keyName);
    setDateGroupType(dateGroupType);
    setSelectedType({selectedType: keyName, dateGroupType});
    getResponseStatistics({
      startDate: start,
      endDate: end,
      dateGroupType,
    });
  };

  const getResponseStatistics = async (filter) => {
    try {
      const {startDate, endDate, dateGroupType} = filter ?? {};
      if (!startDate || !endDate) {
        return;
      }
      setIsLoading(true);
      const {data} = await jwtAxios.get(
        'api/Tenant/GetTenantPublicApiStatistic',
        {
          params: {
            TenantId: tenantId,
            StartDate: formatDate(startDate),
            EndDate: formatDate(endDate),
            dateGroupType,
          },
        },
      );
      if (!data) {
        return;
      }
      setData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const {messages} = useIntl();

  return (
    <AppCard
      sxStyle={{height: 1}}
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>{messages['dashboard.analytics.public_api_request']}</Box>
          <Box>
            <DescriptionTooltip />
          </Box>
        </Box>
      }
      action={
        <DateMenu
          defaultValue={selectedType}
          handleChangeValue={handleSelectionType}
        />
      }
    >
      {isLoading && <Code />}

      {!isLoading && (
        <PublicAPIChart data={data} dateGroupType={dateGroupType} />
      )}
    </AppCard>
  );
};

export default React.memo(PublicAPI);

PublicAPI.propTypes = {
  tenantId: PropTypes.string.isRequired,
};
