import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ReferralCodeSelect = ({ error, helperText, initialData, ...props }) => {
  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={initialData ?? []}
      autoHighlight
      getOptionLabel={(option) => option.ReferralCode}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.Id}
        >
          {option.ReferralCode}
        </Box>
      )}
      isOptionEqualToValue={(option, value) => {
        return option?.Id === value?.Id;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            placeholder: 'Select a referral code',
          }}
        />
      )}
      {...props}
    />
  );
};

export default React.memo(ReferralCodeSelect);

ReferralCodeSelect.propTypes = {};
