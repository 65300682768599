import {
  API_LIST_LOADING,
  CREATE_NEW_PUBLIC_API,
  GET_PUBLIC_API_LIST,
  GET_TENANT_ID_LIST,
  UPDATE_PUBLIC_API_DETAIL,
  GET_TASK_PUBLIC_DETAIL,
  APPROVE_TASK_PUBLIC,
  EDIT_PUBLIC_API,
  EDIT_PUBLIC_API_DONE,
  CANCEL_TASK_PUBLIC,
  SET_CACHED_TENANT_ID_LIST,
} from 'shared/constants/ActionTypes';
import { extraLoadingKeys, loadingKeys } from 'shared/constants/AppsContants';

const initialState = {
  publicapiList: [],
  tenantIdList: [],
  tenantIdListCached: null,
  selectedTask: {},
  totalPublicList: 0,
  editTask: null,
  loading: {
    [loadingKeys.getList]: false,
    [extraLoadingKeys.publicApi + loadingKeys.getDetail]: false,
  },
};

const publicAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PUBLIC_API_LIST:
      return {
        ...state,
        publicapiList: action.payload?.Items || [],
        totalPublicList: action.payload?.TotalCount || 0,
      };
    case EDIT_PUBLIC_API:
      return {
        ...state,
        editTask: action.payload,
      };
    case EDIT_PUBLIC_API_DONE:
      return {
        ...state,
        selectedTask: state.editTask,
        editTask: null,
      };
    case API_LIST_LOADING:
      return {
        ...state,
        loading: {
          [action.payload.key]: action.payload.value,
        },
      };
    case GET_TENANT_ID_LIST:
      return {
        ...state,
        tenantIdList: action.payload,
      };
    case SET_CACHED_TENANT_ID_LIST:
      return {
        ...state,
        tenantIdListCached: action.payload,
      };
    case CREATE_NEW_PUBLIC_API:
      return {
        ...state,
        publicapiList: [action.payload, ...state.publicapiList],
        totalPublicList: state.totalPublicList + 1,
      };
    case GET_TASK_PUBLIC_DETAIL:
      return {
        ...state,
        selectedTask: action.payload,
      };
    case UPDATE_PUBLIC_API_DETAIL:
      return {
        ...state,
        selectedTask: action.payload,
      };
    case APPROVE_TASK_PUBLIC:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          Status: 1,
        },
      };
    case CANCEL_TASK_PUBLIC:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          Status: 2,
        },
      };
    default:
      return state;
  }
};
export default publicAPIReducer;
