import React, {useState, useEffect, useMemo} from 'react';
import AppCard from '@tenant/core/AppCard';
import EntityStatisticList from './EntityStatisticList';
import {statisticData} from '../../constants';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import PropTypes from 'prop-types';
import {formatDate} from '@tenant/utility/helper/DateHelper';
import {Box} from '@mui/material';
import DescriptionTooltip from '@tenant/core/App/DescriptionTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EntityStatisticChooseFilter from './EntityStatisticChooseFilter';
import moment from 'moment';
import {List} from 'react-content-loader';
import {useDispatch, useSelector} from 'react-redux';
import {setFieldTenantDetail} from 'redux/actions';

const FIELD = 'ENTITY_STATISTIC';

const EntityStatistic = ({tenantId}) => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(statisticData);
  const [uptoDate, setUptoDate] = useState();
  const dispatch = useDispatch();
  const tenant = useSelector((s) => s.tenantDetail);
  const storeTenant = useMemo(
    () => tenant?.[tenantId]?.[FIELD] ?? null,
    [tenant],
  );
  const {data: storeData, filter: storeFilter} = storeTenant ?? {};

  const getStatistics = async (uptoDate) => {
    try {
      setLoading(true);
      const data = await jwtAxios.get('api/Tenant/GetTenantEntityStatistic', {
        params: {
          uptoDate: formatDate(uptoDate),
          TenantId: tenantId,
        },
      });

      if (!data.data) {
        return;
      }
      const newStatistics = statisticData.map((e) => ({
        ...e,
        count: data.data[e.field],
      }));
      setStatistics(newStatistics);
      dispatch(
        setFieldTenantDetail({
          tenantId,
          field: FIELD,
          data: newStatistics,
          filter: {uptoDate},
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const defaultUptoDate = storeFilter?.uptoDate ?? new moment();
    setUptoDate(defaultUptoDate);
    if (storeData) {
      setStatistics(storeData);
      return;
    }
    getStatistics(defaultUptoDate);
  }, [tenantId]);

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>Entity Statistics</Box>
          <Box>
            <DescriptionTooltip
              icon={<InfoOutlinedIcon sx={{fontSize: 20}} />}
              title={'analytics.entity.tooltip.caption'}
            />
          </Box>
        </Box>
      }
      sxStyle={{
        height: '100%',
      }}
      action={
        <Box display={'flex'} alignItems={'center'}>
          <EntityStatisticChooseFilter
            value={uptoDate}
            onChange={(value) => {
              setUptoDate(value);
              getStatistics(value);
            }}
          />
        </Box>
      }
    >
      {loading && <List />}

      {!loading && <EntityStatisticList statistics={statistics} />}
    </AppCard>
  );
};

EntityStatistic.propTypes = {
  tenantId: PropTypes.string.isRequired,
};

export default React.memo(EntityStatistic);
