import React, {useCallback, useState} from 'react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {setAuthToken} from '@tenant/services/auth/jwt-auth';
import {loginRequest} from 'authConfig';

const useRefreshToken = () => {
  const {instance, inProgress, accounts} = useMsal();

  const checkHasToken = useCallback(() => {
    const token = localStorage.getItem('token');
    return !!token;
  }, []);

  const processRefreshToken = useCallback(
    (callbackFailure) => {
      const hasToken = checkHasToken();

      if (!hasToken) {
        callbackFailure?.();
        return;
      }

      if (inProgress === InteractionStatus.None && accounts?.[0]) {
        const accessTokenRequest = {
          scopes: loginRequest.scopes,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            const accessToken = accessTokenResponse.accessToken;
            if (accessToken) {
              setAuthToken(accessToken);
            } else {
              callbackFailure?.();
            }
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  // Acquire token interactive success
                  const accessToken = accessTokenResponse.accessToken;
                  if (accessToken) {
                    setAuthToken(accessToken);
                  } else {
                    callbackFailure?.();
                  }
                })
                .catch(function (error) {
                  // Acquire token interactive failure
                  console.error(error);
                });

              return;
            }
            callbackFailure?.();
            console.error(error);
          });
      } else {
        localStorage.removeItem('contractor_portal_logout');
      }
    },
    [instance, accounts, inProgress, checkHasToken],
  );

  return {processRefreshToken};
};

export default useRefreshToken;
