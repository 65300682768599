import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  UPDATE_ORDER_BY,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  orderBy: {
    requestList: 0,
    tenantRequestList: 0,
    tenantList: 0,
    bundleOrders: 0,
    transactions: 0,
  },
  autoHideDuration: 3500,
  customContent: null,
};

const commonReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case UPDATING_CONTENT: {
      return { ...state, error: '', message: '', updatingContent: true };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: payload,
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: payload.message,
        autoHideDuration: payload.autoHideDuration,
        // message: payload,
        loading: false,
        updatingContent: false,
        customContent: payload.customContent || null,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload,
        message: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
        customContent: null,
        autoHideDuration: 3500,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case UPDATE_ORDER_BY:
      return {
        ...state,
        orderBy: {
          ...state.orderBy,
          [payload.key]: payload.value,
        },
      };
    default:
      return state;
  }
};
export default commonReducer;
