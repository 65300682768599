import {
  SET_TENANT_SUBSCRIPTION,
  UPDATE_POINT_BALANCE,
  SET_TENANT_DETAIL,
  SET_DROPDOWN_TENANTS,
  SET_SALE_PERSON_TRACKING,
} from 'shared/constants/ActionTypes';

const initialState = {
  subscriptionInfo: null,
  pointBalance: null,
  tenantDetails: null,
  dropdownTenants: [],
  salePersonTracking: [],
};

const tenantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TENANT_SUBSCRIPTION:
      return {
        ...state,
        subscriptionInfo: payload,
      };
    case UPDATE_POINT_BALANCE:
      return {
        ...state,
        pointBalance: payload,
      };
    case SET_TENANT_DETAIL:
      return {
        ...state,
        tenantDetails: payload,
      };
    case SET_DROPDOWN_TENANTS:
      return {
        ...state,
        dropdownTenants: payload,
      };
    case SET_SALE_PERSON_TRACKING:
      return {
        ...state,
        salePersonTracking: payload,
      };
    default:
      return state;
  }
};

export default tenantReducer;
