import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, Box, Tab, Chip, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Fonts } from 'shared/constants/AppEnums';
import useCompany from '../hooks/useCompany';
import DetailInfoContent from './CompanyDetailContent/DetailInfoContent';
import DetailInvoiceContent from './CompanyDetailContent/DetailInvoiceContent';
import {
  getPaymentTerms,
  setIsMultipleTenants,
  resetStorageTransactions,
} from 'redux/actions';
import { useDispatch } from 'react-redux';
import CustomsBreadcrumbs from '@tenant/core/Breadcrumbs';
import {
  PAYMENT_STATUS,
  AUDIT_TYPE_ENUM,
  PAYMENT_STATUS_ENUM,
} from '../contants/enums';
import DetailMoreTools from './ContractDetailAction/DetailMoreTools';
import AuditTab from './ContractDetail/AuditTab';
import AppInfoView from '@tenant/core/AppInfoView';
import useContractService from '../hooks/useContractService';
import moment from 'moment';
import AuditInvoices from './ContractDetail/AuditInvoices';
import IntlMessages from '@tenant/utility/IntlMessages';
import useVehicleTracking from 'pages/vehicleTracking/request/hooks/useVehicleTracking';
import PointTransactions from 'pages/tenantDetail/components/PointTransactions';
import PointBundles from './ContractDetail/PointBundles';
import {
  CONTRACT_ITEM_TYPE,
  PAYMENT_METHOD_ENUM,
} from '@tenant/utility/constants/enum';

const tabs = [
  {
    id: '1',
    label: 'contract.contractLayer',
  },
  {
    id: '2',
    label: 'contract.invoiceCalculation',
  },
  {
    id: '3',
    label: 'contract.audit',
  },
  {
    id: '4',
    label: 'contract.auditInvoices',
  },
  {
    id: '5',
    label: 'tenant.pointTransactions',
  },
  {
    id: '6',
    label: 'contract.pointBundles',
  },
];

const ContractDetail = () => {
  const dispatch = useDispatch();
  const { id, contract_id } = useParams();
  const {
    getContractDetail,
    getInvoices,
    loadingPurchaseInvoices,
    getPurchaseInvoices,
    getTenantByContract,
  } = useCompany();
  const { getUserLicenseAudit } = useContractService();

  const [tab, setTab] = useState('1');
  const [listInvoices, setListInvoices] = useState([]);
  const [listAudit, setListAudit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInvoice, setLoadingInvoice] = useState(true);
  const [loadingAudit, setLoadingAudit] = useState(false);
  const [contract, setContract] = useState(null);
  const { createDeviceRequestAllowed } = useVehicleTracking();
  const [
    disabledCreateVehicleTracking,
    setDisabledCreateVehicleTracking
  ] = useState('');
  const [purchaseInvoice, setPurchaseInvoice] = useState(null);
  const [tenants, setTenants] = useState(null);
  const [tenantGroup, setTenantGroup] = useState({});

  const tenantIdFromContract = useMemo(
    () => contract?.Contacts?.find((e) => e.IsPrimary)?.TenantId,
    [contract?.Contacts],
  );

  const isActive = useMemo(() => contract?.Status === 1, [contract?.Status]);

  const isEndSubscription = useMemo(
    () =>
      contract?.EndDate
        ? new moment().isAfter(new moment(contract.EndDate))
        : false,
    [contract?.EndDate],
  );

  const isStripeContract = useMemo(
    () => (contract?.PaymentMethod?.PaymentMethodType === PAYMENT_METHOD_ENUM.Stripe),
    [contract?.PaymentMethod]
  );

  const pointBundles = useMemo(
    () => (
      (listInvoices ?? []).filter(
        inv => inv.Type === CONTRACT_ITEM_TYPE.JLPoint
      ) || []
    ),
    [listInvoices]
  );

  const invoicesCalulation = useMemo(
    () => (
      (listInvoices ?? []).filter(
        inv => (isStripeContract ? inv.Type !== CONTRACT_ITEM_TYPE.JLPoint : !!inv)
      ) || []
    ),
    [listInvoices, isStripeContract]
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const onGetContractDetail = useCallback((contractId) => {
    setLoading(true);
    getContractDetail(contractId, (data) => {
      setContract(data);
      setLoading(false);
      onGetInvoices(contractId);
    });
  }, []);

  const onGetLicenseRemovalAudit = useCallback((contractId) => {
    setLoadingAudit(true);
    getUserLicenseAudit({
      payload: { contractId },
      onSuccess: (data) => {
        if (data) {
          const findRemovalNewest = data.find(
            (e) => e.Type === AUDIT_TYPE_ENUM[2],
          );
          setListAudit(
            data.map((e) =>
              e.CreatedAt === findRemovalNewest?.CreatedAt
                ? { ...e, IsNewest: true }
                : e,
            ),
          );
        }
        setLoadingAudit(false);
      },
      onFinally: () => {
        setLoadingAudit(false);
      },
    });
  }, []);

  const onGetInvoices = useCallback((contractId) => {
    setLoadingInvoice(true);
    getInvoices(contractId, (data) => {
      setListInvoices(data.map((item, index) => ({ ...item, Id: index })));
      setLoadingInvoice(false);
    });
  }, []);

  const onGetContractAndInvoice = useCallback((contractId) => {
    onGetContractDetail(contractId);
    onGetInvoices(contractId);
    onGetLicenseRemovalAudit(contractId);
  }, []);

  const onFetchTenantByContract = () => {
    getTenantByContract(contract_id, (tenants) => {
      const { Tenants = [] } = tenants || {};
      const formattedData = Tenants.map((item) => ({
        Tenant: { ...item },
      }));
      setTenants([...Tenants]);
      setTenantGroup({ Tenants: formattedData });
      dispatch(setIsMultipleTenants(formattedData.length > 1));
    });
  };

  const disableCreateVehicleTracking = useCallback(() => {
    createDeviceRequestAllowed({
      id: tenantIdFromContract,
      onSuccess: () => {
        setDisabledCreateVehicleTracking('');
      },
      onError: (err) => {
        setDisabledCreateVehicleTracking(err);
      },
    });
  }, [tenantIdFromContract]);

  useEffect(() => {
    disableCreateVehicleTracking();
  }, [tenantIdFromContract]);

  useEffect(() => {
    dispatch(getPaymentTerms());
    dispatch(resetStorageTransactions());
  }, [dispatch]);

  useEffect(() => {
    onGetContractDetail(contract_id);
  }, [onGetContractDetail, contract_id]);

  useEffect(() => {
    onGetLicenseRemovalAudit(contract_id);
  }, [onGetLicenseRemovalAudit, contract_id]);

  useEffect(() => {
    getPurchaseInvoices({
      contractId: contract_id,
      cbSuccess: (data) => {
        setPurchaseInvoice(data);
      },
    });
  }, [contract_id]);

  useEffect(() => {
    if (contract) {
      onFetchTenantByContract();
    }
  }, [contract]);

  return (
    <Grid container spacing={8}>
      <AppInfoView />
      <Grid item xs={12}>
        <CustomsBreadcrumbs
          paths={[
            { label: 'Home', url: '/' },
            { label: 'Companies', url: '/company' },
            { label: 'Company Detail', url: `/company/${id}` },
            { label: 'Contract Detail' },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          component='h3'
          sx={{
            mb: 4,
            fontSize: 18,
            fontWeight: Fonts.BOLD,
          }}
        >
          {contract?.Description}
          {contract?.Status !== undefined &&
            PAYMENT_STATUS[+contract?.Status] && (
              <Chip
                label={PAYMENT_STATUS[+contract?.Status]?.Name}
                variant='filled'
                sx={{
                  ml: 6,
                  color: 'white',
                  borderColor:
                    PAYMENT_STATUS[+contract?.Status] !== 'undefined'
                      ? PAYMENT_STATUS[+contract?.Status]?.Color
                      : '',
                  backgroundColor:
                    PAYMENT_STATUS[+contract?.Status] !== 'undefined'
                      ? PAYMENT_STATUS[+contract?.Status]?.Color
                      : '',
                }}
              />
            )}
          {contract?.Status === PAYMENT_STATUS_ENUM.Active &&
            contract?.IsPendingChangePaymentMethod && (
              <Chip
                label='Switching pending'
                variant='outlined'
                sx={{ marginLeft: 2 }}
              />
            )}

          {!!tenantIdFromContract && tenants && (
            <Box component={'span'} sx={{ float: 'right' }}>
              <DetailMoreTools
                isInactive={!isActive}
                isEndSubscription={isEndSubscription}
                disabledReduce={contract?.MaximumNumberOfUsers <= 1}
                tenantName={contract?.PrimaryTenantName}
                contractId={contract_id}
                tenantId={tenantIdFromContract}
                subscriptionStart={contract?.StartDate}
                subscriptionEnd={contract?.EndDate}
                maximumNumberOfUsers={contract?.MaximumNumberOfUsers}
                paymentMethodType={contract?.PaymentMethod?.PaymentMethodType}
                accountingManualPlan={contract?.AccountingManualPlan}
                onGetContractAndInvoice={onGetContractAndInvoice}
                onGetContractDetail={onGetContractDetail}
                status={contract?.Status}
                contract={contract}
                setContract={setContract}
                tenants={tenants}
                setTenantGroup={setTenantGroup}
                disabledCreateVehicleTracking={disabledCreateVehicleTracking}
                setLoading={setLoading}
                isLoading={loading}
              />
            </Box>
          )}
        </Box>

        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tab}>
                <Stack
                  direction='row'
                  spacing={1}
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& button.MuiButtonBase-root': {
                      pb: 1,
                    },
                  }}
                >
                  <Box>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label='lab API tabs example'
                    >
                      {tabs
                        .filter(tab => (isStripeContract ? !!tab : tab.id !== '6'))
                        .map(tab => (
                          <Tab
                            key={tab.id}
                            label={<IntlMessages id={tab.label} />}
                            value={tab.id}
                          />
                        ))}
                    </TabList>
                  </Box>
                </Stack>

                <TabPanel value='1'>
                  <DetailInfoContent
                    contract={contract}
                    loading={loading}
                    tenants={tenantGroup}
                  />
                </TabPanel>
                <TabPanel value='2'>
                  <DetailInvoiceContent
                    listInvoices={invoicesCalulation}
                    loading={loadingInvoice || loading}
                  />
                </TabPanel>
                <TabPanel value='3'>
                  <AuditTab
                    data={listAudit}
                    loading={loadingAudit}
                    contract={contract}
                    contractId={contract_id}
                    tenantId={tenantIdFromContract}
                    isEndSubscription={isEndSubscription}
                    onGetContractAndInvoice={onGetContractAndInvoice}
                  />
                </TabPanel>

                <TabPanel value='4'>
                  <AuditInvoices
                    data={purchaseInvoice}
                    loading={loadingPurchaseInvoices}
                  />
                </TabPanel>

                <TabPanel value='5'>
                  <PointTransactions contractId={contract_id} />
                </TabPanel>

                {isStripeContract && (
                  <TabPanel value='6'>
                    <PointBundles
                      data={pointBundles}
                      loading={loadingInvoice || loading}
                    />
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ContractDetail;
