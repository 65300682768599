import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import IntlMessages from '@tenant/utility/IntlMessages';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

import { API } from '../constants';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import AppCard from '@tenant/core/AppCard';
import PercentMenu from '@tenant/core/App/Menu/Percent/PercentMenu';
import TableChart from '@tenant/core/App/Chart/TableChart';

const rowsPerPage = 10;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} component={Paper} />),
  Table: (props) => <Table {...props} stickyHeader />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      left: 2,
    }
  })
);

const columns = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 230,
    headerName: 'Tenant',
    sortable: false,
  },
  {
    field: 'PointsBurnt',
    minWidth: 130,
    headerName: 'Used Points',
    sortable: false,
  },
  {
    field: 'UsagePercent',
    minWidth: 170,
    headerName: 'Usage Percentage (%)',
    sortable: false,
  },
];

const ReachCapStatistic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(90);
  const classes = useStyles();

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async ({ percentValue, pageIndex = 1, } = {}) => {
      try {
        setIsLoading(true);
        const response = await jwtAxios.get(API.GET_REACH_CAP_STATISTIC, {
          params: {
            Percent: percentValue ?? percent,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
          }
        });

        if (response?.status === 200 && response?.data?.Success) {
          if (pageIndex === 1) {
            setData(response?.data?.ObjectData?.Items || []);
          } else {
            setData((s) => [...s, ...response?.data?.ObjectData?.Items || []]);
          }

          setTotal(response?.data?.ObjectData?.Total);
        };
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }, [percent]);

  const handleChangePercent = (data) => {
    setPage(1);
    setPercent(data.Percent);
    getList({ percentValue: data.Percent });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) return;

    getList({ pageIndex: page + 1 });
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <AppCard
        title={
          <Box display='flex' alignItems='center'>
            <Box mr={2}>
              <IntlMessages id='dashboard.reachCapTitle' />
            </Box>
          </Box>
        }
        action={
          <PercentMenu defaultValue={percent} handleChangeValue={handleChangePercent} />
        }
        sxStyle={{
          position: 'relative'
        }}
      >
        {isLoading && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}

        {!!data.length ? (
          <TableVirtuoso
            style={{ height: 325 }}
            data={data}
            components={TableComponents}
            endReached={handleReachedEnd}
            fixedHeaderContent={() => (
              <TableRow>
                <TableCell sx={{ minWidth: 260, maxWidth: 260, backgroundColor: '#FFFFFF' }}>Tenant</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>Used Points</TableCell>
                <TableCell align='right' sx={{ backgroundColor: '#FFFFFF' }}>Usage Percentage (%)</TableCell>
              </TableRow>
            )}
            itemContent={(idx, item) => (
              <React.Fragment key={idx}>
                <>
                  <TableCell sx={{ minWidth: 260, maxWidth: 260, }}>
                    <NavLink
                      className='MuiDataGrid-cellContent'
                      to={`/tenant/detail/${item.TenantId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Tooltip title={item?.CompanyName}>
                        <Typography
                          variant='body2'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.CompanyName}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {item?.PointsBurnt}
                  </TableCell>
                  <TableCell align='right' sx={{ color: 'error.main', fontWeight: 500 }}>
                    {item?.UsagePercent}
                  </TableCell>
                </>

              </React.Fragment>
            )}
          />
        ) : (
          <TableChart
            rows={[]}
            columns={columns}
            uniqueProp={''}
          />
        )}
      </AppCard>
    </>
  );
};

export default React.memo(ReachCapStatistic);
