import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import ToDoApp from './ToDoApp';
import TenantList from './TenantList';
import PublicAPI from './PublicAPI';
import RequestWorkflow from './RequestWorkflow';
import Refcom from './Refcom';
import SubcontractorByTop from './Subcontractor/SubcontractorByTop';
import SubcontractorByMonth from './Subcontractor/SubcontractorByMonth';
import SubcontractorMapping from './Subcontractor/SubcontractorMapping';
import SubcontractorTrending from './Subcontractor/SubcontractorTrending';
import SubcontractorIndustry from './Subcontractor/SubcontractorIndustry';
import SubcontractorTenantTrend from './Subcontractor/SubcontractorTenantTrend';
import SubcontractorMappingTrend from './Subcontractor/SubcontractorMappingTrend';
import ProductUsage from './ProductUsage';
import Notification from './Notification';
import Bundle from './Bundle';
import Tenant from './Tenant';
import Company from './Company';
import Select from './Select';
import Authorization from './Authorization';
import VehicleTracking from './VehicleTracking';
import TenantDetail from "./TenantDetail"
import Marketplace from './Marketplace';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    dashboard: Dashboard,
    common: Common,
    todoApp: ToDoApp,
    tenantList: TenantList,
    publicAPI: PublicAPI,
    requestWorkflow: RequestWorkflow,
    refcom: Refcom,
    subcontractorByTop: SubcontractorByTop,
    subcontractorByMonth: SubcontractorByMonth,
    subcontractorMapping: SubcontractorMapping,
    subcontractorTrending: SubcontractorTrending,
    subcontractorIndustry: SubcontractorIndustry,
    subcontractorTenantTrend: SubcontractorTenantTrend,
    subcontractorMappingTrend: SubcontractorMappingTrend,
    productUsage: ProductUsage,
    notification: Notification,
    bundle: Bundle,
    tenant: Tenant,
    select: Select,
    authorization: Authorization,
    company: Company,
    vehicleTracking: VehicleTracking,
    tenantDetail: TenantDetail,
    marketplace: Marketplace,
  });

export default reducers;
