import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import AppCard from '@tenant/core/AppCard';
import Typography from '@mui/material/Typography';
import AppTooltip from '@tenant/core/AppTooltip';
import AppEmptyResult from '@tenant/core/AppEmptyResult';
import AppSelect from '@tenant/core/AppSelect';
import { Code } from 'react-content-loader';
import { DataGrid } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetSubcontractorByTop } from 'redux/actions';
import { inputFormat } from '@tenant/utility/constants/default';
import {
  ENTITY_SUBCONTRACTOR_ENUM,
  ENTITY_SUBCONTRACTOR_ENUM_KEY,
} from '@tenant/utility/constants/enum';
import { formatNumber } from '@tenant/utility/helper/Utils';

const TopCount = () => {
  const {
    data,
    type,
    total,
    endDate,
    startDate,
    pageSize,
    pageIndex,
    dataRange,
    dateGroupType,
    searchTerm,
  } = useSelector(({ subcontractorByTop }) => subcontractorByTop);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState({
    type,
    endDate,
    startDate,
    pageSize,
    pageIndex,
    dataRange,
    dateGroupType,
    searchTerm,
  });

  const goToTenant = useCallback((e, tenantId) => {
    e.preventDefault();
    if (tenantId) navigate('/tenant/detail/' + tenantId);
  }, []);

  const onCellClick = useCallback((params, event) => {
    if (params.field === 'CompanyName') goToTenant(event, params.row.TenantId);
  }, []);

  const onPageIndexChange = useCallback(
    (payload) => {
      const medium = { ...config, pageIndex: payload + 1 };
      setConfig(medium);
      getStatistic(medium);
      return medium;
    },
    [config],
  );

  const onPageSizeChange = useCallback(
    (payload) => {
      const medium = {
        ...config,
        pageSize: payload,
        pageIndex: 1,
      };
      setConfig(medium);
      getStatistic(medium);
      return medium;
    },
    [config],
  );

  const onSelectionDate = useCallback(
    (data) => {
      const medium = {
        ...config,
        dateGroupType: data.dateGroupType,
        dataRange: data.keyName,
        startDate: moment(data.startDate, inputFormat).toISOString(),
        endDate: moment(data.endDate, inputFormat).toISOString(),
        pageIndex: 1,
      };
      setConfig(medium);
      getStatistic(medium);
      return medium;
    },
    [config],
  );

  const onSelectionType = useCallback(
    (data) => {
      const medium = {
        ...config,
        type: ENTITY_SUBCONTRACTOR_ENUM[data],
        pageIndex: 1,
      };
      setConfig(medium);
      getStatistic(medium);
      return medium;
    },
    [config],
  );

  const getStatistic = useCallback(
    async ({
      type,
      endDate,
      startDate,
      pageSize,
      pageIndex,
      searchTerm,
      dataRange,
      dateGroupType,
    } = config) => {
      try {
        setIsLoading(true);
        await dispatch(
          onGetSubcontractorByTop({
            type,
            endDate,
            startDate,
            pageSize,
            pageIndex,
            searchTerm,
            dataRange,
            dateGroupType,
          }),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const columns = useMemo(
    () => [
      {
        field: 'CompanyName',
        headerName: 'Company',
        minWidth: 200,
        resizable: false,
        flex: 1,
        renderCell: (params) => (
          <Link href='#' underline='none'>
            {params.value}
          </Link>
        ),
      },
      {
        field: 'Industry',
        headerName: 'Industry',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>{params.value}</Typography>
        ),
      },
      {
        field: 'Count',
        headerName: 'Total',
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value)}
          </Typography>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (data?.length <= 0) getStatistic();
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ paddingRight: 5 }}>
            {messages['subcontractor.countbytop']}
          </span>
          <AppTooltip
            arrow
            title={
              <div style={{ padding: 10 }}>
                <p>{messages['subcontractor.topcount.tooltip.caption']}:</p>
                <div>
                  <div style={{ fontSize: 13, paddingTop: 5 }}>
                    -{' '}
                    <strong>
                      {messages['subcontractor.tooltip.subcontractor.title']}:
                    </strong>{' '}
                    {messages['subcontractor.tooltip.subcontractor.content']}
                  </div>
                  <div style={{ fontSize: 13, paddingTop: 5 }}>
                    -{' '}
                    <strong>
                      {messages['subcontractor.tooltip.allocation.title']}:
                    </strong>{' '}
                    {messages['subcontractor.tooltip.allocation.content']}
                  </div>
                  <div style={{ fontSize: 13, paddingTop: 5 }}>
                    -{' '}
                    <strong>
                      {messages['subcontractor.tooltip.subpo.title']}:
                    </strong>{' '}
                    {messages['subcontractor.tooltip.subpo.content']}
                  </div>
                  <div style={{ fontSize: 13, paddingTop: 5 }}>
                    -{' '}
                    <strong>
                      {messages['subcontractor.tooltip.contract.title']}:
                    </strong>{' '}
                    {messages['subcontractor.tooltip.contract.content']}
                  </div>
                </div>
              </div>
            }
          >
            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
          </AppTooltip>
        </Box>
      }
      action={
        <>
          <AppSelect
            menus={Object.keys(ENTITY_SUBCONTRACTOR_ENUM)}
            onChange={onSelectionType}
            defaultValue={ENTITY_SUBCONTRACTOR_ENUM_KEY[type]}
          />
          <DateMenu
            defaultValue={dataRange}
            handleChangeValue={onSelectionDate}
          />
        </>
      }
    >
      {isLoading && <Code />}
      {!isLoading &&
        (data?.length > 0 ? (
          <div style={{ minHeight: 350, height: '100%', width: '100%' }}>
            <DataGrid
              disableColumnSelector
              loading={isLoading}
              columns={columns}
              rowCount={total}
              page={config.pageIndex - 1}
              pageSize={config.pageSize}
              onCellClick={onCellClick}
              onPageChange={onPageIndexChange}
              onPageSizeChange={onPageSizeChange}
              paginationMode='server'
              rows={data.filter((e) => e.TenantId)}
              getRowId={(row) => row.TenantId ?? 'not_used'}
            />
          </div>
        ) : (
          <AppEmptyResult title='Empty' />
        ))}
    </AppCard>
  );
};

export default React.memo(TopCount);
