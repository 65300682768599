import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Slide,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';

import useVehicleTracking from '../hooks/useVehicleTracking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ResquestCancelled = ({item, callBack}) => {
  const [open, setOpen] = React.useState(false);
  const {isLoadingBtn, cancelRequest} = useVehicleTracking();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    cancelRequest(item, callBack);
    handleClose();
  };

  return (
    <>
      <Tooltip title='Cancelled'>
        <LoadingButton color='error' onClick={handleClickOpen}>
          <DeleteIcon />
        </LoadingButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle sx={{fontSize: 20}}>{'Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure, you want to cancel this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <LoadingButton
            color='error'
            onClick={handleCancel}
            loading={isLoadingBtn}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResquestCancelled.propTypes = {
  item: PropTypes.object.isRequired,
  callBack: PropTypes.func,
};

export default React.memo(ResquestCancelled);
