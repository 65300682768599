import React from 'react';
import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';

const InputField = (props) => {
  const {
    errorText,
    type = 'text',
    endAdornmentProp,
    endAdornmentText,
    inputDiscount,
    inputRef = {},
    ...rest
  } = props;
  const [field, meta] = useField(props);

  const _renderHelperText = () => {
    const { touched, error } = meta;
    return Boolean(error) && touched && error;
  };

  return (
    <TextField
      type={type}
      error={Boolean(meta.error) && meta.touched}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      InputProps={{
        endAdornment: endAdornmentProp ? (
          <InputAdornment position='end'>{endAdornmentText}</InputAdornment>
        ) : (
          ''
        ),
        sx: {
          '&.MuiInputBase-root.Mui-disabled': { backgroundColor: '#f8f7f7' },
          '& .MuiInputBase-input.Mui-disabled': {
            backgroundColor: '#f8f7f7',
          },
        },
        inputProps: {
          min: 0,
          ...inputDiscount,
        },
      }}
      inputRef={inputRef}
    />
  );
};

export default InputField;
