import React from 'react';
import { COLORS } from '@tenant/utility/constants/color';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  Label,
} from 'recharts';
import CustomizedShape from './components/Shape';
import CustomizedLabel from './components/Label';
import CustomizedLegend from './components/Legend';
import CustomizedTooltip from './components/Tooltip';
import './styles.css';
import { formatNumber } from '@tenant/utility/helper/Utils';

const CustomActiveShapePieChart = ({
  data,
  width = '100%',
  height = 350,
  legendClass = '',
  unit = 'Tenant',
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          data={data}
          dataKey='value'
          nameKey='text'
          cx='50%'
          cy='50%'
          innerRadius={70}
          outerRadius={100}
          activeIndex={0}
          activeShape={CustomizedShape}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={data[index].color || COLORS[index % COLORS.length]}
            />
          ))}
          <Label
            content={
              <CustomizedLabel
                labelText='Total'
                value={formatNumber(data[0].value + data[1].value)}
              />
            }
            position='center'
          />
        </Pie>
        <Tooltip content={<CustomizedTooltip unit={unit} />} />
        <Legend content={<CustomizedLegend customClass={legendClass} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomActiveShapePieChart;
