import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { at } from 'lodash';
import { InputAdornment } from '@mui/material';

const DatePickerField = (props) => {
  const { label, name, setFieldValue, disabled, required = false, onChangeDate, ...rest } = props;
  const [field, meta] = useField(props);
  let { value: selectedValue } = field;

  const _renderHelperText = () => {
    const { touched, error } = meta;
    return Boolean(error) && touched && error;
  };

  return (
    <DatePicker
      {...rest}
      inputFormat='dd/MM/yyyy'
      label={label}
      value={selectedValue}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
        onChangeDate?.(newValue);
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          required={required}
          fullWidth
          {...params}
          sx={{ backgroundColor: disabled ? '#f8f7f7' : 'inherit' }}
          error={Boolean(meta.error) && meta.touched}
          helperText={_renderHelperText()}
        />
      )}
    />
  );
};

export default DatePickerField;
