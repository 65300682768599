import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useId,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

import { debounce } from '@tenant/utility/helper/Utils';
import useTenant from 'pages/apps/hooks/useTenant';
import IntlMessages from '@tenant/utility/IntlMessages';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SalePersonMultiSelect = ({
  limitTags = 2,
  checkedPersons,
  handleSelectSalePerson,
}) => {
  const id = useId();
  const [checkedValues, setCheckedValues] = useState(checkedPersons);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [initialData, setInitialData] = useState([]);
  const { getListCSMStaff, loadingCMSStaff } = useTenant();

  const getData = (params) => {
    getListCSMStaff(params, (data) => {
      setData(data);

      if (!params?.searchTerm) setInitialData(data);
    });
  };

  const onInputSalePersonChange = debounce((e, value, reason) => {
    if (e?.type === 'change') {
      setData([]);
      getData({
        searchTerm: value,
      });
      setSearch(value);
    }
  }, 700);

  const onOpen = useCallback(() => {
    if (!!data.length || loadingCMSStaff) return;

    getData({
      searchTerm: '',
    });
  }, [data, loadingCMSStaff]);

  const handleBlur = useCallback(() => {
    if (!!search) {
      setSearch('');
      setData(initialData);
    }
  }, [search, initialData]);

  return (
    <Box>
      <Box fontWeight='500' component='p' mb={2}>
        <IntlMessages id='common.salePerson' />
      </Box>
      <Autocomplete
        multiple
        size='small'
        id={`saleperson-multi-select-${id}`}
        limitTags={limitTags}
        options={data}
        disableClearable
        disableCloseOnSelect
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) => option.Id === value.Id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.Id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
          </li>
        )}
        value={checkedValues}
        renderInput={(params) => (
          <TextField {...params} placeholder='Select Sale Person' />
        )}
        onOpen={onOpen}
        loading={loadingCMSStaff}
        onInputChange={onInputSalePersonChange}
        onChange={(e, value) => {
          setCheckedValues(value);
          handleSelectSalePerson(value);
        }}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default React.memo(SalePersonMultiSelect);
