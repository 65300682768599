export const mapItemToList = ({list = [], item = {}, keyField = 'Id'}) => {
  return list
    ? list.map((e) => (e[keyField] === item[keyField] ? {...e, ...item} : e))
    : [];
};

export const removeItemToList = ({list = [], item = {}, keyField = 'Id'}) => {
  return list ? list.filter((e) => e[keyField] !== item[keyField]) : [];
};

export const addItemToList = ({list = [], item = {}}) => {
  const result = list ? [...list] : [];
  result.unshift(item);
  return result;
};
