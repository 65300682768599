import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SUBCONTRACTOR_INDUSTRY,
  SET_FILTER_SUBCONTRACTOR_INDUSTRY,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';
import jwtAxios from '@tenant/services/auth/jwt-auth';

const transformData = (input) => {
  let other = null;
  let otherIndex = null;
  let data = input.map((item, index) => {
    const newItem = {
      name: item.IndustryName.replace(/ /g, ''),
      text: item.IndustryName,
      value: item.Total
    };

    if (newItem.text === 'Other') {
      other = newItem;
      otherIndex = index;
    }

    return newItem;
  });

  if (other) {
    data.splice(otherIndex, 1);
    data.push(other);
  }

  return data;
};

export const onGetSubcontractorIndustry = (payload) => {
  const { type } = payload;
  const { messages } = appIntl();

  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await jwtAxios
      .post('/api/Subcontractor/IndustryStatistic', { Type: type })
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: FETCH_SUCCESS,
          });

          dispatch({
            type: SET_FILTER_SUBCONTRACTOR_INDUSTRY,
            payload
          });

          dispatch({
            type: GET_SUBCONTRACTOR_INDUSTRY,
            payload: transformData(data.data || [])
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message,
        });
      });
  };
};
