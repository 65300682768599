import React from 'react';
import { Grid } from '@mui/material';

import { RoutePermittedRole } from 'shared/constants/AppConst';
import AppGridContainer from '@tenant/core/AppGridContainer';
import AppInfoView from '@tenant/core/AppInfoView';
import AppAnimate from '@tenant/core/AppAnimate';
import ReachExpiryStatistic from './components/ReachExpiryStatistic';
import ReachCapStatistic from './components/ReachCapStatistic';
import BundleUsagePerBundleType from './components/BundleUsagePerBundleType';
import TenantPurchasesStatistic from './components/TenantPurchasesStatistic';
import NewActivation from './components/NewActivation';
import RenewalDashboard from './components/RenewalDashboard';
import CreditControls from 'pages/apps/Company/components/CreditControls';

const PointsDashboards = () => {
  return (
    <>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <AppGridContainer>
          <Grid item xs={12} md={6}>
            <ReachExpiryStatistic />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReachCapStatistic />
          </Grid>
          <Grid item xs={12} md={6}>
            <TenantPurchasesStatistic />
          </Grid>
          <Grid item xs={12} md={6}>
            <BundleUsagePerBundleType />
          </Grid>
          <Grid item xs={12} md={12}>
            <CreditControls />
          </Grid>
          <Grid item xs={12} md={12}>
            <RenewalDashboard />
          </Grid>
          <Grid item xs={12} md={6}>
            <NewActivation />
          </Grid>
        </AppGridContainer>
      </AppAnimate>

      <AppInfoView />
    </>
  );
};

export const pointsDashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/point-dashboards',
    element: <PointsDashboards />,
  },
];

export default pointsDashBoardConfigs;
