import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { SUBSCRIPTION_TYPE_COLOR } from 'pages/tenantDetail/constants';

export const getTableColumns = () => {
  return (
    [
      {
        field: 'TenantName',
        headerName: 'Tenant',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <NavLink
              className='MuiDataGrid-cellContent'
              to={`/tenant/detail/${params.value.tenantId}`}
              style={{
                textDecoration: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </NavLink>
          </Tooltip>
        ),
      },
      {
        field: 'IsPrimaryTenant',
        headerName: 'Primary Tenant',
        minWidth: 130,
        sortable: false,
        align: 'center',
        renderCell: (params) => (
          <>
            {params.value.name ? <CheckIcon color='success' /> : '-'}
          </>
        ),
      },
      {
        field: 'ClientGroupName',
        headerName: 'Client Group',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'PaymentType',
        headerName: 'Payment Type',
        flex: 1,
        minWidth: 110,
        sortable: false,
        renderCell: (params) => (
          <Typography
            variant='body2'
            sx={{ color: params.value.color, fontWeight: 500 }}
          >
            {params.value.name}
          </Typography>
        ),
      },
      {
        field: 'AccountManager',
        headerName: 'Account Manager',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'SalePerson',
        headerName: 'Sale Person',
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'PointsBalance',
        headerName: 'Current Point',
        flex: 1,
        minWidth: 100,
        type: 'number',
        renderCell: (params) => (
          <Typography variant='body2'>{params.value.name}</Typography>
        ),
      },
      {
        field: 'PointsUsage',
        headerName: 'Point Usage',
        flex: 1,
        minWidth: 100,
        type: 'number',
        renderCell: (params) => (
          <Typography variant='body2'>{params.value.name}</Typography>
        ),
      },
      {
        field: 'Email',
        headerName: 'Email',
        flex: 1,
        minWidth: 240,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'Industry',
        headerName: 'Industry',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => (
          <Tooltip title={params.value.name}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {params.value.name}
            </Typography>
          </Tooltip>
        ),
      },
    ]
  );
};

export const getTableRows = (data) => {
  if (!data?.length) return [];

  return data.map((item, index) => ({
    ...item,
    id: index,
    TenantName: {
      name: item.CompanyName || item.AzureResourceName || item.Name,
      tenantId: item.TenantId,
    },
    Email: {
      name: item?.EmailAddress || '-',
    },
    Industry: {
      name: item?.Industry || '-',
    },
    PointsBalance: {
      name: item?.PaymentType === 'GoCardless' ? item?.PointsBalance ?? 0 : '-',
    },
    PointsUsage: {
      name: item?.PaymentType === 'GoCardless' ? item?.PointsUsage ?? 0 : '-',
    },
    ClientGroupName: {
      name: item?.ClientGroupName || '-',
    },
    PaymentType: {
      name: item?.PaymentType || '-',
      color: SUBSCRIPTION_TYPE_COLOR[item?.PaymentType] || 'primary.main'
    },
    AccountManager: {
      name: item?.AccountManager || '-',
    },
    SalePerson: {
      name: item?.SalePerson || '-',
    },
    IsPrimaryTenant: {
      name: item?.IsPrimaryTenant || false,
    }
  })
  );
};


