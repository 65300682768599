import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useModal from '@tenant/utility/hooks/useModal';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import UserRoleAddForm from './UserRoleAddForm';

const initialValuesForm = {
  UserId: '',
  RoleIds: undefined,
};

const validationSchema = yup.object({
  UserId: yup.string().required().label('Email'),
  UserIds: yup.array(),
  RoleIds: yup.array().required().label('Roles'),
  Roles: yup.array(),
});

const UserRoleModal = ({
  onSubmit,
  loading,
  initialState,
  initialRole,
  visible,
  onClose,
  isDetail,
}) => {
  const defaultState = useMemo(
    () =>
      initialRole
        ? {
            ...initialState,
            Roles: [initialRole],
            RoleIds: [initialRole.RoleId],
          }
        : initialState,
    [initialRole, initialState],
  );

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit?.(data, () => {
        onClose();
        setSubmitting(false);
      });
    },
    [onSubmit],
  );

  return (
    <Dialog
      fullWidth
      open={visible}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='sm'
    >
      <Formik
        id='add-user-role-form'
        initialValues={defaultState || initialValuesForm}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, validateField, values, setFieldValue, errors }) => (
          <>
            <DialogTitle
              id='alert-dialog-title'
              fontSize={18}
              display={'flex'}
              justifyContent={'space-between'}
            >
              {initialState ? 'Update' : 'Assignment'} User
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UserRoleAddForm
                disabledEmail={!!initialState}
                disabledRole={!!isDetail}
                values={values}
                setFieldValue={setFieldValue}
                validateField={validateField}
                errors={errors}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color='secondary'>
                Close
              </Button>
              <LoadingButton
                form='add-user-role-form'
                variant='contained'
                onClick={submitForm}
                loading={loading}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(UserRoleModal);

UserRoleModal.propTypes = {};
