import React, { useCallback } from 'react';

const useNextTick = () => {
  const nextTick = useCallback(() => {
    return new Promise((resolve) => {
      resolve();
    });
  }, []);

  return nextTick;
};

export default useNextTick;
