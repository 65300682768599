import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import AppCard from '@tenant/core/AppCard';
import Link from '@mui/material/Link';
import { TRANSACTION_TYPE_ENUM } from '@tenant/utility/constants/enum';
import TransactionTypeDialog from './TransactionTypeDialog';
import { onGetTransactionTypes, setFilterDataDashboard } from 'redux/actions';
import { loadingKeys, extraLoadingKeys } from 'shared/constants/AppsContants';
import LineChartSkeleton from '@tenant/core/AppSkeleton/LineChartSkeleton';
import LineChartTransactionType from './LineChartTransactionType';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionTooltip from '@tenant/core/App/DescriptionTooltip';

const FIELD_NAME = 'transactionType';
const refcomTransactionKey = `${extraLoadingKeys.transactionType}-${loadingKeys.getList}`;

const Transaction = ({ tenantId }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [open, setOpen] = useState(false);
  const loadingType = useSelector(({ refcom }) => refcom.loading);
  const transactionEntities = useSelector(
    ({ refcom }) => refcom.transactionEntities,
  );
  const { [FIELD_NAME]: data, filter } = useSelector((s) => s.dashboard);
  const {
    transaction = [1, 2, 5],
    rangeDate = 'Last 7 days',
    dateGroupType = 0,
  } = filter[FIELD_NAME] || {};
  const defaultTransaction = useRef(transaction);

  const setFilter = (data) => {
    dispatch(
      setFilterDataDashboard({
        data,
        field: FIELD_NAME,
      }),
    );
  };

  const handleSelectionType = (data) => {
    const { startDate, endDate, dateGroupType, keyName } = data || {};
    const params = {
      StartDate: startDate,
      EndDate: endDate,
      TransactionTypeIds: transaction,
      DateGroupType: dateGroupType,
      TenantId: tenantId,
    };
    setFilter({ rangeDate: keyName, dateGroupType });
    dispatch(onGetTransactionTypes(params));
  };

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const onClose = () => {
    setFilter({ transaction: defaultTransaction.current });
    setOpen(false);
  };
  const onSave = (value) => {
    if (!value?.length) {
      return;
    }
    setOpen(false);
    defaultTransaction.current = value;
    setFilter({ transaction: value });
    getReport();
  };

  const getReport = () => {
    const { start, end, dateGroupType } = getRangeDateByKeyName(rangeDate);

    dispatch(
      onGetTransactionTypes({
        StartDate: start,
        EndDate: end,
        TransactionTypeIds: transaction,
        TenantId: tenantId,
        DateGroupType: dateGroupType,
      }),
    );
  };

  const getNameInitTransaction = (idsList) => {
    if (transaction?.length) {
      const maxCharacter = 35;
      const value = idsList
        .map((id) => {
          const name = TRANSACTION_TYPE_ENUM.find(
            (item) => item.Id == id,
          )?.Description;
          return name;
        })
        .join(', ');
      return value?.length > maxCharacter
        ? value.slice(0, maxCharacter) + '...'
        : value;
    }
    return '';
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <AppCard
      sxStyle={{ height: 1 }}
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>{messages['dashboard.analytics.transactionTypes']}</Box>
          <Box>
            <DescriptionTooltip
              icon={<InfoOutlinedIcon sx={{ fontSize: 20 }} />}
              title={'analytics.refcom.transactionTypes'}
            />
          </Box>
        </Box>
      }
      action={
        <>
          <Link href='#' onClick={onOpen} underline='none'>
            {transaction?.length
              ? getNameInitTransaction(transaction)
              : 'Transaction Type'}
          </Link>
          <DateMenu
            defaultValue={rangeDate}
            handleChangeValue={handleSelectionType}
          />
        </>
      }
    >
      <>
        {loadingType[refcomTransactionKey] && <LineChartSkeleton />}
        {!loadingType[refcomTransactionKey] && (
          <TransactionTypeDialog
            open={open}
            onClose={onClose}
            onSave={onSave}
            entities={TRANSACTION_TYPE_ENUM}
            onSetEntities={(data) => setFilter({ transaction: data })}
            initEntities={transaction}
          />
        )}
        {!loadingType[refcomTransactionKey] && (
          <LineChartTransactionType
            data={transactionEntities}
            dateGroupType={dateGroupType}
          />
        )}
      </>
    </AppCard>
  );
};

export default React.memo(Transaction);
