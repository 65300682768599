import { Box, IconButton, Stack } from '@mui/material';
import AppGridContainer from '@tenant/core/AppGridContainer';
import React, { useEffect, useState } from 'react';
import DynamicInput from './DynamicInput';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { API_VEHICLE_TRACKING_REQUEST } from '../../constants';

const listBasic = [
  {
    label: 'Registration Number',
    name: 'RegistrationNumber',
    type: 'text',
  },
  {
    label: 'MOT Due Date',
    name: 'MOTDueDate',
    type: 'DatePicker',
  },
  {
    label: 'Make',
    name: 'Make',
    type: 'text',
  },
  {
    label: 'Model',
    name: 'Model',
    type: 'text',
  },
];

const listAdvances = (id) => [
  {
    label: 'Service Due Date',
    name: 'ServiceDueDate',
    type: 'DatePicker',
  },
  {
    label: 'Last Known Mileage',
    name: 'LastKnownMileage',
    type: 'number',
  },
  {
    label: 'VIN',
    name: 'VIN',
    type: 'text',
  },
  {
    label: 'Maximum Mileage',
    name: 'MaximumMileage',
    type: 'number',
  },
  {
    label: 'Linked Engineer',
    name: 'EngineerId',
    type: 'select',
    optionLabel: 'Name',
    optionKey: 'Id',
    url: API_VEHICLE_TRACKING_REQUEST.LIST_ENGINEER + `?tenantId=${id}`,
    optionEqualToValue: 'Id',
  },
  {
    label: 'Road Fund License Due Date',
    name: 'RoadFundLicenceDueDate',
    type: 'DatePicker',
  },
  {
    label: 'Lease Renewal Due Date',
    name: 'LeaseRenewalDueDate',
    type: 'DatePicker',
  },
  {
    label: 'Additional Info',
    name: 'AdditionalInfo',
    type: 'textarea',
  },
];

function FormCreateVehicle(props) {
  const { setFieldValue, errors, values, tenantId } = props;
  const [expand, setExpand] = useState(false);
  const [fields, setFields] = useState(listBasic);

  useEffect(() => {
    const listAdvancesValue = listAdvances(tenantId);
    if (expand) {
      setFields([...listBasic, ...listAdvancesValue]);
    } else {
      setFields(listBasic);
    }
  }, [expand]);

  return (
    <Box>
      <AppGridContainer spacing={6}>
        {fields.map((field, index) => {
          return (
            <DynamicInput
              key={index}
              setFieldValue={setFieldValue}
              errors={errors}
              values={values}
              {...field}
              {...props}
            />
          );
        })}
        <Stack justifyContent='center' sx={{ width: '100%' }} mt={4}>
          <Box sx={{textAlign: 'center'}}>
            <IconButton onClick={()=>setExpand(!expand)}>
              {!expand ? (
                <KeyboardDoubleArrowDownIcon />
              ) : (
                <KeyboardDoubleArrowUpIcon />
              )}
            </IconButton>
          </Box>
        </Stack>
      </AppGridContainer>
    </Box>
  );
}

export default FormCreateVehicle;
