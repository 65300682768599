import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import AppsContainer from '@tenant/core/AppsContainer';
import CustomsBreadcrumbs from '@tenant/core/Breadcrumbs';

const VehicleTrackingRequest = () => {
  const { id } = useParams();
  const breadcrumbs = [
    { label: 'Home', url: '/' },
    { label: 'Vehicle Tracking Requests', url: '/vehicle-tracking/request' },
  ];
  if (id) {
    breadcrumbs.push({ label: 'Detail' });
  }
  
  return (
    <AppsContainer fullView title={<CustomsBreadcrumbs paths={breadcrumbs} />}>
      <Outlet />
    </AppsContainer>
  );
};

export default React.memo(VehicleTrackingRequest);
