import React, { useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { formatDate } from '@tenant/utility/helper/DateHelper';

import { INDEX_COLOR } from '../../apps/ProductUsage/constants';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import {
  getFontSizeLabelChart,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';

const PointUsageChart = ({ data, dateGroupType }) => {
  const isDay = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day',
    [dateGroupType],
  );

  return (
    <ResponsiveContainer width='100%' height={300}>
      <LineChart
        data={
          data?.Items?.map((e) => ({
            ...e,
            Date: isDay
              ? formatDate(e.Date, 'DD MMM')
              : formatDate(e.Date, 'MMM YYYY'),
          })) ?? []
        }
        dataset={data}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey='Date'
          tickLine={false}
          axisLine={false}
          // padding={{left: 20, right: 20}}
          fontSize={getFontSizeLabelChart(data)}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip labelStyle={{ color: 'black' }} />
        {/* <Legend verticalAlign='top' height={35} /> */}
        <CartesianGrid stroke='#eee' horizontal={true} vertical={false} />
        {/* <Line
          type='monotone'
          dataKey='XeroPoint'
          name={`Invoice`}
          stroke={INDEX_COLOR[1]}
          dot={false}
          strokeWidth={2}
          activeDot={{ r: 4 }}
        /> */}
        <Line
          type='monotone'
          dataKey='JLContractorPoint'
          name={`Points`}
          stroke={INDEX_COLOR[0]}
          dot={false}
          strokeWidth={2}
          activeDot={{ r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

PointUsageChart.defaultProps = {
  data: [],
};

PointUsageChart.propTypes = {
  data: PropTypes.object,
  dateGroupType: PropTypes.number.isRequired,
};

export default React.memo(PointUsageChart);
