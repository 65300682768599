import React from 'react';

import { useSelector } from 'react-redux';
import AppMessageView from '@tenant/core/AppMessageView';
import AppLoader from '@tenant/core/AppLoader';

const AppInfoView = () => {
  const { error, loading, message, autoHideDuration, customContent } = useSelector(({ common }) => common);

  const showMessage = () => {
    return (
      <AppMessageView
        variant='success'
        message={message.toString()}
        autoHideDuration={autoHideDuration}
        customContent={customContent}
      />
    );
  };

  const showError = () => {
    return <AppMessageView variant='error' message={error.toString()} />;
  };

  return (
    <>
      {loading && <AppLoader />}

      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default AppInfoView;
