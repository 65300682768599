import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import {
  API,
  CONTRACT_LENGTH_ENUMS,
  EVENT_TYPE_ENUMS,
  RENEWAL_DASHBOARD_COLUMNS,
  ROW_PER_PAGE,
} from '../constants';

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} component={Paper} />
  )),
  Table: (props) => <Table {...props} stickyHeader />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  EmptyPlaceholder: () => (
    <TableRow
      sx={{
        height: 200,
      }}
    >
      <TableCell
        align='center'
        colSpan={RENEWAL_DASHBOARD_COLUMNS.length}
        sx={{
          borderBottom: 0,
        }}
      >
        <Typography variant='h4'>No data</Typography>
      </TableCell>
    </TableRow>
  ),
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      left: 2,
    },
  }),
);

const RenewalDashboard = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [eventType, setEventType] = React.useState('');
  const [contractLength, setContractLength] = React.useState(2);

  const getList = useCallback(
    async ({ keyName, pageIndex = 1 } = {}) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(keyName || rangeDate);
        const { data = {} } = await jwtAxios.get(API.GET_RENEWAL_EVENTS, {
          params: {
            FromDate: start,
            ToDate: end,
            PageIndex: pageIndex,
            PageSize: ROW_PER_PAGE,
            Event: eventType,
            ContractLength: contractLength,
          },
        });

        if (data?.Data) {
          if (pageIndex === 1) {
            setData(data?.Data || []);
          } else {
            setData((prevState) => [...prevState, ...(data?.Data || [])]);
          }

          setTotal(data?.TotalCount);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate, eventType, contractLength],
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    setPage(1);
    setData([]);
    getList({ keyName });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) {
      return;
    }

    getList({ pageIndex: page + 1 });
    setPage((prevPage) => prevPage + 1);
  };

  const handleSelectEventType = (event) => {
    setEventType(event.target.value);
  };

  const handleSelectContractLength = (event) => {
    setContractLength(event.target.value);
  };

  useEffect(() => {
    getList();
  }, [eventType, contractLength]);

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>Upcoming Annual Renewals</Box>
        </Box>
      }
      action={
        <>
          <FormControl sx={{ mr: 5, minWidth: 200 }} size='small'>
            <InputLabel id='contract-length-label'>Contract Length</InputLabel>
            <Select
              id='contract-length'
              labelId='contract-length-label'
              label='Contract Length'
              value={contractLength}
              onChange={handleSelectContractLength}
            >
              {Object.entries(CONTRACT_LENGTH_ENUMS).map(([keyName, value]) => (
                <MenuItem key={keyName} value={value}>
                  {keyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mr: 5, minWidth: 250 }} size='small'>
            <InputLabel id='event-type-label'>Event Type</InputLabel>
            <Select
              id='event-type'
              labelId='event-type-label'
              label='Event Type'
              value={eventType}
              onChange={handleSelectEventType}
            >
              <MenuItem value=''>None</MenuItem>
              {Object.entries(EVENT_TYPE_ENUMS).map(([key, value], index) => (
                <MenuItem key={key} value={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateMenu
            defaultValue={rangeDate}
            filterBy={[]}
            handleChangeValue={handleChangeDateRange}
          />
        </>
      }
      sxStyle={{
        position: 'relative',
      }}
    >
      {isLoading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}

      <TableVirtuoso
        style={{ height: 325 }}
        data={data}
        components={TableComponents}
        endReached={handleReachedEnd}
        fixedHeaderContent={() => (
          <TableRow>
            {RENEWAL_DASHBOARD_COLUMNS.map(
              ({ field, style, headerName, ...props }) => (
                <TableCell key={field} sx={style} {...props}>
                  {headerName}
                </TableCell>
              ),
            )}
          </TableRow>
        )}
        itemContent={(idx, item) => (
          <React.Fragment key={item.CompanyId || idx}>
            <TableCell sx={{ width: 200, maxWidth: 250 }}>
              <NavLink
                className='MuiDataGrid-cellContent'
                style={{ textDecoration: 'none' }}
                to={`/company/${item.CompanyId}`}
                target='_blank'
              >
                <Tooltip title={item.CompanyName}>
                  <Typography
                    variant='body2'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.CompanyName}
                  </Typography>
                </Tooltip>
              </NavLink>
            </TableCell>
            <TableCell
              sx={{
                minWidth: 150,
                maxWidth: 200,
                wordWrap: 'break-word',
              }}
            >
              {item.ContractName}
            </TableCell>
            <TableCell sx={{ minWidth: 100 }} align='center'>
              {item.ContractLength}
            </TableCell>
            <TableCell align='center'>
              {moment(item.SubscriptionEnd).format('DD/MM/YYYY')}
            </TableCell>
            <TableCell align='center'>{item.EventType}</TableCell>
            <TableCell align='center'>
              {moment(item.EventDate).format('DD/MM/YYYY')}
            </TableCell>
          </React.Fragment>
        )}
      />
    </AppCard>
  );
};

export default RenewalDashboard;
