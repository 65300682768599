import {
  GET_REFCOM_REFERAL_LOADING,
  GET_REFCOM_REFERAL_AND_USAGE,
  GET_TRANSACTION_TYPE,
  GET_TOP_TEN_REFCOM_TRANSACTION,
} from 'shared/constants/ActionTypes';
import { loadingKeys, extraLoadingKeys } from 'shared/constants/AppsContants';

const initialState = {
  refcomReferal: null,
  transactionEntities: null,
  topTransaction: null,
  loading: {
    [`${extraLoadingKeys.refcom}-${loadingKeys.getList}`]: false,
    [`${extraLoadingKeys.transactionType}-${loadingKeys.getList}`]: false,
    [`${extraLoadingKeys.transactionUsage}-${loadingKeys.getList}`]: false,
    [`${extraLoadingKeys.topTenTransaction}-${loadingKeys.getList}`]: false,
  },
};

const refcomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFCOM_REFERAL_AND_USAGE:
      return {
        ...state,
        refcomReferal: action.payload,
      };
    case GET_REFCOM_REFERAL_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.key]: action.payload.value,
        },
      };
    case GET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionEntities: action.payload,
      };
    case GET_TOP_TEN_REFCOM_TRANSACTION:
      return {
        ...state,
        topTransaction: action.payload,
      };
    default:
      return state;
  }
};
export default refcomReducer;
