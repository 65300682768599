import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import { appIntl } from '@tenant/utility/helper/Utils';

const useToast = () => {
  const dispatch = useDispatch();
  const { messages } = appIntl();

  const showSuccessMessage = useCallback(
    (title, autoHideDuration = 3500, customContent = null) => {
      dispatch({
        type: FETCH_SUCCESS,
        payload: title ?? messages['message.successfully'],
      });
      // dispatch({
      //   type: SHOW_MESSAGE,
      //   payload: title ?? messages['message.successfully'],
      // });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: title ?? messages['message.successfully'],
          autoHideDuration,
          customContent,
        },
      });
    },
    [dispatch],
  );

  const showErrorMessage = useCallback(
    (title) => {
      dispatch({
        type: FETCH_ERROR,
        payload: title ?? messages['message.somethingWentWrong'],
      });
    },
    [dispatch],
  );

  return {
    showSuccessMessage,
    showErrorMessage,
  };
};

export default useToast;
