import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

const UserRoleDeleteConfirm = ({
  onDelete,
  loading,
  visible,
  onClose,
  userRole,
}) => {
  return (
    <Dialog
      fullWidth
      open={visible}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='sm'
    >
      <DialogTitle
        id='alert-dialog-title'
        fontSize={18}
        display={'flex'}
        justifyContent={'space-between'}
      >
        Remove Assignment?
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Are you sure to remove assignment{' '}
        <Box component='span' sx={{ fontWeight: 'bold' }}>
          {userRole?.UserId}
        </Box>
        ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Close
        </Button>
        <LoadingButton
          color='error'
          variant='contained'
          loading={loading}
          onClick={() => onDelete({ userId: userRole?.UserId })}
        >
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(UserRoleDeleteConfirm);

UserRoleDeleteConfirm.propTypes = {};
