import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  List,
  Typography,
} from '@mui/material';
import CopyButton from '../CopyButton';
import TenantSubscriptionDetail from '../TenantSubscriptionDetail';
import UserUsage from '../UserUsage';
import EntityStatistic from '../Entity/EntityStatistic';
import Entity from '../Entity/EntityUsage';
import LazyLoad from 'react-lazy-load';
import { Fonts } from 'shared/constants/AppEnums';

const InformationTab = ({
  tenant = {},
  subscription,
  conversationStatistic,
  customerJourneyStage,
  tenantId,
}) => {
  const address = useMemo(() => {
    const addressList = [];
    for (let i = 1; i < 4; i++) {
      if (tenant['Address' + i]) {
        addressList.push(tenant['Address' + i]);
      }
    }
    return addressList.join(', ');
  }, [tenant]);

  return (
    <Grid container spacing={8} alignItems='stretch'>
      <Grid item xs={12} md={5} lg={5} xl={4}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Box
              component='h3'
              sx={{
                mb: 2,
                fontSize: 18,
                fontWeight: Fonts.BOLD,
              }}
            >
              {tenant.Name}
            </Box>

            <Box
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                '& .MuiListItemIcon-root .MuiTypography-root': {
                  minWidth: 75,
                  fontWeight: Fonts.BOLD,
                },
              }}
            >
              <List aria-label='secondary tenant-information'>
                <ListItem
                  secondaryAction={<CopyButton text={tenant.EmailAddress} />}
                >
                  <ListItemIcon>
                    <Typography variant='h5'>Email:</Typography>
                  </ListItemIcon>
                  <Tooltip title={tenant.EmailAddress || '-'}>
                    <ListItemText
                      primary={tenant.EmailAddress || '-'}
                      primaryTypographyProps={{
                        sx: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    />
                  </Tooltip>
                </ListItem>
                <ListItem
                  secondaryAction={<CopyButton text={tenant.TenantId || '-'} />}
                >
                  <ListItemIcon>
                    <Typography variant='h5'>ID:</Typography>
                  </ListItemIcon>
                  <Tooltip title={tenant.TenantId || '-'}>
                    <ListItemText
                      primary={tenant.TenantId || '-'}
                      primaryTypographyProps={{
                        sx: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    />
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Typography variant='h5'>Address:</Typography>
                  </ListItemIcon>
                  {address ? (
                    <ListItemText primary={address} />
                  ) : (
                    <ListItemText primary={'-'} />
                  )}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Typography variant='h5'>Culture:</Typography>
                  </ListItemIcon>
                  <ListItemText primary={tenant.Culture || '-'} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Typography variant='h5'>Currency:</Typography>
                  </ListItemIcon>
                  <ListItemText primary={tenant.Currency || '-'} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Typography variant='h5'>Country:</Typography>
                  </ListItemIcon>
                  <ListItemText primary={tenant.Country || '-'} />
                </ListItem>
              </List>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={7} lg={7} xl={8}>
        <TenantSubscriptionDetail
          subscription={subscription}
          conversationStatistic={conversationStatistic}
          customerJourneyStage={customerJourneyStage}
          packageType={tenant?.PackageType}
        />
      </Grid>

      <Grid item xs={12}>
        {!!tenantId && <UserUsage tenantId={tenantId} />}
      </Grid>

      <Grid item xs={12}>
        <LazyLoad offset={100}>
          {!!tenantId && <EntityStatistic tenantId={tenantId} />}
        </LazyLoad>
      </Grid>

      <Grid item xs={12}>
        <LazyLoad offset={100}>
          {!!tenantId && <Entity tenantId={tenantId} />}
        </LazyLoad>
      </Grid>
    </Grid>
  );
};

export default React.memo(InformationTab);

InformationTab.propTypes = {
  tenant: PropTypes.object,
  subscription: PropTypes.object,
  conversationStatistic: PropTypes.object,
  customerJourneyStage: PropTypes.object,
  tenantId: PropTypes.string,
};
