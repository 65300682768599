import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { TAB_ENUM } from '../constants';

const Tabs = ({
  children,
  tenant,
  breadcrumbs,
  forceTab,
  onChange,
  isReverse,
}) => {
  const [value, setValue] = React.useState('1');
  const {
    IsPublicApiAllowed,
    IsSqlPermissionAllowed,
    IsUseTeltonika,
    IsMainContractor,
    IsRefcomEnabled,
    IsSMSAllowed,
  } = tenant ?? {};

  const tabs = useMemo(() => {
    const excludes = [];
    if (!IsPublicApiAllowed) {
      excludes.push('Public API');
    }
    if (!IsSqlPermissionAllowed) {
      excludes.push('Reporting Access');
    }
    if (!IsUseTeltonika) {
      excludes.push('Vehicle Tracking');
    }
    if (!IsMainContractor) {
      excludes.push('Subcontractor');
    }
    if (!IsRefcomEnabled) {
      excludes.push('Refcom');
    }
    if (!IsSMSAllowed) {
      excludes.push('SMS');
    }

    return Object.keys(TAB_ENUM).filter((e) => !excludes.includes(e));
  }, [
    IsPublicApiAllowed,
    IsSqlPermissionAllowed,
    IsUseTeltonika,
    IsMainContractor,
    IsRefcomEnabled,
    IsSMSAllowed,
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange?.();
  };

  useEffect(() => {
    if (forceTab) {
      setValue(forceTab);
    }
  }, [forceTab]);

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
      }}
    >
      <TabContext value={value}>
        <Box
          alignItems={'center'}
          display={'flex'}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            flexDirection: isReverse ? 'row-reverse' : '',
            height: 50,
            overflow: 'hidden',
            backgroundColor: 'background.paper',
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '60%', lg: '70%' },
              '& .MuiTabs-scroller': {
                display: 'flex',
                justifyContent: isReverse ? 'end' : 'start',
              },
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label='label Tenant tabs'
              variant='scrollable'
            >
              {tabs.map((e) => (
                <Tab key={e} label={e} value={TAB_ENUM[e]} />
              ))}
            </TabList>
          </Box>
          <Box
            sx={{
              width: { sm: '100%', md: '40%', lg: '30%' },
              textAlign: 'right',
              mr: 3,
            }}
          >
            {breadcrumbs}
          </Box>
        </Box>

        <Box sx={{ height: 'calc(100vh - 125px)', overflow: 'auto', p: 2 }}>
          {children}
        </Box>
      </TabContext>
    </Box>
  );
};

export default React.memo(Tabs);

Tabs.propTypes = {
  children: PropTypes.array,
  tenant: PropTypes.object,
  breadcrumbs: PropTypes.element,
  forceTab: PropTypes.string,
  onChange: PropTypes.func,
};
