import React, { useMemo } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Tooltip } from '@mui/material';

import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';

const TenantSubscriptionDetail = ({
  subscription,
  packageType,
  customerJourneyStage,
}) => {
  const isGoCardless = useMemo(
    () => subscription?.PaymentType === 'GoCardless',
    [subscription?.PaymentType],
  );
  const isStripe = useMemo(
    () => subscription?.PaymentType === 'Stripe',
    [subscription?.PaymentType],
  );
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 6, xl: 12 }}
          >
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Payment Type'
                secondary={subscription.PaymentType || '-'}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Payment Status'
                secondary={subscription.PaymentStatus || '-'}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <Tooltip title={subscription.InvoiceEmailAddress}>
                <ListItemText
                  primary='Invoice Email Address'
                  secondary={subscription.InvoiceEmailAddress || '-'}
                  secondaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary={
                  <Tooltip title={'Is Manual Pay by Accountance'}>
                    <Box component={'span'}>Is Manual Pay...</Box>
                  </Tooltip>
                }
                secondary={subscription.IsManualPayByAccountance ? 'YES' : 'NO'}
              />
            </Grid>
          </Grid>

          {isStripe && (
            <>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 12, md: 12, lg: 6, xl: 12 }}
              >
                <Grid item xs={4} sm={6} md={6} lg={3}>
                  <ListItemText
                    primary='Stripe Id'
                    secondary={subscription.StripeId || '-'}
                  />
                </Grid>
                <Grid item xs={4} sm={6} md={6} lg={3}>
                  <ListItemText
                    primary='Sign Up Plan Id'
                    secondary={subscription.SignUpPlanId || '-'}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 6, xl: 12 }}
          >
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Subscription Status'
                secondary={subscription.SubscriptionStatus || '-'}
                secondaryTypographyProps={{
                  textTransform: ' uppercase',
                }}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Subscription Start'
                secondary={
                  subscription.SubscriptionStart
                    ? formatDateFromISO(subscription.SubscriptionStart)
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Subscription End'
                secondary={
                  subscription.SubscriptionEnd
                    ? formatDateFromISO(subscription.SubscriptionEnd)
                    : '-'
                }
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 6, xl: 12 }}
          >
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Sign Up Date'
                secondary={
                  subscription.SignUpDate
                    ? formatDateFromISO(subscription.SignUpDate)
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Package Type'
                secondary={packageType || '-'}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Referral Code'
                secondary={subscription.ReferralCode || '-'}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Number of free user'
                secondary={subscription?.NumberOfFreeUsers}
                secondaryTypographyProps={{
                  sx: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            {isGoCardless && (
              <Grid item xs={4} sm={6} md={6} lg={3}>
                <ListItemText
                  primary='Number of max user'
                  secondary={subscription?.MaximumNumberOfUsers}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
            )}

            {!!subscription?.CancelledOn && (
              <Grid item xs={4} sm={6} md={6} lg={3}>
                <ListItemText
                  primary='Cancelled On'
                  secondary={formatDateFromISO(subscription?.CancelledOn)}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 6, xl: 12 }}
          >
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Account Manager'
                secondary={customerJourneyStage?.AccountManager || '-'}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={3}>
              <ListItemText
                primary='Sale Person'
                secondary={customerJourneyStage?.SalesPerson || '-'}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

TenantSubscriptionDetail.propTypes = {
  subscription: PropTypes.object.isRequired,
  customerJourneyStage: PropTypes.object,
  packageType: PropTypes.string,
};

export default React.memo(TenantSubscriptionDetail);
