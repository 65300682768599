import {
  getFontSizeLabelChart,
  numberToCurrency,
} from '@tenant/utility/helper/Utils';
import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const SMSCostChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height={275}>
      <AreaChart data={data} syncId='smsId'>
        <CartesianGrid strokeDasharray='3 3' stroke='#eee' />
        <XAxis
          dataKey='Key'
          tickLine={false}
          axisLine={false}
          fontSize={getFontSizeLabelChart(data?.length)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) =>
            value ? numberToCurrency(value, 0, 'GBP') : ''
          }
          fontSize={12}
        />
        <Tooltip formatter={(value) => numberToCurrency(value, 2, 'GBP')} />
        <Area
          type='monotone'
          dataKey='Price'
          stroke='#82ca9d'
          strokeWidth={2}
          fill='#82ca9d'
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default React.memo(SMSCostChart);
