import React, { useState, useRef, } from 'react';
import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Stack,
} from '@mui/material';
import { Formik, Form } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PercentIcon from '@mui/icons-material/Percent';
import * as yup from 'yup';

import FormBundleContent from './FormBundleContent';
import { parseCredit } from 'pages/apps/ProductUsage/helpers';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { isDivisibleByHalf } from 'pages/tenantDetail/helpers/Bundles';
import { BUNDLE } from 'pages/tenantDetail/constants';

const BUNDLE_FORM_SCHEMA = yup.object({
  Points: yup
    .number()
    .when('Id', {
      is: (value) => (value === BUNDLE.Topup),
      then: (schema) => schema
        .required('Points must be greater than or equal to 0.5')
        .min(0.5)
        .max(1000)
        .test(
          'divisible_by_half',
          'Please enter the Points value with an increment of 0.5',
          (value) => {
            return (isDivisibleByHalf(value));
          },
        )
    }),
  DiscountPercent: yup
    .number()
    .required('Discounted Percent must be greater than or equal to 0')
    .min(0)
    .max(100)
    .label('Discounted Percent'),
  Quantity: yup
    .number()
    .required('Quantity must be greater than or equal to 1')
    .integer()
    .min(1),
  // DiscountedAmount: yup
  //   .number()
  //   .required('Discounted Amount must be greater than or equal to 0')
  //   .min(0)
  //   .max(yup.ref('Price'))
  //   .test(
  //     'divisible_by_half',
  //     'Please enter the value with a precision of 0.5',
  //     (value) => {
  //       return (isDivisibleByHalf(value));
  //     },
  //   )
  //   .label('Discounted Amount')
});

const FormBundle = ({
  isEdit,
  handleSubmit,
  handleRemoveProduct,
  handleEditProduct,
  bundle,
}) => {
  const formRef = useRef(null);

  const handleAddProduct = () => {
    formRef.current?.submitForm();
  };

  return (
    <>
      {isEdit ? (
        <Formik
          enableReinitialize
          initialValues={bundle}
          validationSchema={BUNDLE_FORM_SCHEMA}
          onSubmit={(form, { setSubmitting, resetForm }) =>
            handleSubmit({ ...form })
          }
          validateOnChange
          validateOnBlur
          validateOnMount={false}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            setTouched,
            setFieldValue,
          }) => (
            <FormBundleContent
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              setTouched={setTouched}
              handleAddProduct={handleAddProduct}
              onRemoveProduct={handleRemoveProduct}
            />
          )}
        </Formik>
      ) : (
        <>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            }}
          >
            {/* Bundle Name */}
            <TableCell sx={{ width: 150, maxWidth: 150, fontSize: 14, }}>{bundle?.Name}</TableCell>

            {/* Bundle Points */}
            <TableCell
              align='right'
              sx={{
                color: 'success.main',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {parseCredit(bundle?.Points)}
            </TableCell>

            {/* Bundle Price */}
            <TableCell
              align='right'
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {numberToCurrency(bundle?.Price ?? 0, 2, 'GBP')}
            </TableCell>

            {/* Quantity */}
            <TableCell
              align='right'
              sx={{
                fontSize: 14,
              }}
            >
              {bundle?.Quantity}
            </TableCell>

            {/* Total Bundle Points */}
            <TableCell
              align='right'
              sx={{
                color: 'success.main',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {parseCredit(bundle?.BundleTotalPoints)}
            </TableCell>

            {/* Total Bundle Prices */}
            <TableCell
              align='right'
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {numberToCurrency(bundle?.BundleTotalPrices ?? 0, 2, 'GBP')}
            </TableCell>

            {/* Discount Value*/}
            <TableCell
              align='right'
              sx={{
                color: 'success.dark',
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent='end'>
                <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
                  {bundle?.DiscountPercent}
                </Typography>
                <PercentIcon fontSize='small' />
              </Stack>
            </TableCell>

            {/* Discounted Price */}
            {/* <TableCell
              align='right'
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {numberToCurrency(bundle?.DiscountedPrices ?? 0, 2, 'GBP')}
            </TableCell> */}

            {/* Action */}
            <TableCell align='right' sx={{ pr: 0 }}>
              <IconButton onClick={() => handleEditProduct(bundle)}>
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton onClick={handleRemoveProduct}>
                <DeleteIcon color='error' fontSize='small' />
              </IconButton>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default FormBundle;
