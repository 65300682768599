import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useToast from '@tenant/utility/hooks/useToast';
import useCompany from '../../hooks/useCompany';

const ReactiveContractConfirm = ({
  visible,
  onClose,
  contractId,
  onCallBackSubmit,
  tenants,
}) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { reactivateTenant } = useCompany();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const onSubmit = useCallback(() => {
    setLoadingBtn(true);
    reactivateTenant({
      contractId,
      onSuccess: () => {
        showSuccessMessage('Reactive contract successfully');
        onClose?.();
        onCallBackSubmit?.(contractId);
      },
      onError: (err) => {
        const message =
          typeof err?.response?.data?.Message === 'string'
            ? err.response.data.Message
            : 'Reactive failed!';
        showErrorMessage(message);
      },
      onFinally: () => {
        setLoadingBtn(false);
      },
    });
  }, [reactivateTenant, contractId]);

  const showTenants = useMemo(() => tenants?.length > 0, [tenants]);

  return (
    <Dialog
      open={visible}
      maxWidth='sm'
      fullWidth
      height={500}
      onClose={onClose}
    >
      <DialogTitle fontSize={16}>Reactive Contract?</DialogTitle>

      <DialogContent mt={4}>
        <DialogContentText id='alert-dialog-description'>
          All the Tenants of the Contract will be reactive. Are you certain that
          you want to proceed with this process?
        </DialogContentText>
        {showTenants && (
          <Box mt={2}>
            <Typography variant='h5' component='div'>
              Tenants will be reactive:
            </Typography>
            <List dense>
              {tenants?.map((tenant) => (
                <ListItem key={tenant.TenantId}>
                  <ListItemText
                    primary={
                      <Link
                        href={`/tenant/detail/${tenant.TenantId}`}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none' }}
                      >
                        {tenant.ContactName}
                      </Link>
                    }
                    secondary={tenant.TenantId}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loadingBtn}
          variant='contained'
          onClick={onSubmit}
        >
          Reactive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ReactiveContractConfirm);

ReactiveContractConfirm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCallBackSubmit: PropTypes.func,
  contractId: PropTypes.string,
  tenants: PropTypes.array,
};
