import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

function ConfirmPopup(props) {
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    children,
    textCancel = 'Cancel',
    textConfirm = 'Confirm',
    loading,
    ...rest
  } = props;
  const handleCloseDialog = (event, reason) => {
    if(reason === 'backdropClick') {
      return;
    }
    handleClose();
  }
  return (
    <div>
      <Dialog open={open} onClose={handleCloseDialog} {...rest}>
        <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions sx={{px: 6, py:4}}>
          <LoadingButton loading={loading} color='error' onClick={handleClose}>
            {textCancel}
          </LoadingButton>
          <LoadingButton loading={loading} onClick={handleConfirm} variant='contained'>
            {textConfirm}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmPopup;
