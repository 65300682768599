import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tooltip,
  Grid,
  Stack,
  styled,
  ListItemText,
  ListItem,
  Divider,
} from '@mui/material';
import { calculatorDiscountNumber } from '../../helpers/calculator';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import IntlMessages from '@tenant/utility/IntlMessages';

const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(4),
}));

const columns = [
  {
    field: 'Item',
    headerName: 'Item',
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography noWrap variant='body2'>
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'Description',
    headerName: 'Description',
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography noWrap variant='body2'>
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'UnitAmount',
    headerName: 'Unit Amount',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2'>{params.value}</Typography>
    ),
  },
  {
    field: 'Quantity',
    headerName: 'Quantity',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2'>{params.value}</Typography>
    ),
  },
  {
    field: 'DiscountValue',
    headerName: 'Discount Value',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='success.main'>
        {!!params.value
          ? params.value
          : (!!params.row.DiscountPercent
            ? `${params.row.DiscountPercent}%`
            : 0
          ) ?? 0}
      </Typography>
    ),
  },
  {
    field: 'TaxRateValue',
    headerName: 'Tax Rate Value',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='error.main'>
        {params.value + '%'}
      </Typography>
    ),
  },
  {
    field: 'TaxAmount',
    headerName: 'Tax Amount',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='error.main'>
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'Amount',
    headerName: 'Amount',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography
        variant='body2'
        color='info.main'
      >
        {params.value}
      </Typography>
    ),
  },
];

const PointBundles = ({ data, loading }) => {

  const summary = useMemo(
    () => {
      const subTotal = data.reduce((total, item) => (
        total + parseFloat(item.Amount)
      ), 0);

      const totalDiscount = data.reduce((total, item) => {
        const discountValue = item.DiscountValue > 0
          ? calculatorDiscountNumber(
            item.DiscountValue,
            item.Amount,
          ) : calculatorDiscountNumber(
            item.DiscountPercent + '%',
            item.Amount,
          );
        return total + discountValue;
      }, 0);

      const totalTaxAmount = data.reduce((total, item) => (
        total + parseFloat(item.TaxAmount)
      ), 0);

      const totalAmount = subTotal - totalDiscount + totalTaxAmount;

      return {
        subTotal,
        totalDiscount,
        totalTaxAmount,
        totalAmount,
      };
    },
    [data]
  );

  return (
    <>
      <Box sx={{ height: 460 }}>
        <Grid item container xs={12} justifyContent={'flex-end'}>
          <Grid item xs={12}>
            <TableChart
              isLoading={loading}
              columns={columns}
              rows={data ?? []}
              uniqueProp={'Id'}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <StyledStack direction='column'>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'primary.main'
                    }}
                  >
                    {numberToCurrency(summary?.subTotal ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText primary={<IntlMessages id='bundle.subTotal' />} />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'success.main'
                    }}
                  >
                    {numberToCurrency(summary?.totalDiscount ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText primary={<IntlMessages id='bundleContract.discount' />} />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'error.main',
                    }}
                  >
                    {numberToCurrency(summary?.totalTaxAmount ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText primary={<IntlMessages id='contractLayer.VAT' />} />
              </ListItem>

              <Divider variant='middle' />

              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                    }}
                  >
                    {numberToCurrency(summary?.totalAmount ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText
                  primary={<IntlMessages id='common.total' />}
                  primaryTypographyProps={{
                    fontWeight: 500
                  }}
                />
              </ListItem>
            </StyledStack>
          </Grid>

        </Grid>
      </Box>
    </>
  );
};

export default PointBundles;

PointBundles.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
